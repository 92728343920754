import { NgTemplateOutlet } from '@angular/common';
import { Component, ContentChild, Input, TemplateRef } from '@angular/core';

import { QPointTabNavDetailTemplateDirective } from './tab-nav-detail-template.directive';

@Component({
  selector: 'qpoint-tab-nav-item',
  template: '',
  standalone: true,
  imports: [NgTemplateOutlet]
})
export class QPointTabNavItemComponent{
  @ContentChild(QPointTabNavDetailTemplateDirective, {read: TemplateRef}) detailTemplate;
  @Input()
  public id: string;

  @Input()
  public label: string;

  @Input()
  public icon: string;

  @Input()
  public destroyOnHide: boolean = true;
}
