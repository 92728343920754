import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Inject,
  InjectionToken,
  Input,
  Optional,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ColorPickerComponent, ColorPickerModule, ColorPickerView, OutputFormat, PaletteSettings } from '@progress/kendo-angular-inputs';

export const DI_QPOINT_COLOR_PICKER_SETTINGS = new InjectionToken<Partial<QPointColorPickerSettings>>('QPointColorPickerSettings');
export const QPOINT_COLOR_PICKER_PALETTE = [
  '#FCE4EC', '#EDE7F6', '#E3F2FD', '#E1F5FE', '#E0F7FA', '#E8F5E9', '#F9FBE7', '#FFFDE7', '#FFF8E1', '#FBE9E7'
  , '#F8BBD0', '#D1C4E9', '#BBDEFB', '#B3E5FC', '#B2EBF2', '#C8E6C9', '#F0F4C3', '#FFF9C4', '#FFECB3', '#FFCCBC'
  , '#F48FB1', '#B39DDB', '#90CAF9', '#81D4FA', '#80DEEA', '#A5D6A7', '#E6EE9C', '#FFF59D', '#FFE082', '#FFAB91'
  , '#F06292', '#9575CD', '#64B5F6', '#4FC3F7', '#4DD0E1', '#81C784', '#DCE775', '#FFF176', '#FFD54F', '#FF8A65'
];

export const QPOINT_COLOR_PICKER_DEFAULT = '#B3E5FC';

export interface QPointColorPickerSettings {
  paletteSettings: PaletteSettings;
  defaultColor: string;
  views: ColorPickerView[];
  activeView: ColorPickerView;
  format: OutputFormat;
  autoClose?: boolean;
}

@Component({
  selector: 'qpoint-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QPointColorPickerComponent),
      multi: true,
    }
  ],
  imports: [
    ColorPickerModule
  ],
  standalone: true
})
export class QPointColorPickerComponent {
  @Input() value = this.colorPickerSettings?.defaultColor || QPOINT_COLOR_PICKER_DEFAULT;
  @Input() paletteSettings: PaletteSettings = this.colorPickerSettings?.paletteSettings || {
    palette: QPOINT_COLOR_PICKER_PALETTE
  };
  @Input() clearButton = false;
  @Input() format: OutputFormat = this.colorPickerSettings?.format || 'hex';
  @Input() views: ColorPickerView[] = this.colorPickerSettings?.views || ['palette'];
  @Input() activeView: ColorPickerView = this.colorPickerSettings?.activeView || 'palette';
  @Input() disabled: boolean;
  @Input() autoClose = this.colorPickerSettings?.autoClose || true;

  @ViewChild(ColorPickerComponent) colorPicker: ColorPickerComponent;

  constructor(@Optional() @Inject(DI_QPOINT_COLOR_PICKER_SETTINGS) private colorPickerSettings: Partial<QPointColorPickerSettings>,
              private cdr: ChangeDetectorRef) {
  }

  public onChange = (_: any) => {
  };
  public onTouched = () => {
  };

  writeValue(obj: string): void {
    if (obj == null) {
      this.value = this.colorPickerSettings?.defaultColor || QPOINT_COLOR_PICKER_DEFAULT;
    } else {
      this.value = obj;
    }
    this.onChange(this.value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }

  autoCloseColorPicker() {
    if (this.autoClose) {
      this.colorPicker.toggle(false);
    }
  }

}
