import { Component, OnInit } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DeliveryNoteTransferDemoComponent } from './delivery-note-transfer-demo/delivery-note-transfer-demo.component';
import { NumericTextBoxModule } from '@progress/kendo-angular-inputs';
import { NullValueDirectiveComponent } from './null-value-directive/null-value-directive.component';
import { ControlGalleryMultipleEmailsInputComponent } from './multiple-emails-input/multiple-emails-input.component';
import { ControlGallerySuggestListComponent } from './suggest-list/suggest-list.component';
import { ControlGalleryAsyncDropDownComponent } from './async-drop-down/async-drop-down.component';
import { ControlGalleryDropDownListComponent } from './drop-down-list/drop-down-list.component';
import { QPointButtonComponent } from '../../../../projects/qpoint-button/src/lib/button/button.component';
import { NgIf, JsonPipe } from '@angular/common';
import { QPointFormGroupComponent, QPointFormLabelComponent, QPointReactiveFormInputGroupComponent } from '@qpoint/forms';
import { QPointTimePickerUnitDirective } from '../../../../projects/qpoint-forms/src/lib/time-picker/time-picker-unit';
import { QPointTimepickerAutocompleteDirective } from '../../../../projects/qpoint-forms/src/lib/time-picker/time-picker-autocomplete';
import { TimePickerModule } from '@progress/kendo-angular-dateinputs';
import { DateTimePickerDemoComponent } from './datetime-picker-demo/datetime-picker-demo.component';
import { MultiViewDateRangePickerDemoComponent } from './multi-view-date-range-picker-demo/multi-view-date-range-picker-demo.component';
import { ColorPickerDemoComponent } from './color-picker-demo/color-picker-demo.component';
import { QPointToolbarComponent, QPointContentContainerComponent } from '@qpoint/layout';

@Component({
    selector: 'app-control-gallery-form',
    templateUrl: './form.component.html',
    standalone: true,
    imports: [
        QPointToolbarComponent,
        QPointContentContainerComponent,
        ColorPickerDemoComponent,
        MultiViewDateRangePickerDemoComponent,
        DateTimePickerDemoComponent,
        TimePickerModule,
        QPointTimepickerAutocompleteDirective,
        QPointTimePickerUnitDirective,
        FormsModule,
        ReactiveFormsModule,
        QPointFormGroupComponent,
        QPointFormLabelComponent,
        NgIf,
        QPointReactiveFormInputGroupComponent,
        QPointButtonComponent,
        ControlGalleryDropDownListComponent,
        ControlGalleryAsyncDropDownComponent,
        ControlGallerySuggestListComponent,
        ControlGalleryMultipleEmailsInputComponent,
        NullValueDirectiveComponent,
        NumericTextBoxModule,
        DeliveryNoteTransferDemoComponent,
        JsonPipe,
    ],
})
export class ControlGalleryFormComponent implements OnInit {
  public form: UntypedFormGroup;
  public validatorsUpdated = false;
  public messages = {
    email: 'E-Mail Adresse fehlerhaft',
    required: 'Feld ist ein Pflichfeld',
  };
  public submitted = false;
  dateMin = new Date('2023-10-09T22:01:00.000Z')
  timePickerFc = new FormControl();

  get email() {
    return this.form.get('email') as UntypedFormControl;
  }

  constructor(private formBuilder: UntypedFormBuilder) {
    this.timePickerFc.valueChanges.subscribe(
      (value) => {
        console.log("timepickerfc: ", value);
      }
    )
  }

  public get number() {
    return this.form.get('number');
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.maxLength(10), Validators.minLength(5)]],
      description: ['', [Validators.required, Validators.minLength(10)]],
      number: [null, Validators.required]
    });
  }

  public get name() {
    return this.form.get('name');
  }

  onSubmit() {
    this.submitted = true;
  }

  onReset() {
    this.submitted = false;
    this.form.reset();
  }

  updateValidators() {
    const field = this.form.get('name');

    if (this.validatorsUpdated) {
      field.setValidators([]);
    } else {
      field.setValidators([Validators.required]);
    }

    this.validatorsUpdated = !this.validatorsUpdated;

    field.updateValueAndValidity();
  }

  onBlur() {
    console.log("TIME PICKER BLUR!")
  }
}
