import { EnvironmentProviders, inject, InjectionToken, makeEnvironmentProviders, Type } from '@angular/core';

export interface IQPointMapsApiKeyService {
  getGoogleMapsApiKey(): Promise<string>;
}

export const DI_MAPS_API_KEY_SERVICE = new InjectionToken<IQPointMapsApiKeyService>('QPointMapsApiKeyService');

export function provideMapsApiKeyService(useClass: Type<IQPointMapsApiKeyService>): EnvironmentProviders {
  return makeEnvironmentProviders([{
    provide: DI_MAPS_API_KEY_SERVICE,
    useClass
  }])
}

export function injectMapsApiKeyService(): IQPointMapsApiKeyService {
  return inject(DI_MAPS_API_KEY_SERVICE);
}
