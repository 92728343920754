<div style="position: relative">

  <kendo-splitbutton [ngClass]="classes" (buttonClick)="onClick($event)" [data]="popUpItems" (open)="onOpen($event)"
    (close)="onClose($event)" [arrowButtonClass]="arrowButtonClass" [buttonClass]="mainButtonClass"
    [popupSettings]="popupSettings" #kendoSplitButton>

    <div style="position: relative; display: flex; align-items: center">
      <ng-container *ngIf="icon">
        <qpoint-icon *ngIf="!loading" [icon]="icon" [iconType]="iconType"></qpoint-icon>
        <qpoint-loading-spinner style="min-width: 15px" [variant]="'dark'" [inline]="true" *ngIf="loading">
        </qpoint-loading-spinner>
      </ng-container>
      <div class="spinner-wrapper" *ngIf="loading && !icon">
        <qpoint-loading-spinner [inline]="true" *ngIf="loading"></qpoint-loading-spinner>
      </div>
      <span style="white-space: nowrap">{{ text }}</span>
    </div>
  </kendo-splitbutton>
</div>
