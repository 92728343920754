import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

export type QPointModalOptions = NgbModalOptions;
export class QPointModalRef<T = any> extends NgbModalRef {
  override get componentInstance(): T {
    return super.componentInstance;
  }
}

@Injectable({
  providedIn: 'root'
})
export class QPointModalService extends NgbModal {
  private readonly defaultOptions: NgbModalOptions = {
    windowClass: 'qpoint-dialog',
    size: 'lg',
    backdrop: 'static',
    centered: true,
  };
  private globalOptions: NgbModalOptions = {...this.defaultOptions};

  override open<T = any>(content: any, options?: QPointModalOptions): QPointModalRef<T> {
    return super.open(content, {...this.defaultOptions, ...this.globalOptions, ...options}) as QPointModalRef<T>;
  }

  setGlobalConfig(options: NgbModalOptions) {
    this.globalOptions = {...options};
  }
}
