import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonSize, ButtonType } from '../button/button.component';
import { CommonModule } from '@angular/common';
import { QPointIconModule } from '@qpoint/icons';

/**
 * @Deprecated use qpoint-button with only icon instead
 */
@Component({
  selector: 'qpoint-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    QPointIconModule
  ]
})
export class DeprecatedQPointIconButtonComponent {
  @Input() icon = null;
  @Input() active = false;
  @Input() title = null;
  @Input() loading = false;
  @Input() disabled = false;
  @Input() type: ButtonType = 'button';
  @Input() variant: 'primary' | 'danger' | 'success' | 'warn' = 'primary';
  @Input() size: ButtonSize = ButtonSize.default;
  @Output() buttonClicked = new EventEmitter<Event>();

  get isDisabled() {
    return this.disabled || this.loading;
  }

  public onClick($event) {
    if (!this.isDisabled) {
      this.buttonClicked.emit($event);
    } else {
      $event.preventDefault();
      $event.stopPropagation();
    }
  }

  get classes(): string[] {
    const classes = ['qpoint-button', 'btn', 'btn-icon', 'btn-icon-only', 'btn-variant-' + this.variant];

    if (this.loading) {
      classes.push('btn-loading');
    }

    if (this.active) {
      classes.push('active');
    }

    switch (this.size) {
      case ButtonSize.lg:
        classes.push('btn-lg');
        break;
      case ButtonSize.sm:
        classes.push('btn-sm');
        break;
    }

    return classes;
  }
}
