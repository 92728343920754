import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ScrollBottomEvent, SelectAllCheckboxState } from '@progress/kendo-angular-grid';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { BaseGridDirective } from './base-grid.directive';


export interface ColumnOrderPosition {
  name: string;
  width: number;
  hidden: boolean;
  sort: 'asc' | 'desc';
  sortOrder: number
}

export interface IVirtualScrollSettings {
  orderBy?: string[];
  skip?: number;
  take?: number;
}

export interface QPointScrollBottomEvent {
  currentItemCount: number;
  currentOrder: string[];

  loadingFinishCallback(): boolean;
}

export interface QPointSelectAllEvent {
  checkboxState: SelectAllCheckboxState;
  loadingFinishCallback(): void;
}

export interface QPointKeydownEndEvent {
  event: KeyboardEvent;
  scrollToBottom: () => void;
}

export declare type ItemNeedSpecialAttentionFn = (dataItem: any) => boolean | {
  [key: string]: any;
};
@Component({
  selector: 'qpoint-grid',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./grid.component.scss'],
  templateUrl: './grid.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QPointGridComponent extends BaseGridDirective implements OnInit {

  /***
   * if enabled and scrollmode == 'virtual', the scrollBottomCallback event emitter will be triggered
   */
  @Input()
  public infiniteScroll = false;

   /***
   * Scrollmode of the grid
   */
  @Input()
  public scrollable: 'none' | 'scrollable' | 'virtual' = 'scrollable';

  /***
   * Scrolled bottom event if virtual scroll + infinite scroll are active
   */
  @Output()
  public scrolledBottom = new EventEmitter<QPointScrollBottomEvent>();

  /***
   * Debounce scroll Bottom event to prevent multiple scrollbottom event emits
   */
  private scrollBottomEventDebounceSubject: Subject<QPointScrollBottomEvent> = new Subject<QPointScrollBottomEvent>();

  /***
   * Hide the most right column containing the context menu
   */
  @Input()
  public hideContextMenuColumn = false;

  ngOnInit() {
    super.ngOnInit();
    this.scrollBottomEventDebounceSubject.pipe(takeUntil(this.ngUnsubscribe), debounceTime(200)).subscribe(value => {
      this.scrolledBottom.emit(value);
    });
  }

  /***
   * @ignore
   *
   * @param $event
   */
  public kendoGridScrolledBottom($event: ScrollBottomEvent) {
    if (this.infiniteScroll && (!this.totalItemCount || this.dataInput.length < this.totalItemCount)) {
      this.isDataLoading = true;
      if (this.scrollable === 'virtual') {
        const event = {} as QPointScrollBottomEvent;
        event.currentOrder = this.virtualScrollSettings.orderBy;
        event.currentItemCount = this.dataInput.length;
        event.loadingFinishCallback = () => {
          this.isDataLoading = false;
          return true;
        };
        this.scrollBottomEventDebounceSubject.next(event);
      }
    }
  }
}
