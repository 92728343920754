<qpoint-toolbar [title]="'Icons'"></qpoint-toolbar>

<qpoint-content-container>
  <!-- Icons -->
  <div class="card">
    <div class="card-header">Font Awesome Icons</div>
    <div class="card-body">
      <div class="row">
        <div class="col-3 d-flex">
          <qpoint-icon icon="address-card"></qpoint-icon>
          <span>address-card solid</span>
        </div>
        <div class="col-3 d-flex">
          <qpoint-icon icon="address-card" iconType="regular"></qpoint-icon>
          <span>address-card regular</span>
        </div>
        <div class="col-3 d-flex">
          <qpoint-icon icon="spinner" iconType="solid" pulse="true"></qpoint-icon>
          <span>spinner solid pulse</span>
        </div>
        <div class="col-3 d-flex">
          <qpoint-icon icon="address-card" iconType="solid" spin="true"></qpoint-icon>
          <span>address-card solid spin</span>
        </div>
      </div>
      <div class="row">
        <div class="col-3 d-flex">
          <qpoint-icon icon="address-card" iconType="solid" border="true"></qpoint-icon>
          <span>address-card solid border</span>
        </div>
        <div class="col-3 d-flex" style="background: black;">
          <qpoint-icon icon="address-card" iconType="solid" inverse="true"></qpoint-icon>
          <span style="color: white">address-card regular inverse</span>
        </div>
        <div class="col-3 d-flex">
          <qpoint-icon icon="address-card" iconType="solid" flip="horizontal"></qpoint-icon>
          <span>address-card solid horizontal flipped</span>
        </div>
        <div class="col-3 d-flex">
          <qpoint-icon icon="address-card" iconType="solid" rotate="90"></qpoint-icon>
          <span>address-card solid rotated 90 degrees</span>
        </div>
      </div>
      <div class="row">
        <div class="col-3 d-flex">
          <qpoint-icon icon="address-card" iconType="light"></qpoint-icon>
          <span>address-card light</span>
        </div>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="card-header">Font Awesome Pro Icons</div>
    <div class="card-body">
      <div class="row">
        <div class="col-3 d-flex">
          <qpoint-icon icon="projector" iconType="regular"></qpoint-icon>
          <span>projector regular</span>
        </div>
        <div class="col-3 d-flex">
          <qpoint-icon icon="projector" iconType="solid"></qpoint-icon>
          <span>projector solid</span>
        </div>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="card-header">Q Point Icons</div>
    <div class="card-body">
      <div class="row">
        <div class="col-3 d-flex">
          <qpoint-icon iconType="qpoint" icon="mixing-plant"></qpoint-icon>
          <span>mixing-plant</span>
        </div>
        <div class="col-3 d-flex">
          <qpoint-icon iconType="qpoint" icon="show-details"></qpoint-icon>
          <span>show-details</span>
        </div>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="card-header">Material Icons</div>
    <div class="card-body">
      <div class="row">
        <div class="col-3 d-flex">
          <qpoint-icon iconType="material" icon="face"></qpoint-icon>
          <span>face</span>
        </div>
        <div class="col-3 d-flex">
          <qpoint-icon iconType="material" icon="cloud_queue"></qpoint-icon>
          <span>cloud_queue</span>
        </div>
        <div class="col-3 d-flex">
          <qpoint-icon iconType="material" icon="apps"></qpoint-icon>
          <span>apps</span>
        </div>
      </div>
      <div class="row">
        <div class="col-2 d-flex">
          <qpoint-icon iconType="material" icon="monetization_on"></qpoint-icon>
          <span>filled</span>
        </div>
        <div class="col-2 d-flex">
          <qpoint-icon iconType="material-outlined" icon="monetization_on"></qpoint-icon>
          <span>outlined</span>
        </div>
        <div class="col-2 d-flex">
          <qpoint-icon iconType="material-round" icon="monetization_on"></qpoint-icon>
          <span>round</span>
        </div>
        <div class="col-2 d-flex">
          <qpoint-icon iconType="material-two-tone" icon="monetization_on"></qpoint-icon>
          <span>two-tone</span>
        </div>
        <div class="col-2 d-flex">
          <qpoint-icon iconType="material-sharp" icon="monetization_on"></qpoint-icon>
          <span>sharp</span>
        </div>
      </div>
    </div>
  </div>
</qpoint-content-container>
