export const MENU = [
  {
    id: 'main',
    path: 'main',
    data: {
      menu: {
        title: 'Main',
        icon: 'database',
        selected: true,
        expanded: false,
        order: 0
      }
    }
  },
  {
    id: 'grid',
    path: 'grid',
    data: {
      menu: {
        title: 'Grid',
        icon: 'mixing-plant',
        iconType: 'qpoint',
        selected: false,
        expanded: false,
        order: 0
      }
    }
  },
  {
    id: 'buttons',
    path: ['buttons'],
    data: {
      menu: {
        title: 'Buttons',
        icon: 'link',
        selected: false,
        expanded: false,
        order: 0
      }
    }
  },
  {
    id: 'dropdowns',
    path: ['dropdowns'],
    data: {
      menu: {
        title: 'Dropdowns',
        icon: 'list',
        selected: false,
        expanded: false,
        order: 0
      }
    }
  },
  {
    id: 'directives',
    path: ['directives'],
    data: {
      menu: {
        title: 'Directives',
        icon: 'link',
        selected: false,
        expanded: false,
        order: 0
      }
    }
  },
  {
    id: 'loading-spinners',
    path: ['loading-spinners'],
    data: {
      menu: {
        title: 'Loading Spinners',
        icon: 'spinner',
        selected: false,
        expanded: false,
        order: 0
      }
    }
  },
  {
    id: 'maps',
    path: ['maps'],
    data: {
      menu: {
        title: 'Maps',
        icon: 'map-marked-alt',
        selected: false,
        expanded: false,
        order: 0
      }
    }
  },
  {
    id: 'forms',
    path: ['forms'],
    data: {
      menu: {
        title: 'Forms',
        icon: 'file-alt',
        selected: false,
        expanded: false,
        order: 0
      }
    }
  },
  {
    id: 'form-validation',
    path: ['form-validation'],
    data: {
      menu: {
        title: 'Form Validation Demo',
        icon: 'file-alt',
        selected: false,
        expanded: false,
        order: 0
      }
    }
  },
  {
    id: 'calendar',
    path: ['calendar'],
    data: {
      menu: {
        title: 'Calendar',
        icon: 'calendar',
        selected: false,
        expanded: false,
        order: 0
      }
    }
  },
  {
    id: 'date-formatting',
    path: ['date-formatting'],
    data: {
      menu: {
        title: 'Date Formatting',
        icon: 'calendar',
        selected: false,
        expanded: false,
        order: 0
      }
    }
  },
  {
    id: 'modal',
    path: ['modal'],
    data: {
      menu: {
        title: 'Modal',
        icon: 'window',
        selected: false,
        expanded: false,
        order: 0
      }
    }
  },
  {
    id: 'Lazy-Loading',
    path: 'lazy-loading',
    data: {
      menu: {
        title: 'Lazy-Loading',
        icon: 'th',
        selected: false,
        expanded: false,
        order: 0
      }
    }
  },
  {
    id: 'gallery',
    data: {
      menu: {
        title: 'Gallery',
        icon: 'credit-card',
        selected: false,
        expanded: false,
        order: 0
      }
    },
    children: [
      {
        id: 'gallery.tabnavigation',
        path: 'tabnavigation',
        data: {
          menu: {
            title: 'Tab Navigation',
            icon: 'credit-card',
            selected: false,
            expanded: false,
            order: 0
          }
        }
      },
      {
        id: 'gallery.tablerows',
        path: 'tablerows',
        data: {
          menu: {
            title: 'Table rows',
            icon: 'credit-card',
            selected: false,
            expanded: false,
            order: 0
          }
        }
      },
      {
        id: 'gallery.descriptions',
        path: 'descriptions',
        data: {
          menu: {
            title: 'Descriptions',
            icon: 'credit-card',
            selected: false,
            expanded: false,
            order: 0
          }
        }
      },
      {
        id: 'gallery.selections',
        path: 'selections',
        data: {
          menu: {
            title: 'Selections',
            icon: 'credit-card',
            selected: false,
            expanded: false,
            order: 0
          }
        }
      },
      {
        id: 'gallery.progressbars',
        path: 'progressbars',
        data: {
          menu: {
            title: 'Progress bars',
            icon: 'credit-card',
            selected: false,
            expanded: false,
            order: 0
          }
        }
      },
      {
        id: 'gallery.notifications',
        path: 'notifications',
        data: {
          menu: {
            title: 'Notifications',
            icon: 'credit-card',
            selected: false,
            expanded: false,
            order: 0
          }
        }
      },
      {
        id: 'gallery.lists',
        path: 'lists',
        data: {
          menu: {
            title: 'Lists',
            icon: 'credit-card',
            selected: false,
            expanded: false,
            order: 0
          }
        }
      },
      {
        id: 'gallery.icons',
        path: 'icons',
        data: {
          menu: {
            title: 'Icons',
            icon: 'credit-card',
            selected: false,
            expanded: false,
            order: 0
          }
        }
      },
      {
        id: 'gallery.inputs',
        path: 'inputs',
        data: {
          menu: {
            title: 'Inputs',
            icon: 'credit-card',
            selected: false,
            expanded: false,
            order: 0
          }
        }
      },
      {
        id: 'gallery.headings',
        path: 'headings',
        data: {
          menu: {
            title: 'Headings',
            icon: 'credit-card',
            selected: false,
            expanded: false,
            order: 0
          }
        }
      },
      {
        id: 'gallery.reactiveInput',
        path: 'reactiveInput',
        data: {
          menu: {
            title: 'Reactive Inputs',
            icon: 'credit-card',
            selected: false,
            expanded: false,
            order: 0
          }
        }
      },
      {
        id: 'gallery.dropzone',
        path: 'dropzone',
        data: {
          menu: {
            title: 'Dropzone',
            icon: 'credit-card',
            selected: false,
            expanded: false,
            order: 0
          }
        }
      },
      {
        id: 'gallery.upload',
        path: 'upload',
        data: {
          menu: {
            title: 'Upload',
            icon: 'credit-card',
            selected: false,
            expanded: false,
            order: 0
          }
        }
      },
      {
        id: 'gallery.pdf',
        path: 'pdf',
        data: {
          menu: {
            title: 'Pdf',
            icon: 'credit-card',
            selected: false,
            expanded: false,
            order: 0
          }
        }
      },
      {
        id: 'gallery.info-message',
        path: 'info-message',
        data: {
          menu: {
            title: 'Info Messages',
            icon: 'credit-card',
            selected: false,
            expanded: false,
            order: 0
          }
        }
      }
    ]
  },
  {
    id: 'accordion',
    path: 'accordion',
    data: {
      menu: {
        title: 'Accordion',
        icon: 'folder-open',
        selected: false,
        expanded: false,
        order: 0
      }
    }
  },
  {
    id: 'pdfdrawing',
    path: 'pdf-drawing',
    data: {
      menu: {
        title: 'PDF drawing',
        icon: 'folder-open',
        selected: false,
        expanded: false,
        order: 0
      }
    }
  },
  {
    id: 'legal',
    path: 'legal',
    data: {
      menu: {
        title: 'Legal',
        icon: 'database',
        selected: false,
        expanded: false,
        order: 0
      }
    }
  },
  {
    id: 'MenuTest',
    path: 'MenuTest',
    data: {
      menu: {
        title: 'St. Gallen',
        translate: false,
        icon: 'database',
        selected: false,
        expanded: false,
        order: 0
      }
    }
  },
];
