import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef, ViewEncapsulation } from '@angular/core';
import { IconType, QPointIconModule } from '@qpoint/icons';

import { QPointInfoMessageTextDirective } from './info-message-text.directive';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { QPointButtonComponent } from '@qpoint/buttons';

export enum MessageTypeEnum {
  INFO = 'info',
  WARN = 'warn',
  CANCEL = 'cancel',
  ORDER = 'order',
  REQUEST = 'request',
  ERROR = 'error',
  VALIDATION = 'validation'

}
export interface InfoMessageCallback {
  callbackFn: () => unknown;
  title: string;
  icon?: string;
}

@Component({
  selector: 'qpoint-info-message',
  templateUrl: './info-message.component.html',
  styleUrls: ['./info-message.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [
    CommonModule,
    QPointIconModule,
    TranslateModule,
    RouterModule,
    QPointButtonComponent
  ],
  standalone: true
})
export class QPointInfoMessageComponent {

  MessageType = MessageTypeEnum;
  @Input()
  public severity: MessageTypeEnum = MessageTypeEnum.INFO;
  @Input()
  public icon: string;
  @Input()
  public iconType: IconType;
  @Input()
  public text: string;
  @Input()
  public bold = true;
  @Input()
  public displayBorder = true;
  @Input()
  public callback: InfoMessageCallback;
  @Input()
  public displayAsButton = false;
  @Input()
  public isEllipsisRedirect = false;
  @Input()
  public isEllipsisButton = false;
  @Input() borderType: 'solid' | 'striped' = 'solid';
  @Output() bannerClicked = new EventEmitter();
  @ContentChild(QPointInfoMessageTextDirective, { read: TemplateRef })
  textTemplate;

  public onBannerClicked() {
    this.bannerClicked.emit();
  }

  callbackClicked() {
    this.callback.callbackFn();
  }
}
