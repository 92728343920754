import { Injectable } from '@angular/core';
import { IQPointLocalizationNamespacesService } from '@qpoint/i18n';

@Injectable({
  providedIn: 'root'
})
export class LocalizationNamespaceService implements IQPointLocalizationNamespacesService {

  constructor() { }

  getNamespaces(): string[] {
    return [];
  }
}
