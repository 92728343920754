import { Injectable, OnDestroy } from '@angular/core';
import { Router, Routes } from '@angular/router';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root'
})
export class QPointSidebarService implements OnDestroy {
  menuItems = new BehaviorSubject<any[]>([]);
  public isMenuCollapsed: boolean;
  public isMenuTemporaryExpanded = false;
  public menuCollapsedChanged = new BehaviorSubject<boolean>(false);
  public menuTemporaryExpandedChanged = new BehaviorSubject<boolean>(false);
  public menuActiveItemChanged = new BehaviorSubject<any>(null);
  /*
  subject to check loading indicators on sidebar component
   */
  public sidebarHeightChanged = new Subject<void>();
  public routeBadgeObservableMap = new Map<string, Observable<number>>();
  public routeBadgeAnimationMap = new Map<string, Observable<boolean>>();
  /**
   * If one would like to mark a route as active without matching the exact url
   * e.g [/, portal , orders , id] is active but you want [/, portal, projects] as active menu item
   */
  public staticActiveRoute: string[];
  protected currentMenuItem = {};

  constructor(private router: Router) {
    this.menuCollapsedChanged
      .pipe(
        takeUntilDestroyed()
      )
      .subscribe(isCollapsed => {
      this.isMenuCollapsed = isCollapsed;
    });
    this.menuTemporaryExpandedChanged
      .pipe(
        takeUntilDestroyed()
      )
      .subscribe(isTemporaryExpanded => {
      this.isMenuTemporaryExpanded = isTemporaryExpanded;
    });
  }

  ngOnDestroy(): void {
    this.menuCollapsedChanged.complete();
    this.menuTemporaryExpandedChanged.complete();
    this.menuActiveItemChanged.complete();
    this.sidebarHeightChanged.complete();
  }

  /***
   * Add a subject to menu item with route id to display number badge
   * @param routeId
   * @param numberSubject
   */
  public registerBadgeSubject(routeId: string, numberSubject: Subject<number>) {
    this.routeBadgeObservableMap.set(routeId, numberSubject);
  }

  public registerBadgeAnimationSubject(routeId: string, booleanSubject: Subject<boolean>) {
    this.routeBadgeAnimationMap.set(routeId, booleanSubject);
  }

  public updateMenuByRoutes(routes: Routes) {
    const convertedRoutes = this.convertRoutesToMenus(_.cloneDeep(routes));
    this.menuItems.next(convertedRoutes);
  }

  public toggleSidebar() {
    this.isMenuCollapsed = !this.isMenuCollapsed;
    this.menuCollapsedChanged.next(this.isMenuCollapsed);
  }

  public temporaryExpand() {
    if (!this.isMenuTemporaryExpanded) {
      this.isMenuTemporaryExpanded = true;
      this.menuTemporaryExpandedChanged.next(this.isMenuTemporaryExpanded);
    }
  }

  public endTemporaryExpand() {
    if (this.isMenuTemporaryExpanded) {
      this.isMenuTemporaryExpanded = false;
      this.menuTemporaryExpandedChanged.next(this.isMenuTemporaryExpanded);
    }
  }

  public convertRoutesToMenus(routes: Routes): any[] {
    const items = this.convertArrayToItems(routes);
    return this.skipEmpty(items);
  }

  public getCurrentItem(): any {
    return this.currentMenuItem;
  }

  public selectMenuItem(menuItems: any[], parent?: any): any[] {
    const items = [];
    menuItems.forEach(item => {
      this.selectItem(item);

      if (item.selected) {
        this.currentMenuItem = item;
        if (parent) {
          parent.expanded = true;
          parent.selected = true;
        }
      }

      if (item.children && item.children.length > 0) {
        item.children = this.selectMenuItem(item.children, item);
      }
      items.push(item);
    });
    return items;
  }

  public notifyActiveMenuItemChanged() {
    this.menuActiveItemChanged.next(this.getCurrentItem());
  }

  protected skipEmpty(items: any[]): any[] {
    const menu = [];
    items.forEach(item => {
      let menuItem;
      if (item.skip) {
        if (item.children && item.children.length > 0) {
          menuItem = item.children;
        }
      } else {
        menuItem = item;
      }

      if (menuItem) {
        menu.push(menuItem);
      }
    });

    return [].concat.apply([], menu);
  }

  protected convertArrayToItems(routes: any[], parent?: any): any[] {
    const items = [];
    routes.forEach(route => {
      items.push(this.convertObjectToItem(route, parent));
    });
    return items;
  }

  protected convertObjectToItem(object, parent?: any): any {
    let item: any = {};
    if (object.id) {
      item.id = object.id;
    }

    if (object.data && object.data.menu) {
      // this is a menu object
      item = object.data.menu;
      item.route = object;
      delete item.route.data.menu;
    } else {
      item.route = object;
      item.skip = true;
    }

    // we have to collect all paths to correctly build the url then
    if (Array.isArray(item.route.path)) {
      item.route.paths = item.route.path;
    } else {
      item.route.paths =
        parent && parent.route && parent.route.paths
          ? parent.route.paths.slice(0)
          : ['/'];
      if (item.route.path) {
        item.route.paths.push(item.route.path);
      }
    }

    if (object.children && object.children.length > 0) {
      item.children = this.convertArrayToItems(object.children, item);
    }

    const prepared = this.prepareItem(item);

    // if current item is selected or expanded - then parent is expanded too
    if ((prepared.selected || prepared.expanded) && parent) {
      parent.expanded = true;
      parent.selected = prepared.selected;
    }

    return prepared;
  }

  protected prepareItem(object: any): any {
    if (!object.skip) {
      object.target = object.target || '';
      object.pathMatch = object.pathMatch || 'full';
      return this.selectItem(object);
    }

    return object;
  }

  protected selectItem(item: any): any {
    const url = this.router.createUrlTree(item.route.paths);
    item.selected = this.router.isActive(url, {paths: 'subset', queryParams: 'subset', fragment: 'ignored', matrixParams: 'ignored'});
    if (this.staticActiveRoute) {
      try {
        item.selected = item.route.paths.toString() === this.staticActiveRoute.toString();
      } catch (e) {
        console.log('error selecting static route ', this.staticActiveRoute);
      }
    }
    return item;
  }
}
