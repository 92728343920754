import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IconType, QPointIconModule } from '@qpoint/icons';
import { QPointLoadingSpinnerComponent, QPointLoadingSpinnerVariant } from '@qpoint/loading';
import { CommonModule } from '@angular/common';

export type ButtonType = 'submit' | 'button';

export enum ButtonSize {
  lg = 'lg',
  sm = 'sm',
  default = 'default',
}

export enum ButtonVariant {
  primary = 'primary',
  secondary = 'secondary',
  icon = 'icon',
}

@Component({
  selector: 'qpoint-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    QPointIconModule,
    QPointLoadingSpinnerComponent
  ]
})
export class QPointButtonComponent {
  @Input() disabled = false;
  @Input() active = false;
  @Input() tabindex: number;
  @Input() type: ButtonType = 'button';
  @Input() loading = false;
  @Input() block = false;
  @Input() variant: ButtonVariant | 'icon' | 'primary' | 'secondary' = ButtonVariant.secondary;
  @Input() size: ButtonSize = ButtonSize.default;
  @Input() icon: string = null;
  @Input() btnClass: string;
  @Input() iconVariant: 'danger' | 'success' | 'warn' | 'primary' = 'primary';
  @Input() iconType: IconType = 'solid';
  @Input() iconRotate: '90' | '180' | '270';
  @Input() formId: string | null = null;

  @Output() buttonClicked = new EventEmitter<Event>();


  QPointLoadingSpinnerVariant = QPointLoadingSpinnerVariant;

  /**
   * @deprecated use type instead
   */
  @Input() set btnType(type: ButtonType) {
    this.type = type;
  }

  /**
   * @deprecated use variant instead
   */
  @Input() set qPointButtonType(variant: 'primary' | 'secondary' | 'icon') {
    switch (variant) {
      case 'primary':
        this.variant = ButtonVariant.primary;
        break;
      case 'secondary':
        this.variant = ButtonVariant.secondary;
        break;
      case 'icon':
        this.variant = ButtonVariant.icon;
        break;
    }
  }

  /**
   * @deprecated use loading instead
   */
  @Input() set isLoading(loading) {
    this.loading = loading;
  }

  get classes(): string[] {
    const classes = ['btn', 'qpoint-button', 'btn-variant-' + this.iconVariant];

    if (this.btnClass) {
      classes.push(this.btnClass);
    }

    switch (this.variant) {
      case ButtonVariant.secondary:
        classes.push('btn-secondary');
        break;
      case ButtonVariant.icon:
        classes.push('btn-icon');
        break;
      default:
        if (this.disabled) {
          classes.push('btn-secondary');
        } else {
          classes.push('btn-primary');
        }
    }

    switch (this.size) {
      case ButtonSize.lg:
        classes.push('btn-lg');
        break;
      case ButtonSize.sm:
        classes.push('btn-sm');
        break;
    }

    if (this.block) {
      classes.push('btn-block');
    }

    if (this.loading) {
      classes.push('btn-loading', 'disabled');
    }

    if (this.active) {
      classes.push('active');
    }

    return classes;
  }

  get isDisabled() {
    return this.disabled || this.loading;
  }

  public onClick($event) {
    if (!this.isDisabled) {
      this.buttonClicked.emit($event);
    } else {
      $event.preventDefault();
      $event.stopPropagation();
    }
  }
}
