import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'qpoint-split-button-action',
  templateUrl: './split-button-action.component.html',
  styleUrls: ['./split-button-action.component.scss'],
  standalone: true
})
export class QPointSplitButtonActionComponent {
  private _text: string;

  @Input() public set text(value: string) {
    this._text = value;
    this.textChanged.emit(value);
  }

  public get text(): string {
    return this._text;
  }

  @Input() disabled: boolean;
  @Output() actionClick = new EventEmitter<never>();
  @Output() textChanged = new EventEmitter<string>();
}
