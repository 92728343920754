<div [qpointLoading]="isLoading">
  <qpoint-toolbar [title]="('user.accountPage.title'| translate)" [showContextbarButton]="false">
    <ng-container>
      <qpoint-toolbar-btn iconClass="undo-alt" label="{{ 'global.action.discardChanges' | translate }}"
        *ngIf="this.formGroup.dirty" (btnClick)="discardChanges()"></qpoint-toolbar-btn>
      <qpoint-toolbar-btn iconClass="check" label="{{ 'global.action.save' | translate }}"
        [disabled]="!this.formGroup.dirty" (btnClick)="updateUserWithFormValues()"></qpoint-toolbar-btn>
    </ng-container>

  </qpoint-toolbar>

  <qpoint-content-container [fullHeight]="true">
    <div class="profile-info d-flex align-items-center">
      <qpoint-icon icon="info-circle"></qpoint-icon>
      {{'user.accountPage.profileInfo' | translate}}
    </div>
    <form [formGroup]="formGroup" *ngIf="formGroup" class="noRowColorDifference">
      <div class="flex-container">
        <div class="profile-image">
          <qpoint-image-selector
            formControlName="image"
            [title]="'common.image.profileImage'"
            [imageUri]="imageUri | authorizedImage | async"
            [imageCanBeDeleted]="true"
            (imageChanged)="imageChanged($event)"
            [resizeToWidth]="400"
            [readonly]="false"
            [deleteWarningMessage]="'common.image.deleteWarningMessage'"
            maxFileSizeInMb="1"
            [maxFileSizeExceededMessage]="'common.image.maxFileSizeExceededMessage'"
            acceptedFormats="png, jpeg">
          </qpoint-image-selector>
        </div>
        <div class="d-flex flex-row profile-form">
          <div id="combine-form-containers">
            <div class="form-container">
              <qpoint-table-row [label]="('user.accountPage.givenname'| translate)">
                <ng-container flexTableMain>
                  <qpoint-reactive-form-input-group [qpointFormControl]="givenname" [isSubmitted]="isSubmitted">
                    <input formControlName="givenname" kendoTextBox />
                  </qpoint-reactive-form-input-group>
                </ng-container>
              </qpoint-table-row>

              <qpoint-table-row [label]="('user.accountPage.surname'| translate)">
                <ng-container flexTableMain>
                  <qpoint-reactive-form-input-group [qpointFormControl]="surname" [isSubmitted]=isSubmitted>
                    <input formControlName="surname" kendoTextBox />
                  </qpoint-reactive-form-input-group>
                </ng-container>
              </qpoint-table-row>

              <qpoint-table-row [label]="('user.accountPage.email'| translate)">
                <ng-container flexTableMain>
                  <qpoint-reactive-form-input-group [qpointFormControl]="eMail" [isSubmitted]="isSubmitted">
                    <input type="email" formControlName="emailAddress" kendoTextBox />
                  </qpoint-reactive-form-input-group>
                </ng-container>
              </qpoint-table-row>

              <qpoint-table-row [label]="('user.accountPage.mobile'| translate)">
                <ng-container flexTableMain>
                  <qpoint-reactive-form-input-group [qpointFormControl]="mobile" [isSubmitted]="isSubmitted">
                    <input formControlName="mobile" kendoTextBox />
                  </qpoint-reactive-form-input-group>
                </ng-container>
              </qpoint-table-row>

              <qpoint-table-row [label]="('user.accountPage.phone'| translate)">
                <ng-container flexTableMain>
                  <qpoint-reactive-form-input-group [qpointFormControl]="phone" [isSubmitted]="isSubmitted">
                    <input formControlName="phone" kendoTextBox />
                  </qpoint-reactive-form-input-group>
                </ng-container>
              </qpoint-table-row>

              <qpoint-table-row [label]="('user.accountPage.language'| translate)" class="input-s">
                <ng-container flexTableMain>
                  <qpoint-reactive-form-input-group [qpointFormControl]="preferredLanguage" [isSubmitted]="isSubmitted">
                    <kendo-dropdownlist id="language" name="preferredLanguage" [data]="languages$ | async"
                      [textField]="'display'" [valueField]="'code'" [valuePrimitive]="true"
                      formControlName="preferredLanguage"></kendo-dropdownlist>
                  </qpoint-reactive-form-input-group>
                </ng-container>
              </qpoint-table-row>

              <qpoint-table-row [label]="('user.accountPage.companyName'| translate)">
                <ng-container flexTableMain>
                  <qpoint-reactive-form-input-group [qpointFormControl]="companyName" [isSubmitted]="isSubmitted">
                    <input formControlName="companyName" kendoTextBox />
                  </qpoint-reactive-form-input-group>
                </ng-container>
              </qpoint-table-row>

              <qpoint-table-row [label]="('user.accountPage.address'| translate)">
                <ng-container flexTableMain>
                  <qpoint-reactive-form-input-group [qpointFormControl]="streetAddress" [isSubmitted]="isSubmitted">
                    <input formControlName="streetAddress" kendoTextBox />
                  </qpoint-reactive-form-input-group>
                </ng-container>
              </qpoint-table-row>

              <qpoint-table-row [label]="('user.accountPage.addressZipCode'| translate)" class="input-s">
                <ng-container flexTableMain>
                  <qpoint-reactive-form-input-group [qpointFormControl]="postalCode" [isSubmitted]="isSubmitted">
                    <input formControlName="postalCode" kendoTextBox />
                  </qpoint-reactive-form-input-group>
                </ng-container>
              </qpoint-table-row>

              <qpoint-table-row [label]="('user.accountPage.addressCity'| translate)">
                <ng-container flexTableMain>
                  <qpoint-reactive-form-input-group [qpointFormControl]="city" [isSubmitted]="isSubmitted">
                    <input formControlName="city" kendoTextBox />
                  </qpoint-reactive-form-input-group>
                </ng-container>
              </qpoint-table-row>

              <qpoint-table-row [label]="('user.accountPage.addressCountry'| translate)" class="input-s">
                <ng-container flexTableMain>
                  <qpoint-reactive-form-input-group [qpointFormControl]="country" [isSubmitted]="isSubmitted">
                    <input formControlName="country" kendoTextBox />
                  </qpoint-reactive-form-input-group>
                </ng-container>
              </qpoint-table-row>
            </div>
            <div class="heading">{{'user.accountPage.displayFormatSettings' | translate}}</div>
            <div class="form-container">
              <qpoint-table-row [label]="('user.accountPage.region' | translate)" class="input-s">
                <ng-container flexTableMain>
                  <qpoint-reactive-form-input-group [qpointFormControl]="cultureInfo" [isSubmitted]="isSubmitted">
                    <kendo-dropdownlist formControlName="cultureInfo" [data]="cultures$ | async" [textField]="'name'"
                      [valueField]="'code'"></kendo-dropdownlist>
                  </qpoint-reactive-form-input-group>
                </ng-container>
              </qpoint-table-row>
              <qpoint-format-preview
               [cultureInfo]="cultureInfo?.value?.code"
               [language]="preferredLanguage?.value"
              ></qpoint-format-preview>
            </div>
          </div>
        </div>
      </div>
    </form>
  </qpoint-content-container>
</div>
