
<div [class]="'tab-container ' + tabOrientation" [class.collapsed]="menuCollapsed">
<ul ngbNav [orientation]="tabOrientation" #ngbNavReference="ngbNav" class="nav-tabs" [activeId]="activeId" (navChange)="onNavChange($event)" [destroyOnHide]="destroyOnHide">
  <ng-container *ngFor="let tabNavItem of tabNavItems">
    <li [ngbNavItem]="tabNavItem.id" [destroyOnHide]="tabNavItem.destroyOnHide">
      <a ngbNavLink>
        <div class="d-flex">
          <div class="title-icon"><qpoint-icon *ngIf="tabNavItem.icon" [icon]="tabNavItem.icon"></qpoint-icon></div>
          <div *ngIf="!menuCollapsed" class="title-text">{{tabNavItem.label}}</div>
        </div>
      </a>
      <ng-template ngbNavContent>
        <ng-template  *ngTemplateOutlet="tabNavItem.detailTemplate || null"></ng-template>
      </ng-template>
    </li>
  </ng-container>
</ul>
<div [ngbNavOutlet]="ngbNavReference" class="mt-2 occupyAvailableSpace"></div>
</div>
