import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {
  QPointDeliveryNoteTransferService, QPointDeliveryNoteTransferTexts
} from '../../../../../projects/qpoint-shared/src/lib/delivery-note-transfer/services/delivery-note-transfer.service';
import { OnboardingStatus } from '../../../../../projects/qpoint-shared/src/lib/delivery-note-transfer/types/onboarding-status.enum';
import { delay, tap } from 'rxjs/operators';
import {
  DeliveryNoteTransferVehicle
} from '../../../../../projects/qpoint-shared/src/lib/delivery-note-transfer/components/delivery-note-transfer.component';

@Injectable({
  providedIn: 'root'
})
export class DeliveryNoteTransferService implements QPointDeliveryNoteTransferService {

  texts: QPointDeliveryNoteTransferTexts = {
    title: 'Lieferscheinzustellung',
    text: 'Das System übermittelt sämtliche Lieferscheine, welche dem LKW zugeordnet sind, an das Mobilgerät bzw. die TruckBuddy-App des Fahrers. Im Gegenzug sendet das Mobilgerät, während der Transportfahrt fortlaufend die aktuelle Position zurück.\n' +
      'Bitte beachten Sie, dass hierzu das Einverständnis des Inhabers der Mobilnummer Voraussetzung ist.',
    transferMobilePhoneInput: 'Phone',
    transferEnabledToggle: 'Enabled',
    resendWelcomeSms: 'Onboarden',
    statusNotFound: 'Not Found',
    statusRegistered: 'Registered',
    statusOnboarding: 'Onboarding',
    startOnboardingErrorConflictText: 'startOnboardingErrorConflictText',
    startOnboardingErrorConflictTitle: 'startOnboardingErrorConflictTitle',
    startOnboardingErrorText: 'startOnboardingErrorText',
    startOnboardingErrorTitle: 'startOnboardingErrorTitle'
  };

  vehicle$ = new BehaviorSubject<DeliveryNoteTransferVehicle>(
    {
      deliveryNoteTransferEnabled: true,
      deliveryNoteTransferMobilePhone: '+436609581288',
      disabled: false,
      driverMobilePhone: '+436609581288',
      id: Date.now().toString(),
    }
  );

  getOnboardingStatus(deliveryNoteTransferMobilePhone: string): Observable<{ status: OnboardingStatus }> {
    console.log('GET ONBOARDING STATUS');
    const demo = [OnboardingStatus.Onboarding, OnboardingStatus.Registered, OnboardingStatus.NotFound, OnboardingStatus.Uninstalled];

    return of({status: demo[this.randomNumber(4)]})
      .pipe(
        delay(500)
      );
  }

  handleVehicleWithTheSameTransferMobilePhone(vehicleId: string, deliveryNoteTransferMobilePhone: string): Observable<boolean> {
    console.log('handleVehicleWithTheSameTransferMobilePhone');
    return of(true)
      .pipe(
        delay(500),
      );
  }

  setDeliveryNoteTransferEnabled(vehicleId: string, deliveryNoteTransferEnabled: boolean): Observable<void> {
    console.log('setDeliveryNoteTransferEnabled');
    return of(null).pipe(
      delay(500),
      tap(() => {
        this.vehicle$.next({
          ...this.vehicle$.getValue(),
          deliveryNoteTransferEnabled: deliveryNoteTransferEnabled
        });
        console.log('this.vehicle: ', this.vehicle$.getValue());
      })
    );
  }

  setDeliveryNoteTransferMobilePhone(vehicleId: string, deliveryNoteTransferMobilePhone: string): Observable<void> {
    console.log('setDeliveryNoteTransferMobilePhone');
    return of(null).pipe(
      delay(500),
      tap(() => {
        this.vehicle$.next({
          ...this.vehicle$.getValue(),
          deliveryNoteTransferMobilePhone: deliveryNoteTransferMobilePhone
        });
      })
    );
  }

  startOnboarding(vehicleId: string, deliveryNoteTransferMobilePhone): Observable<void> {
    console.log('startOnboarding');
    return of(null)
      .pipe(
        delay(500)
      );

  }

  private randomNumber(max: number): number {
    return Math.floor(Math.random() * max);
  }
}
