import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { QPointLoadingSpinnerVariant, QPointLoadingSpinnerComponent, QPointLoadingDirective } from '@qpoint/loading';
import { NgIf, NgFor } from '@angular/common';
import { QPointButtonComponent } from '../../../../projects/qpoint-button/src/lib/button/button.component';
import { QPointToolbarComponent, QPointContentContainerComponent } from '@qpoint/layout';

@Component({
  selector: 'app-loading-spinner-gallery',
  templateUrl: './loading-spinner.component.html',
  standalone: true,
  imports: [
    QPointToolbarComponent,
    QPointContentContainerComponent,
    QPointButtonComponent,
    NgIf,
    NgFor,
    QPointLoadingSpinnerComponent,
    QPointLoadingDirective
  ],
})
export class ControlGalleryLoadingSpinnerComponent {
  private loading$ = new Subject<void>();
  public loading = false;
  public variant: QPointLoadingSpinnerVariant = QPointLoadingSpinnerVariant.bright;
  public sizes = [1, 2, 3, 6, 9, 12, 15];

  constructor() {
    this.loading$.pipe(tap(() => this.loading = true))
      .pipe(debounceTime(Math.random() * 3000 + 1500))
      .subscribe(() => this.loading = false);
  }

  public load() {
    this.loading$.next();
  }

  public start() {
    this.loading = true;
  }

  public stop() {
    this.loading = false;
  }
}
