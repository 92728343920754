<aside id="qpoint-menu-aside" class="qpoint-menu" [ngClass]="{'showLegal': showLegal}" sidebarResize>
  <div class="additional-content">
    <ng-content></ng-content>
  </div>
  <ul class="qpoint-sidebar-list" [ngClass]="{'sidebar-collapsed' : sidebarCollapsed}">
    <qpoint-menu-item
      [menuItem]="item"
      [sidebarCollapsed]="sidebarCollapsed"
      (toggleSubMenu)="toggleSubMenu($event)"
      *ngFor="let item of menuItems"></qpoint-menu-item>
  </ul>
</aside>
