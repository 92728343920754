<qpoint-toolbar [title]="'Description'"></qpoint-toolbar>

<qpoint-content-container>
  <!-- Descriptions -->
  <div class="card">
    <div class="card-header">Beschreibungen</div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-4">
          <p>Beschreibungen vertikal (&lt;dl&gt;)</p>
          <dl>
            <dt>AIP (&lt;dt&gt;)</dt>
            <dd>Ammann Information Point (&lt;dd&gt;)</dd>
            <dt>HTML</dt>
            <dd>Hypertext Markup Language</dd>
          </dl>
        </div>
        <div class="col-md-4">
          <p>Beschreibungen horizontal (&lt;dl class="dl-horizontal"&gt;)</p>
          <dl class="dl-horizontal">
            <dt>AIP (&lt;dt&gt;)</dt>
            <dd>Ammann Information Point (&lt;dd&gt;)</dd>
            <dt>HTML</dt>
            <dd>Hypertext Markup Language</dd>
            <dt>HTTP</dt>
            <dd>Hypertext Transfer Protocol</dd>
            <dt>URL</dt>
            <dd>Uniform Resource Locator</dd>
          </dl>
        </div>
      </div>
    </div>
  </div>
</qpoint-content-container>