import { Directive } from '@angular/core';

@Directive({
  selector: '[qpointGridSpecialAttentionTemplate]'
})
export class GridSpecialAttentionTemplateDirective {

  constructor() { }

}
