import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { QPointReactiveFormInputGroupComponent } from '../../../projects/qpoint-forms/src/public_api';
import { QPointContentContainerComponent, QPointToolbarButtonComponent, QPointToolbarComponent } from '@qpoint/layout';
import { QPointValidationFormDirective, QPointFormControlDirective } from '../../../projects/qpoint-forms/src/lib/form-validation';

@Component({
    selector: 'qpoint-form-validation-demo',
    templateUrl: './form-validation-demo.component.html',
    styleUrls: ['./form-validation-demo.component.scss'],
    standalone: true,
  imports: [QPointToolbarComponent, QPointToolbarButtonComponent, QPointContentContainerComponent, FormsModule, ReactiveFormsModule, QPointReactiveFormInputGroupComponent, QPointValidationFormDirective, QPointFormControlDirective]
})
export class FormValidationDemoComponent {

  form: UntypedFormGroup = this.fb.group({
    username: [null, [Validators.required]],
    password: [null, [Validators.required]],
  });

  constructor(private fb: UntypedFormBuilder) {
    console.log(this.form)
  }

  submit() {
    console.log(this.form);
  }

  resetForm() {
    this.form.reset();
    console.log(this.form);
  }

}
