export const mapStyles: google.maps.MapTypeStyle[] = [
  {
    featureType: 'poi',
    elementType: 'labels',
    stylers: [
      {visibility: 'off'}
    ]
  },
  {
    featureType: 'landscape',
    elementType: 'labels',
    stylers: [
      {visibility: 'off'}
    ]
  },
  {
    featureType: 'transit.station',
    elementType: 'labels',
    stylers: [
      {visibility: 'off'}
    ]
  }
];
