import { Subscription } from 'rxjs';
import { QPointFormControlDirective } from '../directives/qpoint-form-control.directive';

export class FormValidationEvent {}

export class FormValidationFormSubmitted extends FormValidationEvent {}

export class FormValidationFormReset extends FormValidationEvent {}

export class FormValidationControlTouched extends FormValidationEvent {
  constructor(public control: QPointFormControlDirective) {
    super();
  }
}

export class FormValidationControlStatusChanged extends FormValidationEvent {
  constructor(public control: QPointFormControlDirective) {
    super();
  }
}

export class FormValidationControlAdded extends FormValidationEvent {
  constructor(public control: QPointFormControlDirective) {
    super();
  }
}

export class FormValidationControlRemoved extends FormValidationEvent {
  constructor(public control: QPointFormControlDirective) {
    super();
  }
}

export interface ControlSubscription {
  state: ControlState;
  subscription: Subscription;
}

export interface ControlState {
  name: string | number | null;
  parent: string | null;
  control: QPointFormControlDirective;
  hasErrors: boolean;
  touched: boolean;
  formSubmitted: boolean;
}
