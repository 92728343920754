<qpoint-toolbar [title]="'PDF Renderer'"></qpoint-toolbar>

<qpoint-content-container>
  <div class="card">
    <div class="card-header">PDF Renderer</div>
    <div class="card-body" style="height: 600px">
      <p>Lieferschein Dokument</p>
      <qpoint-pdf-thumbnail [pdfSource]="'https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf'"></qpoint-pdf-thumbnail>
      <qpoint-pdf-renderer [filename]="'deliveryNote_1.pdf'" [pdfSource]="'https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf'"></qpoint-pdf-renderer>
    </div>
  </div>
</qpoint-content-container>
