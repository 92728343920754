import {
  AfterViewInit,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  NgZone,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, NgControl, UntypedFormControl } from '@angular/forms';
import { interval, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/// <reference types="@types/google.maps" />
// https://www.typescriptlang.org/docs/handbook/triple-slash-directives.html
declare let google;

export interface PlacesAutoCompleteResult {
  streetNumber: string;
  address: string;
  city: string;
  country: string;
  countryCode: string;
  postalCode: string;
  formattedAddress: string;
  lat: number;
  lng: number;
}

@Component({
  selector: 'qpoint-places-autocomplete',
  templateUrl: './places-autocomplete.component.html',
  styleUrls: ['./places-autocomplete.component.scss']
})
export class QPointPlacesAutocompleteComponent implements AfterViewInit, ControlValueAccessor {
  private destroyRef = inject(DestroyRef);
  private mapLoaded$ = new Subject<void>();
  protected readonly dummyFc = new UntypedFormControl();

  @ViewChild('search', {static: false}) searchElementRef: ElementRef;
  @Input() inputFieldValue: any;
  @Input() filterType: 'cities' | 'address' | 'regions';
  @Input() maxLength: number;
  @Input() disabled: boolean;
  @Input() autoUpdateAfterInput = true;
  @Input() useSelectedValue = false;
  @Output() searchResultSelected: EventEmitter<Partial<PlacesAutoCompleteResult>> = new EventEmitter();
  @Output() inputChange = new EventEmitter<string>();
  public onChange = (_: any) => {
  };
  public onTouched = () => {
  };

  constructor(
    @Self() @Optional() public ngControl: NgControl,
    private ngZone: NgZone) {
    if(ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  async ngAfterViewInit() {
    await this.load();
    const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
    switch (this.filterType) {
      case 'cities':
        autocomplete.setTypes(['(cities)']);
        break;
      case 'regions':
        autocomplete.setTypes(['(regions)']);
        break;
      case 'address':
        autocomplete.setTypes(['address']);
    }
    autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        const place: google.maps.places.PlaceResult = autocomplete.getPlace();

        if (place.geometry === undefined || place.geometry === null) {
          return;
        }

        const autoCompleteResult: Partial<PlacesAutoCompleteResult> = {};
        for (const addressComponent of place.address_components) {
          const types = addressComponent.types;
          if (types.indexOf('street_number') > -1) {
            autoCompleteResult.streetNumber = addressComponent.long_name;
            continue;
          }
          if (types.indexOf('route') > -1) {
            autoCompleteResult.address = addressComponent.long_name;
            continue;
          }
          if (types.indexOf('locality') > -1) {
            autoCompleteResult.city = autoCompleteResult.city ? autoCompleteResult.city : addressComponent.long_name;
            continue;
          }
          if (types.indexOf('country') > -1) {
            autoCompleteResult.country = addressComponent.long_name;
            autoCompleteResult.countryCode = addressComponent.short_name;
            continue;
          }
          if (types.indexOf('postal_code') > -1) {
            autoCompleteResult.postalCode = addressComponent.long_name;
          }
        }
        autoCompleteResult.lng = place.geometry.location.lng();
        autoCompleteResult.lat = place.geometry.location.lat();

        const selectedValue = (this.searchElementRef.nativeElement as HTMLInputElement).value.toString();
        if (this.onChange) {
          this.writeValue(this.useSelectedValue ? selectedValue : place.formatted_address);
          this.onChange(this.inputFieldValue);
        }
        this.searchResultSelected.emit(autoCompleteResult);
      });
    });
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: any): void {
    this.inputFieldValue = obj;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  private async load(): Promise<any> {
    return new Promise((resolve, reject) => {
      if ((<any>window)?.google && (<any>window)?.google?.maps) {
        resolve((<any>window).google.maps);
      }
      interval(100)
        .pipe(
          takeUntil(this.mapLoaded$),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe(value => {
          if ((<any>window)?.google?.maps) {
            this.mapLoaded$.next();
            resolve(true);
          }
        });
    });
  }

  inputChanged(event: Event) {
    const value = (event.target as HTMLInputElement).value;
    this.inputChange.emit(value);
    if (this.autoUpdateAfterInput) {
      this.writeValue(value);
      this.onChange(this.inputFieldValue);
    }
  }
}
