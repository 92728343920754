import { Component, ComponentRef, HostListener, Input, OnInit } from '@angular/core';
import { PopupRef, PopupService } from '@progress/kendo-angular-popup';
import { Subject } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';

import { QPointGridContextMenuItemComponent } from '../grid-context-menu-item.component';
import { QPointGridContextMenuComponent } from '../grid-context-menu.component';

export interface CellMenuSubItemSelect {
  item: QPointGridContextMenuItemComponent;
  dataItem: any;
  index: number;
  anchor: any;
}

/***
 * @ignore
 */
@Component({
  selector: 'qpoint-cell-menu',
  templateUrl: './cell-menu.component.html',
  styleUrls: ['./cell-menu.component.scss']
})
export class QPointCellMenuComponent implements OnInit {
  @Input()
  public dataItem: any;
  public mouseInsideContextMenu = false;
  public popupRef: PopupRef;
  public contextMenu: QPointGridContextMenuComponent;
  public activeSubMenuEntry: QPointGridContextMenuItemComponent;
  activeHoveredMenuEntry: QPointGridContextMenuItemComponent | null = null;

  private subPopupRef: PopupRef | null = null;
  private debounceSubItemSelectSubject: Subject<CellMenuSubItemSelect> = new Subject<CellMenuSubItemSelect>();

  constructor(private popupService: PopupService) {
  }

  ngOnInit(): void {
    this.debounceSubItemSelectSubject.pipe(
      debounceTime(150),
      filter(value => value.item === this.activeHoveredMenuEntry))
      .subscribe(value => {
        this.setSubMenuItems(value.item, value.anchor);
      });
  }

  public clickedInsideKendoPopup($event: Event) {
    $event.preventDefault();
    // $event.stopPropagation();  // <- that will stop propagation on lower layers
  }

  @HostListener('document:click') clickedOutside() {
    // hide context menu if click outside of context menu
    if (!this.mouseInsideContextMenu) {
      this.popupRef.close();
    }
  }

  public contextMenuItemHover(event: MouseEvent, item: QPointGridContextMenuItemComponent, dataItem: any, index: number) {
    this.subPopupRef?.close();
    if (item.subItemArray.length > 0) {
      this.activeHoveredMenuEntry = item;
      this.debounceSubItemSelectSubject.next({item: item, dataItem: dataItem, index: index, anchor: event.target});
    } else {
      this.activeSubMenuEntry = null;
    }
  }

  public contextMenuItemClick(event: MouseEvent, item: QPointGridContextMenuItemComponent, dataItem: any) {
    if (item.subItemArray.length > 0) {
      this.setSubMenuItems(item, event.target);
    } else {
      item.click.emit(dataItem);
      this.popupRef.close();
    }
  }

  itemMouseLeave() {
    this.activeHoveredMenuEntry = null;
  }

  popupLeave() {
    this.mouseInsideContextMenu = false;
  }

  private setSubMenuItems(item: QPointGridContextMenuItemComponent, anchor: any) {
    this.activeSubMenuEntry = item;

    this.subPopupRef = this.popupService.open({
      anchor: anchor,
      content: QPointCellMenuComponent,
      anchorAlign: {
        horizontal: 'left',
        vertical: 'top'
      },
      popupAlign: {
        horizontal: 'right',
        vertical: 'top'
      },
      collision: {
        horizontal: 'fit',
        vertical: 'fit'
      },
      popupClass: 'grid-content-popup popup'
    });

    const component = this.subPopupRef.content as ComponentRef<QPointCellMenuComponent>;
    component.instance.popupRef = this.subPopupRef;
    component.instance.contextMenu = new QPointGridContextMenuComponent();
    component.instance.dataItem = this.dataItem;
    // unfortunately any is needed here, because we can't create a QueryList with a value
    component.instance.contextMenu.contextMenuItems = item.subItemArray as any;
  }
}
