import { Component, DestroyRef, inject, Inject, Input, OnDestroy, ViewChild } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { QPointFormControlDirective, QPointFormValidators, QPointInlineEditingInputComponent, QPointReactiveFormInputGroupComponent, QPointSwitchComponent, QPointValidationFormDirective } from '@qpoint/forms';
import { QPointConfirmDialogService } from '@qpoint/layout';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, from, Observable, of, ReplaySubject, Subject, timer } from 'rxjs';
import { catchError, debounceTime, filter, map, shareReplay, startWith, switchMap, takeUntil, tap, } from 'rxjs/operators';

import { DI_DELIVERY_NOTE_TRANSFER_SERVICE, QPointDeliveryNoteTransferService, } from '../services/delivery-note-transfer.service';
import { InvitationStateIcon, InvitationStateIconColor } from '../types/invitation-state.enum';
import { OnboardingStatus } from '../types/onboarding-status.enum';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { QPointLoadingDirective } from '@qpoint/loading';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { QPointButtonComponent } from '@qpoint/buttons';
import { QPointIconModule } from '@qpoint/icons';

export interface DeliveryNoteTransferVehicle {
  id: string;
  deliveryNoteTransferEnabled: boolean;
  deliveryNoteTransferMobilePhone: string;
  disabled: boolean;
  driverMobilePhone: string;
}

@Component({
  selector: 'qpoint-delivery-note-transfer',
  templateUrl: './delivery-note-transfer.component.html',
  styleUrls: ['./delivery-note-transfer.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    QPointLoadingDirective,
    TranslateModule,
    AsyncPipe,
    QPointButtonComponent,
    QPointSwitchComponent,
    QPointInlineEditingInputComponent,
    ReactiveFormsModule,
    QPointIconModule,
    QPointFormControlDirective,
    QPointReactiveFormInputGroupComponent,
    QPointValidationFormDirective
  ]
})
export class QPointDeliveryNoteTransferComponent implements OnDestroy {
  private destroyRef = inject(DestroyRef);

  private onboardingStatusTrigger$ = new ReplaySubject<DeliveryNoteTransferVehicle>(1);
  private formUpdated$ = new Subject<void>();
  activeInlineEditing$ = new BehaviorSubject<boolean>(false);
  vehicle$ = new BehaviorSubject<DeliveryNoteTransferVehicle | null>(null);
  loading$ = new BehaviorSubject<boolean>(false);
  onboardingStatusState$: Observable<{
    loading: boolean;
    error?: Error | null;
    onboardingStatus?: OnboardingStatus
  }> = this.onboardingStatusTrigger$.pipe(
    filter(value => {
      return !!value;
    }),
    switchMap(vehicle => {
      return this.deliveryNoteTransferService.getOnboardingStatus(vehicle.deliveryNoteTransferMobilePhone)
        .pipe(
          map(response => {
            return {
              loading: false,
              error: null,
              onboardingStatus: response.status
            };
          }),
          catchError(error => of({loading: false, error})),
          startWith({loading: true}),
        );
    }),
    shareReplay(1)
  );

  statusColor$ = this.onboardingStatusState$.pipe(
    map(v => v.onboardingStatus),
    map(onboardingStatus => {
      switch (onboardingStatus) {
        case OnboardingStatus.NotFound:
          return 'red';
        case OnboardingStatus.Uninstalled:
          return 'red';
        case OnboardingStatus.Onboarding:
          return 'orange';
        case OnboardingStatus.Registered:
          return 'green';
      }
    }),
    shareReplay(1)
  );

  invitationState$ = new BehaviorSubject<{
    icon: InvitationStateIcon,
    color: InvitationStateIconColor,
    loading: boolean
  }>({
    icon: InvitationStateIcon.IdleIcon,
    color: InvitationStateIconColor.IdleIconColor,
    loading: false
  });

  form: UntypedFormGroup = this.fb.group({
    deliveryNoteTransferEnabled: this.fb.control({value: null, disabled: true}),
    deliveryNoteTransferMobilePhone: this.fb.control(null, [QPointFormValidators.phoneE164Validator])
  });

  @Input() set loading(value: boolean) {
    this.loading$.next(value);
  }

  @Input() set vehicle(vehicle: DeliveryNoteTransferVehicle) {
    this.updateForm(vehicle);
    this.vehicle$.next(vehicle);
    this.triggerOnboardingStatus();
  }

  @ViewChild(QPointInlineEditingInputComponent) public referenceInlineEdit: QPointInlineEditingInputComponent;

  constructor(@Inject(DI_DELIVERY_NOTE_TRANSFER_SERVICE) public deliveryNoteTransferService: QPointDeliveryNoteTransferService,
              private fb: UntypedFormBuilder,
              private qpointConfirmService: QPointConfirmDialogService) {
  }

  ngOnDestroy() {
    this.formUpdated$.complete();
  }

  get deliveryNoteTransferMobilePhone(): UntypedFormControl {
    return this.form.get('deliveryNoteTransferMobilePhone') as UntypedFormControl;
  }

  get deliveryNoteTransferEnabled(): UntypedFormControl {
    return this.form.get('deliveryNoteTransferEnabled') as UntypedFormControl;
  }

  get vehicle(): DeliveryNoteTransferVehicle {
    return cloneDeep(this.vehicle$.getValue());
  }

  private updateForm(vehicle: DeliveryNoteTransferVehicle) {
    this.formUpdated$.next();
    this.form.reset();
    this.form.patchValue({
      deliveryNoteTransferEnabled: vehicle.deliveryNoteTransferEnabled,
      deliveryNoteTransferMobilePhone: vehicle.deliveryNoteTransferMobilePhone
    });

    this.activeInlineEditing$.next(true);

    const deliveryNoteTransferEnabled = this.form.get('deliveryNoteTransferEnabled');

    if (vehicle.disabled || !vehicle.deliveryNoteTransferMobilePhone || vehicle.deliveryNoteTransferMobilePhone === '') {
      deliveryNoteTransferEnabled.disable();
    } else {
      deliveryNoteTransferEnabled.enable();
    }

    this.setupFormValueChangesListener();
  }

  onEditModeIsActive(event: boolean) {
    if (event && !this.deliveryNoteTransferMobilePhone.value && this.vehicle$.getValue().driverMobilePhone) {
      this.referenceInlineEdit.setInputFieldValue(this.vehicle$.getValue().driverMobilePhone);
    }
  }

  restartOnboarding() {
    this.deliveryNoteTransferService.startOnboarding(this.vehicle$.getValue().id, this.deliveryNoteTransferMobilePhone.value)
      .pipe(
        tap(() => {
            this.invitationState$.next({
              color: InvitationStateIconColor.SuccessIconColor,
              loading: false,
              icon: InvitationStateIcon.SuccessIcon
            });
          }
        ),
        catchError(() => {
          this.invitationState$.next({
            color: InvitationStateIconColor.ErrorIconColor,
            loading: false,
            icon: InvitationStateIcon.ErrorIcon
          });
          return of(null);
        }),
        switchMap(() => timer(10000))
      )
      .subscribe({
        next: () => {
          this.invitationState$.next({
            color: InvitationStateIconColor.IdleIconColor,
            loading: false,
            icon: InvitationStateIcon.IdleIcon
          });
        }
      });
  }

  triggerOnboardingStatus() {
    this.onboardingStatusTrigger$.next(this.vehicle);
  }

  private setupFormValueChangesListener() {
    this.deliveryNoteTransferMobilePhone.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        takeUntil(this.formUpdated$),
        debounceTime(300),
        switchMap(phoneNumber => {
          if (this.deliveryNoteTransferMobilePhone.valid &&
            phoneNumber !== this.vehicle.deliveryNoteTransferMobilePhone) {

            if (phoneNumber === '') {
              this.deliveryNoteTransferEnabled.patchValue(false);
              this.deliveryNoteTransferService.setDeliveryNoteTransferEnabled(this.vehicle.id, false);
              return this.deliveryNoteTransferService.setDeliveryNoteTransferMobilePhone(this.vehicle.id, phoneNumber);
            }

            return this.deliveryNoteTransferService.handleVehicleWithTheSameTransferMobilePhone(this.vehicle.id, phoneNumber)
              .pipe(
                switchMap(isHandled => {
                  if (isHandled == null) {
                    return of(null);
                  }

                  if (!isHandled) {
                    this.deliveryNoteTransferMobilePhone.setValue(this.vehicle.deliveryNoteTransferMobilePhone);
                    this.deliveryNoteTransferMobilePhone.markAsPristine();
                    this.triggerOnboardingStatus();
                    return of(null);
                  } else {
                    return this.deliveryNoteTransferService.getOnboardingStatus(this.deliveryNoteTransferMobilePhone.value)
                      .pipe(
                        switchMap(response => {
                          if (response != null) {
                            if (response.status === OnboardingStatus.NotFound) {
                              return this.deliveryNoteTransferService.startOnboarding(this.vehicle.id, this.deliveryNoteTransferMobilePhone.value)
                                .pipe(
                                  switchMap(() => {
                                    return this.deliveryNoteTransferService.setDeliveryNoteTransferMobilePhone(this.vehicle.id, this.deliveryNoteTransferMobilePhone.value);
                                  }),
                                  catchError(error => {
                                    this.referenceInlineEdit.toggleReadOnly();
                                    this.referenceInlineEdit.writeValue(this.vehicle.deliveryNoteTransferMobilePhone);
                                    this.referenceInlineEdit.setInputFieldValue(this.deliveryNoteTransferMobilePhone.value);
                                    this.deliveryNoteTransferMobilePhone.markAsPristine();

                                    if (error.status === 400) {
                                      return from(this.qpointConfirmService.confirm(1, this.deliveryNoteTransferService.texts?.startOnboardingErrorTitle ?? 'vehicle.startOnboardingErrorTitle', this.deliveryNoteTransferService.texts?.startOnboardingErrorText ?? 'vehicle.startOnboardingErrorText', 'common.ok', null, null, null, 'error'));
                                    }
                                    if (error.status === 409) {
                                      return from(this.qpointConfirmService.confirm(1, this.deliveryNoteTransferService.texts?.startOnboardingErrorConflictTitle ?? 'vehicle.startOnboardingConflictTitle', this.deliveryNoteTransferService.texts?.startOnboardingErrorConflictText ?? 'vehicle.startOnboardingConflictText', 'common.ok', null, null, null, 'error'));
                                    }

                                    return of(null);
                                  })
                                );
                            } else {
                              return this.deliveryNoteTransferService.setDeliveryNoteTransferMobilePhone(this.vehicle.id, this.deliveryNoteTransferMobilePhone.value);
                            }
                          } else {
                            this.referenceInlineEdit.toggleReadOnly();
                            this.referenceInlineEdit.writeValue(this.vehicle.deliveryNoteTransferMobilePhone);
                            this.referenceInlineEdit.setInputFieldValue(this.deliveryNoteTransferMobilePhone.value);
                            this.deliveryNoteTransferMobilePhone.markAsPristine();
                            return of(null);
                          }
                        }),
                        catchError(error => {
                          console.error(error);
                          return from(this.qpointConfirmService.confirm(1, 'vehicle.startOnboardingErrorTitle', 'vehicle.startOnboardingErrorText', 'common.ok', null, null, null, 'error'));
                        }),
                      );
                  }
                })
              );
          } else {
            return of(null);
          }
        }),
        catchError(error => {
          console.error(error);
          return of(null);
        })
      ).subscribe();

    this.deliveryNoteTransferEnabled.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        takeUntil(this.formUpdated$),
        debounceTime(300),
        switchMap(value => {
          if (value) {
            return this.deliveryNoteTransferService.handleVehicleWithTheSameTransferMobilePhone(this.vehicle.id, this.deliveryNoteTransferMobilePhone.value)
              .pipe(
                switchMap(result => {
                  if (result == null) {
                    return of(null);
                  }

                  if (!result) {
                    this.deliveryNoteTransferEnabled.patchValue(!value, {emitEvent: false});
                    return of(null);
                  } else {
                    return this.deliveryNoteTransferService.setDeliveryNoteTransferEnabled(this.vehicle.id, value);
                  }
                })
              );
          } else {
            return this.deliveryNoteTransferService.setDeliveryNoteTransferEnabled(this.vehicle.id, value);
          }
        }),
        catchError(error => {
          console.error(error);
          return of(null);
        })
      ).subscribe();
  }
}
