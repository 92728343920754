<qpoint-toolbar>
  <qpoint-toolbar-btn qPointButtonType="primary" (btnClick)="resetForm()">Reset Form</qpoint-toolbar-btn>
  <qpoint-toolbar-btn qPointButtonType="primary" btnType="submit" formId="demo-form">Submit!</qpoint-toolbar-btn>
</qpoint-toolbar>
<qpoint-content-container>
  <form [formGroup]="form" id="demo-form" (ngSubmit)="submit()">
    <div class="d-flex flex-column my-2">
      <div class="input-label">Username</div>
      <qpoint-reactive-form-input-group>
        <input type="text" class="form-control" formControlName="username" qpointValidationFormControl />
      </qpoint-reactive-form-input-group>
    </div>
    <div class="d-flex flex-column">
      <div class="input-label">Password</div>
      <qpoint-reactive-form-input-group>
        <input type="password" class="form-control" formControlName="password" qpointValidationFormControl />
      </qpoint-reactive-form-input-group>
    </div>
  </form>
</qpoint-content-container>
