import { Pipe, PipeTransform } from '@angular/core';
import { fromGeoJSON } from '../map-helpers';

@Pipe({
  name: 'qpointGeoJsonToGmaps'
})
export class GeoJsonToGmapsPipe implements PipeTransform {

  transform(value: [number, number][], removeDuplicateVertex = true): google.maps.LatLngLiteral[] {
    return fromGeoJSON(value, removeDuplicateVertex);
  }

}
