import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import {
  ControlState,
  ControlSubscription,
  FormValidationControlAdded,
  FormValidationControlRemoved,
  FormValidationControlStatusChanged,
  FormValidationControlTouched,
  FormValidationEvent,
  FormValidationFormReset,
  FormValidationFormSubmitted
} from '../types/types';
import { distinctUntilChanged } from 'rxjs/operators';
import { QPointFormControlDirective } from '../directives/qpoint-form-control.directive';
import { QPointValidationFormDirective } from '../directives/qpoint-validation-form.directive';

@Injectable()
export class QPointFormValidationService implements OnDestroy {
  private formValidationChangeSubject$ = new Subject<ControlState>();
  private formSubmitSubject$ = new BehaviorSubject<boolean>(false);
  private formValidationSubject$ = new Subject<FormValidationEvent>();

  private subjects = [this.formValidationChangeSubject$, this.formValidationSubject$];

  private formGroup: QPointValidationFormDirective;
  private formControls: Set<QPointFormControlDirective> = new Set();
  private statusSubscriptions = new Map<QPointFormControlDirective, ControlSubscription>();

  formValidationChange$ = this.formValidationChangeSubject$.asObservable();
  formValidationEvent$ = this.formValidationSubject$.asObservable();

  constructor() {
    this.formValidationEvent$.subscribe(event => {
      if (event instanceof FormValidationFormReset) {
        this.formControls.forEach(control => {
          const status = this.statusSubscriptions.get(control);
          status.state.formSubmitted = false;
          status.state.touched = false;
          this.statusSubscriptions.set(control, status);
          this.emitControlChange(control);
        });
      }

      if (event instanceof FormValidationFormSubmitted) {
        this.formControls.forEach(control => {
          const status = this.statusSubscriptions.get(control);
          status.state.formSubmitted = true;
          this.statusSubscriptions.set(control, status);
          this.emitControlChange(control);
        });
      }

      if (event instanceof FormValidationControlStatusChanged) {
        const status = this.statusSubscriptions.get(event.control);
        status.state.hasErrors = event.control.getControl().invalid;
        this.statusSubscriptions.set(event.control, status);
        this.emitControlChange(event.control);
      }

      if (event instanceof FormValidationControlAdded) {
        this.emitControlChange(event.control);
      }

      if (event instanceof FormValidationControlTouched) {
        const status = this.statusSubscriptions.get(event.control);
        status.state.touched = true;
        this.statusSubscriptions.set(event.control, status);
        this.emitControlChange(event.control);
      }
    });
  }

  ngOnDestroy(): void {
    this.formControls = null;

    this.subjects.forEach(subject => {
      subject.complete();
    });
    this.subjects = null;

    const controlSubscriptions = Array.from(this.statusSubscriptions.values());
    controlSubscriptions.forEach(control => {
      control.subscription.unsubscribe();
    });
  }

  setFormGroup(formGroup: QPointValidationFormDirective) {
    this.formGroup = formGroup;
  }

  removeFormGroup(formGroup: QPointValidationFormDirective) {
    this.formGroup = null;
  }

  addControl(control: QPointFormControlDirective) {
    this.formControls.add(control);
    const subscription = control
      .getControl()
      .statusChanges.pipe(distinctUntilChanged())
      .subscribe(() => {
        this.formValidationSubject$.next(new FormValidationControlStatusChanged(control));
      });

    this.statusSubscriptions.set(control, {
      subscription,
      state: {
        hasErrors: control.getControl().invalid,
        name: control.getName(),
        parent: control.getParent(),
        control,
        touched: false,
        formSubmitted: this.formSubmitSubject$.value,
      },
    });

    this.formValidationSubject$.next(new FormValidationControlAdded(control));
  }

  removeControl(control: QPointFormControlDirective) {
    if (this.formControls) {
      this.formControls.delete(control);
      this.statusSubscriptions.get(control).subscription.unsubscribe();
      this.statusSubscriptions.delete(control);
      this.formValidationSubject$.next(new FormValidationControlRemoved(control));
    }
  }

  controlBlurred(control: QPointFormControlDirective) {
    this.formValidationSubject$.next(new FormValidationControlTouched(control));
  }

  onFormSubmit(formGroup: QPointValidationFormDirective, event: Event) {
    this.formSubmitSubject$.next(true);
    this.formValidationSubject$.next(new FormValidationFormSubmitted());
  }

  onFormReset(formGroup: QPointValidationFormDirective, event: Event) {
    this.formSubmitSubject$.next(false);
    this.formValidationSubject$.next(new FormValidationFormReset());
  }

  private emitControlChange(control: QPointFormControlDirective) {
    const status = this.statusSubscriptions.get(control);
    setTimeout(() => {
      this.formValidationChangeSubject$.next(status.state);
    });
  }
}
