<qpoint-toolbar [title]="'Notification'"></qpoint-toolbar>

<qpoint-content-container>

  <!-- Notifications -->
  <div class="card">
    <div class="card-header">Notifications</div>
    <div class="card-body">
      <p>Header Notifications</p>
      <p>
        <ngb-alert type="success">
          <i class="fa fa-check"></i> Vorgang erfolgreich abgeschlossen.</ngb-alert>
      </p>
      <p>
        <ngb-alert type="info">
          <i class="fa fa-info-circle"></i> 100 Datensätze wurden reorganisiert.</ngb-alert>
      </p>
      <p>
        <ngb-alert type="warning">
          <i class="fa fa-times"></i> Passwort läuft in drei Tagen ab.</ngb-alert>
      </p>
      <p>
        <ngb-alert type="danger">
          <i class="fa fa-minus-circle"></i> Es ist ein unerwarteter Fehler aufgetreten.</ngb-alert>
      </p>
      <hr>
      <p>Popup Notifications</p>
      <blockquote class="blockquote">Existiert noch nicht in Kendo UI for Angular.</blockquote>
    </div>
  </div>

</qpoint-content-container>