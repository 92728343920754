<div class="d-flex flex-column context-menu-wrapper">
  <qpoint-button *ngFor="let entry of contextMenu" [icon]="entry?.icon" (buttonClicked)="contextMenuEntryClicked(entry)"
                 block="true" class="w-100"
                 btnClass="rounded-0 border-0 w-100 map-context-menu-btn justify-content-start"
                 qPointButtonType="primary" [disabled]="entry?.disabled ? entry.disabled(event) : false">
    <div class="d-flex flex-row justify-content-between">
      <div>
        {{entry?.label | translate}}
      </div>
    </div>
  </qpoint-button>
</div>
