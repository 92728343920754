import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { QPointProfileComponent } from '../../../projects/qpoint-profile/src/lib/user-profile/user-profile.component';
import {
  ControlGalleryButtonsComponent,
  ControlGalleryDescriptionComponent,
  ControlGalleryDirectivesComponent,
  ControlGalleryDropdownComponent,
  ControlGalleryDropzoneComponent,
  ControlGalleryFormComponent,
  ControlGalleryHeadingComponent,
  ControlGalleryIconsComponent,
  ControlGalleryInputComponent,
  ControlGalleryListComponent,
  ControlGalleryLoadingSpinnerComponent,
  ControlGalleryNotificationsComponent,
  ControlGalleryProgressbarComponent,
  ControlGallerySelectionComponent,
  ControlGalleryTabnavigationComponent,
} from '../control-gallery';
import { ControlGalleryUploadComponent } from '../control-gallery/control-gallery-upload/control-gallery-upload.component';
import {
  ControlGalleryReactiveInputComponent,
} from '../control-gallery/reactive-input/control-gallery-reactive-input.component';
import { ControlGalleryTableRowComponent } from '../control-gallery/table-row/table-row.component';
import { GridTestComponent } from '../grid-test/grid-test.component';
import { LegalComponent } from '../legal/legal.component';
import { MainComponent } from '../main/main.component';
import { ControlGalleryPdfComponent } from '../control-gallery';
import { ControlGalleryInfoMessageComponent } from '../control-gallery/control-gallery-info-message/control-gallery-info-message.component';
import { ModalComponent } from '../modal/modal.component';
import { FormValidationDemoComponent } from '../form-validation-demo/form-validation-demo.component';
import { AccordionTestComponent } from '../accordion-test/accordion-test.component';

const routes: Routes = [
  { path: '', redirectTo: '/main', pathMatch: 'full' },
  { path: 'main', loadComponent: () => import('../main/main.component').then(m => m.MainComponent) },
  { path: 'legal', component: LegalComponent },
  { path: 'main/:id', component: MainComponent },
  { path: 'profile', component: QPointProfileComponent },
  { path: 'grid', component: GridTestComponent },
  { path: 'buttons', component: ControlGalleryButtonsComponent },
  { path: 'directives', component: ControlGalleryDirectivesComponent },
  { path: 'forms', component: ControlGalleryFormComponent },
  { path: 'form-validation', component: FormValidationDemoComponent },
  { path: 'loading-spinners', component: ControlGalleryLoadingSpinnerComponent },
  { path: 'descriptions', component: ControlGalleryDescriptionComponent },
  { path: 'dropdowns', component: ControlGalleryDropdownComponent },
  { path: 'dropzone', component: ControlGalleryDropzoneComponent },
  { path: 'reactiveInput', component: ControlGalleryReactiveInputComponent },
  { path: 'headings', component: ControlGalleryHeadingComponent },
  { path: 'icons', component: ControlGalleryIconsComponent },
  { path: 'inputs', component: ControlGalleryInputComponent },
  { path: 'lists', component: ControlGalleryListComponent },
  { path: 'notifications', component: ControlGalleryNotificationsComponent },
  { path: 'progressbars', component: ControlGalleryProgressbarComponent },
  { path: 'selections', component: ControlGallerySelectionComponent },
  { path: 'tabnavigation', component: ControlGalleryTabnavigationComponent },
  { path: 'tablerows', component: ControlGalleryTableRowComponent },
  { path: 'upload', component: ControlGalleryUploadComponent },
  { path: 'pdf', component: ControlGalleryPdfComponent },
  { path: 'info-message', component: ControlGalleryInfoMessageComponent },
  { path: 'lazy-loading', loadChildren: () => import('../modules/lazy-loading/lazy-loading.module').then(m => m.LazyLoadingModule) },
  { path: 'calendar', loadChildren: () => import('../calendar/calendar.module').then(m => m.CalendarModule) },
  { path: 'date-formatting', loadChildren: () => import('../date-formatting/date-formatting.module').then(m => m.DateFormattingModule) },
  { path: 'accordion', component: AccordionTestComponent },
  { path: 'modal', component: ModalComponent },
  { path: 'pdf-drawing', loadComponent: () => import('../pdf-drawing/pdf-drawing.component').then(m => m.PdfDrawingComponent) },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
