import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QPointPlacesAutocompleteComponent } from './lib/components/places-autocomplete/places-autocomplete.component';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QPointPlacesSuggestlistComponent } from './lib/components/places-suggestlist/places-suggestlist.component';
import { QPointSuggestlistComponent } from '@qpoint/forms';


@NgModule({
  declarations: [
    QPointPlacesAutocompleteComponent,
    QPointPlacesSuggestlistComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputsModule,
    FormsModule,
    QPointSuggestlistComponent
  ],
  exports: [
    QPointPlacesAutocompleteComponent,
    QPointPlacesSuggestlistComponent
  ]
})
export class QPointMapsInputsModule { }
