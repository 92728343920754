import { Component } from '@angular/core';
import { QPointToolbarComponent, QPointContentContainerComponent } from '@qpoint/layout';

@Component({
    selector: 'app-control-gallery-progressbar',
    templateUrl: './progressbar.component.html',
    styleUrls: ['./progressbar.component.scss'],
    standalone: true,
    imports: [QPointToolbarComponent, QPointContentContainerComponent]
})
export class ControlGalleryProgressbarComponent {

  constructor() { }
}
