import { Pipe, PipeTransform } from '@angular/core';
import { QPointMapMode } from './mode';

@Pipe({
  name: 'mapModeIcon'
})
export class MapModeIconPipe implements PipeTransform {

  transform(value: QPointMapMode): string {
    switch (value) {
      case QPointMapMode.Polygon:
        return 'map';
      case QPointMapMode.Polyline:
        return 'route';
    }
  }

}
