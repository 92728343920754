import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { MapComponent } from './components/map/map.component';
import { MapMarkerComponent } from './components/map/map-marker/map-marker.component';
import { MapCircleComponent } from './components/map/map-circle/map-circle.component';
import { MapInfoWindowComponent } from './components/map/map-info-window/map-info-window.component';
import { MapPolylineComponent } from './components/map/map-polyline/map-polyline.component';
import { MapMarkerLabelDirective } from './components/map/map-marker/map-marker-label.directive';
import { MapFooterOverlayDirective } from './components/map/map-footer-overlay.directive';
import { HttpClientJsonpModule } from '@angular/common/http';
import { MarkerLabelInternalDirective } from './components/map/map-marker/marker-label-internal.directive';
import { MapPolygonComponent } from './components/map/map-polygon/map-polygon.component';
import { GeoJsonToGmapsPipe } from './components/map/pipes/geo-json-to-gmaps.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { MapContextMenuComponent } from './components/map/map-context-menu/map-context-menu.component';
import { MapModeSelectionComponent } from './components/map/map-mode-selection/map-mode-selection.component';
import { MapModeIconPipe } from './components/map/map-mode-selection/map-mode-icon.pipe';
import { QPointIconModule } from '@qpoint/icons';
import { QPointButtonComponent } from '@qpoint/buttons';

@NgModule({
  declarations: [
    MapComponent,
    MapMarkerComponent,
    MapCircleComponent,
    MapInfoWindowComponent,
    MapPolylineComponent,
    MapMarkerLabelDirective,
    MapFooterOverlayDirective,
    MarkerLabelInternalDirective,
    MapPolygonComponent,
    GeoJsonToGmapsPipe,
    MapContextMenuComponent,
    MapModeSelectionComponent,
    MapModeIconPipe,
  ],
  imports: [
    CommonModule,
    GoogleMapsModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientJsonpModule,
    TranslateModule,
    QPointIconModule,
    QPointButtonComponent
  ],
  exports: [
    MapComponent,
    MapMarkerComponent,
    MapCircleComponent,
    MapInfoWindowComponent,
    MapMarkerLabelDirective,
    MapFooterOverlayDirective,
    MapPolylineComponent,
    MapPolygonComponent,
  ]
})
export class QPointMapsModule { }
