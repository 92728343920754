/** The common anchor for markers which represent fixed positions. */
const ARROW_BASED_ICON_ANCHOR: Point = { x: 24.5, y: 62 };
/** The common size for markers which represent fixed positions. */
const ARROW_BASED_ICON_SIZE: Size = { width: 49, height: 78 };

/** The common anchor for markers which represent moving positions. */
const ROUND_ICON_ANCHOR: Point = { x: 28.5, y: 26.5 };
/** The common size for markers which represent moving positions. */
const ROUND_ICON_SIZE: Size = { width: 57, height: 57 };

// --== fixed position icons ==--
/**
 * Returns the default 'plant'-marker icon (including the corresponding anchor position).
 *
 * @param scaleFactor an optional parameter to change the size of the icon
 * @param baseUrl an optional alternative base URL to load the icon from
 *
 * @returns the default 'plant'-marker icon (including the corresponding anchor position)
 */
export function plantIcon( scaleFactor?: number, baseUrl?: string ): google.maps.Icon {
  return createIcon( 'plant.svg', ARROW_BASED_ICON_ANCHOR, toResizeOption( scaleFactor, ARROW_BASED_ICON_SIZE ), baseUrl );
}

/**
 * Returns the default 'general construction site'-marker icon (including the corresponding anchor position).
 *
 * @param scaleFactor an optional parameter to change the size of the icon
 * @param baseUrl an optional alternative base URL to load the icon from
 *
 * @returns the default 'general construction site'-marker icon (including the corresponding anchor position)
 */
export function constructionSiteIcon( scaleFactor?: number, baseUrl?: string ): google.maps.Icon {
  return createIcon( 'construction_site.svg', ARROW_BASED_ICON_ANCHOR, toResizeOption( scaleFactor, ARROW_BASED_ICON_SIZE ), baseUrl );
}

/**
 * Returns the default 'construction site position'-marker icon (including the corresponding anchor position).
 *
 * @param scaleFactor an optional parameter to change the size of the icon
 * @param baseUrl an optional alternative base URL to load the icon from
 *
 * @returns the default 'construction site position'-marker icon (including the corresponding anchor position)
 */
export function constructionSitePositionIcon( scaleFactor?: number, baseUrl?: string ): google.maps.Icon {
  return createIcon( 'construction_site_position.svg', ARROW_BASED_ICON_ANCHOR, toResizeOption( scaleFactor, ARROW_BASED_ICON_SIZE ), baseUrl );
}

/**
 * Returns the default 'site begin'-marker icon (including the corresponding anchor position).
 *
 * @param scaleFactor an optional parameter to change the size of the icon
 * @param baseUrl an optional alternative base URL to load the icon from
 *
 * @returns the default 'site begin'-marker icon (including the corresponding anchor position)
 */
export function siteBeginIcon( scaleFactor?: number, baseUrl?: string ): google.maps.Icon {
  return createIcon( 'site_begin.svg', ARROW_BASED_ICON_ANCHOR, toResizeOption( scaleFactor, ARROW_BASED_ICON_SIZE ), baseUrl );
}

/**
 * Returns the default 'site end'-marker icon (including the corresponding anchor position).
 *
 * @param scaleFactor an optional parameter to change the size of the icon
 * @param baseUrl an optional alternative base URL to load the icon from
 *
 * @returns the default 'site end'-marker icon (including the corresponding anchor position)
 */
export function siteEndIcon( scaleFactor?: number, baseUrl?: string ): google.maps.Icon {
  return createIcon( 'site_end.svg', ARROW_BASED_ICON_ANCHOR, toResizeOption( scaleFactor, ARROW_BASED_ICON_SIZE ), baseUrl );
}

/**
 * Returns the default 'site entrance'-marker icon (including the corresponding anchor position).
 *
 * @param scaleFactor an optional parameter to change the size of the icon
 * @param baseUrl an optional alternative base URL to load the icon from
 *
 * @returns the default 'site entrance'-marker icon (including the corresponding anchor position)
 */
export function siteEntranceIcon( scaleFactor?: number, baseUrl?: string ): google.maps.Icon {
  return createIcon( 'site_entrance.svg', ARROW_BASED_ICON_ANCHOR, toResizeOption( scaleFactor, ARROW_BASED_ICON_SIZE ), baseUrl );
}

/**
 * Returns the default 'site exit'-marker icon (including the corresponding anchor position).
 *
 * @param scaleFactor an optional parameter to change the size of the icon
 * @param baseUrl an optional alternative base URL to load the icon from
 *
 * @returns the default 'site exit'-marker icon (including the corresponding anchor position)
 */
export function siteExitIcon( scaleFactor?: number, baseUrl?: string ): google.maps.Icon {
  return createIcon( 'site_exit.svg', ARROW_BASED_ICON_ANCHOR, toResizeOption( scaleFactor, ARROW_BASED_ICON_SIZE ), baseUrl );
}

/**
 * Returns the default 'turning place'-marker icon (including the corresponding anchor position).
 *
 * @param scaleFactor an optional parameter to change the size of the icon
 * @param baseUrl an optional alternative base URL to load the icon from
 *
 * @returns the default 'turning place'-marker icon (including the corresponding anchor position)
 */
export function turningPlaceIcon( scaleFactor?: number, baseUrl?: string ): google.maps.Icon {
  return createIcon( 'turning_place.svg', ARROW_BASED_ICON_ANCHOR, toResizeOption( scaleFactor, ARROW_BASED_ICON_SIZE ), baseUrl );
}

/**
 * Returns the default 'waiting place'-marker icon (including the corresponding anchor position).
 *
 * @param scaleFactor an optional parameter to change the size of the icon
 * @param baseUrl an optional alternative base URL to load the icon from
 *
 * @returns the default 'waiting place'-marker icon (including the corresponding anchor position)
 */
export function waitingPlaceIcon( scaleFactor?: number, baseUrl?: string ): google.maps.Icon {
  return createIcon( 'waiting_place.svg', ARROW_BASED_ICON_ANCHOR, toResizeOption( scaleFactor, ARROW_BASED_ICON_SIZE ), baseUrl );
}

/**
 * Returns the default 'water place'-marker icon (including the corresponding anchor position).
 *
 * @param scaleFactor an optional parameter to change the size of the icon
 * @param baseUrl an optional alternative base URL to load the icon from
 *
 * @returns the default 'water place'-marker icon (including the corresponding anchor position)
 */
export function waterPlaceIcon( scaleFactor?: number, baseUrl?: string ): google.maps.Icon {
  return createIcon( 'water_place.svg', ARROW_BASED_ICON_ANCHOR, toResizeOption( scaleFactor, ARROW_BASED_ICON_SIZE ), baseUrl );
}

/**
 * Returns the default 'waypoint'-marker icon (including the corresponding anchor position).
 *
 * @param scaleFactor an optional parameter to change the size of the icon
 * @param baseUrl an optional alternative base URL to load the icon from
 *
 * @returns the default 'waypoint'-marker icon (including the corresponding anchor position)
 */
export function waypointIcon( scaleFactor?: number, baseUrl?: string ): google.maps.Icon {
  return createIcon( 'waypoint.svg', ARROW_BASED_ICON_ANCHOR, toResizeOption( scaleFactor, ARROW_BASED_ICON_SIZE ), baseUrl );
}

/**
 * Returns the default 'assembly location'-marker icon (including the corresponding anchor position).
 *
 * @param scaleFactor an optional parameter to change the size of the icon
 * @param baseUrl an optional alternative base URL to load the icon from
 *
 * @returns the default 'assembly location'-marker icon (including the corresponding anchor position)
 */
export function assemblyLocationIcon( scaleFactor?: number, baseUrl?: string ): google.maps.Icon {
  return createIcon( 'assembly_location.svg', ARROW_BASED_ICON_ANCHOR, toResizeOption( scaleFactor, ARROW_BASED_ICON_SIZE ), baseUrl );
}

/**
 * Returns the default 'break space'-marker icon (including the corresponding anchor position).
 *
 * @param scaleFactor an optional parameter to change the size of the icon
 * @param baseUrl an optional alternative base URL to load the icon from
 *
 * @returns the default 'break space'-marker icon (including the corresponding anchor position)
 */
export function breakSpaceIcon( scaleFactor?: number, baseUrl?: string ): google.maps.Icon {
  return createIcon( 'break_space.svg', ARROW_BASED_ICON_ANCHOR, toResizeOption( scaleFactor, ARROW_BASED_ICON_SIZE ), baseUrl );
}

/**
 * Returns the default 'cleaning space'-marker icon (including the corresponding anchor position).
 *
 * @param scaleFactor an optional parameter to change the size of the icon
 * @param baseUrl an optional alternative base URL to load the icon from
 *
 * @returns the default 'cleaning space'-marker icon (including the corresponding anchor position)
 */
export function cleaningSpaceIcon( scaleFactor?: number, baseUrl?: string ): google.maps.Icon {
  return createIcon( 'cleaning_space.svg', ARROW_BASED_ICON_ANCHOR, toResizeOption( scaleFactor, ARROW_BASED_ICON_SIZE ), baseUrl );
}

/**
 * Returns the default 'grid depot'-marker icon (including the corresponding anchor position).
 *
 * @param scaleFactor an optional parameter to change the size of the icon
 * @param baseUrl an optional alternative base URL to load the icon from
 *
 * @returns the default 'grid depot'-marker icon (including the corresponding anchor position)
 */
export function gridDepotIcon( scaleFactor?: number, baseUrl?: string ): google.maps.Icon {
  return createIcon( 'grid_depot.svg', ARROW_BASED_ICON_ANCHOR, toResizeOption( scaleFactor, ARROW_BASED_ICON_SIZE ), baseUrl );
}

/**
 * Returns the default 'material'-marker icon (including the corresponding anchor position).
 *
 * @param scaleFactor an optional parameter to change the size of the icon
 * @param baseUrl an optional alternative base URL to load the icon from
 *
 * @returns the default 'material'-marker icon (including the corresponding anchor position)
 */
export function materialIcon( scaleFactor?: number, baseUrl?: string ): google.maps.Icon {
  return createIcon( 'material.svg', ARROW_BASED_ICON_ANCHOR, toResizeOption( scaleFactor, ARROW_BASED_ICON_SIZE ), baseUrl );
}

// --== vehicle related (moving) icons ==--
/**
 * Returns the default 'vehicle loaded'-marker icon (including the corresponding anchor position).
 *
 * @param scaleFactor an optional parameter to change the size of the icon
 * @param baseUrl an optional alternative base URL to load the icon from
 *
 * @returns the default 'vehicle loaded'-marker icon (including the corresponding anchor position)
 */
export function vehicleLoadedIcon( scaleFactor?: number, baseUrl?: string ): google.maps.Icon {
  return createIcon( 'vehicle_loaded.svg', ROUND_ICON_ANCHOR, toResizeOption( scaleFactor, ROUND_ICON_SIZE ), baseUrl );
}

/**
 * Returns the default 'vehicle unloaded'-marker icon (including the corresponding anchor position).
 *
 * @param scaleFactor an optional parameter to change the size of the icon
 * @param baseUrl an optional alternative base URL to load the icon from
 *
 * @returns the default 'vehicle unloaded'-marker icon (including the corresponding anchor position)
 */
export function vehicleUnloadedIcon( scaleFactor?: number, baseUrl?: string ): google.maps.Icon {
  return createIcon( 'vehicle_unloaded.svg', ROUND_ICON_ANCHOR, toResizeOption( scaleFactor, ROUND_ICON_SIZE ), baseUrl );
}

/**
 * Returns the default 'vehicle paused'-marker icon (including the corresponding anchor position).
 *
 * @param scaleFactor an optional parameter to change the size of the icon
 * @param baseUrl an optional alternative base URL to load the icon from
 *
 * @returns the default 'vehicle paused'-marker icon (including the corresponding anchor position)
 */
export function vehiclePausedIcon( scaleFactor?: number, baseUrl?: string ): google.maps.Icon {
  return createIcon( 'vehicle_paused.svg', {x: 28, y: 26 }, toResizeOption( scaleFactor, { width: 56, height: 56 } ), baseUrl );
}

/**
 * Returns the default 'vehicle offline'-marker icon (including the corresponding anchor position).
 *
 * @param scaleFactor an optional parameter to change the size of the icon
 * @param baseUrl an optional alternative base URL to load the icon from
 *
 * @returns the default 'vehicle offline'-marker icon (including the corresponding anchor position)
 */
export function vehicleOfflineIcon( scaleFactor?: number, baseUrl?: string ): google.maps.Icon {
  return createIcon( 'vehicle_offline.svg', {x: 28, y: 26 }, toResizeOption( scaleFactor, { width: 56, height: 56 } ), baseUrl );
}

// --== create methods ==--
/**
 * Returns a newly created marker icon (including the corresponding anchor position).
 *
 * @param imageName the "file"-name of the marker icon
 * @param anchor an optional anchor which describes where the geo-point is located relative to the image-content
 *              (if missing the default is along the center point of the bottom of the image)
 * @param resize an optional configuration object to scale the loaded icon
 * @param baseUrl an optional alternative base URL to load the icon from
 *               (if missing `assets/qpoint-maps/icons/` will be used)
 *
 * @returns a newly created marker icon (including the corresponding anchor position)
 */
export function createIcon( imageName: string, anchor?: Point, resize?: ResizeOption,  baseUrl: string = 'assets/qpoint-maps/icons/' ): google.maps.Icon {
  let icon: google.maps.Icon = {
    url: buildURL( baseUrl, imageName )
  };

  if( resize ) {
    icon.scaledSize = new google.maps.Size( resize.originalImageSize.width * resize.scaleFactor, resize.originalImageSize.height * resize.scaleFactor );
  }

  if( anchor ) {
    const scaleFactor = resize?.scaleFactor || 1.0;
    icon.anchor = new google.maps.Point( anchor.x * scaleFactor , anchor.y  * scaleFactor );
  }

  return icon;
}

function buildURL( baseUrl: string, imageName: string ): string {
  return baseUrl.endsWith( '/' ) ? baseUrl + imageName : baseUrl + '/' + imageName;
}

function toResizeOption( scaleFactor: number = 1.0, originalImageSize: Size ) : ResizeOption {
  if( scaleFactor == 1 ) return null;

  return {
    originalImageSize,
    scaleFactor
  };
}

/**
 * Describes all needed options to render the image in a different size.
 */
export interface ResizeOption {
  /** The factor to resize the image and its anchor point. */
  scaleFactor: number;

  /** The original size of the image to calculate the new scaled size. */
  originalImageSize: Size;
}

/**
 * Describes the size of an image.
 */
export interface Size {
  width: number;
  height: number;
}

/**
 * Describes the coordinates of a point.
 */
export interface Point {
  x: number;
  y: number;
}