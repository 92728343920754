import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { QPointSidebarService } from '../sidebar/sidebar.service/sidebar.service';
import { DI_USER_INFO_SIDEBAR, injectUserInfoSidebarService, IQPointUserInfoSidebarService } from './user-info-sidebar.service';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CommonModule } from '@angular/common';
import { QPointAuthorizedImagePipe } from '../../pipes';
import { QPointIconModule } from '@qpoint/icons';
import { TranslateModule } from '@ngx-translate/core';

export interface UserInfoDropDownEntry {
  text: string;
  icon: string;
  idName: string;
  id: UserInfoDropDownEntryType;
  selected: boolean;
}

export const enum UserInfoDropDownEntryType {
  Logout, MyAccount
}

@Component({
  selector: 'qpoint-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
  imports: [
    CommonModule,
    QPointAuthorizedImagePipe,
    QPointIconModule,
    TranslateModule
  ],
  standalone: true
})
export class QPointUserInfoComponent implements OnInit, OnDestroy {
  private userInfo = injectUserInfoSidebarService();
  public entries: Array<UserInfoDropDownEntry>;
  public isSubmenuExpand = false;

  @Input()
  public showMyAccount = false;

  @Input()
  public myAccountRoutePath;

  @Output()
  public myAccount: EventEmitter<void> = new EventEmitter();

  @Output()
  public logout: EventEmitter<void> = new EventEmitter();

  constructor(
    public service: QPointSidebarService,
    private router: Router) {
  }

  public get userName(): string {
    return this.userInfo.name;
  }

  public get pictureUrl(): string {
    return this.userInfo.pictureUrl;
  }

  private ngUnsubscribe = new Subject<void>();

  public ngOnInit() {
    this.initEntries();
    this.router.events.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(value => {
        this.initEntries();
      });
  }

  private initEntries() {
    this.entries = [{
      text: 'user.logout',
      icon: 'sign-out-alt',
      idName: 'logout',
      id: UserInfoDropDownEntryType.Logout,
      selected: false
    }];

    if (this.showMyAccount) {
      this.entries.unshift({
        text: 'user.myAccount',
        icon: 'user',
        idName: 'my-account',
        id: UserInfoDropDownEntryType.MyAccount,
        selected: !this.myAccountRoutePath || this.router.isActive(this.myAccountRoutePath, {paths: 'subset', queryParams: 'subset', fragment: 'ignored', matrixParams: 'ignored'})
      });
    }
  }

  public entryClicked(entry: UserInfoDropDownEntry) {
    switch (entry.id) {
      case UserInfoDropDownEntryType.Logout:
        this.logout.emit();
        break;
      case UserInfoDropDownEntryType.MyAccount:
        this.myAccount.emit();
        break;
    }
  }

  public toggleSubmenu() {
    if (this.isSubmenuExpand && this.service.isMenuCollapsed) {
      this.isSubmenuExpand = true;
    } else {
      this.isSubmenuExpand = !this.isSubmenuExpand;
    }

    if (this.service.isMenuCollapsed) {
      this.service.toggleSidebar();
    }
    this.service.sidebarHeightChanged.next();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
