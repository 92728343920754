import { ChangeDetectorRef, Component, ContentChildren, Input, OnInit, Optional, QueryList, ViewEncapsulation } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { QPointFormControlDirective } from '../form-validation/directives/qpoint-form-control.directive';
import { QPointFormValidationService } from '../form-validation/services/qpoint-form-validation.service';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { QPointValidationMessageComponent } from '../validation-message/validation-message.component';

@Component({
  selector: 'qpoint-reactive-form-input-group',
  templateUrl: './reactive-form-input-group.component.html',
  styleUrls: ['./reactive-form-input-group.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [
    CommonModule,
    QPointValidationMessageComponent
  ],
  standalone: true
})
export class QPointReactiveFormInputGroupComponent implements OnInit {
  showError$: Observable<boolean>;
  @Input() qpointFormControl: UntypedFormControl;

  @Input() isSubmitted = false;

  @ContentChildren(QPointFormControlDirective, {descendants: true}) formControls: QueryList<QPointFormControlDirective>;

  constructor(
    @Optional() private formValidationService: QPointFormValidationService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
  }

  ngOnInit() {
    if (this.formValidationService) {
      this.showError$ = this.formValidationService.formValidationChange$.pipe(
        map(info => {
          for (const formControl of this.formControls) {
            if (formControl.getName() === info.name) {
              return {
                info: info,
                control: formControl.getControl()
              };
            }
          }
          return null;
        }),
        filter(mapped => mapped != null),
        map(mapped => (mapped.info.touched || mapped.info.formSubmitted) && mapped.control.errors !== null),
        tap(() => this.changeDetectorRef.markForCheck()),
      );
    }
  }

  get hasErrors() {
    if (this.formControls) {
      return this.formControls.some(formControl => formControl.getControl().errors != null);
    }

    return false;
  }

  public get displayInvalidClass() {
    return this.qpointFormControl && this.isInvalidControl(this.qpointFormControl) ? 'qPointControlInvalid' : '';
  }

  public isInvalidControl(control: AbstractControl) {
    return (control.invalid && ((control.dirty) || this.isSubmitted));
  }


}
