<fa-icon *ngIf="iconType !== 'qpoint' && !isMaterialType(iconType)" 
    [icon]="[mappedIconType, icon]"
    [border]="border"
    [inverse]="inverse" 
    [animation]="animation()"
    [flip]="flip"
    [rotate]="rotate"
>
</fa-icon>
<i *ngIf="iconType === 'qpoint'" class="qpoint-icon {{'qpoint-'+ icon}}"></i>
<i *ngIf="isMaterialIcon" [ngClass]="materialClassName" class="material-icon">{{icon}}</i>
