import { Component } from '@angular/core';
import {
  DI_DELIVERY_NOTE_TRANSFER_SERVICE
} from '../../../../../projects/qpoint-shared/src/lib/delivery-note-transfer/services/delivery-note-transfer.service';
import { DeliveryNoteTransferService } from './delivery-note-transfer.service';
import { AsyncPipe, JsonPipe } from '@angular/common';
import { QPointDeliveryNoteTransferComponent } from '../../../../../projects/qpoint-shared/src/lib/delivery-note-transfer/components/delivery-note-transfer.component';

@Component({
    selector: 'qpoint-delivery-note-transfer-demo',
    templateUrl: './delivery-note-transfer-demo.component.html',
    styleUrls: ['./delivery-note-transfer-demo.component.scss'],
    providers: [
        {
            provide: DI_DELIVERY_NOTE_TRANSFER_SERVICE,
            useExisting: DeliveryNoteTransferService
        }
    ],
    standalone: true,
    imports: [QPointDeliveryNoteTransferComponent, AsyncPipe, JsonPipe]
})
export class DeliveryNoteTransferDemoComponent {
  constructor(public deliveryNoteTransferService: DeliveryNoteTransferService) { }
}
