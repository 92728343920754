<div id="container">
  <aside id="qpoint-sidebar" class="qpoint-sidebar" sidebarResize (mouseover)="onMouseOver()" (mouseleave)="onMouseLeave()">
    <section class="header">
      <div class="logo-container-big" *ngIf="!isMenuCollapsed">
        <img class="logo-big" src="{{imgPath}}" alt="QPoint Logo"/>
      </div>
      <div class="logo-container-small" *ngIf="isMenuCollapsed">
        <img class="logo-small" src="{{imgPathSmall}}" alt="QPoint Logo"/>
      </div>
    </section>
    <section class="body">
      <div (mouseenter)="scrollUpMenu($event)" (mouseleave)="scrollUpMenu($event)"
           [@showIndicator]="showScrollUpIndicator ? 'show' : 'hide'" class="scrollIndicator scrollUpIndicator">
        <qpoint-icon *ngIf="showScrollUpIndicator" icon="angle-up"></qpoint-icon>
      </div>
      <qpoint-menu [sidebarCollapsed]="isMenuCollapsed" (expandMenu)="menuExpand()" [showLegal]="showLegal">
        <qpoint-user-info *ngIf="showUserInfo" (logout)="onLogout()" [showMyAccount]="showMyAccount"
                          [myAccountRoutePath]="myAccountRoutePath"
                          (myAccount)="onMyAccount()"></qpoint-user-info>
        <ng-content></ng-content>
      </qpoint-menu>
      <div (mouseenter)="scrollDownMenu($event)" (mouseleave)="scrollDownMenu($event)"
           [@showIndicator]="showScrollDownIndicator ? 'show' : 'hide'" class="scrollIndicator scrollDownIndicator">
        <qpoint-icon *ngIf="showScrollDownIndicator" icon="angle-down"></qpoint-icon>
      </div>
    </section>
    <section class="footer d-flex justify-content-center align-items-center" *ngIf="showLegal">
      <a class="legal-notice" (click)="onLegalClick()" *ngIf="!isMenuCollapsed">
        {{'common.legal.text' | translate}}
      </a>
      <qpoint-icon class="legal-notice" icon="info-circle" *ngIf="isMenuCollapsed"
                   (click)="onLegalClick()"></qpoint-icon>
    </section>
  </aside>
  <div id="close-area" *ngIf="!isMenuCollapsed" (click)="menuCollapse()"></div>
</div>
