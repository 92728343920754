export enum QPointMapMode {
  'Polyline' = 'polyline',
  'Polygon' = 'polygon'
}


export interface QPointMapModeConfiguration {
  mode: QPointMapMode;
  multi: boolean;
  active: boolean;
  tooltip: string;
}
