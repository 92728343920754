import { Component, ComponentRef, ViewChild } from '@angular/core';
import { QPointCellMenuComponent } from '../cell-menu/cell-menu.component';
import { PopupService } from '@progress/kendo-angular-popup';
import { QPointGridContextMenuComponent } from '../grid-context-menu.component';
import { timer } from 'rxjs';

@Component({
  selector: 'qpoint-ellipse-menu',
  templateUrl: './ellipse-menu.component.html',
  styleUrls: ['./ellipse-menu.component.scss']
})
export class EllipseMenuComponent {

  @ViewChild('anchorToOpen')
  private anchor;
  constructor(private popupService: PopupService) { }
  public contextMenu: QPointGridContextMenuComponent;
  public dataItem: any;

  openPopup() {
    timer(1).subscribe(() => {
      this.onContextButtonToggle(this.anchor, this.dataItem);
    });
  }

  /***
   * Open Submenu popup when context menu is toggled
   * @param anchor
   * @param dataItem
   */
  public onContextButtonToggle(anchor, dataItem): void {
    const popupReference = this.popupService.open({
      anchor: this.anchor,
      content: QPointCellMenuComponent,
      anchorAlign: {
        horizontal: 'right',
        vertical: 'bottom'
      },
      popupAlign: {
        horizontal: 'right',
        vertical: 'top'
      },
      collision: {
        horizontal: 'flip',
        vertical: 'flip'
      },
      popupClass: 'grid-content-popup popup'
    });

    const component = popupReference.content as ComponentRef<QPointCellMenuComponent>;
    component.instance.popupRef = popupReference;
    component.instance.dataItem = dataItem;
    component.instance.contextMenu = this.contextMenu;
  }

}
