import { Component, Input, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ObjectToKeysPipe } from '@qpoint/utils';
import { TranslateModule } from '@ngx-translate/core';
import { QPointIconModule } from '@qpoint/icons';

@Component({
  selector: 'qpoint-validation-message',
  templateUrl: './validation-message.component.html',
  styleUrls: ['./validation-message.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [
    CommonModule,
    ObjectToKeysPipe,
    TranslateModule,
    QPointIconModule
  ],
  standalone: true
})
export class QPointValidationMessageComponent {
  @Input()
  public controlToValidate: UntypedFormControl;

  @Input()
  public isSubmitted: boolean;


}
