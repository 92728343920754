import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ContentChild,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostListener,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { GoogleMap, MapInfoWindow, MapMarker, MapPolygon, MapPolyline } from '@angular/google-maps';
import { PopupRef, PopupService } from '@progress/kendo-angular-popup';
import { combineLatest, merge, Subject, timer } from 'rxjs';
import { debounceTime, delay, filter, take, takeUntil, tap, throttleTime } from 'rxjs/operators';

import { QPointMapsLoader } from '../../services/maps-loader.service';
import { CustomPopup, CustomPopupPosition } from './customPopup';
import { HasPosition } from './has-position';
import { InternalMapService } from './internal-map.service';
import { MapCircleComponent } from './map-circle/map-circle.component';
import {
  FunctionType,
  MapContextMenuComponent,
  MapContextMenuEntry,
  TargetComponent,
} from './map-context-menu/map-context-menu.component';
import { MapFooterOverlayDirective } from './map-footer-overlay.directive';
import { MapInfoWindowComponent } from './map-info-window/map-info-window.component';
import { MapMarkerComponent } from './map-marker/map-marker.component';
import { MarkerLabelInternalDirective } from './map-marker/marker-label-internal.directive';
import { MapModeSelectionComponent } from './map-mode-selection/map-mode-selection.component';
import { MapModeStateService } from './map-mode-selection/map-mode-state.service';
import { QPointMapModeConfiguration } from './map-mode-selection/mode';
import { MapPolygonStateService } from './map-polygon/map-polygon-state.service';
import { MapPolygonComponent } from './map-polygon/map-polygon.component';
import { MapPolylineStateService } from './map-polyline/map-polyline-state.service';
import { MapPolylineComponent } from './map-polyline/map-polyline.component';
import { mapStyles } from './map.styles';

import LatLng = google.maps.LatLng;
import LatLngLiteral = google.maps.LatLngLiteral;

export * from './default-map-icons';

export interface ILatLng {
  lat: number;
  lng: number;
}

export interface CustomPopupReference {
  reference: HasPosition;
  popup: CustomPopup;
}

type ContextMenuTarget = 'Polyline' | 'Polygon';

@Component({
  selector: 'qpoint-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  providers: [InternalMapService, MapPolygonStateService, MapPolylineStateService, MapModeStateService],
  encapsulation: ViewEncapsulation.None
})
export class MapComponent implements OnInit, OnChanges, OnDestroy, AfterContentInit, AfterViewInit {
  public mapInitialLoaded = false;
  onChanges = new Subject<SimpleChanges>();
  zoomChanges = new Subject<number>();
  triggerContextMenu$ = new Subject<{ event: MouseEvent | PointerEvent, anchor?: HTMLElement | ElementRef, target?: ContextMenuTarget }>();
  private mapIdleTriggeredAction: Subject<void> = new Subject<void>();
  private stopFitToBounds: Subject<void> = new Subject();
  private zoomChangedByComponent$: Subject<void> = new Subject<void>();
  private appendPopupsThrottleSubject$: Subject<void> = new Subject<void>();
  private appendPopupsDelay = 500;
  private zoomChangedByComponent = false;
  private popupRef: PopupRef | null = null;
  private lastRightclickTarget = TargetComponent.Map;
  private polygonListener: google.maps.MapsEventListener[] = [];
  private polylineListener: google.maps.MapsEventListener[] = [];
  private customPopups: CustomPopupReference[] = [];

  constructor(
    private mapService: InternalMapService,
    public mapsLoaderService: QPointMapsLoader,
    private mapPolygonStateService: MapPolygonStateService,
    private mapPolylineStateService: MapPolylineStateService,
    @Optional() private popupService: PopupService, // not used in maps on Android/iOS apps
    private cdr: ChangeDetectorRef,
    public mapModeState: MapModeStateService,
    private viewContainerRef: ViewContainerRef,
    private injector: Injector) {
  }

  public googleMapOptions: google.maps.MapOptions;
  public markers: MapMarkerComponent[] = [];
  public circles: MapCircleComponent[] = [];
  public infoWindows: MapInfoWindowComponent[] = [];
  polygons: MapPolygonComponent[] = [];
  polylines: MapPolylineComponent[] = [];

  private ngUnsubscribe = new Subject<void>();
  private fitToBounceThrottleSubject = new Subject<boolean>();

  @ViewChild(GoogleMap)
  public map: GoogleMap;
  @ViewChildren('divsToShowPopups', {read: ElementRef})
  public divsToShowPopups: QueryList<ElementRef>;
  @ViewChildren(MarkerLabelInternalDirective)
  public markerLabelsToShow: QueryList<MarkerLabelInternalDirective>;
  @ViewChildren(MapMarker)
  public googleMapMarkers: QueryList<MapMarker>;
  @ContentChildren(MapMarkerComponent)
  public markerQueryList: QueryList<MapMarkerComponent>;
  @ViewChildren(MapInfoWindow)
  public googleInfoWindows: QueryList<MapInfoWindow>;
  @ContentChildren(MapInfoWindowComponent)
  public infoWindowsQueryList: QueryList<MapInfoWindowComponent>;

  @ContentChildren(MapPolylineComponent) set mapPolylineComponents(mapPolylineComponents: QueryList<MapPolylineComponent>) {
    this.polylines = mapPolylineComponents.toArray();
  }

  @ContentChildren(MapCircleComponent)
  public circleQueryList: QueryList<MapCircleComponent>;

  @ContentChildren(MapPolygonComponent) set mapPolygonComponents(mapPolygonComponents: QueryList<MapPolygonComponent>) {
    this.polygons = mapPolygonComponents.toArray();
  }

  @ViewChildren(MapPolygon) set gmapsPolygons(gmapsPolygons: QueryList<MapPolygon>) {
    this.setupPolygonEditListener(gmapsPolygons.toArray());
  }

  @ViewChildren(MapPolyline) set gmapsPolylines(gmapsPolylines: QueryList<MapPolyline>) {
    this.setupPolylineEditListener(gmapsPolylines.toArray());
  }

  @ContentChild(MapFooterOverlayDirective, {read: TemplateRef})
  public mapFooterOverlay: TemplateRef<any>;

  @Input()
  public width: string | number | null;
  @Input()
  public zoom: number;
  @Input()
  public maxZoom: number;
  @Input()
  public minZoom: number = 5;
  @Input()
  public minZoomToShowLabels: number = 5;
  @Input()
  public height: string | number | null;
  @Input()
  public center: google.maps.LatLng | ILatLng;
  @Input()
  public zoomPosition: google.maps.ControlPosition;
  @Input()
  public setZoomLevelOnFitBounds = true;
  @Input()
  public stickToCenter = false;
  @Input()
  public fitToBoundsDelay = 200;
  @Input()
  public gestureHandling: google.maps.MapOptions['gestureHandling'] = 'cooperative';
  @Input()
  public disableDefaultUi = false;
  @Input()
  public fitToBoundsOnMarkerChanges = true;
  @Input()
  public enableFullscreenControl = false;
  @Input()
  public stopFitToBoundsOnManualInteraction;
  @Input()
  public debugOutput = false;
  @Input()
  public contextMenu: MapContextMenuEntry[];
  @Input()
  public contextMenuDisabled: Function;

  @Input('modes') set modesSetter(modes: QPointMapModeConfiguration | QPointMapModeConfiguration[]) {
    this.mapModeState.setModes([].concat(modes));
    this.checkModeControls();
  }

  @Output() public mapClick = new EventEmitter<google.maps.MapMouseEvent>();
  @Output() public mapRightClick = new EventEmitter<google.maps.MapMouseEvent>();
  @Output() public mapCenterChanged = new EventEmitter<void>();
  @Output() public mapLoaded = new EventEmitter<google.maps.Map>();
  @Output() polygonClick = new EventEmitter<google.maps.PolyMouseEvent>();

  @HostListener('document:keydown.escape', ['$event'])
  onEscape() {
    this.mapModeState.deactivateAll();
  }

  @HostListener('document:click', ['$event'])
  public documentClick(event: KeyboardEvent): void {
    if (this.popupRef && !this.popupRef.popupElement.contains(event.target as Node)) {
      this.checkAndClosePopupRef();
    }
  }

  get isFullscreen(): boolean {
    return this.map?.googleMap?.getDiv()?.firstChild === document.fullscreenElement;
  }

  public setCenter(latLng: ILatLng, isHandledAsManualInput = false) {
    this.map.googleMap.setCenter(latLng);
    if (isHandledAsManualInput) {
      this.stopFitToBounds.next();
    }
  }

  private allElementsVisibleOnMap(): boolean {
    const markersMissingOnMap = !this.positionsVisibleOnMap(this.markers);
    const circlesMissingOnMap = !this.positionsVisibleOnMap(this.circles);
    const centerMissingOnMap = this.markers?.length === 0 && this.circles?.length === 0 && (!(this.map?.googleMap && this.map.googleMap.getBounds()) || !this.map?.googleMap?.getBounds()?.contains(this.center));
    const elementsMissing = markersMissingOnMap || circlesMissingOnMap || centerMissingOnMap;
    return !elementsMissing;
  }

  private positionsVisibleOnMap(values: HasPosition[]) {
    if (!this.map?.googleMap || !this.map.googleMap.getBounds()) {
      return false;
    }
    return values.length === 0 || values.filter(value => value.shouldFitToBounds() && value.position() && !this.map.googleMap.getBounds().contains(value.position())).length === 0;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.triggerContextMenu$.complete();
  }

  ngOnInit(): void {
    this.zoomChangedByComponent$
      .pipe(
        tap(() => this.zoomChangedByComponent = true),
        debounceTime(200),
        takeUntil(this.ngUnsubscribe))
      .subscribe(value => {
        this.zoomChangedByComponent = false;
      });
    this.mapsLoaderService.mapsApiLoaded$.pipe(take(1)).subscribe(x => {
      this.zoomPosition = this.zoomPosition ?? google.maps.ControlPosition.RIGHT_BOTTOM;
      this.googleMapOptions = {
        streetViewControl: false,
        zoom: this.zoom ?? 15,
        styles: mapStyles,
        gestureHandling: this.gestureHandling,
        disableDoubleClickZoom: true,
        minZoom: this.minZoom,
        maxZoom: this.maxZoom ?? undefined,
        zoomControlOptions: {
          position: this.zoomPosition
        },
        mapTypeControlOptions: {
          mapTypeIds: ['roadmap', 'hybrid'],
          position: google.maps.ControlPosition.LEFT_BOTTOM,
          style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR
        },
        disableDefaultUI: this.disableDefaultUi,
        fullscreenControl: this.enableFullscreenControl,
        center: this.center
      };

      this.appendPopupsThrottleSubject$.pipe(throttleTime(this.appendPopupsDelay)).subscribe(() => this.appendPopups());

      combineLatest([this.onChanges, this.mapsLoaderService.mapsApiLoaded$]).pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(([changes, apiLoaded]) => {
          if (changes['center']) {
            this.googleMapOptions.center = changes['center'].currentValue;
            this.fitToBounds();
          }
          if (changes['zoom']) {
            this.zoomChanges.next(changes['zoom'].currentValue);
          }

          this.checkModeControls();
        });
      combineLatest([this.fitToBounceThrottleSubject, this.mapsLoaderService.mapsApiLoaded$])
        .pipe(debounceTime(this.fitToBoundsDelay), takeUntil(this.stopFitToBounds), takeUntil(this.ngUnsubscribe))
        .subscribe(([value, apiLoaded]) => this.fitToBoundsOnGoogleMap(value));

      combineLatest([this.zoomChanges, this.mapsLoaderService.mapsApiLoaded$])
        .pipe(debounceTime(50), takeUntil(this.ngUnsubscribe))
        .subscribe(([zoom, apiloaded]) => {
          this.zoomChangedByComponent$.next();
          const zoomToSet = zoom ?? 15;
          this.debugLog('setting zoom to', zoomToSet);
          this.googleMapOptions.zoom = zoomToSet;
          this.map?.googleMap?.setZoom(zoomToSet);
        });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.debugLog('on changes component', changes);
    this.onChanges.next(changes);
  }

  ngAfterContentInit(): void {
    this.mapsLoaderService.mapsApiLoaded$.pipe().subscribe(x => {
      this.markers = this.markerQueryList.toArray();
      this.circles = this.circleQueryList.toArray();
      this.infoWindows = this.infoWindowsQueryList.toArray();

      this.markerQueryList.changes.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
        this.markers = this.markerQueryList.toArray();
      });
      this.circleQueryList.changes.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
        this.circles = value;
        this.fitToBounds();
      });

      this.infoWindowsQueryList.changes.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
        this.infoWindows = value;
      });

      this.mapService.markerChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(values => {
        if (this.fitToBoundsOnMarkerChanges) {
          this.fitToBounds();
        }
        this.appendPopups();
      });

      this.checkModeControls();
    });

    this.triggerContextMenu$.pipe(
      debounceTime(80),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(event => {
      this.openContextMenu(event.event, event.anchor, event.target);
    });
  }

  ngAfterViewInit(): void {
    merge(this.divsToShowPopups.changes, this.markerQueryList.changes, this.markerLabelsToShow.changes)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(divsToShow => {
        this.appendPopups();
      });

    this.googleMapMarkers.changes.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
      this.debugLog('google map markers changed', value, this.markers);
      timer(100).subscribe(() => this.fitToBounds());
    });
    this.markerLabelsToShow.changes.subscribe(() => this.appendPopups());
    this.mapsLoaderService.mapsApiLoaded$.pipe(delay(50), take(1)).subscribe(value => {
      timer(50).subscribe(() => {
        this.debugLog('fitting bounds after view init', this.markers, this.markerQueryList);
        this.appendPopups();
        this.fitToBoundsOnGoogleMap(false);
      });
    });
  }

  private appendPopups() {
    this.mapsLoaderService.mapsApiLoaded$.pipe(filter(value => value), take(1)).subscribe(x => {
      if (!this.divsToShowPopups || !this.infoWindowsQueryList || !this.markerLabelsToShow) {
        return;
      }
      const divsToShowArray = this.divsToShowPopups.toArray();
      this.removePopupsWithChangedPosition();

      for (let i = 0; i < divsToShowArray.length; i++) {
        const divsToShowArrayElement = divsToShowArray[i];
        const mapInfo = this.infoWindowsQueryList.toArray()[i];
        const position = mapInfo.options.position as google.maps.LatLng;
        if (!position) {
          continue;
        }
        if (this.customPopups.find(value => value.reference === mapInfo)) {
          continue;
        }
        const customPopup = new CustomPopup(
          position ? position : new google.maps.LatLng(42, 42),
          divsToShowArrayElement.nativeElement,
        );
        customPopup.overlayView.setMap(this.map.googleMap);
        this.customPopups.push({popup: customPopup, reference: mapInfo});
      }
      const mapBounds = this.map?.getBounds();
      const markerLabelsToShow = this.markerLabelsToShow.toArray();
      for (let i = 0; i < markerLabelsToShow.length; i++) {
        const markerLabelDirective = markerLabelsToShow[i];
        const qpointMarker = markerLabelDirective.markerRef;
        if (!qpointMarker.labelTemplateRef) {
          continue;
        }
        const position = qpointMarker.options.position as google.maps.LatLng;
        if (!position) {
          continue;
        }
        const existingPopup = this.customPopups.find(value => value.reference === qpointMarker);
        const currentZoom = this.map?.googleMap?.getZoom();
        if (existingPopup) {
          if (currentZoom < this.minZoomToShowLabels || !mapBounds?.contains(qpointMarker.position())) {
            existingPopup.popup.overlayView.setMap(null);
            this.customPopups.splice(this.customPopups.indexOf(existingPopup), 1);
          }
          continue;
        }
        if (currentZoom >= this.minZoomToShowLabels && mapBounds?.contains(qpointMarker.position())) {
          const customPopup = new CustomPopup(
            position ? position : new google.maps.LatLng(42, 42),
            markerLabelDirective.elementRef.nativeElement,
            CustomPopupPosition.right,
            qpointMarker.labelTemplateDirective
          );
          customPopup.overlayView.setMap(this.map.googleMap);
          this.customPopups.push({popup: customPopup, reference: qpointMarker});
        }
      }
    });
  }

  public fitToBounds(checkVisibilityOfMarkers = true) {
    this.fitToBounceThrottleSubject.next(checkVisibilityOfMarkers);
  }

  public manualFitToBounds(checkVisibilityOfMarkers = true, setZoomOnActualLevel = true) {
    this.fitToBoundsOnGoogleMap(checkVisibilityOfMarkers, setZoomOnActualLevel);
  }

  private fitToBoundsOnGoogleMap(checkVisibilityOfMarkers = true, setZoomOnActualLevel = true) {
    const pointsForFitBounds: (LatLng | LatLngLiteral)[] = [];
    if (checkVisibilityOfMarkers && this.allElementsVisibleOnMap() && !this.stickToCenter) {
      this.debugLog('ignoring fit to bounds because elements visible', this.markers, this.circles);
      return;
    }
    const mapMarkers = this.googleMapMarkers.toArray();
    for (let i = 0; i < mapMarkers.length; i++) {
      const marker = mapMarkers[i];
      this.debugLog('considering marker', marker);
      if (marker.getPosition() && this.markerQueryList.get(i).shouldFitToBounds()) {
        this.debugLog('append bounds marker', marker);
        pointsForFitBounds.push(marker.getPosition());
      }
    }

    this.circles.forEach((circle, index) => {
      if (circle.options?.center && this.circleQueryList.get(index).shouldFitToBounds()) {
        pointsForFitBounds.push(circle.options.center);
      }
    });

    let fitBoundToCenterOnly = false;
    if (pointsForFitBounds.length === 0 && this.center) {
      pointsForFitBounds.push(this.center);
      // zoom when only center is set and center is only latLng
      fitBoundToCenterOnly = !this.stickToCenter;
    }

    const bounds = new google.maps.LatLngBounds();
    for (const bound of pointsForFitBounds) {
      bounds.extend(bound);
    }
    this.debugLog('map is ready', this.map);
    this.debugLog('fitting bounds based on points', pointsForFitBounds);
    this.debugLog('map is already active', this.map);
    if (this.map) {
      timer(1).subscribe(() => {
        if (this.stickToCenter) {
          if (!this.center || bounds.getCenter()) {
            this.debugLog('no center available');
          }
          this.map.googleMap.setCenter(this.center ?? bounds.getCenter());
        } else {
          if (bounds.isEmpty()) {
            this.debugLog('no bounds detected');
            return;
          }

          this.zoomChangedByComponent$.next();
          this.debugLog('fit to bounds', bounds.toJSON());
          this.map.fitBounds(bounds);

        }
      });

      this.debugLog('zoom after fitToBound',
        'setZoomOnActualLevel: ' + setZoomOnActualLevel,
        'setZoomLevelOnFitBounds: ' + this.setZoomLevelOnFitBounds,
        'onlyCenter: ' + fitBoundToCenterOnly);
      if (setZoomOnActualLevel) {
        const isSinglePoi = mapMarkers.length === 1 && pointsForFitBounds.length === 1;
        this.mapIdleTriggeredAction.pipe(take(1), takeUntil(this.ngUnsubscribe))
          .subscribe(() => {
            if (this.setZoomLevelOnFitBounds || fitBoundToCenterOnly || isSinglePoi) {
              this.zoomChanges.next(this.zoom);
            }
          });
      }
    }

  }

  mapIdle($event: void) {
    if (!this.mapInitialLoaded) {
      this.mapInitialLoaded = true;
      this.mapLoaded.next(this.map.googleMap);
    }
    this.mapIdleTriggeredAction.next();

  }

  markerDragEnd($event: google.maps.MapMouseEvent, marker: MapMarkerComponent) {
    marker.mapDragend.next($event);
    this.fitToBounds();
  }

  public googleMapMapCenterChanged() {
    this.appendPopupsThrottleSubject$.next();
    this.mapCenterChanged.next();
  }

  private removePopupsWithChangedPosition() {
    const popupsToKeep = [];
    for (const customPopup of this.customPopups) {
      const isLngDifferent = customPopup.popup.position()?.lng !== customPopup.reference.position().lng;
      const isLatDifferent = customPopup.popup.position()?.lat !== customPopup.reference.position().lat;
      if (isLngDifferent || isLatDifferent) {
        customPopup.popup.overlayView.setMap(null);
      } else {
        popupsToKeep.push(customPopup);
      }
    }
    this.customPopups = [...popupsToKeep];
  }

  mapDragend($event: void) {
    if (this.stopFitToBoundsOnManualInteraction) {
      this.debugLog('stop fit to bounds');
      this.stopFitToBounds.next();
    }
  }

  private debugLog(...message: any) {
    if (!this.debugOutput) {
      return;
    }
    console.log([...message]);
  }

  zoomChanged($event: void) {
    if (this.stopFitToBoundsOnManualInteraction && !this.zoomChangedByComponent) {
      this.stopFitToBounds.next();
    }
    this.appendPopups();
  }

  public openInfoWindow(infoWindow: MapInfoWindow, marker: MapMarker): void {
    this.googleInfoWindows.toArray().forEach(infoWindow => infoWindow.close());
    infoWindow.open(marker);
  }

  public onMapClicked($event: google.maps.MapMouseEvent): void {
    this.googleInfoWindows.toArray().forEach(infoWindow => infoWindow.close());
    this.checkAndClosePopupRef();
    this.mapClick.next($event);
  }

  public onMapRightClick($event: google.maps.MapMouseEvent): void {
    this.mapRightClick.next($event);
    this.resetLastRightClickTarget();
    this.triggerContextMenu$.next({event: $event.domEvent as MouseEvent})
  }

  setActiveMapPolygonComponent(component: MapPolygonComponent, event: google.maps.PolyMouseEvent, index: number) {    
    this.mapRightClick.emit(event);
    this.debugLog('set active map polygon');
    if (!component.canDeleteVertex(index) && event.vertex !== undefined) {
      return;
    }
    this.resetActivePolyline();

    this.mapPolygonStateService.setActivePolygonComponent(component, event, index);
    this.lastRightclickTarget = MapComponent.detectTargetComponent( event, TargetComponent.Polygon );
    this.triggerContextMenu$.next(
      {
        event: event.domEvent as MouseEvent,
        anchor: event.vertex != null || event.edge != null ? event.domEvent.target as HTMLElement : undefined,
        target: 'Polygon',
      });
  }


  setActiveMapPolylineComponent(polyline: MapPolylineComponent, event: google.maps.PolyMouseEvent) {
    this.mapRightClick.emit(event);
    this.debugLog('set active map polyline');
    if (!polyline.canDeleteVertex) {
      return;
    }
    this.resetActivePolygon();

    this.mapPolylineStateService.setActivePolyline(polyline, event);
    this.lastRightclickTarget = MapComponent.detectTargetComponent( event, TargetComponent.Polyline );
    this.triggerContextMenu$.next(
      {
        event: event.domEvent as MouseEvent,
        anchor: event.vertex != null || event.edge != null ? event.domEvent.target as HTMLElement : undefined,
        target: 'Polyline'
      });
  }

  private static detectTargetComponent( event: google.maps.PolyMouseEvent, defaultTargetComponent: TargetComponent ) : TargetComponent {
    if( event.vertex ) return TargetComponent.Vertex;
    if( event.edge ) return TargetComponent.Edge;
    return defaultTargetComponent;
  }

  deletePolygonVertex() {
    this.mapPolygonStateService.deletePolygonVertex();
    this.resetActivePolygon();
    this.checkAndClosePopupRef();
  }

  deletePolylineVertex() {
    this.mapPolylineStateService.deletePolylineVertex();
    this.resetActivePolyline();
    this.checkAndClosePopupRef();
  }

  resetActivePolygon() {
    this.mapPolygonStateService.setActivePolygonComponent(null, null, null);
  }

  resetActivePolyline() {
    this.mapPolylineStateService.setActivePolyline(null, null);
  }

  openContextMenu($event: MouseEvent, anchor?: HTMLElement | ElementRef, target?: ContextMenuTarget) {
    this.checkAndClosePopupRef();

    if (this.contextMenuDisabled ? this.contextMenuDisabled() : false) {
      this.resetLastRightClickTarget();
      return;
    }

    const visibleContextMenuItems = this.contextMenu
      .filter(i => i.targetComponents.includes(this.lastRightclickTarget) &&
        ((i?.modes || [])?.some(mode => this.mapModeState.isModeActive(mode)) || (i.modes || [])?.length === 0)
      );
    if (visibleContextMenuItems.length === 0) {
      this.resetLastRightClickTarget();
      return;
    }

    visibleContextMenuItems.filter(i => i.internalFunctionType === FunctionType.DeleteVertex)
      .forEach(i => {
        switch (target) {
          case 'Polygon':
            return i.entryClick = this.deletePolygonVertex.bind(this);
          case 'Polyline':
            return i.entryClick = this.deletePolylineVertex.bind(this);
        }
      });

    const isFullscreen = this.isFullscreen;

    this.popupRef = this.popupService.open({
      content: MapContextMenuComponent,
      offset: {
        top: isFullscreen ? $event.y : anchor ? 0 : $event.pageY,
        left: isFullscreen ? $event.x : anchor ? 0 : $event.pageX,
      },
      positionMode: 'absolute',
      popupClass: 'rounded-0',
      anchor,
      collision: {horizontal: 'flip', vertical: 'fit'}
    });

    if (isFullscreen) {
      this.map.googleMap.getDiv().firstChild.appendChild(this.popupRef.popupElement);
    }

    const instance: MapContextMenuComponent = this.popupRef.content.instance;
    instance.contextMenu = visibleContextMenuItems;
    instance.event = $event;
    instance.geoCoordinate = this.toLatLng($event);
    instance.popupRef = this.popupRef;

    if (this.lastRightclickTarget === TargetComponent.Vertex || this.lastRightclickTarget === TargetComponent.Polygon || this.lastRightclickTarget === TargetComponent.Polyline) {
      switch (target) {
        case 'Polyline':
          return instance.data = this.mapPolylineStateService.activePolylineComponent.polyline;
        case 'Polygon':
          return instance.data = this.mapPolygonStateService.activePolygonComponent.polygon;
      }
    }

    instance.popupRef = this.popupRef;
    this.resetLastRightClickTarget();
  }

  checkAndClosePopupRef() {
    if (this.popupRef) {
      this.popupRef.close();
    }
  }

  private toLatLng($event: MouseEvent): LatLng {
    const bound = ($event.target as HTMLElement).getBoundingClientRect();
    const x = $event.clientX - bound.left;
    const y = $event.clientY - bound.top;

    const projection = this.map.getProjection();
    const topRight = projection.fromLatLngToPoint(this.map.getBounds().getNorthEast());
    const bottomLeft = projection.fromLatLngToPoint(this.map.getBounds().getSouthWest());
    const scale = 1 << this.map.getZoom();

    return projection.fromPointToLatLng(new google.maps.Point(x / scale + bottomLeft.x, y / scale + topRight.y));
  }

  private setupPolygonEditListener(polygons: MapPolygon[]) {
    this.polygonListener.forEach(listener => listener.remove());

    polygons.forEach((polygon, index) => {
      const setAt = google.maps.event.addListener(polygon.getPath(), 'set_at', () => {
        this.mapPolygonStateService.updatePolygon(polygon, this.polygons[index], index);
      });

      const insertAt = google.maps.event.addListener(polygon.getPath(), 'insert_at', () => {
        this.mapPolygonStateService.updatePolygon(polygon, this.polygons[index], index);
      });

      this.polygonListener.push(setAt, insertAt);
    });
  }

  private setupPolylineEditListener(polylines: MapPolyline[]) {
    this.polylineListener.forEach(listener => listener.remove());

    polylines.forEach((polyline, index) => {
      const setAt = google.maps.event.addListener(polyline.getPath(), 'set_at', event => {
        this.mapPolylineStateService.updatePolyline(polyline, this.polylines[index]);
        this.cdr.markForCheck();
      });

      const insertAt = google.maps.event.addListener(polyline.getPath(), 'insert_at', () => {
        this.mapPolylineStateService.updatePolyline(polyline, this.polylines[index]);
        this.cdr.markForCheck();
      });

      this.polylineListener.push(setAt, insertAt);
    });
  }

  polylineTrackBy(index, item: MapPolylineComponent) {
    return item.polyline?.coordinates;
  }

  private checkModeControls() {
    if (!this.map) {
      return;
    }

    this.map.controls[google.maps.ControlPosition.TOP_LEFT].clear();

    const wrapper = document.createElement('div');
    wrapper.style.margin = '10px'; // default google maps margin
    wrapper.style.userSelect = 'none';
    const component: ComponentRef<MapModeSelectionComponent> =
      this.viewContainerRef.createComponent(
        MapModeSelectionComponent,
        {
          injector: this.injector,
          index: null
        }
      );
    wrapper.appendChild(component.location.nativeElement);
    this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(wrapper);
  }


  /**
   * can be used to append dialogs that should be available in fullscreen
   */
  getMapDialogAnchor(): HTMLElement {
    if (!this.map) {
      return undefined;
    }
    this.map?.controls[google.maps.ControlPosition.TOP_CENTER].clear();
    const div = document.createElement('div');
    this.map?.controls[google.maps.ControlPosition.TOP_CENTER].push(div);

    return div;
  }

  resetLastRightClickTarget() {
    this.lastRightclickTarget = TargetComponent.Map;
  }
}
