/**
 * Settings for the visual appearance of the user interface.
 *
 * * @export
 * @class ThemeSettings
 */
export class ThemeSettings {
  /**
   *
   * Dark mode status enabled.
   * @type {boolean}
   * @memberof ThemeSettings
   */
  public darkMode = false;
}

export enum ThemeState {
  Dark = 'dark',
  Light = 'light',
  System = 'system',
}
