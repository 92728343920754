import { HasPosition } from './has-position';
import { MapMarkerLabelDirective } from './map-marker/map-marker-label.directive';

export enum CustomPopupPosition {
  top,
  right
}

export class CustomPopup implements HasPosition {
  positionOnMap: google.maps.LatLng;
  containerDiv: HTMLDivElement;
  placeRight = false;
  public overlayView: google.maps.OverlayView;
  private content;
  private labelDirective: MapMarkerLabelDirective;

  constructor(position: google.maps.LatLng, content: HTMLElement, popupPosition = CustomPopupPosition.top, labelDirective?: MapMarkerLabelDirective) {
    this.overlayView = new google.maps.OverlayView();
    this.overlayView.onAdd = this.onAdd.bind(this);
    this.overlayView.onRemove = this.onRemove.bind(this);
    this.overlayView.draw = this.draw.bind(this);
    this.positionOnMap = position;
    this.content = content;
    this.labelDirective = labelDirective;
    this.containerDiv = document.createElement('div');
    this.containerDiv.classList.add('popup-container');
    switch (popupPosition) {
      case CustomPopupPosition.right:
        content.classList.add('custom-overlay-window-right');
        if (labelDirective) {
          this.containerDiv.style.marginLeft = labelDirective.shiftLabelHorizontal + 'px';
          this.containerDiv.style.marginTop= labelDirective.shiftLabelVertical + 'px';
        }

        break;
      case CustomPopupPosition.top:
      default:
        content.classList.add('custom-overlay-window');
    }

    // This zero-height div is positioned at the bottom of the bubble.
    const windowAnchor = document.createElement('div');

    windowAnchor.classList.add('custom-overlay-window-anchor');
    windowAnchor.appendChild(content);

    // This zero-height div is positioned at the bottom of the tip.
    this.containerDiv.appendChild(windowAnchor);

    // Optionally stop clicks, etc., from bubbling up to the map.
    google.maps.OverlayView.preventMapHitsAndGesturesFrom(this.containerDiv);
  }

  /** Called when the popup is added to the map. */
  onAdd() {
    // tslint:disable-next-line:no-non-null-assertion
    this.overlayView.getPanes()!.floatPane.appendChild(this.containerDiv);
  }

  /** Called when the popup is removed from the map. */
  onRemove() {
    if (this.containerDiv.parentElement) {
      this.containerDiv.parentElement.removeChild(this.containerDiv);
    }
  }

  /** Called each frame when the popup needs to draw itself. */
  draw() {
    const divPosition = this.overlayView.getProjection().fromLatLngToDivPixel(
      this.positionOnMap
    )!;
    // Hide the popup when it is far out of view.

    const display =
      Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000
        ? 'block'
        : 'none';

    if (display === 'block') {
      this.containerDiv.style.left = divPosition.x + 'px';
      this.containerDiv.style.top = divPosition.y + 'px';
    }

    if (this.containerDiv.style.display !== display) {
      this.containerDiv.style.display = display;
    }
  }

  position(): google.maps.LatLng | google.maps.LatLngLiteral {
    return this.positionOnMap;
  }

  shouldFitToBounds(): boolean {
    return true;
  }
}

