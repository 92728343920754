import { Component, EventEmitter, Input, Output } from '@angular/core';

import { HasPosition } from '../has-position';

@Component({
  selector: 'qpoint-map-circle',
  template: ''
})
export class MapCircleComponent implements HasPosition {

  @Input()
  public options: google.maps.CircleOptions;

  @Output()
  public circleClick = new EventEmitter<google.maps.MapMouseEvent>();

  @Input()
  public fitToBounds = true;

  position(): google.maps.LatLng | google.maps.LatLngLiteral {
    return this.options?.center;
  }

  shouldFitToBounds(): boolean {
    return this.fitToBounds;
  }
}

