import { Injectable } from '@angular/core';
import { IQPointLocalizationInfo } from '../../../projects/qpoint-i18n/src/lib/services/localization';
import { ILanguage } from '@qpoint/i18n';
import { firstValueFrom, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocalizationInfoService implements IQPointLocalizationInfo {
  defaultLocale = 'en-US';

  getCultureInfoDatetime(): string {
    return 'de-CH';
  }

  getCultureInfoNumber(): string {
    return 'de-CH';
  }

  getLocale(): string {
    return 'de';
  }

  getLanguages(): Promise<ILanguage[]> {
    return firstValueFrom<ILanguage[]>(of<ILanguage[]>([
      { display: 'Englisch', code: 'en-US' },
      { display: 'Deutsch', code: 'de-DE' },
      { display: 'Romänisch', code: 'ro-RO' }
    ]));
  }


}
