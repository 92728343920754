import { Component, OnInit } from '@angular/core';

import { MessageTypeEnum } from '../../../../projects/qpoint-layout/src/lib/components/info-message/info-message.component';
import { NgFor } from '@angular/common';
import { QPointToolbarComponent, QPointContentContainerComponent, QPointInfoMessageComponent, InfoMessageCallback } from '@qpoint/layout';

@Component({
    selector: 'qpoint-control-gallery-info-message',
    templateUrl: './control-gallery-info-message.component.html',
    styleUrls: ['./control-gallery-info-message.component.scss'],
    standalone: true,
    imports: [QPointToolbarComponent, QPointContentContainerComponent, NgFor, QPointInfoMessageComponent]
})
export class ControlGalleryInfoMessageComponent implements OnInit {
  MessageTypeEnum = MessageTypeEnum;
  public callback: InfoMessageCallback;

  ngOnInit() {
    this.callback = { callbackFn: this.showInfo, title: 'Anzeigen' };
  }

  get messageTypes(): string[] {
    return Object.keys(MessageTypeEnum);
  }

  public onBannerClicked() {
    console.log("Banner clicked!");
  }

  public showInfo() {
    console.log('show info...');
  }
}
