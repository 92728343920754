import { Component, OnInit } from '@angular/core';
import { TextBoxModule } from '@progress/kendo-angular-inputs';
import { QPointTableRowComponent } from '@qpoint/forms';
import { NgbAccordionDirective, NgbAccordionItem, NgbAccordionHeader, NgbAccordionToggle, NgbAccordionButton, NgbCollapse, NgbAccordionCollapse, NgbAccordionBody } from '@ng-bootstrap/ng-bootstrap';
import { QPointToolbarComponent, QPointContentContainerComponent } from '@qpoint/layout';

@Component({
    selector: 'app-accordion-test',
    templateUrl: './accordion-test.component.html',
    styleUrls: ['./accordion-test.component.scss'],
    standalone: true,
    imports: [QPointToolbarComponent, QPointContentContainerComponent, NgbAccordionDirective, NgbAccordionItem, NgbAccordionHeader, NgbAccordionToggle, NgbAccordionButton, NgbCollapse, NgbAccordionCollapse, NgbAccordionBody, QPointTableRowComponent, TextBoxModule]
})
export class AccordionTestComponent implements OnInit {
  ngOnInit(): void {
    console.log('Init AccordionTestComponent...');
  }
}
