import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { interval } from 'rxjs';
import { QPointInlineEditingInputComponent } from '../../../../projects/qpoint-forms/src/public_api';
import {
  PlacesAutoCompleteResult
} from '../../../../projects/qpoint-maps/map-inputs/src/map-input/lib/components/places-autocomplete/places-autocomplete.component';
import {
  IPlace
} from '../../../../projects/qpoint-maps/map-inputs/src/map-input/lib/components/places-suggestlist/places-suggestlist.component';
import { QPointMapsInputsModule } from '../../../../projects/qpoint-maps/map-inputs/src/map-input/maps-inputs.module';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { QPointButtonComponent } from '../../../../projects/qpoint-button/src/lib/button/button.component';
import { TextBoxModule, SharedModule } from '@progress/kendo-angular-inputs';
import { QPointTableRowComponent, QPointReactiveFormInputGroupComponent, QPointInlineEditingInputComponent as QPointInlineEditingInputComponent_1 } from '@qpoint/forms';
import { QPointToolbarComponent, QPointContentContainerComponent } from '@qpoint/layout';

@Component({
    selector: 'app-control-gallery-selection',
    templateUrl: './control-gallery-reactive-input.component.html',
    styleUrls: ['./control-gallery-reactive-input.component.scss'],
    standalone: true,
    imports: [QPointToolbarComponent, QPointContentContainerComponent, FormsModule, ReactiveFormsModule, QPointTableRowComponent, QPointReactiveFormInputGroupComponent, TextBoxModule, QPointButtonComponent, SharedModule, DropDownListModule, QPointInlineEditingInputComponent_1, QPointMapsInputsModule]
})
export class ControlGalleryReactiveInputComponent implements OnInit {

  @ViewChild('referenceInlineEdit')
  public referenceInlineEdit: QPointInlineEditingInputComponent;
  public testFormGroup;

  public isSubmitted = true;
  public dropdownData = ['Ein Eintrag', 'Zweiter Eintrag'];

  autocompleteAddressFormGroup = new UntypedFormGroup({
    postalAddress: new UntypedFormControl(),
    postalCode: new UntypedFormControl(),
    city: new UntypedFormControl(),
    country: new UntypedFormControl(),
    suggest: new UntypedFormControl(),
  });

  private readonly placesSuggestStartValue: IPlace = {
    identifier: 'Stroheckgasse 11, 1090 Wien',
    lat: 48.226534745811485,
    lng: 16.364611713848753
  }

  public template = null;

  constructor() {
    this.autocompleteAddressFormGroup.get('postalAddress')
      .valueChanges.subscribe(
      {
        next: (value) => {
          console.log('value changed', value);
        }
      }
    )
  }

  ngOnInit() {
    this.testFormGroup = new UntypedFormGroup({
      validFormControl: new UntypedFormControl(null),
      requiredFormControl: new UntypedFormControl(null),
      textFormControl: new UntypedFormControl(null, [Validators.maxLength(15)]),
      placeFormControl: new UntypedFormControl(this.placesSuggestStartValue),
    });
    this.textFormControl.valueChanges.subscribe((text) => console.log('value changed', text));
    interval(3500).subscribe(value => this.referenceInlineEdit.setInputFieldValue('test'));
  }

  public get validFormControl(): UntypedFormControl {
    return this.testFormGroup.get('validFormControl');
  }

  public get requiredFormControl() {
    return this.testFormGroup.get('requiredFormControl');
  }

  public get textFormControl(): UntypedFormControl {
    return this.testFormGroup.get('textFormControl');
  }

  public logForm() {
    console.log(this.testFormGroup.value);
  }

  logEvent($event: boolean) {
    console.log($event);
  }

  get postalAddress() {
    return this.autocompleteAddressFormGroup.get('postalAddress') as UntypedFormControl;
  }

  get postalCode() {
    return this.autocompleteAddressFormGroup.get('postalCode') as UntypedFormControl;
  }

  get city() {
    return this.autocompleteAddressFormGroup.get('city') as UntypedFormControl;
  }

  get country() {
    return this.autocompleteAddressFormGroup.get('country') as UntypedFormControl;
  }

  placeSelected(result: PlacesAutoCompleteResult) {
    console.log("PLACE SELECTED: ", result);

    if (result.city) {
      this.city.patchValue(result.city);
    }
    if (result.postalCode) {
      this.postalCode.patchValue(result.postalCode);
    }
    if (result.country) {
      this.country.patchValue(result.country);
    }
    if (result.address) {
      this.postalAddress.patchValue(result.address + (result.streetNumber ? ` ${result.streetNumber}` : ''));
    }
  }

  placeSuggestListSelected(result: IPlace) {
    console.log("PLACE SUGGESTLIST SELECTED: ", result);
  }
}
