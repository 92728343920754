import { Component } from '@angular/core';
import { QPointModalService } from '../../../projects/qpoint-layout/src/lib/components/modal/qpoint-modal.service';
import { ModalDemoComponent } from './modal-demo/modal-demo.component';
import { QPointButtonComponent } from '../../../projects/qpoint-button/src/lib/button/button.component';
import { QPointToolbarComponent, QPointContentContainerComponent } from '@qpoint/layout';

@Component({
    selector: 'qpoint-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
    standalone: true,
    imports: [QPointToolbarComponent, QPointContentContainerComponent, QPointButtonComponent]
})
export class ModalComponent {

  constructor(private qpointModalService: QPointModalService) { }

  openModal() {
    const ref = this.qpointModalService.open<ModalDemoComponent>(ModalDemoComponent);

    ref.closed.subscribe(
      closed => console.log({ closed })
    );
  }
}
