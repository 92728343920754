import { Observable } from 'rxjs';
import { EnvironmentProviders, inject, InjectionToken, makeEnvironmentProviders, Type } from '@angular/core';

export interface IQPointImageLoaderService {
  getProfileImage(imageUri: string): Observable<Blob>;
}

export const DI_IMAGE_LOADER_SERVICE = new InjectionToken<IQPointImageLoaderService>('QPointImageLoaderService');

export function provideImageLoaderService(useClass: Type<IQPointImageLoaderService>): EnvironmentProviders {
  return makeEnvironmentProviders([{
    provide: DI_IMAGE_LOADER_SERVICE,
    useClass
  }]);
}

export function injectImageLoaderService(): IQPointImageLoaderService {
  return inject(DI_IMAGE_LOADER_SERVICE);
}
