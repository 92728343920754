import { Component, OnInit } from '@angular/core';
import { QPointToolbarComponent, QPointContentContainerComponent } from '@qpoint/layout';

@Component({
    selector: 'app-control-gallery-description',
    templateUrl: './description.component.html',
    styleUrls: ['./description.component.scss'],
    standalone: true,
    imports: [QPointToolbarComponent, QPointContentContainerComponent]
})
export class ControlGalleryDescriptionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
}
