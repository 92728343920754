import { BehaviorSubject, Observable } from 'rxjs';
import { UserProfile } from './model/user.model';
import IUser = UserProfile.IUser;
import ICulture = UserProfile.ICulture;
import { EnvironmentProviders, inject, InjectionToken, makeEnvironmentProviders, Type } from '@angular/core';


export interface IQPointProfileService {
  getCultures(): BehaviorSubject<ICulture[]>;
  getUserProfileData(): Observable<IUser>;
  saveUserProfile(user: IUser, imageHasChanged: boolean): void;
  getCurrentUserDefaultProfileImage(): Observable<Blob>;
}

export const DI_USER_PROFILE_SERVICE = new InjectionToken<IQPointProfileService>('QPointUserProfileService');

export function provideUserProfileService(useClass: Type<IQPointProfileService>): EnvironmentProviders {
  return makeEnvironmentProviders([{
    provide: DI_USER_PROFILE_SERVICE,
    useClass
  }])
}

export function injectUserProfileService(): IQPointProfileService {
  return inject(DI_USER_PROFILE_SERVICE);
}
