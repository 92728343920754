<qpoint-toolbar [title]="'Lists'"></qpoint-toolbar>

<qpoint-content-container>
  <!-- Lists -->
  <div class="card">
    <div class="card-header">Listen</div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-4">
          <p>Geordnete Liste (&lt;ol&gt;)</p>
          <ol>
            <li>Bindemittel (&lt;li&gt;)</li>
            <li>Wasser</li>
            <li>Gesteinskörnung</li>
          </ol>
        </div>
        <div class="col-md-4">
          <p>Ungeordnete Liste (&lt;ul&gt;)</p>
          <ul>
            <li>Asphalt (&lt;li&gt;)</li>
            <li>Beton</li>
            <li>Kies</li>
          </ul>
        </div>
        <div class="col-md-4">
          <p>Unformatierte Liste (&lt;ul class="list-unstyled"&gt;)</p>
          <ul class="list-unstyled">
            <li>Rot (&lt;li&gt;)</li>
            <li>Gelb</li>
            <li>Blau</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- Draggable List -->
  <div class="card">
    <div class="card-header">Sortierbare Liste (Q Plant Articles)</div>
    <div class="card-body" style="background-color: lightgrey">
      <qpoint-button (click)="readonly=!readonly">switch readonly</qpoint-button><br>
      <br>
      <qpoint-draggable-list [items]="draggableListItems" (repositionItem)="onRepositionItem($event)"
        [readonly]="readonly">
        <ng-template let-item="item">
          <div class="multiple-articles-item">
            <div class="multiple-articles-item-column multiple-articles-item-time">
              <div *ngIf="item.scheduledDt">
                {{ item.scheduledDt | qPointDateFormat:QPointDateFormat.ShortTime }}
              </div>
              <div *ngIf="item.scheduledDt && item.showDate">
                {{ item.scheduledDt | qPointDateFormat }}
              </div>
              <div *ngIf="item.pauseMinutes">
                + {{ item.pauseMinutes }} min
              </div>
            </div>
            <div class="multiple-articles-item-column multiple-articles-item-article">
              <div class="article-identifier">{{ item.articleIdentifier }}</div>
              <div class="article-group-identifier">{{ item.articleGroupIdentifier }}</div>
            </div>
            <div class="multiple-articles-item-column multiple-articles-item-quantity">
              {{ item.quantity | qPointNumberFormat }} {{ item.quantityUnit }}
            </div>
          </div>
        </ng-template>
      </qpoint-draggable-list>
    </div>
  </div>
</qpoint-content-container>