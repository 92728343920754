import { Component } from '@angular/core';
import { UntypedFormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QPointColorPickerComponent } from '@qpoint/forms';
import { QPointButtonComponent } from '../../../../../projects/qpoint-button/src/lib/button/button.component';

@Component({
    selector: 'qpoint-color-picker-demo',
    templateUrl: './color-picker-demo.component.html',
    styleUrls: ['./color-picker-demo.component.scss'],
    standalone: true,
    imports: [QPointButtonComponent, QPointColorPickerComponent, FormsModule, ReactiveFormsModule]
})
export class ColorPickerDemoComponent {

  fc = new UntypedFormControl(null);

  toggleDisabled() {
    this.fc.disabled ? this.fc.enable() : this.fc.disable();
  }
}
