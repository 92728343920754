import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { QPointButtonComponent } from '../../../../projects/qpoint-button/src/lib/button/button.component';
import { FormsModule } from '@angular/forms';
import { QPointModalHeaderComponent, QPointModalBodyComponent, QPointModalFooterComponent } from '@qpoint/layout';

@Component({
    selector: 'qpoint-modal-demo',
    templateUrl: './modal-demo.component.html',
    styleUrls: ['./modal-demo.component.scss'],
    standalone: true,
    imports: [QPointModalHeaderComponent, QPointModalBodyComponent, FormsModule, QPointModalFooterComponent, QPointButtonComponent]
})
export class ModalDemoComponent {

  input = '';

  constructor(private ngbActive: NgbActiveModal) {
  }

  close() {
    this.ngbActive.dismiss();
  }

  save() {
    this.ngbActive.close(this.input);
  }

  get disabled(): boolean {
    return this.input == '';
  }
}
