import { IQPointMapsApiKeyService } from '../../../projects/qpoint-maps/src/lib/services/google-api-key.service';

export class MapsApiKeyService implements IQPointMapsApiKeyService {

  constructor() {
  }

  getGoogleMapsApiKey(): Promise<string> {
    return Promise.resolve('AIzaSyDttusj6eMhXaQ3nFUrH1RZkqSoAaX2e1k');
  }
}
