import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'qpoint-legal-info',
  templateUrl: './legal-info.component.html',
  styleUrls: ['./legal-info.component.scss']
})
export class QPointLegalInfoComponent implements OnInit {
  @Input()
  public infoTitle: string;
  @Input()
  public imprintTitle: string;
  @Input()
  public termsOfUseTitle: string;
  @Input()
  public privacyTitle: string;

  @Input()
  public infoText: string;
  @Input()
  public imprintText: string;
  @Input()
  public termsOfUseText: string;
  @Input()
  public privacyText: string;

  @Input()
  public productName: string;
  @Input()
  public productImagePath: string;


  public imprintTab = 'imprint';
  public termsOfUseTab = 'termsOfUse';
  public infoTab = 'info';
  public privacyTab = 'privacy';
  public tabs = [this.privacyTab, this.imprintTab, this.infoTab, this.termsOfUseTab];

  public activeId: string;

  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.route.queryParams.pipe(take(1)).subscribe(params => {
        if (this.tabs.find(t => t === params['activeTab'])) {
          this.activeId = params['activeTab'];
        }
        this.updateQueryParams(this.activeId);
      });
  }

  tabChange(event: NgbNavChangeEvent) {
    this.activeId = event.nextId;
    this.updateQueryParams(this.activeId);
  }

  public updateQueryParams(activeTab: string) {
    this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: { activeTab: activeTab }
    });
  }
}
