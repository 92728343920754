import { Component } from '@angular/core';
import { NgFor, JsonPipe } from '@angular/common';
import { QPointDropDownListComponent } from '@qpoint/forms';
import { FormsModule } from '@angular/forms';

interface IDummyItem {
  key: string;
  value: string;
}

@Component({
    selector: 'app-control-gallery-drop-down-list',
    templateUrl: './drop-down-list.component.html',
    standalone: true,
    imports: [
        FormsModule,
        QPointDropDownListComponent,
        NgFor,
        JsonPipe,
    ],
})
export class ControlGalleryDropDownListComponent {
  items: IDummyItem[] = [];
  disabled = false;
  readonly = false;
  loading = false;
  value = null;

  constructor() {
    this.items = [];

    for (let i = 0; i < 50; i++) {
      this.items.push({
        key: `item-${i}`,
        value: `This is item ${i}`,
      });
    }
  }

  onChange($event) {
    console.log($event);
    this.value = $event;
  }

  setToPreselection() {
    this.value = {'key': 'item-40', 'value': 'This is item 40'};
  }

  setToNonExisting() {
    requestAnimationFrame(() => this.value = {'key': 'item----', 'value': 'This is item ----'});
  }

  setToNewItems() {
    this.items = [...this.items];
  }
}
