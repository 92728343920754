import { Component, ContentChild, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef, ViewChild, } from '@angular/core';
import { timer } from 'rxjs';

import { HasPosition } from '../has-position';
import { InternalMapService } from '../internal-map.service';
import { MapMarkerLabelDirective } from './map-marker-label.directive';
import { QPointMapsLoader } from '../../../services/maps-loader.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'qpoint-map-marker',
  template: '<ng-template #templateForInfoWindow><ng-content></ng-content></ng-template>',
})
export class MapMarkerComponent implements HasPosition, OnChanges {


  constructor(private mapService: InternalMapService, private mapLoader: QPointMapsLoader) {
  }

  @ViewChild('templateForInfoWindow', {read: TemplateRef})
  public templateRef: TemplateRef<any>;
  @ContentChild(MapMarkerLabelDirective, {read: TemplateRef})
  public labelTemplateRef: TemplateRef<any>;

  @ContentChild(MapMarkerLabelDirective)
  public labelTemplateDirective: MapMarkerLabelDirective;
  @Input()
  public options: google.maps.MarkerOptions = {};
  @Input()
  public fitToBounds = true;

  @Output()
  public mapDragend: EventEmitter<google.maps.MapMouseEvent> = new EventEmitter<google.maps.MapMouseEvent>();

  @Output()
  public mapClick: EventEmitter<google.maps.MapMouseEvent> = new EventEmitter<google.maps.MapMouseEvent>();

  @Output()
  public mapMarkerRightClick: EventEmitter<google.maps.MapMouseEvent> = new EventEmitter<google.maps.MapMouseEvent>();

  @Output()
  public optionsUpdated = new EventEmitter<void>();

  @Output()
  public mapDragStart: EventEmitter<google.maps.MapMouseEvent> = new EventEmitter<google.maps.MapMouseEvent>();

  position(): google.maps.LatLng | google.maps.LatLngLiteral {
    return this.options?.position;
  }

  shouldFitToBounds(): boolean {
    return this.fitToBounds;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.mapLoader.mapsApiLoaded$.pipe(take(1)).subscribe(() => {
      if (changes.options) {
        const value = changes.options.currentValue as google.maps.MarkerOptions;
        if (value.icon && !(typeof value.icon === 'string') && !value.icon.labelOrigin) {
          value.icon.labelOrigin = new google.maps.Point(60, 10);
        }
        timer(10).subscribe(timerValue => {
          this.optionsUpdated.next();
        });
      }
      this.mapService.markerChanged.next();
    });

  }

}
