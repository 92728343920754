<ng-container *ngIf="(mapsLoaderService.mapsApiLoaded$ | async) && googleMapOptions">
  <google-map (idle)="mapIdle($event)" style="height: 100%; width: 100%" [options]="googleMapOptions"
              [ngClass]="{'map-invisible': !mapInitialLoaded}"
              (mapDragend)="mapDragend($event)"
              (zoomChanged)="zoomChanged($event)"
              (centerChanged)="googleMapMapCenterChanged()" (mapClick)="onMapClicked($event)" [width]="width"
              [height]="height"
              (mapRightclick)="onMapRightClick($event)">

    <ng-container *ngFor="let marker of markers">

      <map-info-window #infoWindow="mapInfoWindow" [options]="marker.options">
        <div #infoWindowContentReference>
          <ng-container *ngTemplateOutlet="marker?.templateRef"></ng-container>
        </div>
      </map-info-window>
      <map-marker *ngIf="marker.options" [options]="marker.options" (mapDragend)="markerDragEnd($event, marker)"
                  #markerReference="mapMarker"
                  (mapRightclick)="marker.mapMarkerRightClick.next($event)"
                  (mapClick)="marker.mapClick.next($event);infoWindowContentReference.children.length > 0 ? openInfoWindow(infoWindow, markerReference): null"></map-marker>

    </ng-container>

    <map-circle *ngFor="let circle of circles" [options]="circle.options"
                (circleClick)="circle.circleClick.next($event)"></map-circle>

    <map-polyline *ngFor="let polyline of polylines; trackBy: polylineTrackBy" [path]="polyline?.polyline?.coordinates | qpointGeoJsonToGmaps"
                  [options]="polyline.polylineOptions" (polylineRightclick)="setActiveMapPolylineComponent(polyline, $event)">
    </map-polyline>

    <ng-container *ngFor="let polygon of polygons">
      <ng-container *ngFor="let coordinatesArray of (polygon?.polygon?.coordinates || []); index as i">
        <map-polygon *ngIf="coordinatesArray?.length > 0" [options]="polygon.polygonOptions"
                     [paths]="coordinatesArray | qpointGeoJsonToGmaps" (polygonClick)="polygonClick.emit($event)"
                     (polygonRightclick)="setActiveMapPolygonComponent(polygon, $event, i)"></map-polygon>
      </ng-container>
    </ng-container>
  </google-map>
  <div class="hidden-at-first" #divsToShowPopups *ngFor="let infoWindow of infoWindows; let i = index">
    <ng-container *ngTemplateOutlet="infoWindow?.templateRef"></ng-container>
  </div>
  <ng-container *ngFor="let marker of markers; let i = index">
    <div qpointMarkerLabelInternal class="hidden-at-first" [markerRef]="marker" *ngIf="marker.labelTemplateRef">
      <ng-container>
        <div class="poi-label poi-label-custom">
          <ng-container *ngTemplateOutlet="marker?.labelTemplateRef"></ng-container>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <div class="map-overlay-footer" *ngIf="mapFooterOverlay">
    <div class="map-overlay-content">
      <ng-container *ngTemplateOutlet="mapFooterOverlay"></ng-container>
    </div>
  </div>
</ng-container>
