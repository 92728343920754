import { 
  AfterViewInit, 
  Component, 
  DestroyRef, 
  EventEmitter, 
  Input, 
  Output, 
  forwardRef, 
  inject, 
  signal 
} from "@angular/core";
import { takeUntilDestroyed, toObservable } from "@angular/core/rxjs-interop";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { PreventableEvent } from "@progress/kendo-angular-dropdowns";
import { DropDownVariant } from "@qpoint/forms";
import { debounceTime, interval, takeWhile } from "rxjs";

/// <reference types="@types/google.maps" />
// https://www.typescriptlang.org/docs/handbook/triple-slash-directives.html
declare let google;

export interface IPlace {
  identifier: string;
  placeId?: string;
  lat?: number;
  lng?: number;
}

@Component({
  selector: 'qpoint-places-suggestlist',
  templateUrl: './places-suggestlist.component.html',
  styleUrls: ['./places-suggestlist.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QPointPlacesSuggestlistComponent),
      multi: true
    }
  ]
})
export class QPointPlacesSuggestlistComponent implements AfterViewInit, ControlValueAccessor {
  private destroyRef = inject(DestroyRef);
  private mapLoaded = signal<boolean>(false);
  private autoCompleteService: google.maps.places.AutocompleteService;
  private placesService: google.maps.places.PlacesService;
  private sessionToken: google.maps.places.AutocompleteSessionToken = null;

  private searchTerm = signal<string>('');

  @Input()
  required: boolean;
  @Input() set value(inputValue: IPlace) {
    this._value = inputValue;
    if (this._value) {
      this.placeSuggestions = [ this._value ];
    }
  }
  @Input()
  disabled: boolean;
  @Input()
  readonly: boolean;
  @Input()
  placeholder: string;
  @Input()
  dropDownVariant: DropDownVariant;
  @Input()
  itemDisabled = () => false;
  @Output() searchResultSelected: EventEmitter<Partial<IPlace>> = new EventEmitter();

  public get value(): IPlace {
    return this._value;
  }

  public _value: IPlace;
  public placeSuggestions: IPlace[] = [];
  public isLoading = signal<boolean>(false);

  public constructor() {
    toObservable(this.searchTerm)
    .pipe(
      takeUntilDestroyed(this.destroyRef), 
      debounceTime(300)
    ).subscribe(searchTerm => this.loadSuggestions(searchTerm));
  }

  public onChange: any = () => { };
  public onTouched: any = () => { };

  public async ngAfterViewInit(): Promise<void> {
    await this.load();
    this.autoCompleteService = new google.maps.places.AutocompleteService();
    this.placesService = new google.maps.places.PlacesService(document.createElement('div'));
  }

  writeValue(value: IPlace): void {
    this._value = value;
    if (this._value) {
      this.placeSuggestions = [ this._value ];
    }
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  } 
  
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public handleOnChange(val: any) {
    if (val) {
      this._value = this.placeSuggestions.find(p => p.identifier === val);
      this.updatePlaceDetails(this._value, this.searchResultSelected);
      this.sessionToken = null; /* sessionTokens must be renewed after fetching place details */
    } else {
      this._value = null;
      this.placeSuggestions = [];
      this.searchResultSelected.emit(this._value);
    }
    this.onChange(this._value);
  }

  public async handleFilter(newSearchTerm: string) {
    this.searchTerm.set(newSearchTerm);
  }

  public onClose(event: PreventableEvent) { }

  public onFocusOut() {
    this.sessionToken = null;
  }

  private async load(): Promise<any> {
    return new Promise((resolve, reject) => {
      if ((<any>window)?.google && (<any>window)?.google?.maps) {
        resolve((<any>window).google.maps);
      }
      interval(100)
        .pipe(
          takeWhile(() => !this.mapLoaded(), true),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe(() => {
          if ((<any>window)?.google?.maps) {
            this.mapLoaded.set(true);
            resolve(true);
          }
        });
    });
  }

  private loadSuggestions(searchText: string): void {
    if (searchText?.length) {
      this.isLoading.set(true);

      if (this.sessionToken == null) {
        this.sessionToken = new google.maps.places.AutocompleteSessionToken();
      }

      const request: google.maps.places.AutocompletionRequest = {
        input: searchText,
        sessionToken: this.sessionToken
      };

      this.autoCompleteService.getPlacePredictions(request, this.updatePlaceSuggestions);
    } else {
      this.placeSuggestions = this._value ? [ this._value ] : [];
    }
  }
  
  private updatePlaceSuggestions = (predictions: google.maps.places.AutocompletePrediction[] | null, status: google.maps.places.PlacesServiceStatus): void => {
    this.isLoading.set(false);

    if (predictions?.length && status === google.maps.places.PlacesServiceStatus.OK) {
      this.placeSuggestions = predictions.map(p => {
        return { identifier: p.description, placeId: p.place_id }
      });
    } else {
      this.placeSuggestions = this._value ? [ this._value ] : []
    }
  }

  private updatePlaceDetails(placeToUpdate: IPlace, eventEmitter: EventEmitter<Partial<IPlace>> = null) {
    if (placeToUpdate?.placeId) {
      const request: google.maps.places.PlaceDetailsRequest = {
        sessionToken: this.sessionToken,
        placeId: placeToUpdate.placeId,
        fields: ['geometry'] /* request only required fields - each field will be charged separately */
      }

      this.placesService.getDetails(request, function(place: google.maps.places.PlaceResult|null, status: google.maps.places.PlacesServiceStatus) {
        if (place && status === google.maps.places.PlacesServiceStatus.OK) {
          placeToUpdate.lat = place.geometry.location.lat();
          placeToUpdate.lng = place.geometry.location.lng();
        }
        
        if (eventEmitter) {
          eventEmitter.emit(placeToUpdate);
        }
      });
    }
  }
}