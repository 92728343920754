import { Injectable } from '@angular/core';
import {
  QPointTimePickerConfigServiceImplementation
} from '../../../projects/qpoint-forms/src/lib/time-picker/time-picker-override.directive';
import { DateInputFormatPlaceholder } from '@progress/kendo-angular-dateinputs';

@Injectable({
  providedIn: 'root'
})
export class TimePickerConfigService implements QPointTimePickerConfigServiceImplementation {

  constructor() {
  }

  getConfig(): DateInputFormatPlaceholder {
    return {
      minute: 'mm',
      hour: 'hh'
    } as DateInputFormatPlaceholder;
  }
}
