import { AfterViewInit, Directive, Inject, InjectionToken, OnDestroy, Optional, Provider, Self, Type } from '@angular/core';
import { DateInputFormatPlaceholder, TimePickerComponent } from '@progress/kendo-angular-dateinputs';
import { combineLatest, of, Subject } from 'rxjs';
import { catchError, startWith, takeUntil } from 'rxjs/operators';
import { QPointTimePickerUnitDirective } from './time-picker-unit';

export interface QPointTimePickerConfigServiceImplementation {
  getConfig: () => DateInputFormatPlaceholder;
}

export const DI_TIME_PICKER_OVERRIDE_ACTIVE = new InjectionToken<boolean>('Boolean to enable/disable override directive');
export function provideTimePickerOverrideActive(active: boolean): Provider {
  return {
    provide: DI_TIME_PICKER_OVERRIDE_ACTIVE,
    useValue: active
  };
}

export const DI_TIME_PICKER_FORMAT_PLACEHOLDER = new InjectionToken<QPointTimePickerConfigServiceImplementation>('Format placeholder for kendo time picker');
export function provideTimePickerFormatPlaceholder(useClass: Type<QPointTimePickerConfigServiceImplementation>): Provider {
  return {
    provide: DI_TIME_PICKER_FORMAT_PLACEHOLDER,
    useClass
  };
}

@Directive({
  selector: 'kendo-timepicker, [qpointTimePickerOverride]',
  standalone: true
})
export class QPointTimePickerOverrideDirective implements OnDestroy, AfterViewInit {
  private destroy$ = new Subject<void>();

  constructor(
    @Optional() @Inject(DI_TIME_PICKER_OVERRIDE_ACTIVE) private overrideActive: boolean = true,
    @Optional() @Inject(DI_TIME_PICKER_FORMAT_PLACEHOLDER) private formatPlaceholder: QPointTimePickerConfigServiceImplementation,
    @Self() @Optional() private timepickerUnit: QPointTimePickerUnitDirective,
    @Self() public kendoTimepicker: TimePickerComponent) {
    if (this.kendoTimepicker && this.overrideActive == null || this.overrideActive === true) {
      kendoTimepicker.formatPlaceholder = this.formatPlaceholder?.getConfig() || {hour: 'hh', minute: 'mm'} as DateInputFormatPlaceholder;
    }
  }

  ngAfterViewInit() {
    if (this.kendoTimepicker && this.overrideActive == null || this.overrideActive === true) {
      combineLatest([
        this.kendoTimepicker.dateInput.valueChange.pipe(startWith(() => null)),
        this.kendoTimepicker.onBlur.pipe(startWith(() => null))
      ])
        .pipe(
          catchError(() => of(null)),
          takeUntil(this.destroy$)
        ).subscribe({
          next: () => this.checkValue()
        }
      );
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private checkValue() {
    const wrapperEl = this.kendoTimepicker.wrapper.nativeElement as HTMLElement;
    const inputValue = this.kendoTimepicker.dateInput.inputValue;
    if (inputValue.split(':').map((item) => item.replace(this?.timepickerUnit?.text, '').trim()).some(entry => !isNaN(+entry))) {
      wrapperEl.classList.remove('default-placeholder-color');
    } else {
      wrapperEl.classList.add('default-placeholder-color');
    }
  }
}
