<qpoint-toolbar [title]="'Directives'"></qpoint-toolbar>

<qpoint-content-container>
  <div class="card mb-2">
    <div class="card-header"><code>qPointHidden</code></div>
    <div class="card-body">
      <div class="mb-3">
        <qpoint-button (buttonClicked)="toggle()">Toggle visibility</qpoint-button>
      </div>
      <div class="card" [qpointHidden]="hidden">
        <div class="card-header">Some content</div>
        <div class="card-body">
          Some content that should be hidden.
        </div>
      </div>
    </div>
  </div>

  <div class="card mb-2">
    <div class="card-header"><code>Safe HTML pipe</code></div>
    <div class="card-body">
      <code><pre>&lt;div [innerHTML]="htmlContent | qpointSafeHtml"&gt;&lt;/div&gt;</pre></code>
      <div [innerHTML]="htmlContent | qpointSafeHtml"></div>
    </div>
  </div>

  <div class="card">
    <div class="card-header">
      <code>qpointShowIfTruncated</code><strong> needs ngbTooltip!</strong>
    </div>
    <div class="card-body">
      <p [style.max-width.ch]="truncatedTextMaxWidth" class="text-truncate" ngbTooltip="This text is too long to render" qpointShowIfTruncated>This text is too long to render</p>
      <button (click)="toggleTruncatedTextMaxWidth()">Toggle text max-width</button>
    </div>
  </div>
</qpoint-content-container>
