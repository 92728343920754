<qpoint-toolbar [title]="'Grid'" [showContextbarButton]="false">
  <qpoint-searchfield (doSearch)="doSearch($event)" [searchActive]="searchLoading"></qpoint-searchfield>
  <qpoint-toolbar-divider></qpoint-toolbar-divider>
  <qpoint-toolbar-divider></qpoint-toolbar-divider>
  <qpoint-toolbar-divider></qpoint-toolbar-divider>
  <qpoint-toolbar-btn iconClass="redo" (btnClick)="refresh()" placement="bottom"></qpoint-toolbar-btn>
  <qpoint-toolbar-btn (btnClick)="toggleLoading()" placement="bottom">LoadGrid</qpoint-toolbar-btn>
</qpoint-toolbar>
<qpoint-content-container [fullHeight]="true">
  <qpoint-expansion-panel storageIdentifier="test" title="test">

  </qpoint-expansion-panel>
  <qpoint-grid [excelExportTooltip]="'Export Test Grid'" [dataInput]="dataSubject | async" rowType="medium"
    scrollable="virtual" storageIdentifier="test-grid" (serverFilterChanged)="serverFilterChanged($event)"
    (dblClickOnRow)="dblClickOnRowEventHandler($event)" (scrolledBottom)="scrolledBottom($event)"
    [virtualScrollSettings]="virtualScrollSettings" [changeSelectedDataSubject]="changeSelectedDataSubject"
    [rowBorderBottom]="true" [isDataLoading]="searchLoading"
    [excelExportEnabled]="true" [selectionMode]="'multiple'"
    [infiniteScroll]="true" [showDetailViewValue]="true" [footerToolbarEnabled]="true"
    [isSpecialAttentionRowCallback]="itemNeedSpecialAttentionFunction" (selectionChanged)="updateSelectedItems($event)"
    [filterable]="true" [columnConfigurationEnabled]="true" [columnsTextPath]="'Spalten'"
    [columnsApplyTextPath]="'Anwenden'" [keydownEndCallback]="scrollToBottom" [columnsResetTextPath]="'Zurücksetzen'"
    [sortable]="{allowUnsort: false, mode: 'multiple'}" [persistSortSettings]="false">
    <qpoint-grid-context-menu>
      <qpoint-grid-context-menu-item label="{{ 'Aktion 1' | translate }}">
        <qpoint-grid-context-menu-item label="{{ 'Sub Aktion 1' | translate }}">
        </qpoint-grid-context-menu-item>
        <qpoint-grid-context-menu-item label="{{ 'Sub Aktion 2' | translate }}">
        </qpoint-grid-context-menu-item>
        <qpoint-grid-context-menu-item label="{{ 'Sub Aktion 2' | translate }}">
        </qpoint-grid-context-menu-item>
        <qpoint-grid-context-menu-item label="{{ 'Sub Aktion 2' | translate }}">
        </qpoint-grid-context-menu-item>
        <qpoint-grid-context-menu-item label="{{ 'Sub Aktion 2' | translate }}">
        </qpoint-grid-context-menu-item>
        <qpoint-grid-context-menu-item label="{{ 'Sub Aktion 2' | translate }}">
        </qpoint-grid-context-menu-item>
        <qpoint-grid-context-menu-item label="{{ 'Sub Aktion 2' | translate }}">
        </qpoint-grid-context-menu-item>
        <qpoint-grid-context-menu-item label="{{ 'Sub Aktion 2' | translate }}">
        </qpoint-grid-context-menu-item>
        <qpoint-grid-context-menu-item label="{{ 'Sub Aktion 2' | translate }}">
        </qpoint-grid-context-menu-item>
        <qpoint-grid-context-menu-item label="{{ 'Sub Aktion 2' | translate }}">
        </qpoint-grid-context-menu-item>
        <qpoint-grid-context-menu-item label="{{ 'Sub Aktion 2' | translate }}">
        </qpoint-grid-context-menu-item>
        <qpoint-grid-context-menu-item label="{{ 'Sub Aktion 2' | translate }}">
        </qpoint-grid-context-menu-item>
        <qpoint-grid-context-menu-item label="{{ 'Sub Aktion 2' | translate }}">
        </qpoint-grid-context-menu-item>
        <qpoint-grid-context-menu-item label="{{ 'Sub Aktion 2' | translate }}">
        </qpoint-grid-context-menu-item>
      </qpoint-grid-context-menu-item>
      <qpoint-grid-context-menu-item label="{{ 'Aktion 2' | translate }}">
      </qpoint-grid-context-menu-item>
    </qpoint-grid-context-menu>
    <ng-template qpointGridSpecialAttentionTemplate let-data>
      <div class="d-flex flex-column" style="padding: 15px; background-color: white">
        <div>Ein Popup für Kunde {{data.customer}}</div>
        <div (click)="detailsEnd(data)">Das ist ein Special Template für {{data.operatingCompany}}</div>
        <div>Hier kann beliebiger Content hinein</div>
        <div class="d-flex flex-row justify-content-between">
          <qpoint-button [variant]="'primary'">Freigeben</qpoint-button>
          <qpoint-button [variant]="'secondary'">Ablehnen</qpoint-button>
        </div>
      </div>
    </ng-template>
    <ng-template qpointGridDetailTemplate let-dataItem>
      <qpoint-grid [dataInput]="tests" rowType="medium" scrollable="virtual" storageIdentifier="test-sub-grid"
        (serverFilterChanged)="serverFilterChanged($event)" (dblClickOnRow)="dblClickOnRowEventHandler($event)"
        (scrolledBottom)="scrolledBottom($event)" [virtualScrollSettings]="virtualScrollSettings"
        [changeSelectedDataSubject]="changeSelectedDataSubject" [rowBorderBottom]="true" [isDataLoading]="searchLoading"
        [selectionMode]="'multiple'" [infiniteScroll]="true" [showDetailViewValue]="true" [footerToolbarEnabled]="true"
        [isSpecialAttentionRowCallback]="itemNeedSpecialAttentionFunction"
        (selectionChanged)="updateSelectedItems($event)" [filterable]="true" [columnsTextPath]="'Spalten'"
        [columnsApplyTextPath]="'Anwenden'" [columnsResetTextPath]="'Zurücksetzen'">
        <kendo-grid-column title="Anlage" field="plant"></kendo-grid-column>
        <kendo-grid-column title="Lieferschein" field="deliveryNote"></kendo-grid-column>
        <kendo-grid-column title="Kunde" field="customer"></kendo-grid-column>
        <kendo-grid-column title="Baustelle" field="constructionSite"></kendo-grid-column>
        <kendo-grid-column title="test" field="test"></kendo-grid-column>
      </qpoint-grid>
    </ng-template>
    <kendo-grid-column title="DokumentTyp" field="documentType">
      <!-- <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <span *ngIf="(rowIndex % 2 == 1)">Row: {{rowIndex}}</span><br *ngIf="rowIndex % 2 == 1" />
          <strong>{{dataItem.documentType }}</strong>
      </ng-template> -->
    </kendo-grid-column>
    <kendo-grid-column title="Betreiberfirma\nMultiTest" field="operatingCompany">
      <ng-template kendoGridHeaderTemplate>
        <div class="d-flex flex-column">
          <span>Betreiberfirma</span>
          <span>MultiTest</span>
        </div>
      </ng-template>
      <ng-template let-value="value" kendoGridGroupHeaderTemplate>
        {{value}} mit Count: {{myCount}}
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>{{dataItem.operatingCompany}}</span>
      </ng-template>
    </kendo-grid-column>


    <kendo-grid-column title="Anlage" field="plant">
      <ng-template kendoGridCellTemplate let-dataItem>
        <qpoint-info-corner [iconStyle]="'error'"
          [content]="'Hier steht der Errortext'">{{dataItem.plant}}</qpoint-info-corner>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Lieferschein" field="deliveryNote">
      <ng-template kendoGridCellTemplate let-dataItem>
        <qpoint-info-corner [iconStyle]="'warning'" [showIcon]="true">{{dataItem.deliveryNote}}</qpoint-info-corner>

      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Kunde" field="customer"></kendo-grid-column>
    <kendo-grid-column title="Baustelle" field="constructionSite"></kendo-grid-column>
    <kendo-grid-column title="test" field="test">
      <ng-template kendoGridCellTemplate let-dataItem>
        <qpoint-info-corner [content]="'Hier steht der Infotext'" [position]="'top-left'" [iconStyle]="'info'"
          [showIcon]="true">{{dataItem.test}}</qpoint-info-corner>
      </ng-template>
    </kendo-grid-column>

    <!--    <qpoint-grid-context-menu>-->
    <!--      <qpoint-grid-context-menu-item [showFunction]="showContextMenuItem" label="Details">-->
    <!--        <qpoint-grid-context-menu-item label="Details Start"></qpoint-grid-context-menu-item>-->
    <!--        <qpoint-grid-context-menu-item label="Details 3"></qpoint-grid-context-menu-item>-->
    <!--        <qpoint-grid-context-menu-item label="Details 4"></qpoint-grid-context-menu-item>-->
    <!--        <qpoint-grid-context-menu-item label="Details 5"></qpoint-grid-context-menu-item>-->
    <!--        <qpoint-grid-context-menu-item label="Details 6"></qpoint-grid-context-menu-item>-->
    <!--        <qpoint-grid-context-menu-item (click)="detailsEnd($event)" label="Details End"></qpoint-grid-context-menu-item>-->
    <!--      </qpoint-grid-context-menu-item>-->
    <!--      <qpoint-grid-context-menu-item [showFunction]="showContextMenuItem" label="Test"></qpoint-grid-context-menu-item>-->
    <!--    </qpoint-grid-context-menu>-->
    <qpoint-excel-export-custom>
      <kendo-excelexport-column title="Betreiberfirma" field="operatingCompany">
        <ng-template kendoExcelExportGroupHeaderTemplate let-value="value">
          <span>Ja was da los {{value}}</span>
        </ng-template>
      </kendo-excelexport-column>
      <kendo-excelexport-column title="Lieferschein" field="deliveryNote"></kendo-excelexport-column>
    </qpoint-excel-export-custom>

  </qpoint-grid>

  <div qpointContentContainerBottom>test 123</div>
</qpoint-content-container>
