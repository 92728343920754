import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { HasPosition } from '../has-position';

@Component({
  selector: 'qpoint-map-info-window',
  template: '<ng-template #templateForInfoWindow><ng-content></ng-content></ng-template>',
})
export class MapInfoWindowComponent implements OnInit, HasPosition {
  @ViewChild('templateForInfoWindow', {read: TemplateRef})
  public templateRef: TemplateRef<any>;
  @Input()
  public options: google.maps.InfoWindowOptions;
  @Input()
  public dialogClass: string;

  constructor() {
  }

  ngOnInit(): void {
  }


  position(): google.maps.LatLng | google.maps.LatLngLiteral {
    return this.options?.position;
  }

  shouldFitToBounds(): boolean {
    return true;
  }

}
