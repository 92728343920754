import { Injectable } from '@angular/core';
import { firstValueFrom, from, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { injectMapsApiKeyService } from './google-api-key.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class QPointMapsLoader {
  private mapsApiKeyService = injectMapsApiKeyService();
  public mapsApiLoaded$: ReplaySubject<boolean> = new ReplaySubject();

  constructor(private httpClient: HttpClient) {
  }

  public async initMap() {
    if ((<any>window)?.google?.maps) {
      this.mapsApiLoaded$.next(true);
      return;
    }
    const apiKey = await this.mapsApiKeyService.getGoogleMapsApiKey();
    this.httpClient.jsonp(`https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`, 'callback')
      .pipe(
        map(() => true),
        catchError((err) => {
          console.error('error loading maps api', err);
          return of(false);
        }),
      ).subscribe(value => {
        console.log('google maps api key loaded');
        this.mapsApiLoaded$.next(value);
    });
  }

  public load(): Promise<any> {
    if ((<any>window)?.google?.maps) {
      return Promise.resolve((<any>window).google.maps);
    }
    return firstValueFrom(this.mapsApiLoaded$.pipe(take(1)));
  }

  public load$(): Observable<any> {
    return from(this.load());
  }
}
