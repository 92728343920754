import { EnvironmentProviders, inject, InjectionToken, makeEnvironmentProviders, Type } from '@angular/core';

export interface IQPointUserInfoSidebarService {
  name: string;
  pictureUrl: string;
}

export const DI_USER_INFO_SIDEBAR = new InjectionToken<IQPointUserInfoSidebarService>('QPointUserInfoSidebar');

export function injectUserInfoSidebarService(): IQPointUserInfoSidebarService {
  return inject(DI_USER_INFO_SIDEBAR);
}

export function provideUserInfoSidebar(useClass: Type<IQPointUserInfoSidebarService>): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: DI_USER_INFO_SIDEBAR,
      useClass
    }
  ]);
}
