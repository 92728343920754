import { Component } from '@angular/core';
import { QPointSafeHtmlPipe } from '../../../../projects/qpoint-layout/src/lib/pipes/qpoint-safe-html.pipe';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { QPointButtonComponent } from '../../../../projects/qpoint-button/src/lib/button/button.component';
import { QPointToolbarComponent, QPointContentContainerComponent, QPointHiddenDirective } from '@qpoint/layout';

@Component({
    selector: 'app-directives',
    templateUrl: './directives.component.html',
    standalone: true,
    imports: [
        QPointToolbarComponent,
        QPointContentContainerComponent,
        QPointButtonComponent,
        NgbTooltip,
        QPointSafeHtmlPipe,
      QPointHiddenDirective
    ],
})
export class ControlGalleryDirectivesComponent {
  public hidden = false;
  public htmlContent = '<h3>This is dynamic html content</h3>';
  truncatedTextMaxWidth = 10;

  toggle() {
    this.hidden = !this.hidden;
  }

  toggleTruncatedTextMaxWidth() {
    this.truncatedTextMaxWidth = this.truncatedTextMaxWidth === 10 ? 100 : 10;
  }
}
