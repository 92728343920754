import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { delay, first, takeUntil, tap } from 'rxjs/operators';
import { JsonPipe } from '@angular/common';
import { QPointAsyncDropDownComponent } from '@qpoint/forms';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-control-gallery-async-drop-down',
    templateUrl: './async-drop-down.component.html',
    standalone: true,
    imports: [
        FormsModule,
        QPointAsyncDropDownComponent,
        JsonPipe,
    ],
})
export class ControlGalleryAsyncDropDownComponent implements OnInit, OnDestroy {
  disabled = false;
  readonly = false;
  loading = false;
  value = null;
  request: Observable<any>;
  instantRequest: Observable<any>;
  emptyRequest: Observable<any>;
  errorRequest: Observable<any>;
  private destroy$ = new Subject<void>();

  constructor(private httpClient: HttpClient) {
  }

  ngOnInit(): void {
    this.restart();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private getRequest() {
    return this.httpClient.get('https://jsonplaceholder.typicode.com/albums')
      .pipe(first(), delay(2000));
  }

  restart() {
    this.request = this.getRequest();
    this.emptyRequest = of([]).pipe(takeUntil(this.destroy$));
    this.instantRequest = of([{ id: 1, title: 'bar' }]).pipe(takeUntil(this.destroy$));
    this.errorRequest = of([]).pipe(takeUntil(this.destroy$))
      .pipe(delay(2000), takeUntil(this.destroy$), tap(() => {
        throw new Error('Some error with date as message: ' + new Date());
      }));
  }

  onSelectionChange($event) {
    console.log($event);
  }
}
