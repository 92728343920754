import { Component } from '@angular/core';
import { QPointDateFormat } from '@qpoint/i18n';
import { NgIf } from '@angular/common';
import { QPointButtonComponent } from '../../../../projects/qpoint-button/src/lib/button/button.component';
import { QPointToolbarComponent, QPointContentContainerComponent, DraggableListComponent } from '@qpoint/layout';

@Component({
    selector: 'app-control-gallery-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    standalone: true,
    imports: [QPointToolbarComponent, QPointContentContainerComponent, QPointButtonComponent, DraggableListComponent, NgIf]
})
export class ControlGalleryListComponent {

  QPointDateFormat = QPointDateFormat;

  public draggableListItems: Array<QPlantArticle> = [
    {
      scheduledDt: new Date(2021, 2, 10, 13, 48, 0),
      pauseMinutes: null,
      articleIdentifier: 'Article 1',
      articleGroupIdentifier: 'xyz',
      quantity: 5,
      quantityUnit: 't',
      showDate: true
    },
    {
      scheduledDt: new Date(2021, 2, 10, 14, 20, 0),
      pauseMinutes: null,
      articleIdentifier: 'Article 2',
      articleGroupIdentifier: 'xyz',
      quantity: 2,
      quantityUnit: 't',
      showDate: false,
      borderColor: 'warning'
    },
    {
      scheduledDt: null,
      pauseMinutes: 25,
      articleIdentifier: 'Article 3',
      articleGroupIdentifier: 'xyz',
      quantity: 8,
      quantityUnit: 't',
      borderColor: 'error'
    }
  ];
  public readonly = false;

  constructor() {
  }

  public onRepositionItem($event: { indexOrigin: number, indexDestination: number }) {
    [this.draggableListItems[$event.indexOrigin], this.draggableListItems[$event.indexDestination]]
      = [this.draggableListItems[$event.indexDestination], this.draggableListItems[$event.indexOrigin]];
  }
}

export class QPlantArticle {
  scheduledDt: Date;
  pauseMinutes: number;
  articleIdentifier: string;
  articleGroupIdentifier: string;
  quantity: number;
  quantityUnit: string;
  showDate?: boolean;
  borderColor?: string;
}
