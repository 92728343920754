import { ColumnBase, ColumnComponent, ColumnResizeArgs } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import * as _ from 'lodash';

import { ColumnOrderPosition } from './grid.component';


export function orderColumnsBasedOnLocalStorage(dataColumnsFromContent: any[]): ColumnComponent[] {
  if (this.storageIdentifier && localStorage.getItem(this.localStorageGridOrderKey)) {
    const dataColumnFields = dataColumnsFromContent.map(value => value.field);
    const localStorageOrderItems = JSON.parse(localStorage.getItem(this.localStorageGridOrderKey)) as ColumnOrderPosition[];
    const fieldsFromLocalStorage = localStorageOrderItems
      .map(value => value.name);
    const newColumns = _.difference(dataColumnFields, fieldsFromLocalStorage);
    this.localStorageColumnMirrorArray = localStorageOrderItems.filter(l => dataColumnFields.includes(l.name));
    const dataColumnsToAdd = [];
    this.localStorageColumnMirrorArray.forEach(value => {
      const dataColumnFromContent = dataColumnsFromContent.find(dataColumnValue => {
        return dataColumnValue.field === value.name;
      });
      if (value.width) {
        dataColumnFromContent.width = value.width;
      }

      if(value.hidden != null) {
        dataColumnFromContent.hidden = value.hidden;
      }

      if(value.sort && (!dataColumnFromContent.sortable || !this.persistSortSettings)) {
        value.sort = undefined;
      }
      dataColumnsToAdd.push(dataColumnFromContent);
    });
    newColumns.forEach(newCol => {
      const index = dataColumnFields.indexOf(newCol);
      const dataColumn = dataColumnsFromContent.find(d => d.field === newCol);
      dataColumnsToAdd.splice(index, 0, dataColumn);
      this.localStorageColumnMirrorArray.push({name: dataColumn.field});
    })
    persistOnReorder.bind(this)(dataColumnsToAdd);
    return dataColumnsToAdd;
  } else {
    this.localStorageColumnMirrorArray = dataColumnsFromContent.map((value) => {
      return {name: value.field} as ColumnOrderPosition;
    });
  }
  return dataColumnsFromContent;
}

export function persistOnReorder(columns: ColumnBase[]) {
  if (this.storageIdentifier) {
    columns = columns.sort((a, b) => (a.orderIndex > b.orderIndex) ? 1 : ((b.orderIndex > a.orderIndex) ? -1 : 0));
    let reorderedStorageArray = [];

    columns.forEach((col: ColumnComponent) => {
      if (col.field) {
        reorderedStorageArray.push(this.localStorageColumnMirrorArray.find(c => c.name === col.field));
      }
    });

    this.localStorageColumnMirrorArray = reorderedStorageArray;
    localStorage.setItem(this.localStorageGridOrderKey, JSON.stringify(this.localStorageColumnMirrorArray));
  }
}

export function persistOnResize(event: Array<ColumnResizeArgs>) {
  if (this.storageIdentifier) {
    event.forEach(columnResizeEvent => {
      const itemToUpdate = this.localStorageColumnMirrorArray.find(value => value.name === (columnResizeEvent.column as any).field);
      itemToUpdate.width = columnResizeEvent.newWidth;
    });
    localStorage.setItem(this.localStorageGridOrderKey, JSON.stringify(this.localStorageColumnMirrorArray));
  }
}

export function persistOnVisibilityChange(columns: Array<ColumnComponent>) {
  if (this.storageIdentifier) {
    columns.forEach(columnComponent => {
      const itemToUpdate = this.localStorageColumnMirrorArray.find(value => value.name === columnComponent.field);
      itemToUpdate.hidden = columnComponent.hidden;
    });
    localStorage.setItem(this.localStorageGridOrderKey, JSON.stringify(this.localStorageColumnMirrorArray));
  }
}

export function persistOnSort(sort: SortDescriptor[]) {
  if (this.storageIdentifier) {
    this.localStorageColumnMirrorArray.forEach(l => {
      l.sort = undefined;
      l.sortOrder = undefined;
    } );
    if (sort.length > 0) {
      sort.forEach((s: SortDescriptor, index: number) => {
        const itemToUpdate = this.localStorageColumnMirrorArray.find(value => value.name === s.field);
        itemToUpdate.sort = s.dir;
        itemToUpdate.sortOrder = index;
      })
    }
    if (this.persistSortSettings) {
      localStorage.setItem(this.localStorageGridOrderKey, JSON.stringify(this.localStorageColumnMirrorArray));
    }
  }
}
