import { inject, Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { injectImageLoaderService, IQPointImageLoaderService } from '../services/image-loader.service';

@Pipe({
  name: 'authorizedImage',
  standalone: true
})
export class QPointAuthorizedImagePipe implements PipeTransform {
  private imageLoaderService: IQPointImageLoaderService = injectImageLoaderService();
  private domSanitizer: DomSanitizer = inject(DomSanitizer);

  transform(imagePath: string): Observable<SafeUrl | string> {
    if (!imagePath) {
      return of(null);
    }
    const imageUrl = new URL(imagePath);
    if (imageUrl.searchParams.has('sig')) {
      return of(imagePath);
    }

    return this.imageLoaderService.getProfileImage(imagePath)
      .pipe(
        map(val => this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(val))));
  }

}
