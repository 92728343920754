<li *ngIf="!menuItem.hidden" title="{{ menuItem.title | translate }}"
  [ngClass]="{'qpoint-sidebar-list-item': !child, 'qpoint-sidebar-sublist-item': child, 'selected': (menuItem.selected && !menuItem.expanded) || (menuItem.selected && sidebarCollapsed), 'with-sub-menu': menuItem.children, 'qpoint-sidebar-item-expanded': menuItem.expanded, 'sidebar-collapsed': sidebarCollapsed}">

  <a id="{{ id }}" *ngIf="!menuItem.children && !menuItem.url" [routerLink]="menuItem.route.paths"
    class="qpoint-sidebar-list-link">
    <ng-container *ngTemplateOutlet="menuItemDivTemplate; context: {$implicit: menuItem}"></ng-container>
  </a>

  <a id="{{ id }}" *ngIf="!menuItem.children && menuItem.url" [href]="menuItem.url" [target]="menuItem.target"
    class="qpoint-sidebar-list-link">
    <ng-container *ngTemplateOutlet="menuItemDivTemplate; context: {$implicit: menuItem}"></ng-container>
  </a>

  <a id="{{ id }}" *ngIf="menuItem.children && menuItem.route.parentLinkActive" [routerLink]="menuItem.route.paths"
    (click)="onToggleSubMenu($event, menuItem)" class="qpoint-sidebar-list-link">
    <ng-container *ngTemplateOutlet="menuItemDivTemplate; context: {$implicit: menuItem}"></ng-container>
  </a>

  <a id="{{ id }}" *ngIf="menuItem.children && !menuItem.route.parentLinkActive"
    (click)="onToggleSubMenu($event, menuItem)" class="qpoint-sidebar-list-link" href>
    <ng-container *ngTemplateOutlet="menuItemDivTemplate; context: {$implicit: menuItem}"></ng-container>
  </a>

  <ul [@openClose]="menuItem.expanded ? 'open' : 'closed'" *ngIf="menuItem.children" class="qpoint-sidebar-sublist"
    [ngClass]="{'slide-right': menuItem.slideRight}">
    <qpoint-menu-item [menuItem]="subItem" [child]="true" (toggleSubMenu)="onToggleSubMenu($event, subItem)"
      *ngFor="let subItem of menuItem.children"></qpoint-menu-item>
  </ul>
</li>

<ng-template #menuItemDivTemplate let-menuItem>
  <div style="display: inline-block">
    <qpoint-icon *ngIf="menuItem.icon" [iconType]="menuItem.iconType" [icon]="menuItem.icon"></qpoint-icon>
    <span *ngIf="badgeObservable && (badgeObservable | async) > 0" [ngClass]="{'level1': !child, 'level2': child}"
      class="badge bg-danger level1 menuInfoBadge">{{badgeDisplay}}</span>
    <span
      *ngIf="badgeObservable && (badgeObservable | async) > 0 && badgeAnimationObservable && (badgeAnimationObservable | async)"
      [ngClass]="{'level1': !child, 'level2': child}" class="badge badge-danger level1 menuInfoBadge animation-badge">
      <span>{{badgeDisplay}}</span>
    </span>
  </div>
  <span *ngIf="menuItem.translate" translate class="menu-item-title">{{ menuItem.title }}</span>
  <span *ngIf="!menuItem.translate" class="menu-item-title">{{ menuItem.title }}</span>
  <qpoint-icon (click)="toggleWithoutPropagationForLink($event, menuItem)" *ngIf="menuItem.children" class="menu-item-toggler"
               icon="{{ menuItem.expanded ? 'angle-up' : 'angle-down'}}"></qpoint-icon>
</ng-template>
