import { Injectable } from '@angular/core';
import { MapPolygonComponent } from './map-polygon.component';
import { MapPolygon } from '@angular/google-maps';
import { toGeoJSON } from '../map-helpers';
import { cloneDeep } from 'lodash';

@Injectable()
export class MapPolygonStateService {

  private _polygonComponent: MapPolygonComponent | null = null;
  private _mouseEvent: google.maps.PolyMouseEvent | null = null;
  private _polygonIndex: number | null = null;

  get mouseEvent() {
    return this._mouseEvent;
  }

  get activePolygonComponent() {
    return this._polygonComponent;
  }

  get polygonIndex() {
    return this._polygonIndex;
  }

  setActivePolygonComponent(polygonComponent: MapPolygonComponent | null, event: google.maps.PolyMouseEvent | null, index: number) {
    this._polygonComponent = polygonComponent;
    this._mouseEvent = event;
    this._polygonIndex = index;
  }

  deletePolygonVertex() {
    if (this._mouseEvent && this._polygonComponent) {
      this._polygonComponent.deletePolygonVertex(this.mouseEvent, this.polygonIndex);
    }
  }

  resetPolygon() {
    if (this._polygonComponent) {
      this._polygonComponent.resetPolygon();
    }
  }

  updatePolygon(polygon: MapPolygon, qpointPolygon: MapPolygonComponent, polygonIndex: number) {
    const geoJson = toGeoJSON(polygon.getPath().getArray().map(entry => entry.toJSON()));
    if (JSON.stringify(geoJson) !== JSON.stringify(qpointPolygon.polygon.coordinates[polygonIndex])) {
      const polygonCopy = cloneDeep(qpointPolygon.polygon);
      polygonCopy.coordinates[polygonIndex] = geoJson;
      qpointPolygon.updatePolygon(polygonCopy);
    }
  }
}
