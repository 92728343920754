import { Injectable } from '@angular/core';
import { IQPointAuthenticationService } from '@qpoint/utils';

@Injectable({
  providedIn: 'root'
})
export class Authentication implements IQPointAuthenticationService {
    constructor() {
    }

    public getAccessToken(): string {
        return 'AccessTokenDemo';
    }

    public get name(): string {
        return 'test';
    }

    public get pictureUrl(): string {
        return null;
    }

    public isAuthenticated(): boolean {
        return true;
    }
}
