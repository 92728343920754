<qpoint-toolbar [title]="'Dropzone'"></qpoint-toolbar>

<qpoint-content-container>

  <!-- Dropzone -->
  <div class="card">
    <div class="card-header">Dropzone</div>
    <div class="card-body">
      <p>Dropzone</p>
      <kendo-upload></kendo-upload>
    </div>
  </div>
</qpoint-content-container>