import { Component } from '@angular/core';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { QPointToolbarComponent, QPointContentContainerComponent } from '@qpoint/layout';

@Component({
    selector: 'app-control-gallery-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss'],
    standalone: true,
    imports: [QPointToolbarComponent, QPointContentContainerComponent, NgbAlert]
})
export class ControlGalleryNotificationsComponent {

  constructor() { }
}
