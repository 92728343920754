import { Component, OnInit } from '@angular/core';

import { IRoutingBreadcrumb } from '../../../projects/qpoint-layout/src/lib/components/toolbar/toolbar.component';
import { QPointLegalModule } from '../../../projects/qpoint-legal/src/lib/qpoint-legal.module';
import { QPointToolbarComponent, QPointContentContainerComponent } from '@qpoint/layout';

@Component({
    selector: 'qpoint-legal',
    templateUrl: './legal.component.html',
    styleUrls: ['./legal.component.scss'],
    standalone: true,
    imports: [QPointToolbarComponent, QPointContentContainerComponent, QPointLegalModule]
})
export class LegalComponent implements OnInit {
  public imprintText: string;
   public breadcrumbs: IRoutingBreadcrumb[] = [
    {
      name: 'Rechtliches | Impressum',
      route: 'legal'
    },
    {
      name: '900.1001.200',
      route: `legal`,
      localizeText: false,
    }
  ];

  constructor() {
    this.imprintText = `
    # Datenschutz
Dieser Datenschutzhinweis gibt Ihnen eine Übersicht darüber, wie Q Point personenbezogene Daten verarbeitet. Die ausführliche Datenschutzerklärung finden Sie [hier](https://www.q-point.com/assets/PDF/DSGVO/f1d73ccc2a/Ausfuehrliche-Datenschutzerklaerung_de-1.pdf).

1. DEFINITION PERSONENBEZOGENE DATEN (PERSONENDATEN)
Personendaten bzw. personenbezogene Daten sind alle Angaben und Informationen, die sich auf eine bestimmte oder bestimmbare Person beziehen. Dazu gehören z.B. Adresse, Telefonnummer, E-Mail-Adresse, aber auch die IP-Adresse.

2. ERFASSUNG UND VERWENDUNGSZWECK
Q Point erfasst Daten wie z.B. die IP-Adresse und den verwendeten Browser der Besucher der Q Point Webseiten. Q Point nutzt diese Personendaten in weiterer Folge ausschließlich für statistische und anonymisierte Auswertungen.
[Hier](https://www.q-point.com/assets/PDF/DSGVO/f1d73ccc2a/Ausfuehrliche-Datenschutzerklaerung_de-1.pdf) finden Sie ausführliche Information zu Google Analytics. Q Point speichert bzw. bearbeitet Personendaten, die Sie auf der Q Point Webseite direkt in ein entsprechendes Textfeld oder Formular eingeben. Diese verwendet Q Point ausschließlich für den auf der entsprechenden Webseite jeweils ausgewiesenen Zweck.
In gewissen Fällen ist Ihre ausdrückliche Einverständniserklärung zur Datenbearbeitung erforderlich. Ein entsprechender Hinweis an der betreffenden Stelle informiert Sie jeweils umfassend. Sie haben die Möglichkeit, diese Einwilligung jederzeit zu widerrufen.
Der Internetauftritt von Q Point enthält Verknüpfungen zu Internetinhalten Dritter und verwendet Cookies (unter anderem auch über Social Plugins) zur Umsetzung ausgewiesener Services. Sie können der Verwendung von Cookies jederzeit widersprechen. Mehr Informationen hierzu finden Sie in den Cookie-Hinweisen von Q Point.
Bei der Nutzung der mobilen Sales und Service Apps von Q Point kann es zum Einsatz von Analysetechniken kommen, deren Anwendungsbereich dem der Cookies entspricht. Informationen hierzu finden Sie in den jeweiligen Nutzungsbedingungen.
Im Allgemeinen verarbeitet Q Point so wenig Personendaten wie möglich.

3. WEITERGABE VON PERSONENBEZOGENEN DATEN
Q Point gibt keine Daten an Dritte weiter. Ausgenommen sind gesetzliche Verpflichtungen sowie von Q Point beauftragte Unternehmen, welche eine von Ihnen gewünschte Dienstleistung für Q Point erbringen. Unabhängig von ihrem Standort werden diese Unternehmen vertraglich zum Schutz personenbezogener Daten gemäß den von Q Point vorgegebenen Standards verpflichtet.

4. AUFBEWAHRUNGSDAUER VON PERSONENBEZOGENEN DATEN
Q Point speichert personenbezogene Daten, die Sie auf der Q Point Webseite direkt in ein entsprechendes Textfeld oder Formular eingeben, in einer geschützten und gesicherten Betriebsumgebung in einem Rechenzentrum in der Schweiz.
Q Point speichert von Ihnen eingegebene Daten nur so lange, wie sie für den vorgesehenen Zweck benötigt werden; danach werden sie gelöscht.

5. BETROFFENEN-RECHTE
Sie haben jederzeit das Recht, beim Datenschutzbeauftragten von Q Point (Kontaktangaben siehe unten) Auskunft über die zu Ihrer Person bearbeiteten Daten sowie deren Berichtigung oder Löschung zu verlangen. Zudem steht Ihnen das Recht zu, der Bearbeitung der Sie betreffenden Daten (teilweise) zu widersprechen bzw. Ihre Einwilligung zu widerrufen sowie eine Weitergabe an Dritte sperren zu lassen.
Weiter haben Sie jederzeit das Recht, Beschwerde bei der Aufsichtsbehörde einzureichen.

6. KONTAKT
Für Fragen, Anregungen oder Anträge zur Datenbearbeitung wenden Sie sich bitte an:
Q Point AG
Datenschutzbeauftragter
Güterstrasse 20
4901 Langenthal
dpo@q-point.com

7. ÄNDERUNGEN/ANPASSUNGEN DER DATENSCHUTZERKLÄRUNG
Über Änderungen und Anpassungen unserer Datenschutzerklärung informiert die Q Point Gruppe jeweils auf Ihrer Hauptseite.

 
Langenthal, 27.03.2019
    `;
  }

  ngOnInit() {}
}
