import { Component, OnInit } from '@angular/core';
import { UploadModule } from '@progress/kendo-angular-upload';
import { QPointToolbarComponent, QPointContentContainerComponent } from '@qpoint/layout';

@Component({
    selector: 'app-control-gallery-dropzone',
    templateUrl: './dropzone.component.html',
    styleUrls: ['./dropzone.component.scss'],
    standalone: true,
    imports: [QPointToolbarComponent, QPointContentContainerComponent, UploadModule]
})
export class ControlGalleryDropzoneComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
}
