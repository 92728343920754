import { Component, Input, ViewEncapsulation } from '@angular/core';
import { formatDate, formatNumber, splitDateFormat } from '@progress/kendo-angular-intl';
import { QPointIconModule } from '@qpoint/icons';
import { TranslateModule } from '@ngx-translate/core';

const DEFAULT_CODE : string = 'de-DE';

/**
 * A component which previews date, times and numbers according to the given culture and language.
 * The dates will be formatted in the same way as it is done by the QPointDateFormatPipe but this
 * preview component is able to change the culture and language without the need to reconfigure a
 * localization or format service.
 */
@Component({
  selector: 'qpoint-format-preview',
  templateUrl: './format-preview.component.html',
  styleUrls: ['./format-preview.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [
    QPointIconModule,
    TranslateModule
  ],
  standalone: true
})
export class QPointFormatPreviewComponent {

  @Input() infoBoxNumber = 1500.23;
  @Input() dateToDisplay = new Date();

  /** The ISO code to retrieve the formats and format-symbols. */
  @Input({required: true}) cultureInfo: string;

  /** The ISO code to retrieve the translations from (e.g.: name of months, weekdays, ...). */
  @Input({required: true}) language: string;

  constructor() {
  }

  get dateShort() {
    return formatDate(this.dateToDisplay, 'd', this.getCultureInfo());
  }

  get dateLong() {
    const dateFormatFromSpecificLocale = splitDateFormat({date: 'long'}, this.getCultureInfo()).map(value => value.pattern).join('');
    return formatDate(this.dateToDisplay, dateFormatFromSpecificLocale, this.getLanguage());
  }

  get time() {
    return formatDate(this.dateToDisplay, 't', this.getCultureInfo());
  }

  get number() {
    return formatNumber(this.infoBoxNumber, 'n2', this.getCultureInfo());
  }

  private getCultureInfo(): string {
    return this.cultureInfo ?? this.getDefaultCode( 'cultureInfo' );
  }

  private getLanguage(): string {
    return this.language ?? this.getDefaultCode( 'language' );
  }

  private getDefaultCode( methodName: string ): string {
    console.warn( `[qpoint-format-preview] get default for '${methodName}' = ${DEFAULT_CODE}` );
    return DEFAULT_CODE;
  }
}
