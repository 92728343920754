import { NgFor, NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, ContentChildren, EventEmitter, Input, Output, QueryList, ViewChild } from '@angular/core';
import { NgbNav, NgbNavChangeEvent, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { TabStripModule } from '@progress/kendo-angular-layout';
import { QPointIconModule } from '@qpoint/icons';

import { QPointTabNavItemComponent } from './tab-nav-item.component';

@Component({
    selector: 'qpoint-tab-nav',
    templateUrl: './tab-nav.component.html',
    styleUrls: ['./tab-nav.component.scss'],
    standalone: true,
    imports: [TabStripModule, NgbNavModule, QPointIconModule, NgIf, NgFor, NgForOf, NgTemplateOutlet]
})
export class QPointTabNavComponent {
  @Input()
  public tabOrientation: 'horizontal'|'vertical' = 'horizontal';
  @Input()
  public menuCollapsed = false;
  @Input()
  public activeId: string;
  @Input()
  public destroyOnHide: boolean = true;

  @ContentChildren(QPointTabNavItemComponent)
  public tabNavItems: QueryList<QPointTabNavItemComponent>;

  @Output() 
  navChange = new EventEmitter<NgbNavChangeEvent>();

  @ViewChild('ngbNavReference')
  private tabset: NgbNav;
  
  public toggleTabOrientation() {
    if(this.tabOrientation === 'horizontal') {
      this.tabOrientation = "vertical"
    } else {
      this.menuCollapsed = false;
      this.tabOrientation = "horizontal"
    }
  } 

  public toggleMenuCollapsed() {
    if(this.tabOrientation === 'horizontal') return;
    this.menuCollapsed = !this.menuCollapsed;
  }

  public onNavChange($event: NgbNavChangeEvent) {
    this.activeId = $event.nextId;
    this.navChange.emit($event);
  }

  public select(tabId: string) {
    this.tabset.select(tabId);
  }

  constructor() { }
}
