<qpoint-toolbar [title]="'Buttons'">
  <qpoint-toolbar-btn qPointButtonType="primary" label="Toolbar Button" [iconClass]="'magnifying-glass'" [iconRotate]="90" ></qpoint-toolbar-btn>
</qpoint-toolbar>

<qpoint-content-container>
  <ng-template #buttonsDefault>
    <qpoint-button>Default Button</qpoint-button>
    <qpoint-button [loading]="true">Default</qpoint-button>
    <qpoint-button [loading]="false">Default</qpoint-button>
    <qpoint-button icon="clock" [loading]="false">Default</qpoint-button>
    <qpoint-button icon="clock" [loading]="false" [disabled]="true">Default</qpoint-button>
    <qpoint-button icon="clock" [loading]="false" size="lg">Default</qpoint-button>
    <qpoint-button icon="clock" [loading]="false" size="sm">Default</qpoint-button>
    <qpoint-button icon="clock" [active]="true">Default active</qpoint-button>
    <qpoint-button icon="clock" iconRotate="270" [loading]="false" size="sm">Default Icon Rotate</qpoint-button>
  </ng-template>

  <ng-template #buttonsPrimary>
    <qpoint-button variant="primary" [loading]="true">Primary</qpoint-button>
    <qpoint-button variant="primary" icon="clock" [loading]="true">Primary</qpoint-button>
    <qpoint-button variant="primary" [loading]="false">Primary</qpoint-button>
    <qpoint-button variant="primary" icon="clock" [loading]="false">Primary</qpoint-button>
    <qpoint-button variant="primary" icon="clock" [loading]="false" [disabled]="true">Primary</qpoint-button>
    <qpoint-button variant="primary" icon="clock" [loading]="false" size="lg">Primary</qpoint-button>
    <qpoint-button variant="primary" icon="clock" [loading]="false" size="sm">Primary</qpoint-button>
    <qpoint-button variant="primary" icon="clock" [active]="true">Primary</qpoint-button>
    <qpoint-button variant="primary" icon="clock" iconVariant="success">Secondary success</qpoint-button>
    <qpoint-button variant="primary" icon="clock" iconVariant="danger">Secondary danger</qpoint-button>
    <qpoint-button variant="primary" icon="clock" iconVariant="warn">Secondary warn</qpoint-button>
  </ng-template>

  <ng-template #buttonsSecondary>
    <qpoint-button variant="secondary" [loading]="true">Secondary</qpoint-button>
    <qpoint-button variant="secondary" icon="clock" [loading]="true">Secondary</qpoint-button>
    <qpoint-button variant="secondary" [loading]="false">Secondary</qpoint-button>
    <qpoint-button variant="secondary" icon="clock" [loading]="false">Secondary</qpoint-button>
    <qpoint-button variant="secondary" icon="clock" [loading]="false" [disabled]="true">Secondary</qpoint-button>
    <qpoint-button variant="secondary" icon="clock" [loading]="false" size="lg">Secondary</qpoint-button>
    <qpoint-button variant="secondary" icon="clock" [loading]="false" size="sm">Secondary</qpoint-button>
    <qpoint-button variant="secondary" icon="clock" [active]="true">Secondary</qpoint-button>
    <qpoint-button variant="secondary" icon="clock" iconVariant="success">Secondary success</qpoint-button>
    <qpoint-button variant="secondary" icon="clock" iconVariant="danger">Secondary danger</qpoint-button>
    <qpoint-button variant="secondary" icon="clock" iconVariant="warn">Secondary warn</qpoint-button>
  </ng-template>

  <ng-template #buttonsIcons>
    <qpoint-button variant="icon" [loading]="true"></qpoint-button>
    <qpoint-button variant="icon" icon="clock" [loading]="true"></qpoint-button>
    <qpoint-button variant="icon" [loading]="false"></qpoint-button>
    <qpoint-button variant="icon" icon="clock" [loading]="false"></qpoint-button>
    <qpoint-button variant="icon" icon="clock" [loading]="false" [disabled]="true"></qpoint-button>
    <qpoint-button variant="icon" icon="clock" [loading]="false" size="lg"></qpoint-button>
    <qpoint-button variant="icon" icon="clock"></qpoint-button>
    <qpoint-button variant="icon" icon="clock" [active]="true">Icon active</qpoint-button>
  </ng-template>

  <ng-template #buttonsIconsOnly>
    <qpoint-icon-button icon="clock" [loading]="true"></qpoint-icon-button>
    <qpoint-icon-button icon="clock" [loading]="true"></qpoint-icon-button>
    <qpoint-icon-button icon="clock" title="foo"></qpoint-icon-button>
    <qpoint-icon-button icon="clock"></qpoint-icon-button>
    <qpoint-icon-button icon="clock" [disabled]="true"></qpoint-icon-button>
    <qpoint-icon-button icon="clock" size="lg"></qpoint-icon-button>
    <qpoint-icon-button icon="clock" size="sm"></qpoint-icon-button>
    <qpoint-icon-button icon="clock" [active]="true"></qpoint-icon-button>
    <qpoint-icon-button icon="clock" variant="danger"></qpoint-icon-button>
    <qpoint-icon-button icon="clock" variant="warn"></qpoint-icon-button>
    <qpoint-icon-button icon="clock" variant="success"></qpoint-icon-button>
  </ng-template>

  <ng-template #buttonsBlock>
    <div class="row">
      <div class="col">
        <qpoint-button variant="icon" [loading]="true" [block]="true">Block Button</qpoint-button>
        <qpoint-button variant="icon" [block]="true">Block Button</qpoint-button>
        <qpoint-button variant="icon" [block]="true" type="button">type="button"</qpoint-button>
        <qpoint-button variant="icon" [block]="true" type="submit">type="submit"</qpoint-button>
      </div>
      <div class="col">
        <qpoint-button variant="primary" [loading]="true" [block]="true">Block Button</qpoint-button>
        <qpoint-button variant="primary" [block]="true">Block Button</qpoint-button>
        <qpoint-button variant="primary" [block]="true" type="button">type="button"</qpoint-button>
        <qpoint-button variant="primary" [block]="true" type="submit">type="submit"</qpoint-button>
      </div>
      <div class="col">
        <qpoint-button variant="secondary" [loading]="true" [block]="true">Block Button</qpoint-button>
        <qpoint-button variant="secondary" [block]="true">Block Button</qpoint-button>
        <qpoint-button variant="secondary" [block]="true" type="button">type="button"</qpoint-button>
        <qpoint-button variant="secondary" [block]="true" type="submit">type="submit"</qpoint-button>
      </div>
    </div>
  </ng-template>

  <ng-template #splitButtons>
    <div class="row">
      <div class="col">
        <qpoint-split-button icon="clock" text="Loading">
          <qpoint-split-button-action text="Click Me" (actionClick)="itemClick()"></qpoint-split-button-action>
          <qpoint-split-button-action text="Click Me 2" (actionClick)="itemClickCopy()"></qpoint-split-button-action>
          <qpoint-split-button-action text="Click Me (Disabled)" (actionClick)="itemClickCopy()" [disabled]="true"></qpoint-split-button-action>
        </qpoint-split-button>
        <qpoint-split-button icon="clock" [mainButtonDisabled]="true" text="Split Button">
          <qpoint-split-button-action text="Click Me" (actionClick)="itemClick()"></qpoint-split-button-action>
          <qpoint-split-button-action text="Click Me 2" (actionClick)="itemClickCopy()"></qpoint-split-button-action>
          <qpoint-split-button-action text="Click Me (Disabled)" (actionClick)="itemClickCopy()" [disabled]="true"></qpoint-split-button-action>
        </qpoint-split-button>
        <qpoint-split-button icon="clock" variant="primary" text="Split Button">
          <qpoint-split-button-action text="Click Me" (actionClick)="itemClick()"></qpoint-split-button-action>
          <qpoint-split-button-action text="Click Me 2" (actionClick)="itemClickCopy()"></qpoint-split-button-action>
          <qpoint-split-button-action text="Click Me (Disabled)" (actionClick)="itemClickCopy()" [disabled]="true"></qpoint-split-button-action>
        </qpoint-split-button>
        <qpoint-split-button icon="clock" variant="secondary" text="Split Button (No Items)">
        </qpoint-split-button>
      </div>
      <div class="col">
        <qpoint-split-button icon="clock" [loading]="true" text="Split Button">
          <qpoint-split-button-action text="Click Me" (actionClick)="itemClick()"></qpoint-split-button-action>
          <qpoint-split-button-action text="Click Me 2" (actionClick)="itemClickCopy()"></qpoint-split-button-action>
          <qpoint-split-button-action text="Click Me (Disabled)" (actionClick)="itemClickCopy()" [disabled]="true"></qpoint-split-button-action>
        </qpoint-split-button>
        <qpoint-split-button [loading]="true" text="Loading">
          <qpoint-split-button-action text="Click Me" (actionClick)="itemClick()"></qpoint-split-button-action>
          <qpoint-split-button-action text="Click Me 2" (actionClick)="itemClickCopy()"></qpoint-split-button-action>
          <qpoint-split-button-action text="Click Me (Disabled)" (actionClick)="itemClickCopy()" [disabled]="true"></qpoint-split-button-action>
        </qpoint-split-button>
        <qpoint-split-button icon="clock" size="sm" text="Split Button">
          <qpoint-split-button-action text="Click Me" (actionClick)="itemClick()"></qpoint-split-button-action>
          <qpoint-split-button-action text="Click Me 2" (actionClick)="itemClickCopy()"></qpoint-split-button-action>
          <qpoint-split-button-action text="Click Me (Disabled)" (actionClick)="itemClickCopy()" [disabled]="true"></qpoint-split-button-action>
        </qpoint-split-button>
        <qpoint-split-button size="sm" text="Split Button">
          <qpoint-split-button-action text="Click Me" (actionClick)="itemClick()"></qpoint-split-button-action>
          <qpoint-split-button-action text="Click Me 2" (actionClick)="itemClickCopy()"></qpoint-split-button-action>
          <qpoint-split-button-action text="Click Me (Disabled)" (actionClick)="itemClickCopy()" [disabled]="true"></qpoint-split-button-action>
        </qpoint-split-button>
      </div>
    </div>
  </ng-template>

  <div class="card">
    <div class="card-header">Interaction demo</div>
    <div class="card-body">
      <qpoint-button [disabled]="disabled" [loading]="loading" [size]="size">Demo button</qpoint-button>
      <qpoint-button variant="secondary" [disabled]="disabled" [loading]="loading" [size]="size">Demo button</qpoint-button>

      <hr>
      <qpoint-button (buttonClicked)="disabled = !disabled">Set disabled</qpoint-button>
      <qpoint-button (buttonClicked)="loading = !loading">Set loading</qpoint-button>
      <qpoint-button (buttonClicked)="setSize(buttonSize.sm)">xs</qpoint-button>
      <qpoint-button (buttonClicked)="setSize(buttonSize.default)">md</qpoint-button>
      <qpoint-button (buttonClicked)="setSize(buttonSize.lg)">lg</qpoint-button>
    </div>
  </div>

  <div class="card">
    <div class="card-header">Default</div>
    <div class="card-body">
      <ng-container [ngTemplateOutlet]="buttonsDefault"></ng-container>
    </div>
    <div class="card-body" style="background: #999">
      <ng-container [ngTemplateOutlet]="buttonsDefault"></ng-container>
    </div>
  </div>

  <div class="card">
    <div class="card-header">Primary</div>
    <div class="card-body">
      <ng-container [ngTemplateOutlet]="buttonsPrimary"></ng-container>
    </div>
    <div class="card-body" style="background: #999">
      <ng-container [ngTemplateOutlet]="buttonsPrimary"></ng-container>
    </div>
  </div>

  <div class="card">
    <div class="card-header">Secondary</div>
    <div class="card-body">
      <ng-container [ngTemplateOutlet]="buttonsSecondary"></ng-container>
    </div>
    <div class="card-body" style="background: #999">
      <ng-container [ngTemplateOutlet]="buttonsSecondary"></ng-container>
    </div>
  </div>

  <div class="card">
    <div class="card-header">Icon</div>
    <div class="card-body">
      <ng-container [ngTemplateOutlet]="buttonsIcons"></ng-container>
    </div>
    <div class="card-body" style="background: #999">
      <ng-container [ngTemplateOutlet]="buttonsIcons"></ng-container>
    </div>
  </div>

  <div class="card">
    <div class="card-header"><code>&lt;qpoint-icon-button&gt;</code></div>
    <div class="card-body">
      <ng-container [ngTemplateOutlet]="buttonsIconsOnly"></ng-container>
    </div>
    <div class="card-body" style="background: #999">
      <ng-container [ngTemplateOutlet]="buttonsIconsOnly"></ng-container>
    </div>
  </div>
  <div class="card">
    <div class="card-header">Block buttons</div>
    <div class="card-body">
      <ng-container [ngTemplateOutlet]="buttonsBlock"></ng-container>
    </div>
    <div class="card-body" style="background: #999">
      <ng-container [ngTemplateOutlet]="buttonsBlock"></ng-container>
    </div>
  </div>

  <div class="card">
    <div class="card-header">Split Button</div>
    <div class="card-body">
      <ng-container [ngTemplateOutlet]="splitButtons"></ng-container>
    </div>
    <div class="card-body" style="background: #999">
      <ng-container [ngTemplateOutlet]="splitButtons"></ng-container>
    </div>
  </div>

  <div class="card">
    <div class="card-header">Legacy attributes test</div>
    <div class="card-body">
      <h3><code>qPointButtonType</code></h3>
      <qpoint-button>Default</qpoint-button>
      <qpoint-button qPointButtonType="primary">qPointButtonType="primary"</qpoint-button>
      <qpoint-button qPointButtonType="secondary">qPointButtonType="secondary"</qpoint-button>
      <qpoint-button qPointButtonType="icon" icon="globe">qPointButtonType="icon"</qpoint-button>

      <h3><code>isLoading</code></h3>
      <qpoint-button [isLoading]="true">[isLoading]="true"</qpoint-button>

      <h3><code>btnType</code></h3>
      <qpoint-button btnType="submit">btnType="submit"</qpoint-button>
      <qpoint-button btnType="button">btnType="button"</qpoint-button>
    </div>
  </div>
</qpoint-content-container>
