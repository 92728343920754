<div class="infobox">
  <qpoint-icon class="info-icon" icon="info-circle"></qpoint-icon>
  <table>
    <tr>
      <td>{{'user.accountPage.shortDate' | translate}}:</td>
      <td>{{ dateShort }}</td>
    </tr>
    <tr>
      <td>{{'user.accountPage.longDate' | translate}}:</td>
      <td>{{ dateLong }}</td>
    </tr>
    <tr>
      <td>{{'user.accountPage.time' | translate}}:</td>
      <td>{{ time }}</td>
    </tr>
    <tr>
      <td>{{'user.accountPage.numbers' | translate}}:</td>
      <td>{{ number }}</td>
    </tr>
  </table>
</div>
