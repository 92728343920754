import { computed, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { QPointMapMode, QPointMapModeConfiguration } from './mode';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { toSignal } from '@angular/core/rxjs-interop';

@Injectable()
export class MapModeStateService {
  private modes$$ = new BehaviorSubject<QPointMapModeConfiguration[]>([]);
  modesSignal = toSignal(this.modes$$);

  anyModeActive = computed<boolean>(() => {
    return this.modesSignal().some(mode => mode.active);
  });

  get modes() {
    return this.modes$$.getValue();
  }

  getModeConfiguration(mode: QPointMapMode): QPointMapModeConfiguration {
    return this.modes.find(c => c.mode === mode);
  }

  setModes(modes: QPointMapModeConfiguration[]) {
    this.modes$$.next(modes);
  }

  isModeActive(mode: QPointMapMode): boolean | undefined {
    return this.getModeConfiguration(mode)?.active ?? undefined;
  }

  isModeActive$(mode: QPointMapMode): Observable<boolean | undefined> {
    return this.modes$$.pipe(
      distinctUntilChanged(),
      map(() => {
        return this.isModeActive(mode);
      })
    );
  }

  updateModeActive(mode: QPointMapMode, active: boolean): void {
    let modes = cloneDeep(this.modes);
    const config = modes.find(c => c.mode === mode);

    if (!config.multi) {
      modes = modes.map(c => {
        return {
          ...c,
          active: c.mode === mode ? active : false
        };
      });
    } else {
      modes.splice(modes.findIndex(c => c.mode === mode), 1, {...config, active});
    }

    this.setModes(modes);
  }

  deactivateAll() {
    if (this.modes?.length === 0) {
      return;
    }

    this.setModes((this.modes).map(c => {
      return {
        ...c,
        active: false,
      };
    }));
  }

}
