import { Component, OnInit } from '@angular/core';
import { DatePickerModule } from '@progress/kendo-angular-dateinputs';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { QPointButtonComponent } from '../../../../projects/qpoint-button/src/lib/button/button.component';
import { TextBoxModule } from '@progress/kendo-angular-inputs';
import { QPointTableRowComponent, QPointDateTimePickerComponent } from '@qpoint/forms';
import { QPointToolbarComponent, QPointContentContainerComponent } from '@qpoint/layout';

@Component({
    selector: 'qpoint-control-gallery-table-row',
    templateUrl: './table-row.component.html',
    styleUrls: ['./table-row.component.scss'],
    standalone: true,
    imports: [QPointToolbarComponent, QPointContentContainerComponent, QPointTableRowComponent, TextBoxModule, QPointButtonComponent, DropDownListModule, DatePickerModule, QPointDateTimePickerComponent]
})
export class ControlGalleryTableRowComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
