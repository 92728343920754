<qpoint-toolbar [title]="breadcrumbs" [showContextbarButton]="true"></qpoint-toolbar>
<qpoint-content-container>
  <qpoint-legal-info
  infoTitle="Info"
  imprintTitle="Impressum"
  termsOfUseTitle="Nutzungsbedingungen"
  privacyTitle="Datenschutz"
  infoText="Inhalt des ersten Tabs"
  imprintText="{{imprintText}}"
  termsOfUseText="Inhalt des dritten Tabs"
  privacyText="Inhalt des vierten Tabs"
  productImagePath="https://prod0infrastorage2.blob.core.windows.net/public/qpoint-logo.png?st=2018-07-17T08%3A27%3A41Z&se=2058-07-18T08%3A27%3A00Z&sp=r&sv=2018-03-28&sr=b&sig=Jq09tahWh5LTRqIHqSADdoNQ%2BdJJU9Jgj7IRa%2BOqPeY%3D"
  productName="Q Point testing area"
  productVersion="1.0.0"
  >
  </qpoint-legal-info>
</qpoint-content-container>
