import { Inject, Injectable } from '@angular/core';
import { IQPointUserInfoSidebarService } from '@qpoint/layout';
import { DI_AUTHENTICATION, IQPointAuthenticationService } from '@qpoint/utils';

@Injectable({
  providedIn: 'root'
})
export class UserInfoSidebarProviderService implements IQPointUserInfoSidebarService {

  name: string;
  pictureUrl: string;

  constructor(@Inject(DI_AUTHENTICATION) private authentication: IQPointAuthenticationService) {
    this.name = authentication.name;
    this.pictureUrl = authentication.pictureUrl;
  }
}
