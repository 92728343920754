<qpoint-toolbar [title]="'Table Rows'"></qpoint-toolbar>

<qpoint-content-container>
  <qpoint-table-row [label]="'Input Field'">
    <ng-container flexTableMain>
      <input kendoTextBox placeholder="Input">
    </ng-container>
    <ng-container flexTableActions>
      <qpoint-button icon="check" qPointButtonType="icon">Icon Button 1</qpoint-button>
      <qpoint-button icon="check" qPointButtonType="icon">Icon Button 2</qpoint-button>
    </ng-container>
  </qpoint-table-row>

  <qpoint-table-row [label]="'DropDown'">
    <ng-container flexTableMain>
      <kendo-dropdownlist></kendo-dropdownlist>
    </ng-container>
    <ng-container flexTableActions>
      <qpoint-button icon="plus-circle" qPointButtonType="primary">Primary</qpoint-button>
    </ng-container>
  </qpoint-table-row>

  <qpoint-table-row [label]="'Date Picker'">
    <ng-container flexTableMain>
      <kendo-datepicker></kendo-datepicker>
    </ng-container>
  </qpoint-table-row>

  <qpoint-table-row [label]="'Date Picker formated'">
    <ng-container flexTableMain>
      <kendo-datepicker format="dd/MMM/yyyy"></kendo-datepicker>
    </ng-container>
  </qpoint-table-row>

  <qpoint-table-row [label]="'Date Picker formated 1'">
    <ng-container flexTableMain>
      <kendo-datepicker [format]="'dd.MMMM.yyyy'"></kendo-datepicker>
    </ng-container>
  </qpoint-table-row>

  <qpoint-table-row [label]="'Input Field'" [icon]="'info_outline'" [iconType]="'material'"
    [toolTip]="'Das ist ein Test'">
    <ng-container flexTableMain>
      <input kendoTextBox placeholder="Input">
    </ng-container>
  </qpoint-table-row>
  <qpoint-table-row [label]="'Datetimepicker'">
    <ng-container flexTableMain>
      <qpoint-datetime-picker cancelToolTip="test c" acceptToolTip="test a" cancelLabel="1 Cancel">
      </qpoint-datetime-picker>
      <qpoint-datetime-picker [format]="'dd.MMMM.yyyy'" [dayOnly]="true"></qpoint-datetime-picker>
    </ng-container>
  </qpoint-table-row>
</qpoint-content-container>