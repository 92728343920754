import { Directive, HostListener, Inject, InjectionToken, Optional, Provider, Self } from '@angular/core';
import { TimePickerComponent } from '@progress/kendo-angular-dateinputs';
import { QPointTimePickerUnitDirective } from './time-picker-unit';
import { set } from 'date-fns';
import { cloneDeep } from 'lodash';

export const DI_TIME_PICKER_AUTOCOMPLETE_ENABLED = new InjectionToken<boolean>('Boolean to enable/disable autocomplete directive');
export function provideTimePickerAutocompleteEnabled(enabled: boolean): Provider {
  return {
    provide: DI_TIME_PICKER_AUTOCOMPLETE_ENABLED,
    useValue: enabled
  };
}

@Directive({
  selector: 'kendo-timepicker, kendo-timepicker[qpointTimePickerAutocomplete]',
  standalone: true
})
export class QPointTimepickerAutocompleteDirective {
  private ignoreBlur = false;
  @HostListener('blur') onBlur() {
    if(this.ignoreBlur) {
      this.ignoreBlur = false;
      return;
    }

    this.updateValue();
  }

  constructor(
    @Self() @Optional() public kendoTimepicker: TimePickerComponent,
    @Self() @Optional() private timepickerUnit: QPointTimePickerUnitDirective,
    @Inject(DI_TIME_PICKER_AUTOCOMPLETE_ENABLED) @Optional() private autocompleteEnabled: boolean
  ) {
    if(timepickerUnit) {
      timepickerUnit.onBlur = () => {};
    }
  }

  private updateValue() {
    if(!this.kendoTimepicker || (this.autocompleteEnabled != null && this.autocompleteEnabled === false)) {
      return;
    }
    const eventEmitterCopy = cloneDeep(this.kendoTimepicker.onBlur);
    const inputValue = this.kendoTimepicker?.dateInput?.inputValue;
    const splitValue = (inputValue || '').split(':').map((item) => item.replace(this?.timepickerUnit?.text, '').trim());

    if(inputValue && splitValue?.length > 0 && splitValue.some(entry => !isNaN(+entry))) {
      const minHours = this.kendoTimepicker?.min?.getHours() || 0;
      const minMinutes = this.kendoTimepicker?.min?.getMinutes() || 0;

      const date = set(this.kendoTimepicker?.min || new Date(), {
        hours: !isNaN(+splitValue[0]) && +splitValue[0] >= minHours ? +splitValue[0] : minHours,
        minutes: !isNaN(+splitValue[1]) && (+splitValue[0] > minHours || +splitValue[1] >= minMinutes) ? +splitValue[1] : minMinutes,
        seconds: 0,
        milliseconds: 0
      });

      this.kendoTimepicker.dateInput.writeValue(date);
      this.kendoTimepicker.handleInputChange(date);

      this.ignoreBlur = true;
      this.kendoTimepicker.onBlur.emit = () => {};
      this.kendoTimepicker.dateInput.inputElement.blur();
      this.kendoTimepicker.onBlur = eventEmitterCopy;
      this.ignoreBlur = false;
    }
    if(this.timepickerUnit) {
      this.timepickerUnit.updateInputElement();
    }
  }
}
