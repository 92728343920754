import { AfterViewInit, Component, OnInit } from '@angular/core';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { BehaviorSubject, timer } from 'rxjs';
import {
  IVirtualScrollSettings,
  QPointKeydownEndEvent,
  QPointScrollBottomEvent
} from '../../../projects/qpoint-layout/src/lib/components/grid/grid.component';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';
import { QPointInfoCornerComponent } from '../../../projects/qpoint-layout/src/lib/components/grid/info-corner/info-corner.component';
import { SharedModule, HeaderModule, GroupModule, BodyModule } from '@progress/kendo-angular-grid';
import { QPointButtonComponent } from '../../../projects/qpoint-button/src/lib/button/button.component';
import { QPointGridModule } from '../../../projects/qpoint-layout/src/lib/components/grid/q-point-grid.module';
import { QPointToolbarComponent, QPointSearchfieldComponent, QPointToolbarDividerComponent, QPointToolbarButtonComponent, QPointContentContainerComponent, QPointExpansionPanelComponent } from '@qpoint/layout';

@Component({
    selector: 'app-grid-test',
    templateUrl: './grid-test.component.html',
    styleUrls: ['./grid-test.component.scss'],
    standalone: true,
    imports: [QPointToolbarComponent, QPointSearchfieldComponent, QPointToolbarDividerComponent, QPointToolbarButtonComponent, QPointContentContainerComponent, QPointExpansionPanelComponent, QPointGridModule, QPointButtonComponent, SharedModule, HeaderModule, GroupModule, BodyModule, QPointInfoCornerComponent, ExcelExportModule, AsyncPipe, TranslateModule]
})
export class GridTestComponent implements OnInit, AfterViewInit {
  public dataSubject: BehaviorSubject<any> = new BehaviorSubject([]);
  public filter = {
    skip: 0,
    take: 50,
    orderBy: ['documentType asc']
  };
  public myCount = 55;
  public changeSelectedDataSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public groups = [{ field: 'operatingCompany' }];
  public items: any[];
  public tests: any[];
  public additionalOrderBy: SortDescriptor[];
  public filteredItems: any[];
  public dateValue = new Date();
  private searchFilter: string;
  private valuecount = 0;
  virtualScrollSettings: IVirtualScrollSettings = { take: 150, skip: 0 };
  public searchLoading: boolean;

  constructor() {
  }

  ngOnInit() {
    this.additionalOrderBy = [{ field: 'test', dir: 'asc' }];
    this.valuecount = 200;
    const values = [];
    for (let i = 1; i <= this.valuecount; i++) {
      const test = Math.floor(Math.random() * 10);
      const gridEntry = {
        documentType: i,
        operatingCompany: 'Betreiberfirma ' + i % 2,
        plant: 'Anlage ' + i,
        deliveryNote: 'Lieferschein ' + test,
        customer: 'Kunde ' + i,
        test: 'Kunde ' + test,
        constructionSite: 'Baustelle ' + i,
        showDetailViewValue: Math.random() >= 0.4,
      };

      values.push(gridEntry);
    }

    this.items = values;
    this.filteredItems = values;

    const subValues = [];
    for (let i = 1; i <= 120; i++) {
      const gridEntry = {
        documentType: i,
        operatingCompany: 'Sub Betreiberfirma ' + i % 2,
        plant: 'Sub Anlage ' + i,
        deliveryNote: 'Sub Lieferschein ' + i,
        customer: 'Sub Kunde ' + i,
        constructionSite: 'Sub Baustelle ' + i,
        showDetailViewValue: Math.random() >= 0.4,
      };

      subValues.push(gridEntry);
    }
    this.tests = subValues;
  }

  public itemNeedSpecialAttentionFunction = (dataItem) =>  {
    return dataItem.documentType % 2 === 1;
  }

  ngAfterViewInit(): void {
    timer(1).subscribe(value => {
      this.dataSubject.next(this.items);
    });
  }

  public doSearch(event: any) {
    // this.searchFilter = event;
    // this.serverFilterChanged(this.filter);
    this.searchLoading = !this.searchLoading;
    console.log(this.searchLoading);
  }

  public serverFilterChanged(filter: any) {
    console.log('server filter changed', filter);
    let filteredItems = this.items;
    if (this.searchFilter) {
      filteredItems = this.items.filter(i => i.documentType.startsWith(this.searchFilter));
    }
    if (this.filter.orderBy) {
      const sort = [];
      this.filter.orderBy.forEach((item) => {
        const splittedItems = item.split(' ');
        const dir = splittedItems.length === 2 ? splittedItems[1] : null;
        sort.push({
          field: splittedItems[0],
          dir: dir && dir === 'desc' ? 'desc' : 'asc'
        });
      });


      filteredItems = orderBy(filteredItems, sort);
      this.filteredItems = filteredItems;
    }
    this.dataSubject.next(filteredItems);
    this.filter.skip = 0;
  }

  public updateSelectedItems(event) {
    console.log("Selection Changed: ", event);
  }

  public refresh() {
    this.changeSelectedDataSubject.next([]);
    const filteredItems = [...this.items];
    this.dataSubject.next(filteredItems);
  }

  scrolledBottom($event: QPointScrollBottomEvent) {
    console.log($event);
    timer(3000).subscribe(value => {
      const values = [];
      for (let i = this.valuecount + 1; i <= this.valuecount + 1000; i++) {
        const gridEntry = {
          documentType: i,
          operatingCompany: 'Betreiberfirma ' + i % 2,
          plant: 'Anlage ' + i,
          deliveryNote: 'Lieferschein ' + i,
          customer: 'Kunde ' + i,
          constructionSite: 'Baustelle ' + i,
          showDetailViewValue: Math.random() >= 0.4,
        };

        values.push(gridEntry);
      }
      console.log('values', values);
      this.valuecount = this.valuecount + 10000;
      $event.loadingFinishCallback();
      this.items = [...this.items, ...values];
      this.dataSubject.next(this.items);
    });
  }

  dblClickOnRowEventHandler($event: any) {
    console.log('master-click', $event);
  }

  dblClickOnDetailGridEventHandler($event: any) {
    console.log('detail-click', $event);
  }

  detailsEnd($event: any) {
    console.log($event);
  }

  dateChanged(date: Date) {
    console.log(date);
  }

  toggleLoading() {
    this.searchLoading = !this.searchLoading;
  }

  scrollToBottom(event: QPointKeydownEndEvent): void {
    console.log({ event });
    event.scrollToBottom();
  }
}
