import { cloneDeep, first, last, isEqual } from 'lodash';

export function toGeoJSON(coordinates: google.maps.LatLngLiteral[], addTailVertex = true): [number, number][] {
  const coordinatesCopy = cloneDeep(coordinates);

  if (addTailVertex && !isEqual( first( coordinatesCopy), last(coordinatesCopy ) ) ) {
    coordinatesCopy.push(first( coordinatesCopy ) ); // close the polygon (needed for a polygon GEOJson )
  }

  return (coordinatesCopy || []).map(entry => {
    return [entry.lng, entry.lat];
  });
}

export function fromGeoJSON(coordinates: [number, number][], removeClosingPolygonVertex = false): google.maps.LatLngLiteral[] {
  const result = (coordinates || []).map(entry => {
    return {
      lng: entry[0],
      lat: entry[1]
    };
  });

  if( removeClosingPolygonVertex && result.length > 1 && isEqual( first( result ), last( result ) ) ) {
    result.pop() // remove last element  (provided by a GEOJson polygon)
  }

  return result;
}
