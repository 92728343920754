import 'hammerjs';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { ExcelModule, GridModule, PDFModule } from '@progress/kendo-angular-grid';
import { CheckBoxModule, InputsModule } from '@progress/kendo-angular-inputs';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { PopupModule } from '@progress/kendo-angular-popup';
import { QPointButtonComponent } from '@qpoint/buttons';
import { QPointIconModule } from '@qpoint/icons';
import { QPointDateFormatPipe, QPointNumberFormatPipe } from '@qpoint/i18n';
import { QPointContextbarService } from '../contextbar/contextbar.service/contextbar.service';
import { QPointCellMenuComponent } from './cell-menu/cell-menu.component';
import { EllipseMenuComponent } from './ellipse-menu/ellipse-menu.component';
import { QPointExcelExportCustomComponent } from './excel-export-custom/excel-export-custom.component';
import { QPointGridContextMenuItemComponent } from './grid-context-menu-item.component';
import { QPointGridContextMenuComponent } from './grid-context-menu.component';
import { QPointGridDetailTemplateDirective } from './grid-detail-template.directive';
import { GridSpecialAttentionTemplateDirective } from './grid-special-attention-template.directive';
import { QPointGridVirtualGroupComponent } from './grid-virtual-group/grid-virtual-group.component';
import { QPointGridComponent } from './grid.component';
import { QPointInfoCornerComponent } from './info-corner/info-corner.component';
import { SpecialAttentionComponent } from './special-attention/special-attention.component';
import { SidebarSizes } from '../sidebar';
import { QPointLoadingSpinnerComponent } from '@qpoint/loading';

const QPOINT_SERVICES = [
  QPointContextbarService,
  SidebarSizes
];

@NgModule({
  declarations: [
    QPointGridComponent,
    QPointGridVirtualGroupComponent,
    QPointGridContextMenuComponent,
    QPointGridContextMenuItemComponent,
    QPointGridDetailTemplateDirective,
    GridSpecialAttentionTemplateDirective,
    QPointExcelExportCustomComponent,
    EllipseMenuComponent,
    QPointCellMenuComponent,
    SpecialAttentionComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    LayoutModule,
    QPointIconModule,
    DateInputsModule,
    InputsModule,
    TranslateModule,
    RouterModule,
    PopupModule,
    DragDropModule,
    GridModule,
    PDFModule,
    CheckBoxModule,
    ExcelModule,
    ButtonsModule,
    NgbModule,
    QPointButtonComponent,
    QPointInfoCornerComponent,
    QPointDateFormatPipe,
    QPointNumberFormatPipe,
    QPointLoadingSpinnerComponent
  ],
  exports: [
    QPointGridComponent,
    QPointGridVirtualGroupComponent,
    QPointGridContextMenuComponent,
    QPointGridContextMenuItemComponent,
    QPointGridDetailTemplateDirective,
    GridSpecialAttentionTemplateDirective,
    QPointInfoCornerComponent,
    QPointExcelExportCustomComponent,
  ],
  providers: [
    ...QPOINT_SERVICES,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerGestureConfig,
    }
  ]
})
export class QPointGridModule {
}
