import { Component, OnInit } from '@angular/core';
import { IQPointBase64Image } from '../../../../projects/qpoint-upload/src/lib/cropper/q-point-cropper.component';
import { QPointCropperComponent } from '@qpoint/uploads';
import { QPointToolbarComponent, QPointContentContainerComponent } from '@qpoint/layout';

@Component({
    selector: 'qpoint-control-gallery-upload',
    templateUrl: './control-gallery-upload.component.html',
    styleUrls: ['./control-gallery-upload.component.scss'],
    standalone: true,
    imports: [QPointToolbarComponent, QPointContentContainerComponent, QPointCropperComponent]
})
export class ControlGalleryUploadComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  imageChanged($event: IQPointBase64Image) {
    console.log($event);
  }
}
