import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { QPointSidebarService, } from '../../projects/qpoint-layout/src/lib/components/sidebar/sidebar.service/sidebar.service';
import { IWorkspace, } from '../../projects/qpoint-layout/src/lib/components/workspace-selection/workspace-selection.component';
import { SidebarBadgesService } from './services/sidebar-badges.service';
import { QPointMapsLoader } from '../../projects/qpoint-maps/src/lib/services/maps-loader.service';
import { ThemeService } from '../../projects/qpoint-theme/src/lib/theme/theme.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public workspaces: IWorkspace[];
  public selectedWorkspace: IWorkspace;


  constructor (
    private sidebarService: QPointSidebarService,
    private router: Router,
    private translateService: TranslateService,
    private sidebarBadgesService: SidebarBadgesService,
    private mapsService: QPointMapsLoader,
    private themeService: ThemeService) {
    this.workspaces = [
      { id: '1', name: 'Eurovia Industrie GmbH - Standord Neufelden', imageSrc: 'https://picsum.photos/100/100' },
      { id: '2', name: 'Eurovia Industrie GmbH - Standord Neufelden mit Extras', imageSrc: 'https://picsum.photos/100/100' },
      { id: '3', name: 'Test3' }
    ];
    this.selectedWorkspace = this.workspaces[0];
    mapsService.initMap();
  }

  public setDarkmode() {
    this.themeService.setDarkMode();
  }
  public setLightMode() {
    this.themeService.setLightMode();
  }

  setSystemDefault() {
    this.themeService.setSystemMode();
  }
  public ngOnInit() {
    // timer(10000).subscribe(() => alert('sers'));
    this.themeService.apply();
    for (let _i = 3; _i < 50; _i++) {
      this.workspaces.push({ id: _i.toString(), name: 'test' + _i });
    }
    this.translateService.setDefaultLang('en-US');

    this.sidebarService.registerBadgeSubject('main', this.sidebarBadgesService.myBadgeSubject);
    this.sidebarService.registerBadgeSubject('gallery', this.sidebarBadgesService.myBadgeSubject);
  }

  public addNewWorkspace () {
    console.log('addNewWorkspace');
  }

  public selectedWorkspaceSettings () {
    console.log('selectedWorkspaceSettings');
  }

  public selectWorkspace (event) {
    console.log('selectWorkspace', event);
  }

  public navigateToLegal (): void {
    this.router.navigate(['legal']);
  }

  myAccountClick () {
    this.router.navigateByUrl('/profile');
  }

}
