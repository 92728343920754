import { Component, Input, OnInit, signal } from '@angular/core';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import { AnimationProp } from '@fortawesome/angular-fontawesome';

export type IconType = 'solid' | 'regular' | 'light' | 'qpoint' | 'material'
  | 'material-outlined' | 'material-round' | 'material-two-tone' | 'material-sharp';

export type IconFlip = 'horizontal' | 'vertical' | 'both';

export type IconRotate = '90' | '180' | '270';

@Component({
  selector: 'qpoint-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnInit {
  animation = signal<AnimationProp | null>(null);


  /**
   * Name of the icon, gallery: https://fontawesome.com/icons?d=gallery
   */
  @Input() public icon: string | IconName;

  /**
   * Icon Type
   */
  @Input() public iconType: IconType = 'solid';

  /**
   * Icon should spin
   */
  @Input() set spin(value: boolean) {
    this.animation.set(value ? 'spin' : null);
  }

  /**
   * Icon should be flipped
   */
  @Input() public flip?: IconFlip;

  /**
   * Icon should be rotated 90 | 180 | 270 degrees
   */
  @Input() public rotate?: IconRotate;

  /**
   * Icon should pulse
   */
  @Input() set pulse(value: boolean) {
    this.animation.set(value ? 'spin-pulse' : null);
  }

  /**
   * Places a border around the icon
   */
  @Input() public border: boolean;

  /**
   * Inverse color
   */
  @Input() public inverse?: boolean;

  @Input('animation') set animationSetter(value: AnimationProp) {
    this.animation.set(value);
  }

  public isMaterialIcon = false;
  public materialClassName: string;

  /**
   * get font awesome icon type mapping for custom input
   */
  public get mappedIconType(): IconPrefix {
    switch (this.iconType) {
      case 'regular':
        return 'far';
      case 'light':
        return 'fal';
      case 'solid':
      default:
        return 'fas';
    }
  }

  constructor() {
  }

  ngOnInit(): void {
    if (this.iconType) {
      this.isMaterialIcon = this.isMaterialType(this.iconType);
      this.materialClassName = this.resolveMaterialClassName(this.iconType);
    }
  }

  public isMaterialType(iconType: IconType): boolean {
    const type = iconType as string;
    return type && type.startsWith('material');
  }

  public resolveMaterialClassName(iconType: IconType): string {
    const type = iconType as string;
    let typeEnding = '';
    if (type.includes('-')) {
      typeEnding = type.substring(type.indexOf('-'));
    }
    return 'material-icons' + typeEnding;
  }
}
