import { Directive, Input } from '@angular/core';

@Directive({
  selector: '[qpointMapMarkerLabel]'
})
export class MapMarkerLabelDirective {

  constructor() { }

  @Input()
  public shiftLabelHorizontal: number;
  @Input()
  public shiftLabelVertical: number;
}
