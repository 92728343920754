import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  QPointMultiViewDateRangePickerConfig, QPointMultiViewDateRangePickerSelectionMode,
  QPointMultiViewDateRangePickerTranslations
} from '../../../../../projects/qpoint-forms/src/lib/multi-view-date-range-picker/multi-view-date-range-picker.component';
import { addDays } from 'date-fns';
import { AsyncPipe, JsonPipe } from '@angular/common';
import { QPointMultiViewDateRangePickerComponent } from '@qpoint/forms';
import { QPointButtonComponent } from '../../../../../projects/qpoint-button/src/lib/button/button.component';

@Component({
    selector: 'qpoint-multi-view-date-range-picker-demo',
    templateUrl: './multi-view-date-range-picker-demo.component.html',
    styleUrls: ['./multi-view-date-range-picker-demo.component.scss'],
    standalone: true,
    imports: [QPointButtonComponent, QPointMultiViewDateRangePickerComponent, FormsModule, ReactiveFormsModule, AsyncPipe, JsonPipe]
})
export class MultiViewDateRangePickerDemoComponent implements OnInit {
  multiViewDateRangePickerFormControl = new UntypedFormControl(null);
  translations: QPointMultiViewDateRangePickerTranslations = {
    from: 'Von',
    to: 'Bis',
    modeSingle: 'Datum',
    modeRange: 'Bereich'
  };
  disabled = false;
  showNavigationArrows = false;
  incrementConfig: QPointMultiViewDateRangePickerConfig = {
    single: {
      days: 1,
    },
    range: {
      weeks: 1,
      rangeDelta: true,
    }
  };

  mode: QPointMultiViewDateRangePickerSelectionMode = 'single';
  showSelectionButtons = true;
  disabledDates: ((date: Date) => boolean) | Date[] | Day[] | undefined = (date: Date) => {
    return addDays(new Date(), 5).getTime() < date.getTime();
  }

  constructor() {
  }

  ngOnInit(): void {
  }

  patchToday() {
    this.multiViewDateRangePickerFormControl.patchValue({start: new Date(), end: new Date()});
  }

  patchRange() {
    this.multiViewDateRangePickerFormControl.patchValue({start: new Date(), end: addDays(new Date(), 3)});
  }

  patchIncompleteRange() {
    this.multiViewDateRangePickerFormControl.patchValue({start: new Date()});
  }

  toggleMode() {
    this.mode = this.mode === 'single' ? 'range' : 'single';
  }

  toggleSelectionButtons() {
    this.showSelectionButtons = !this.showSelectionButtons;
  }
}
