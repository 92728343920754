<qpoint-main>
  <qpoint-sidebar [imgPath]="'assets/img/logo-white.svg'" [imgPathSmall]="'assets/img/logo-small.svg'" (legal)="navigateToLegal()" [showLegal]="true">

    <qpoint-user-info (myAccount)="myAccountClick()" [showMyAccount]="true"></qpoint-user-info>
    <qpoint-workspace-selection [workspaces]="workspaces"
                                [workspaceSettingsRoutePath]="'/main'"
                                [selectedWorkspace]="selectedWorkspace"
                                (workspaceClick)="selectWorkspace($event)"
                                (selectedWorkspaceSettingsClick)="selectedWorkspaceSettings()"
                                (addNewWorkspaceClick)="addNewWorkspace()"></qpoint-workspace-selection>
  </qpoint-sidebar>
  <router-outlet></router-outlet>
</qpoint-main>
<div style="position:fixed; bottom: 0; left: 0; z-index: 5000">
  <button kendoButton (click)="setDarkmode()">Dark</button>
  <button kendoButton (click)="setLightMode()">light</button>
  <button kendoButton (click)="setSystemDefault()">default</button>
</div>
