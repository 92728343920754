import { Component } from '@angular/core';
import { QPointPdfThumbnailComponent, QPointPdfRendererComponent } from '@qpoint/pdf';
import { QPointToolbarComponent, QPointContentContainerComponent } from '@qpoint/layout';

@Component({
    selector: 'app-control-gallery-pdf',
    templateUrl: './pdf.component.html',
    styleUrls: ['./pdf.component.scss'],
    standalone: true,
    imports: [QPointToolbarComponent, QPointContentContainerComponent, QPointPdfThumbnailComponent, QPointPdfRendererComponent]
})
export class ControlGalleryPdfComponent {

  constructor() { }
}
