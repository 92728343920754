import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QPointFormValidators, QPointFormGroupComponent, QPointFormLabelComponent, QPointMultipleEmailsInputComponent } from '@qpoint/forms';

@Component({
    selector: 'app-control-gallery-multiple-emails-input',
    templateUrl: './multiple-emails-input.component.html',
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        QPointFormGroupComponent,
        QPointFormLabelComponent,
        QPointMultipleEmailsInputComponent,
    ],
})
export class ControlGalleryMultipleEmailsInputComponent implements OnInit {
  public form: UntypedFormGroup;
  public messages = {
    email: 'E-Mail Adressen fehlerhaft',
    required: 'Feld ist ein Pflichtfeld',
  };

  emailsValidator = (control: AbstractControl): { [key: string]: any } | null => {
    const forbidden = control.value.some(email => QPointFormValidators.emailValidator(new UntypedFormControl(email)));
    return forbidden ? { 'email': { value: control.value } } : null;
  };

  constructor() {
  }

  ngOnInit() {
    this.initForm();
  }

  get readonly() {
    return this.form.get('readonly').value;
  }

  initForm() {
    this.form = new UntypedFormGroup({
      readonly: new UntypedFormControl(),
      disabled: new UntypedFormControl(),
      emailAddresses: new UntypedFormControl([], [Validators.required, this.emailsValidator])
    });

    this.form.get("disabled").valueChanges.subscribe(isDisabled => {
      if (isDisabled)
        this.form.get("emailAddresses").disable();
      else
        this.form.get("emailAddresses").enable();
    });
  }

}
