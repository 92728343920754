import { Injectable } from '@angular/core';
import { IQPointImageLoaderService } from '@qpoint/layout';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: "root"
})
export class ImageLoaderService implements IQPointImageLoaderService {
  constructor(
  ) {
  }

  getProfileImage(imageUri: string): Observable<Blob> {
    return null;
  }
}
