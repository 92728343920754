<div class="grid-wrapper" #gridWrapper
  [ngClass]="{'qpoint-data-loading': isDataLoading, 'scrollbar-visible': isScrollbarVisible}">
  <kendo-grid #mainGrid [data]="kendoGridInput" [group]="group" [resizable]="resizableColumn"
    [reorderable]="reorderableColumn" (groupChange)="groupChange($event)" [groupable]="false"
    [hideHeader]="!gridColumnsMergeComplete" [sortable]="sortable" [sort]="sort" (sortChange)="onSortChange($event)"
    [kendoGridSelectBy]="selectionKey" [selectedKeys]="kendoGridSelectedKeys"
    (selectedKeysChange)="onSelectedKeysChange($event)" [selectable]="kendoSelectableSettings"
    (dblclick)="onDblClickEvent($event)" [class]="'qpoint-grid'" [ngClass]="gridClasses"
    (selectionChange)="selectionChange($event)" (pageChange)="pageChange($event)"
    (scrollBottom)="kendoGridScrolledBottom($event)" (cellClick)="onCellClick($event)" [scrollable]="scrollable"
    [rowClass]="rowClassCallback" [pageSize]="virtualScrollSettings?.take" [skip]="virtualScrollSettings?.skip"
    (columnReorder)="columnOrderChanged($event)" (columnResize)="columnResize($event)" [rowHeight]="rowHeight"
    [detailRowHeight]="detailRowHeight" (detailExpand)="onDetailExpand($event)" (excelExport)="exportDone($event)"
    [kendoGridExpandDetailsBy]="expandDetailsBy" [(expandedDetailKeys)]="expandedDetailKeys"
    (columnVisibilityChange)="onColumnVisabilityChange($event)">

    <kendo-grid-messages [columns]="columnsTextPath | translate" [columnsApply]="columnsApplyTextPath | translate"
      [columnsReset]="columnsResetTextPath | translate">
    </kendo-grid-messages>
    <!-- The column with the checkboxes -->
    <kendo-grid-checkbox-column #checkboxColumn [class]="'checkboxColumn k-noexport'" [reorderable]="false"
      [resizable]="false" [headerClass]="'k-noexport gridCheckboxColumnHeader'"
      [hidden]="!selectable || selectionMode === 'single'" [showSelectAll]="showSelectAllColumn" [width]="30">

      <ng-template kendoGridHeaderTemplate>
        <div *ngIf="reorderableColumn" class="drop-hint-hider-left"></div>
        <input id="selectAllCheckboxId" kendoGridSelectAllCheckbox size="medium" kendoCheckBox
               (selectAllChange)="onSelectAllChange($event)" (mousedown)="selectAllCheckboxClicked = true" [state]="selectAllState">
        <label
          *ngIf="dataInput && dataInput.length > 0 && showSelectAllColumn && (maxCountToEnableSelectAllFeature >= dataInput.length)"
          class="k-checkbox-label" for="selectAllCheckboxId"></label>
      </ng-template>
      <ng-template kendoGridCellTemplate let-idx="rowIndex" let-column="column">
        <input [id]="idx + 'checkbox'" type="checkbox" kendoCheckBox (click)="selectionClicked($event)" size="medium"
          [style.visibility]="checkBoxesDisplayNone ? 'hidden' : 'visible'" [kendoGridSelectionCheckbox]="idx" />
        <label [style.visibility]="checkBoxesDisplayNone ? 'hidden' : 'visible'" [for]="idx + 'checkbox'"
          (click)="selectionClicked($event)"></label>
      </ng-template>
    </kendo-grid-checkbox-column>

    <kendo-grid-excel [fileName]="excelFileName" [fetchData]="excelExportFetchData" [filterable]="filterable">
    </kendo-grid-excel>
    <ng-template *ngIf="gridDetailTemplate" kendoGridDetailTemplate let-dataItem
      [kendoGridDetailTemplateShowIf]="showDetailCondition">
      <div class="detail-template-container" [style.height]="detailRowHeight ? detailRowHeight + 'px': 'auto'">
        <ng-container *ngTemplateOutlet="gridDetailTemplate; context: {$implicit: dataItem}"></ng-container>
      </div>
    </ng-template>
    <ng-template kendoGridNoRecordsTemplate>
      {{ noRecordsTextTemplate }}
    </ng-template>
    <ng-template *ngIf="footerToolbarEnabled || columnConfigurationEnabled" kendoGridToolbarTemplate
      [position]="'bottom'">
      <kendo-grid-column-chooser *ngIf="columnConfigurationEnabled"></kendo-grid-column-chooser>
      <div *ngIf="footerToolbarEnabled" class="qpoint-grid-toolbar">
        <div *ngIf="footerToolbarEnabled" style="padding-right: 15px;display:inline-block">
          <span>{{(this.totalItemCount ? this.totalItemCount : dataInput?.length) | qPointNumberFormat }} </span>
          <span *ngIf="dataInput?.length !== 1">{{elementPluralTextPath | translate}}</span>
          <span *ngIf="dataInput?.length === 1">{{elementTextPath | translate}}</span>
        </div>
        <span *ngIf="kendoGridSelectedKeys?.length > 1">{{kendoGridSelectedKeys.length | qPointNumberFormat}}
          {{multipleElementSelectedTextPath | translate}}</span>
        <span *ngIf="kendoGridSelectedKeys?.length === 1">{{kendoGridSelectedKeys.length | qPointNumberFormat}}
          {{singleElementSelectedTextPath | translate}}</span>
      </div>
    </ng-template>
    <ng-content></ng-content>
  </kendo-grid>
  <div *ngIf="isDataLoading" class="loading-spinner-block">
    <qpoint-loading-spinner size="3" class="loading-spinner"></qpoint-loading-spinner>
  </div>
</div>
