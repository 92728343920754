import { Component } from '@angular/core';
import { QPointIconModule } from '../../../../projects/qpoint-icons/src/lib/q-point-icon.module';
import { QPointToolbarComponent, QPointContentContainerComponent } from '@qpoint/layout';

@Component({
    selector: 'app-control-gallery-icons',
    templateUrl: './icons.component.html',
    styleUrls: ['./icons.component.scss'],
    standalone: true,
    imports: [QPointToolbarComponent, QPointContentContainerComponent, QPointIconModule]
})
export class ControlGalleryIconsComponent {

  constructor() { }
}
