<div class="card mt-3">
  <div class="card-header">Multiple E-Mail Input</div>
  <div class="card-body">
    <form class="form-horizontal" [formGroup]="form">

      <div class="checkbox">
        <label class="d-flex align-items-center">
          <input type="checkbox" formControlName="readonly">
          <span class="ml-1">readonly</span>
        </label>
      </div>

      <div class="checkbox">
        <label class="d-flex align-items-center">
          <input type="checkbox" formControlName="disabled">
          <span class="ml-1">disabled</span>
        </label>
      </div>

      <qpoint-form-group forControl="emailAddresses" [messages]="messages">
        <qpoint-form-label>E-Mails</qpoint-form-label>
        <qpoint-multiple-emails-input formControlName="emailAddresses" [readonly]="readonly" placeholder="Add e-mail and press enter">
        </qpoint-multiple-emails-input>
      </qpoint-form-group>

    </form>
  </div>
</div>
