<div class="card mt-3">
  <div class="card-header">Suggest List</div>
  <div class="card-body">

    <div class="form-group"><label><input type="checkbox" [(ngModel)]="loading"> Loading</label></div>
    <div class="form-group"><label><input type="checkbox" [(ngModel)]="readonly"> readonly</label></div>
    <div class="form-group"><label><input type="checkbox" [(ngModel)]="disabled"> disabled</label></div>

    <div>
      <label>Drop Down Lists</label>
      <qpoint-suggestlist
        [dropDownVariant]="dropDownVariant.DropDownList"
        textField="value"
        valueField="key"
        [data]="items"
        placeholder="test"
        [filterable]="false"
        [isLoading]="loading"
        [disabled]="disabled"
        [hasMoreEntries]="true"
        [dropdownListClearable]="true"
        [valuePrimitive]="false"
        [readonly]="readonly"
      >
        <ng-template qpointSuggestlistValueTemplate let-dataItem>
          {{ dataItem?.key}}
        </ng-template>
      </qpoint-suggestlist>

      <label>Combobox</label>
      <qpoint-suggestlist
        [dropDownVariant]="dropDownVariant.ComboBox"
        textField="value"
        valueField="key"
        [data]="items"
        placeholder="test"
        [isLoading]="loading"
        [disabled]="disabled"
        [readonly]="readonly"
      >
      </qpoint-suggestlist>

      <label>Combobox FormControl</label>
      <qpoint-reactive-form-input-group [qpointFormControl]="suggestListFormControl" [isSubmitted]="true">
        <qpoint-suggestlist
          [dropDownVariant]="dropDownVariant.ComboBox"
          textField="value"
          [data]="items"
          placeholder="test"
          valueField="key"
          [formControl]="suggestListFormControl"
          [isLoading]="loading"
          [disabled]="disabled"
          [readonly]="readonly"
        >
        </qpoint-suggestlist>
      </qpoint-reactive-form-input-group>

      <label>Multiselect</label>
      <qpoint-suggestlist
        [dropDownVariant]="dropDownVariant.MultiSelect"
        textField="value"
        valueField="key"
        [data]="items"
        placeholder="test"
        [isLoading]="loading"
        [disabled]="disabled"
        [hasMoreEntries]="true"
        [readonly]="readonly"
      >
      </qpoint-suggestlist>


      <label>Checkbox List</label>
      <qpoint-checkbox-list
        textField="key"
        [data]="items"
        [isLoading]="loading"
        [disabled]="disabled"
        [readonly]="readonly"
      >
      </qpoint-checkbox-list>
    </div>
  </div>
</div>
