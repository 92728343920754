<div class="card">
  <div class="card-header">Drop Down List</div>
  <div class="card-body">

    <div class="form-group"><label><input type="checkbox" [(ngModel)]="loading"> Loading</label></div>
    <div class="form-group"><label><input type="checkbox" [(ngModel)]="readonly"> readonly</label></div>
    <div class="form-group"><label><input type="checkbox" [(ngModel)]="disabled"> disabled</label></div>

    <div class="form-group">
      <label>Drop Down List</label>
      <qpoint-drop-down-list
        [items]="items"
        [readonly]="readonly"
        [disabled]="disabled"
        [loading]="loading"
        [value]="value"
        (selectionChanged)="onChange($event)"
        placeholder="Bitte wählen..."
      ></qpoint-drop-down-list>

      <select [value]="value?.key">
        <option *ngFor="let item of items" [value]="item.key">{{ item.value }}</option>
      </select>

      <div>{{ value | json }}</div>

      <button (click)="setToPreselection()">Set to preselection</button>
      <button (click)="setToNonExisting()">Set to non existing</button>
      <button (click)="setToNewItems()">Set to new items</button>
    </div>
  </div>
</div>
