import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { TabStripModule } from '@progress/kendo-angular-layout';
import { QPointIconModule } from '@qpoint/icons';
import { QPointContentContainerComponent, QPointToolbarButtonComponent, QPointToolbarComponent } from '@qpoint/layout';

import {
  QPointTabNavDetailTemplateDirective,
} from '../../../../projects/qpoint-layout/src/lib/components/tab-nav/tab-nav-detail-template.directive';
import {
  QPointTabNavItemComponent,
} from '../../../../projects/qpoint-layout/src/lib/components/tab-nav/tab-nav-item.component';
import { QPointTabNavComponent } from '../../../../projects/qpoint-layout/src/lib/components/tab-nav/tab-nav.component';

@Component({
    selector: 'app-control-gallery-tabnavigation',
    templateUrl: './tabnavigation.component.html',
    styleUrls: ['./tabnavigation.component.scss'],
    standalone: true,
    imports: [
      QPointToolbarComponent, QPointContentContainerComponent, TabStripModule, 
      NgbNavModule, QPointToolbarButtonComponent, QPointIconModule, NgIf, QPointTabNavComponent,
      QPointTabNavItemComponent, QPointTabNavDetailTemplateDirective]
})
export class ControlGalleryTabnavigationComponent {

  constructor() { }
}
