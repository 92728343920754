import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'qpoint-info-corner',
  templateUrl: './info-corner.component.html',
  styleUrls: ['./info-corner.component.scss'],
  standalone: true,
  imports: [NgbModule, CommonModule],
})

export class QPointInfoCornerComponent {
  @Input() content: string;
  @Input() showIcon: boolean = true;
  @Input() position: "top-right" | "top-left" = "top-right";
  @Input() iconStyle: "error" | "warning" | "info" = "info";
}