import { Component } from '@angular/core';
import { ButtonSize } from '@qpoint/buttons';
import { NgTemplateOutlet } from '@angular/common';
import { QPointSplitButtonActionComponent } from '../../../../projects/qpoint-button/src/lib/split-button/split-button-action/split-button-action.component';
import { QPointSplitButtonComponent } from '../../../../projects/qpoint-button/src/lib/split-button/split-button.component';
import { DeprecatedQPointIconButtonComponent } from '../../../../projects/qpoint-button/src/lib/icon-button/icon-button.component';
import { QPointButtonComponent } from '../../../../projects/qpoint-button/src/lib/button/button.component';
import { QPointToolbarComponent, QPointToolbarButtonComponent, QPointContentContainerComponent } from '@qpoint/layout';

@Component({
    selector: 'app-control-gallery-buttons',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
    standalone: true,
    imports: [QPointToolbarComponent, QPointToolbarButtonComponent, QPointContentContainerComponent, QPointButtonComponent, DeprecatedQPointIconButtonComponent, QPointSplitButtonComponent, QPointSplitButtonActionComponent, NgTemplateOutlet]
})
export class ControlGalleryButtonsComponent {
  public disabled = false;
  public loading = false;
  public size: ButtonSize = ButtonSize.default;
  public buttonSize = ButtonSize;

  constructor() {}

  public setSize(size: ButtonSize) {
    this.size = size;
  }

  public itemClick() {
    console.log('Item Click');
  }

  public itemClickCopy() {
    console.log('Item Click Copy');
  }
}
