<div class="content-popup">
  <div *ngIf="contextMenu" (click)="clickedInsideKendoPopup($event)" (mouseenter)="mouseInsideContextMenu = true"
       (mouseleave)="popupLeave()" class="d-flex flex-row-reverse">
    <div class="d-flex">
      <ul id="grouplist" class="list-group">
        <div class="borderless">
          <li #popupAnchor [style.display]="item.showItem(dataItem) ? 'block' : 'none'"
              class="list-group-item {{item === activeSubMenuEntry ? 'selected-element':''}}"
              (click)="contextMenuItemClick($event, item, dataItem)"
              (mouseenter)="contextMenuItemHover($event,item,dataItem,i)" (mouseleave)="itemMouseLeave()"
              *ngFor="let item of contextMenu.contextMenuItems; let i = index">
            <div class="d-flex justify-content-between" *ngIf="item.showItem(dataItem)">
              <div class="d-flex contextMenuIconWrapper">
                <qpoint-icon *ngIf="item.icon || item.iconClass(dataItem)"
                             [icon]="item.iconClass(dataItem)"></qpoint-icon>
              </div>
              <div class="d-flex contextMenuIconLabel">
                {{ item.label }}
              </div>
              <div class="d-flex contextMenuIconWrapper">
                <qpoint-icon *ngIf="item.subItemArray.length > 0 && !(item === activeSubMenuEntry)"
                             [icon]="'angle-right'"></qpoint-icon>
                <qpoint-icon *ngIf="item.subItemArray.length > 0 && item === activeSubMenuEntry"
                             [icon]="'angle-down'"></qpoint-icon>
              </div>
            </div>

          </li>
        </div>
      </ul>
    </div>
  </div>
</div>
