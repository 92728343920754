import { AfterViewInit, Component, ViewEncapsulation } from '@angular/core';

import { QPointSidebarService } from '../sidebar/sidebar.service/sidebar.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'qpoint-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [
    CommonModule
  ],
  standalone: true
})
export class QPointMainComponent implements AfterViewInit {
  public isMenuCollapsed = false;
  private _isMenuCollapsed = false;
  public isMenuTemporaryExpanded = false;
  private _isMenuTemporaryExpanded = false;
  private viewInit = false;

  constructor(private sidebarService: QPointSidebarService) {
    this.setSidebar();
  }

  public ngAfterViewInit() {
    this.viewInit = true;
    setTimeout(() => this.isMenuCollapsed = this._isMenuCollapsed);
  }

  private setSidebar() {
    this.sidebarService.menuCollapsedChanged.subscribe(isCollapsed => {
      if (this.viewInit) {
        setTimeout(() => this.isMenuCollapsed = isCollapsed);
      } else {
        this._isMenuCollapsed = isCollapsed;
      }
    });
    this.sidebarService.menuTemporaryExpandedChanged.subscribe(isTemporaryExpanded => {
      if (this.viewInit) {
        setTimeout(() => this.isMenuTemporaryExpanded = isTemporaryExpanded);
      } else {
        this._isMenuTemporaryExpanded = isTemporaryExpanded;
      }
    });
  }
}
