import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PopupRef } from '@progress/kendo-angular-popup';
import { QPointMapPolygon } from '../map-polygon/map-polygon.component';
import { QPointMapPolyline } from '../map-polyline/map-polyline.component';
import { QPointMapMode } from '../map-mode-selection/mode';

export enum TargetComponent {
  Vertex,
  /** A UI element which creates a new Vertex. */
  Edge,
  Polygon,
  Polyline,
  Map
}

export enum FunctionType {
  DeleteVertex
}

export interface MapContextMenuEntry {
  label: string;
  entryClick?: Function;
  internalFunctionType?: FunctionType;
  icon?: string;
  targetComponents?: TargetComponent[];
  disabled?: Function;
  modes?: QPointMapMode[];
}

@Component({
  selector: 'qpoint-map-context-menu',
  templateUrl: './map-context-menu.component.html',
  styleUrls: ['./map-context-menu.component.scss']
})
export class MapContextMenuComponent<T = unknown> {

  @Input() event: T;
  @Input() contextMenu: MapContextMenuEntry[];
  @Input() popupRef: PopupRef;
  @Input() autoClosePopupRef = true;
  @Input() geoCoordinate: google.maps.LatLng;
  @Input() data: QPointMapPolygon | QPointMapPolyline;

  @Output() popupClose = new EventEmitter<void>();

  constructor() {}

  contextMenuEntryClicked(entry: MapContextMenuEntry) {
    if (entry.entryClick == null) {
      return;
    }

    entry?.entryClick(this.event, this.geoCoordinate, this.data);

    if (this.autoClosePopupRef) {
      this.popupRef?.close();
      this.popupClose.emit();
    }
  }
}
