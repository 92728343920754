import { ChangeDetectorRef, Component, EventEmitter, Inject, InjectionToken, Input, OnDestroy, Optional, Output } from '@angular/core';
import { cloneDeep, isEqual, pullAt } from 'lodash';
import { MapModeStateService } from '../map-mode-selection/map-mode-state.service';
import { Subject } from 'rxjs';
import { QPointMapMode } from '../map-mode-selection/mode';
import { takeUntil } from 'rxjs/operators';
import PolylineOptions = google.maps.PolylineOptions;

export type QPointMapPolylineType = 'LineString';

export interface QPointMapPolyline {
  type: QPointMapPolylineType;
  /**
   * WGS 84, Array of [Longitude, Latitude] tuples
   */
  coordinates: [number, number][];
}

export type QPointMapPolylineOptions = Partial<PolylineOptions> & {
  minimumVerticesNumber?: number;
};

export const QPOINT_POLYLINE_DEFAULT_OPTIONS = new InjectionToken<QPointMapPolylineOptions>('Polyline Options');

@Component({
  selector: 'qpoint-map-polyline',
  template: ''
})
export class MapPolylineComponent implements OnDestroy {
  private destroy$ = new Subject<void>();
  private readonly _defaultOptions: QPointMapPolylineOptions = this.defaultOptions || {
    strokeOpacity: 1,
    zIndex: 10,
    minimumVerticesNumber: 2,
    strokeColor: '#0B91B1',
    strokeWeight: 5,
    editable: false,
  };

  polyline: QPointMapPolyline | null = null;
  private editMode: boolean | undefined;

  @Input('polyline') set polylineSetter(polyline: QPointMapPolyline) {
    this.polyline = cloneDeep(polyline);
  }

  @Input() options: QPointMapPolylineOptions;

  @Output() polylineChanged = new EventEmitter<QPointMapPolyline>();
  @Output() resetPolylineClicked = new EventEmitter<void>();

  private currentPolylineOptions: QPointMapPolylineOptions | null;

  constructor(@Inject(QPOINT_POLYLINE_DEFAULT_OPTIONS) @Optional() private defaultOptions: QPointMapPolylineOptions,
              private cdr: ChangeDetectorRef, private mapModeState: MapModeStateService) {
    this.mapModeState.isModeActive$(QPointMapMode.Polyline).pipe(
      takeUntil(this.destroy$)
    ).subscribe(
      isActive => this.editMode = isActive
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get editable(): boolean {
    return this.editMode ?? this.options?.editable ?? this._defaultOptions?.editable ?? false;
  }

  get polylineOptions(): QPointMapPolylineOptions {
    const newOptions = this.createPolylineOptions();

    if( !this.currentPolylineOptions || !isEqual( this.currentPolylineOptions, newOptions ) ) {
      this.currentPolylineOptions = newOptions;
    }
    return this.currentPolylineOptions;
  }

  private createPolylineOptions() : QPointMapPolylineOptions {
    return {
      ...this._defaultOptions,
      ...this.options,
      editable: this.editable,
      zIndex: this.editable ? 100 : this.options?.zIndex ?? this._defaultOptions.zIndex,
      strokeOpacity: this.mapModeState.anyModeActive() && !this.editable ? 0.3 : {
        ...this._defaultOptions,
        ...this.options
      }.strokeOpacity,
    };
  }

  get canDeleteVertex(): boolean {
    return this.polylineOptions.editable && this.polyline.coordinates?.length > this.polylineOptions.minimumVerticesNumber;
  }

  deletePolylineVertex(event: google.maps.PolyMouseEvent) {
    if (!this.canDeleteVertex || event.vertex == null) {
      return;
    }

    pullAt(this.polyline.coordinates, event.vertex);
    this.emitAndUpdatePolyline(this.polyline);
  }

  updatePolyline(polyline: QPointMapPolyline) {
    this.emitAndUpdatePolyline(polyline);
  }

  resetPolyline() {
    this.resetPolylineClicked.emit();
  }

  private emitAndUpdatePolyline(polyline: QPointMapPolyline) {
    this.polyline = cloneDeep(polyline);
    this.polylineChanged.emit(cloneDeep(polyline));
    this.cdr.markForCheck();
  }
}
