import { Directive } from '@angular/core';

@Directive({
  selector: '[qpointMapFooterOverlay]'
})
export class MapFooterOverlayDirective {

  constructor() { }

}
