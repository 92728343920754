<qpoint-toolbar [title]="'Dropdown'"></qpoint-toolbar>

<qpoint-content-container>
  <!-- ComboBox, DropDownList & MultiSelect -->
  <div class="card">
    <div class="card-header">ComboBox, DropDownList & MultiSelect</div>
    <div class="card-body">
      <qpoint-button (click)="isLoading = true">Start Loading</qpoint-button>
      <qpoint-button (click)="isLoading = false">Stop Loading</qpoint-button>
      <p>Dropdowns Active</p>
      <div class="row">
        <p></p>
        <div class="col-md-3">
          <qpoint-suggestlist [filterable]="false" [dropDownVariant]="DropdownVariant.DropDownList" [dropdownListClearable]="true" [data]="listItems"></qpoint-suggestlist> 
        </div>
        <div class="col-md-3">
          <qpoint-suggestlist [dropDownVariant]="DropdownVariant.ComboBox" [data]="listItems"></qpoint-suggestlist>
        </div>
        <div class="col-md-3">
          <qpoint-suggestlist [dropDownVariant]="DropdownVariant.MultiSelect" [data]="listItems"></qpoint-suggestlist>
        </div>
      </div>
      <p>Dropdowns Disabled</p>
      <div class="row">
        <div class="col-md-3">
          <qpoint-suggestlist [dropDownVariant]="DropdownVariant.DropDownList" [disabled]="true" [data]="listItems"></qpoint-suggestlist>
        </div>
        <div class="col-md-3">
          <qpoint-suggestlist [disabled]="true" [dropDownVariant]="DropdownVariant.ComboBox" [data]="listItems"></qpoint-suggestlist>
        </div>
        <div class="col-md-3">
          <qpoint-suggestlist [disabled]="true" [dropDownVariant]="DropdownVariant.MultiSelect" [data]="listItems"></qpoint-suggestlist>
        </div>
      </div>
      <p>Dropdowns Readonly</p>
      <div class="row">
        <div class="col-md-3">
          <qpoint-suggestlist [readonly]="true" [dropDownVariant]="DropdownVariant.DropDownList" [data]="listItems"></qpoint-suggestlist>
        </div>
        <div class="col-md-3">
          <qpoint-suggestlist [readonly]="true" [dropDownVariant]="DropdownVariant.ComboBox" [data]="listItems"></qpoint-suggestlist>
        </div>
        <div class="col-md-3">
          <qpoint-suggestlist [readonly]="true" [dropDownVariant]="DropdownVariant.MultiSelect" [data]="listItems"></qpoint-suggestlist>
        </div>
      </div>
    </div>
  </div>
</qpoint-content-container>
