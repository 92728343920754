<qpoint-toolbar [title]="title" [showContextbarButton]="true">
  <!--  <qpoint-searchfield (doSearch)="log($event)" [searchPattern]="searchPattern"-->
  <!--                      [autoSearchOnTextChange]="true"-->
  <!--                      [searchPatternValidationMessage]="searchPatternMessage"-->
  <!--                      [suggestions]="suggestions"></qpoint-searchfield>-->
  <!--  <qpoint-toolbar-dateslider [date]="date" (dateChanged)="log($event)"></qpoint-toolbar-dateslider>-->
  <qpoint-toolbar-dateslider [dateFormat]="'MMMM yyyy'" activeView="year" bottomView="year"></qpoint-toolbar-dateslider>
  <qpoint-toolbar-btn iconClass="check" [loading]="isTestLoading" (btnClick)="testNotification()" [badgeValue]="10">
  </qpoint-toolbar-btn>
  <qpoint-toolbar-btn iconClass="home" [loading]="isTestLoading" qPointButtonType="primary"
                      [iconType]="'material-outlined'" [label]="'Bearbeiten'" (btnClick)="testNotification()"
                      [badgeValue]="10">
  </qpoint-toolbar-btn>
  <qpoint-toolbar-btn iconClass="sync" (btnClick)="testLoading()" [loading]="isTestLoading" label="Loading">

  </qpoint-toolbar-btn>
  <qpoint-toolbar-btn (btnClick)="testLoading()" [loading]="isTestLoading" label="Loading">

  </qpoint-toolbar-btn>
  <qpoint-toolbar-button-wrapper>
    <kendo-dropdownbutton [data]="buttons" [popupSettings]="{ popupClass: 'toolbar-dropdown-popup' }">
      <qpoint-icon icon="plus-circle"></qpoint-icon>
      <span>{{ 'cluster.newCluster' | translate }}</span>
      <ng-template kendoDropDownButtonItemTemplate let-dataItem>
        {{ dataItem.id }}
      </ng-template>
    </kendo-dropdownbutton>
  </qpoint-toolbar-button-wrapper>

  <!--<qpoint-toolbar-button-wrapper>-->
  <qpoint-toolbar-split-button [loading]="isTestLoading" icon="clock" iconType="regular" text="Neues Rezept">
    <qpoint-toolbar-split-button-action text="Click Me"></qpoint-toolbar-split-button-action>
    <qpoint-toolbar-split-button-action text="Click Me 2"></qpoint-toolbar-split-button-action>
    <qpoint-toolbar-split-button-action text="Click Me (Disabled)" [disabled]="true">
    </qpoint-toolbar-split-button-action>
  </qpoint-toolbar-split-button>
  <!--</qpoint-toolbar-button-wrapper>-->
  <qpoint-toolbar-btn (btnClick)="setCenter()" btnType="submit" label="CenterMap" form="some-form"></qpoint-toolbar-btn>

</qpoint-toolbar>
<qpoint-contextbar>

  <qpoint-contextbar-section [title]="'Filter'" [open]="true">

    <div class="contextbar-row">
      <qpoint-button [btnType]="'button'" [qPointButtonType]="'primary'" [isLoading]="showButtonLoadingIndicator"
                     (buttonClicked)="showButtonLoadingIndicator = !showButtonLoadingIndicator">
        Show Loading Indicator
      </qpoint-button>
    </div>
    <div class="contextbar-row">
      <qpoint-button [btnType]="'button'" [qPointButtonType]="'secondary'" [isLoading]="showLoadingIndicator"
                     (buttonClicked)="showLoadingIndicator = !showLoadingIndicator">
        Show Button Loading Indicator
      </qpoint-button>
    </div>
  </qpoint-contextbar-section>
  <qpoint-contextbar-section title="Excel Export">
    <qpoint-excel-import></qpoint-excel-import>
  </qpoint-contextbar-section>
</qpoint-contextbar>
<qpoint-content-container>
  <div>
    <div style="width: 100%; height: 500px">
      <qpoint-map [fitToBoundsDelay]="0" [debugOutput]="true"
                  [center]="{lat: 48.50200732001635, lng: 16.22775872783587}"
                  [stopFitToBoundsOnManualInteraction]="true"
                  [contextMenu]="mapContextMenu" [enableFullscreenControl]="true"
                  (polygonClick)="log('polygon clicK: ', $event.latLng.toJSON())"
                  (mapClick)="log('map click: ', $event)" [modes]="mapModes">
        <qpoint-map-marker [options]="markerOptions">
          <div>Das ist mein Fancy Template</div>
        </qpoint-map-marker>

        <qpoint-map-marker *ngFor="let option of additionalMarkerOptions" [options]="option"></qpoint-map-marker>

        <qpoint-map-polygon *ngIf="qpointSwitchNgModel" [polygon]="geofence"
                            (polygonChanged)="polygonChanged($event)"
                            (resetPolygonClicked)="resetPolygon()"></qpoint-map-polygon>
        <qpoint-map-polyline [polyline]="polyline"
                             (polylineChanged)="log('polyline changed: ', $event)"></qpoint-map-polyline>

        <qpoint-map-polyline [polyline]="geoJSONCheckPolyline"
                            (polylineChanged)="log('geoJSONCheckPolyline changed: ', $event)"></qpoint-map-polyline>

        <!--        <qpoint-map-marker *ngIf="secondMarkerOptions" [options]="secondMarkerOptions">-->
        <!--          <div>Das ist mein Fancy Template</div>-->
        <!--          <ng-template *ngIf="displayDetailTemplate" [shiftLabelHorizontal]="25" [shiftLabelVertical]="-30"-->
        <!--                       qpointMapMarkerLabel>-->
        <!--            <div>-->
        <!--              <div>Zeile Truck</div>-->
        <!--              <div>Zeile2</div>-->
        <!--            </div>-->
        <!--          </ng-template>-->
        <!--        </qpoint-map-marker>-->
        <!--        <qpoint-map-info-window [dialogClass]="'overlay-medium'" [options]="{position: {lat: 40, lng: 30.12}}">-->
        <!--          <div style="max-height: 150px; overflow: auto">-->
        <!--            <div style="background-color: green; height: 100px; width: 150px" *ngFor="let i of [1,2,3,4,5]">-->
        <!--              <span style="color: white">Hello</span>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </qpoint-map-info-window>-->
        <!--        <ng-template qpointMapFooterOverlay>-->
        <!--          <div style="background-color: white; padding: 5px; border-radius: 2px">This is my map overlay</div>-->
        <!--        </ng-template>-->
      </qpoint-map>
    </div>
  </div>
  <div class="d-flex flex-row">
    <div>
      <label>
        Show Geofence:
      </label>
      <qpoint-switch [(ngModel)]="qpointSwitchNgModel"></qpoint-switch>
    </div>

    <div>
      <qpoint-button (buttonClicked)="openDemoDialog()">Open Dialog</qpoint-button>
    </div>
  </div>

  <kendo-chart style="height:250px" [xAxis]="{color: 'red', majorGridLines: {color: 'blue'}}"
               [valueAxis]="{color: 'green', line: {color: 'red'}, majorGridLines: {color: 'green'}}"
               [chartArea]="{background: 'transparent'}">
    <kendo-chart-series>
      <kendo-chart-series-item [line]="{color: 'blue'}" [data]="[1, 4, 5, 2, 1, 8]">
      </kendo-chart-series-item>
    </kendo-chart-series>
  </kendo-chart>
  <qpoint-inline-edit-date [activeInlineEditing]="true"></qpoint-inline-edit-date>
  <qpoint-inline-editing-input [activeInlineEditing]="true" [ngModel]="'test'" [placeholder]="'placeholder'"
                               [suffix]="'test/'"></qpoint-inline-editing-input>
  <div #anchor class="d-flex flex-row align-items-center status-group" (click)="toggleShow()">
    <span>hello</span>
    <qpoint-button [active]="showStateTransitionDropdown" [variant]="'icon'"
                   [icon]="showStateTransitionDropdown ? 'angle-up' : 'angle-down'"></qpoint-button>
  </div>
  <qpoint-image-cropper></qpoint-image-cropper>
  <div id="testField">Translated label without translation: {{'projects.label.projectName' | translate}}</div>
  <div>Already translated text: {{'Das ist ein Langer Text.' | translate}}</div>

  <div>
    {{ {value: 57, unitSign: 'm' } | qPointFormatNumberAndTranslateUnit }}
    {{ {value: 3664, unitSign: 't' } | qPointFormatNumberAndTranslateUnit }}
    {{ 't' | qPointTranslateUnit }}
  </div>
  <div>
    {{ {value: 0} | qPointFormatNumberAndTranslateUnit }}
  </div>
  <div>
    {{ {value: -5.385, unitSign: 'pcs'} | qPointFormatNumberAndTranslateUnit }}
  </div>

  <div>Icon
    <qpoint-icon iconType="solid" icon="hand"></qpoint-icon>
  </div>

  <div style="background-color: rosybrown;">
    <qpoint-image-selector title="Titel" [image]="image" deleteWarningMessage="image.deleteMessage"
    (imageChanged)="imageChanged($event)" maxFileSizeInMb="1"
    maxFileSizeExceededMessage="File size exceeded" resizeToWidth="200" [imageCanBeDeleted]="true"
    [roundImage]="false" [readonly]="false" [maintainAspectRatio]="true" [aspectRatio]="1.33" acceptedFormats="png, jpeg"></qpoint-image-selector>
  </div>

  <qpoint-button (buttonClicked)="secondImageIsInEditMode = !secondImageIsInEditMode">Edit image</qpoint-button>
    <qpoint-image-upload title="Titel" [imageData]="secondImage" (imageChanged)="secondImageChanged($event)"
    [isInEditMode]="secondImageIsInEditMode" [imageCanBeDeleted]="true"
    [roundImage]="false" [maintainAspectRatio]="false" [aspectRatio]="1.33"></qpoint-image-upload>
  <!-- Put your content here | START -->
  <div class="row">
    <qpoint-loading-indicator [isLoading]="showButtonLoadingIndicator"></qpoint-loading-indicator>
  </div>

  <div>
    <qpoint-loading-indicator [isLoading]="true"></qpoint-loading-indicator>
  </div>
  <div>
    <kendo-progressbar style="width: 500px;" [value]="50"></kendo-progressbar>
  </div>
  <qpoint-button (buttonClicked)="createMoreMocks()">Einträge hinzufügen</qpoint-button>
  <div style="height: 400px; position: relative">
    <button (click)="updateSelectedValue()">Update</button>

    <qpoint-grid [columnConfigurationEnabled]="true" [excelExportTooltip]="'test'" [dataInput]="gridData"
                 (selectionChanged)="selectionChanged($event)"
                 [changeSelectedDataSubject]="changeSelectedDataSubject$" [excelExportEnabled]="true"
                 [showSelectAllColumn]="false" [selectionMode]="'single'"
                 [storageIdentifier]="'mainGrid'">
      <qpoint-grid-context-menu>
        <qpoint-grid-context-menu-item label="{{ 'Aktion 1' | translate }}">
        </qpoint-grid-context-menu-item>
        <qpoint-grid-context-menu-item label="{{ 'Aktion 2' | translate }}">
        </qpoint-grid-context-menu-item>
      </qpoint-grid-context-menu>
      <kendo-grid-column title="1 DokumentTyp" field="lcDocumentType"></kendo-grid-column>
      <kendo-grid-column title="2 Betreiberfirma" field="operatingCompany"></kendo-grid-column>
      <kendo-grid-column title="3 Anlage" field="plant"></kendo-grid-column>
      <kendo-grid-column title="4 Lieferschein" field="deliveryNote" width="150"></kendo-grid-column>
      <kendo-grid-column title="5 Kunde" field="customer"></kendo-grid-column>


      <ng-template qpointGridSpecialAttentionTemplate let-data let-closeCallback="closeCallback">
        <div style="background-color: red">Das ist ein Special Template {{data.id}}</div>
        <div style="background-color: red">Das ist ein Special Template {{data.name}}</div>
        <qpoint-button (buttonClicked)="closeCallback()">Close</qpoint-button>
      </ng-template>

    </qpoint-grid>

    <p>Q Point Places Autocomplete Search:</p>
    <qpoint-places-autocomplete
      (searchResultSelected)="searchResultSelected($event)"
      [filterType]="'cities'">
    </qpoint-places-autocomplete>

    <form id="some-form" (ngSubmit)="onFormSubmit()">
      <p>This form can be submitted by the button in the toolbar. Just pass the ID as a string to the button as
        <code>form</code> input
      </p>
      <div class="form-group">
        <label for="dummy">Some input</label>
        <input id="dummy" name="dummy" class="form-control">
      </div>
      <qpoint-button btnType="submit">Submit button</qpoint-button>
    </form>
    <button (click)="confirmDialogDeleteWarning()">Dialog for delete Warning</button>
    <button (click)="confirmDialogDeleteError()">Dialog for delete Error</button>
    <button (click)="confirmDialogDeleteErrorWithTitle()">Dialog for delete Error Title</button>
    <button (click)="confirmDialogDeleteOk()">Dialog just OK</button>

    <div>Translated example text with translateTo() German: {{ translatedTextExampleGerman }}</div>
    <div>Translated example text with translateTo() English: {{ translatedTextExampleEnglish }}</div>
    <div>Translated with regular pipe: {{ 'global.action.save' | translate }} </div>

    <!-- Put your content here | END -->

  </div>

  <ng-template qpointContentContainerBottom>
    <qpoint-changed-info [userChangedByFirstName]="'Peter'" [userChangedBySurname]="'Lustig'"
                         [changedDto]="currentDate"></qpoint-changed-info>
  </ng-template>
</qpoint-content-container>
