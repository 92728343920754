import { Directive, HostBinding, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ControlState } from '../types/types';
import { FormControlName } from '@angular/forms';
import { QPointFormValidationService } from '../services/qpoint-form-validation.service';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Directive({
  selector: '[qpointValidationFormControl]',
  standalone: true
})
export class QPointFormControlDirective implements OnInit, OnDestroy {
  controlState: ControlState = {
    hasErrors: false,
    formSubmitted: false,
    touched: false,
    control: this,
    name: this.getName(),
    parent: this.getParent(),
  };

  private controlStateChangeSubject$ = new Subject<ControlState>();
  controlStateChange$ = this.controlStateChangeSubject$.asObservable();

  @HostBinding('class.is-invalid') get isInvalid() {
    return (
      this.controlState && (this.controlState.touched || this.controlState.formSubmitted) && this.controlState.hasErrors
    );
  }

  @HostListener('blur', ['$event'])
  @HostListener('formValidationBlur', ['$event'])
  onBlur($event) {
    this.formValidationService.controlBlurred(this);
  }

  constructor(private formControlName: FormControlName, private formValidationService: QPointFormValidationService) {
  }

  ngOnInit(): void {
    this.formValidationService.addControl(this);

    this.formValidationService.formValidationChange$
      .pipe(filter(controlState => controlState.name === this.getName() && controlState.parent === this.getParent()))
      .subscribe(controlState => {
        this.controlState = controlState;
        this.controlStateChangeSubject$.next(this.controlState);
      });
  }

  ngOnDestroy(): void {
    this.controlStateChangeSubject$.complete();
    this.formValidationService.removeControl(this);
  }

  getControl() {
    return this.formControlName.control;
  }

  getName() {
    return this.formControlName.name;
  }

  getParent() {
    return this.formControlName.path.length === 1 ? null : this.formControlName.path[this.formControlName.path.length - 2];
  }
}
