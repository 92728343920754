<qpoint-toolbar [title]="'Reactive Inputs'"></qpoint-toolbar>

<qpoint-content-container>
  <!--<qpoint-reactive-form-input-group class="qPointControlInvalid" [isSubmitted]="isSubmitted" [qpointFormControl]="validFormControl">-->
  <!--<input [formControl]="validFormControl">-->
  <!--</qpoint-reactive-form-input-group>-->

  <!--<br>-->

  <!--<qpoint-reactive-form-input-group [isSubmitted]="isSubmitted" [qpointFormControl]="requiredFormControl">-->
  <!--<input placeholder="Required" [formControl]="requiredFormControl">-->
  <!--</qpoint-reactive-form-input-group>-->
  <div [formGroup]="testFormGroup">
    <qpoint-table-row>
      <ng-container label>
        Label 1<br>
        xyz
      </ng-container>
      <ng-container flexTableMain>
        <qpoint-reactive-form-input-group [isSubmitted]="true" [qpointFormControl]="requiredFormControl">
          <input formControlName="requiredFormControl"  kendoTextBox>
        </qpoint-reactive-form-input-group>
      </ng-container>
      <ng-container flexTableActions>
        <qpoint-button qPointButtonType="icon" [icon]="'pencil-alt'">Button 1</qpoint-button>
        <qpoint-button qPointButtonType="icon" [icon]="'pencil-alt'">Button 2
        </qpoint-button>
      </ng-container>
    </qpoint-table-row>

    <qpoint-table-row [label]="'Label 2'">
      <ng-container flexTableMain>
        <qpoint-reactive-form-input-group [isSubmitted]="true" [qpointFormControl]="requiredFormControl">
          <textarea formControlName="requiredFormControl" kendoTextArea></textarea>
        </qpoint-reactive-form-input-group>
      </ng-container>
      <ng-container flexTableActions>
        <qpoint-button qPointButtonType="icon" [icon]="'pencil-alt'">Button 1</qpoint-button>
      </ng-container>
    </qpoint-table-row>

    <qpoint-table-row [label]="'Label 3'">
      <ng-container flexTableMain>
        <qpoint-reactive-form-input-group [isSubmitted]="true" [qpointFormControl]="requiredFormControl">
          <kendo-dropdownlist [data]="dropdownData" formControlName="requiredFormControl"></kendo-dropdownlist>
        </qpoint-reactive-form-input-group>
      </ng-container>
    </qpoint-table-row>

    <qpoint-table-row label='Inline Editing Input'>
      <ng-container flexTableMain>
        <qpoint-reactive-form-input-group
          [qpointFormControl]="textFormControl"
          [isSubmitted]="true">
            <qpoint-inline-editing-input style="width: 250px"
                                         #referenceInlineEdit
                                         (editModeIsActive)="logEvent($event)"
              [activeInlineEditing]="true"
              [loadingIndicator]="false"
              infoMessageOnEdit="Info Message"
              formControlName="textFormControl">
            </qpoint-inline-editing-input>
          <input kendoTextBox readonly [readonly]="true" [value]="'Ein value'">
        </qpoint-reactive-form-input-group>
      </ng-container>
    </qpoint-table-row>

    <qpoint-table-row [label]="'Label 4'">
      <ng-container flexTableMain>
        <qpoint-reactive-form-input-group [isSubmitted]="true" [qpointFormControl]="requiredFormControl">
          <input formControlName="requiredFormControl" kendoTextBox>
        </qpoint-reactive-form-input-group>
      </ng-container>
      <ng-container flexTableActions>
        <qpoint-button qPointButtonType="icon" [icon]="'pencil-alt'">Button 1</qpoint-button>
        <qpoint-button qPointButtonType="icon" [icon]="'pencil-alt'">Button 2
        </qpoint-button>
      </ng-container>
    </qpoint-table-row>

    <qpoint-table-row [label]="'Google Place'">
      <ng-container flexTableMain>
        <qpoint-reactive-form-input-group [isSubmitted]="true" [qpointFormControl]="requiredFormControl">
          <qpoint-places-suggestlist 
            maxLength="100" 
            (searchResultSelected)="placeSuggestListSelected($event)"
            formControlName="placeFormControl"
          ></qpoint-places-suggestlist>
        </qpoint-reactive-form-input-group>
      </ng-container>
    </qpoint-table-row>  
  </div>

  <form [formGroup]="autocompleteAddressFormGroup">
    <qpoint-table-row label="postalAddress">
      <ng-container flexTableMain>
        <qpoint-reactive-form-input-group [qpointFormControl]="postalAddress"
                                          [isSubmitted]="postalAddress.touched || postalAddress.dirty || isSubmitted">
          <qpoint-places-autocomplete maxLength="500" filterType="address" (searchResultSelected)="placeSelected($event)"
                                      formControlName="postalAddress"></qpoint-places-autocomplete>
        </qpoint-reactive-form-input-group>
      </ng-container>
    </qpoint-table-row>

    <qpoint-table-row label="postalCode">
      <ng-container flexTableMain>
        <qpoint-reactive-form-input-group [qpointFormControl]="postalCode"
                                          [isSubmitted]="postalCode.touched || postalCode.dirty || isSubmitted">
          <qpoint-places-autocomplete maxLength="50" filterType="regions" (searchResultSelected)="placeSelected($event)"
                                      formControlName="postalCode"></qpoint-places-autocomplete>
        </qpoint-reactive-form-input-group>
      </ng-container>
    </qpoint-table-row>

    <qpoint-table-row label="city">
      <ng-container flexTableMain>
        <qpoint-reactive-form-input-group [qpointFormControl]="city"
                                          [isSubmitted]="city.touched || city.dirty || isSubmitted">
          <qpoint-places-autocomplete maxLength="100" filterType="cities" (searchResultSelected)="placeSelected($event)"
                                       formControlName="city"></qpoint-places-autocomplete>
        </qpoint-reactive-form-input-group>
      </ng-container>
    </qpoint-table-row>

    <qpoint-table-row label="country">
      <ng-container flexTableMain>
        <qpoint-reactive-form-input-group [qpointFormControl]="country"
                                          [isSubmitted]="country.touched || country.dirty || isSubmitted">
          <input formControlName="country" kendoTextBox maxlength="100" />
        </qpoint-reactive-form-input-group>
      </ng-container>
    </qpoint-table-row>
  </form>
</qpoint-content-container>
