import {
  AfterViewInit,
  DestroyRef,
  Directive,
  HostListener,
  inject,
  Inject,
  InjectionToken,
  Input,
  Optional,
  Provider,
  Self
} from '@angular/core';
import { TimePickerComponent } from '@progress/kendo-angular-dateinputs';
import { TranslateService } from '@ngx-translate/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export const DI_TIME_PICKER_UNIT_TEXT = new InjectionToken<string>('Text to be displayed after the input format');

export function provideTimePickerUnitText(text: string): Provider {
  return {
    provide: DI_TIME_PICKER_UNIT_TEXT,
    useValue: text
  };
}

@Directive({
  selector: 'kendo-timepicker[qpointTimePickerUnit]',
  standalone: true
})
export class QPointTimePickerUnitDirective implements AfterViewInit {
  private destroyRef = inject(DestroyRef);
  private translate = inject(TranslateService);

  @Input() qpointTimePickerUnit: string | undefined = undefined;

  @HostListener('blur') onBlur() {
    this.updateInputElement();
  }

  constructor(
    @Self() @Optional() public kendoTimepicker: TimePickerComponent,
    @Inject(DI_TIME_PICKER_UNIT_TEXT) @Optional() private unitText: string
  ) {
  }

  get text(): string | undefined {
    return this.qpointTimePickerUnit || this.unitText;
  }

  private get inputElement(): HTMLInputElement | undefined {
    return this?.kendoTimepicker?.dateInput?.inputElement;
  }

  ngAfterViewInit() {
    if (this.inputElement) {
      this.updateInputElement();

      this.kendoTimepicker?.dateInput?.formControl?.valueChanges?.pipe(
        takeUntilDestroyed(this.destroyRef)
      ).subscribe(value => {
        if(value == null && this.inputElement !== document.activeElement) {
          this.updateInputElement();
        }
      });
    }
  }

  updateInputElement() {
    if(this.kendoTimepicker?.value) {
      const input = this.inputElement;
      const value = input?.value.replace(new RegExp("\\b"+this.translate.instant(this.qpointTimePickerUnit || this.unitText)+"\\b"), '').trim();
      input.value = `${value} ${this.translate.instant(this.qpointTimePickerUnit || this.unitText)}`;
    }
  }
}
