import { ElementRef, Injectable } from '@angular/core';

import { BaseGridDirective } from './base-grid.directive';

@Injectable({
  providedIn: 'root'
})
export class GridConnectService {

  private registeredGrid: BaseGridDirective;

  constructor() {
  }

  public registerGrid(grid: BaseGridDirective) {
    if( this.registeredGrid ) {
      if( this.isRegisteredGrid( grid ) ) {
        console.warn(`CAUTION: The given grid is already registered! (current: ${GridConnectService.asString( this.registeredGrid )}, new one: ${GridConnectService.asString( grid )})`);
      } else {
        console.warn(`CAUTION: There is already a grid registered which will now be replaced! (current: ${GridConnectService.asString( this.registeredGrid )}, new one: ${GridConnectService.asString( grid )})`);
      }
    }
    this.registeredGrid = grid;
  }

  public unregisterGrid( grid: BaseGridDirective ) {
    if( !this.isRegisteredGrid( grid ) ) {
      console.warn(`CAUTION: A different grid wants to unregister! (current: ${GridConnectService.asString(this.registeredGrid)}, the one to unregister: ${GridConnectService.asString(grid)})`);
    }
    this.registeredGrid = null;
  }

  public isRegisteredGrid( grid: BaseGridDirective ) {
    return this.registeredGrid === grid;
  }

  /* --== methods to handle excel export ==-- */
  public exportExcel() {
    this.registeredGrid.exportExcel();
  }

  public excelExportTooltip(): string {
    return this.registeredGrid.getExcelExportTooltip();
  }

  public isExcelExportEnabled(): boolean {
    return this.registeredGrid?.excelExportEnabled || false;
  }

  public manuallyPlaceExcelExportButton(): boolean {
    return this.registeredGrid?.manuallyPlaceExcelExportButton || false;
  }

  public getSelectedRowCount(): number {
    return this.registeredGrid?.kendoGridSelectedKeys?.length || 0;
  }

  /* --== methods to handle column selection in 'page settings' ==-- */
  public isColumnConfigurationEnabled(): boolean {
    return this.registeredGrid?.columnConfigurationEnabled || false;
  }

  public openChooserMenu() {
    return this.registeredGrid?.openChooserMenu();
  }

  public columnChooserComponent(): ElementRef {
    return this.registeredGrid?.columnChooserComponent;
  }

  /* --== internal methods ==-- */
  private static asString( grid: BaseGridDirective ): string {
    return `${grid?.constructor?.name} - ${grid?.storageIdentifier}`;
  }
}
