<qpoint-modal-header>Modal Header</qpoint-modal-header>
<qpoint-modal-body>
  <div class="d-flex flex-column">
    <label>Input test</label>
    <input type="text" class="form-control" [(ngModel)]="input"/>
  </div>
</qpoint-modal-body>
<qpoint-modal-footer>
  <qpoint-button variant="primary"
                 (click)="save()" [disabled]="disabled">Save
  </qpoint-button>
  <qpoint-button
    (click)="close()">Close
  </qpoint-button>
</qpoint-modal-footer>
