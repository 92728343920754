import { Injectable } from '@angular/core';
import { MapPolylineComponent } from './map-polyline.component';
import { MapPolyline } from '@angular/google-maps';
import { toGeoJSON } from '../map-helpers';

@Injectable({
  providedIn: 'root'
})
export class MapPolylineStateService {
  private _polylineComponent: MapPolylineComponent | null = null;
  private _mouseEvent: google.maps.PolyMouseEvent | null = null;

  get mouseEvent() {
    return this._mouseEvent;
  }

  get activePolylineComponent() {
    return this._polylineComponent;
  }

  setActivePolyline(polylineComponent: MapPolylineComponent | null, event: google.maps.PolyMouseEvent) {
    this._polylineComponent = polylineComponent;
    this._mouseEvent = event;
  }

  deletePolylineVertex() {
    if (this.activePolylineComponent && this.mouseEvent) {
      this.activePolylineComponent.deletePolylineVertex(this.mouseEvent);
    }
  }

  resetPolyline() {
    if (this.activePolylineComponent) {
      this.activePolylineComponent.resetPolyline();
    }
  }

  updatePolyline(polyline: MapPolyline, qpointPolyline: MapPolylineComponent) {
    const geoJson = toGeoJSON(polyline.getPath().getArray().map(entry => entry.toJSON()), false);
    if (JSON.stringify(geoJson) !== JSON.stringify(qpointPolyline.polyline.coordinates)) {
      qpointPolyline.updatePolyline({...qpointPolyline.polyline, coordinates: geoJson});
    }
  }
}
