import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { QPointSidebarService } from '../sidebar/sidebar.service/sidebar.service';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { QPointIconModule } from '@qpoint/icons';
import { QPointRestrictedLinesTextComponent } from '../restricted-lines-text/restricted-lines-text.component';
import { TranslateModule } from '@ngx-translate/core';

export interface IWorkspace {
  id: string;
  clusterId?: string;
  name: string;
  imageSrc?: string;
}

@Component({
  selector: 'qpoint-workspace-selection',
  templateUrl: './workspace-selection.component.html',
  styleUrls: ['./workspace-selection.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [
    CommonModule,
    QPointIconModule,
    QPointRestrictedLinesTextComponent,
    TranslateModule
  ],
  standalone: true
})
export class QPointWorkspaceSelectionComponent implements OnInit, OnDestroy {
  public isSubmenuExpand = false;

  @Input()
  public selectedWorkspace: IWorkspace;

  @Input()
  public workspaces: IWorkspace[];

  @Input()
  public showWorkspaceSettings = true;

  @Input()
  public showAddWorkspaceButton = true;

  @Input()
  public workspaceSettingsRoutePath: string;

  @Input()
  public workspaceChangeDisabled = false;

  @Output()
  public addNewWorkspaceClick: EventEmitter<void> = new EventEmitter();

  @Output()
  public selectedWorkspaceSettingsClick: EventEmitter<void> = new EventEmitter();

  @Output()
  public workspaceClick: EventEmitter<IWorkspace> = new EventEmitter();

  public isWorkspaceSettingsSelected = false;
  private ngUnsubscribe = new Subject<void>();

  constructor(
    public service: QPointSidebarService, private router: Router) {
  }


  public ngOnInit(): void {
    this.isWorkspaceSettingsSelected = this.router.isActive(this.workspaceSettingsRoutePath, {paths: 'subset', queryParams: 'subset', fragment: 'ignored', matrixParams: 'ignored'});
    this.router.events.pipe(takeUntil(this.ngUnsubscribe)).subscribe((event) => {
      {
        if (event instanceof NavigationEnd) {
          this.isWorkspaceSettingsSelected = this.router.isActive(this.workspaceSettingsRoutePath, {paths: 'subset', queryParams: 'subset', fragment: 'ignored', matrixParams: 'ignored'});
        }
      }
    });
  }

  public onWorkspaceClick(workspace: IWorkspace) {
    if (!this.workspaceChangeDisabled) {
      this.workspaceClick.emit(workspace);
    }

  }

  public onSelectedWorkspaceSettingsClick() {
    this.selectedWorkspaceSettingsClick.emit();
  }

  public onAddNewWorkspaceClick() {
    this.addNewWorkspaceClick.emit();
  }

  public toggleSubmenu() {
    if (this.isSubmenuExpand && this.service.isMenuCollapsed) {
      this.isSubmenuExpand = true;
    } else {
      this.isSubmenuExpand = !this.isSubmenuExpand;
    }

    if (this.service.isMenuCollapsed) {
      this.service.toggleSidebar();
    }
    this.service.sidebarHeightChanged.next();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
