import { AfterViewInit, Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'qpoint-restricted-lines-text',
  templateUrl: './restricted-lines-text.component.html',
  styleUrls: ['./restricted-lines-text.component.scss'],
  imports: [
    NgbTooltipModule
  ],
  standalone: true
})
export class QPointRestrictedLinesTextComponent implements AfterViewInit {
  @Input()
  public text: string;

  @Input()
  public lines = 2;

  @Input()
  public tooltipPlacement = 'bottom';

  @ViewChild('restrictedtext')
  private container: ElementRef;

  constructor(private render: Renderer2) {
  }

  ngAfterViewInit(): void {
    this.render.setStyle(this.container.nativeElement, 'line-clamp', this.lines);
    this.render.setStyle(this.container.nativeElement, '-webkit-line-clamp', this.lines);
  }
}
