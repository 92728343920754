import { Injectable, InjectionToken } from '@angular/core';

export interface IQPointLocalizationNamespacesService {
    /**
    * A function that returns localization namespaces
    */
    getNamespaces(): string[];
}

export const DI_LOCALIZATION_NAMESPACES = new InjectionToken<string>('QPointLocalizationNamespacesService');

@Injectable()
export class DefaultLocalizationNamespacesService implements IQPointLocalizationNamespacesService {
    getNamespaces() { return null; }
}

