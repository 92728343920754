import { CommonModule } from '@angular/common';
import { Inject, NgModule, Optional } from '@angular/core';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconDefinition, IconPack } from '@fortawesome/fontawesome-svg-core';
import { IconComponent } from './icon/icon.component';

export const DI_FONTAWESOME_ICONS = 'FontawesomeIcons';
export const DI_FONTAWESOME_ICON_PACKS = 'FontawesomeIconPacks';

@NgModule({
  declarations: [IconComponent],
  imports: [
    CommonModule,
    FontAwesomeModule
  ],
  exports: [IconComponent]
})
export class QPointIconModule {
  constructor(library: FaIconLibrary,
              @Optional() @Inject(DI_FONTAWESOME_ICONS) icons: IconDefinition[],
              @Optional() @Inject(DI_FONTAWESOME_ICON_PACKS) iconPacks: IconPack[] ) {
    if(icons) {
      library.addIcons(...icons);
    }

    if( iconPacks ) {
      library.addIconPacks( ...iconPacks );
    }

    if( !icons && !iconPacks ) {
      console.warn( "QPointIconModule: No icons to add to the library given! (check/see DI-variables: DI_FONTAWESOME_ICONS and DI_FONTAWESOME_ICON_PACKS)" );
    }
  }
}
