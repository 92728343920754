import { Component } from '@angular/core';
import { TextBoxModule } from '@progress/kendo-angular-inputs';
import { FloatingLabelModule } from '@progress/kendo-angular-label';
import { QPointToolbarComponent, QPointContentContainerComponent } from '@qpoint/layout';

@Component({
    selector: 'app-control-gallery-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss'],
    standalone: true,
    imports: [QPointToolbarComponent, QPointContentContainerComponent, FloatingLabelModule, TextBoxModule]
})
export class ControlGalleryInputComponent {

  constructor() { }
}
