import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class QPointContextbarService {
  private isContextbarCollapsedValue: boolean;

  public get isContextbarCollapsed(): boolean {
    return this.isContextbarCollapsedValue;
  }

  public set isContextbarCollapsed(value: boolean) {
    if (this.isContextbarCollapsed !== value) {
      this.isContextbarCollapsedValue = value;
      this.contextbarVisibilityChanged.next(this.isContextbarCollapsedValue);
    }
  }

  public contextbarVisibilityChanged = new Subject<boolean>();

  public collapseContextbar() {
    this.isContextbarCollapsed = true;
  }

  public toggleContextbar() {
    this.isContextbarCollapsed = !this.isContextbarCollapsed;
  }
}
