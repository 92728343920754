import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { QPointSidebarService } from '../../sidebar.service/sidebar.service';
import { CommonModule } from '@angular/common';
import { QPointIconModule } from '@qpoint/icons';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'qpoint-menu-item',
  templateUrl: './menu-item.html',
  styleUrls: ['./menu-item.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        visibility: 'visible'
      })),
      state('closed', style({
        visibility: 'hidden',
        height: 0
      })),
      transition('open => closed', [
        style({visibility: 'hidden'}),
        animate('0.3s', style({height: 0})),
        animate('0.1s', style({visibility: 'hidden'}))
      ]),
      transition('closed => open', [
        animate('0.1s', style({visibility: 'visible'})),
        animate('0.2s', style({height: '*'})),
        style({overflow: 'hidden'})
      ]),
    ]),
  ],
  imports: [
    CommonModule,
    QPointIconModule,
    TranslateModule,
    RouterModule
  ],
  standalone: true
})
export class QPointMenuItemComponent implements OnInit, OnDestroy {
  public badgeObservable: Observable<number>;
  public badgeAnimationObservable: Observable<boolean>;
  public badgeDisplay: string;
  @Input()
  public menuItem: any;
  @Input()
  public child = false;

  @Input()
  public sidebarCollapsed = false;

  @Output()
  public toggleSubMenu = new EventEmitter<any>();

  private ngUnsubscribe = new Subject<void>();

  constructor(private navigationService: QPointSidebarService) {
  }

  public get id() {
    if (this.menuItem && this.menuItem.route && this.menuItem.route.id) {
      return 'nav-' + this.menuItem.route.id;
    } else {
      return null;
    }
  }

  public ngOnInit(): void {
    this.badgeObservable = this.navigationService.routeBadgeObservableMap.get(this.menuItem.route.id);
    this.badgeAnimationObservable = this.navigationService.routeBadgeAnimationMap.get(this.menuItem.route.id);
    if (this.badgeObservable) {
      this.badgeObservable.pipe(
        takeUntil(this.ngUnsubscribe))
        .subscribe(value => {
          if (value > 99) {
            this.badgeDisplay = '99+';
          } else {
            this.badgeDisplay = `${value}`;
          }
        });
    }
  }

  public onToggleSubMenu($event, item): boolean {
    this.toggleSubMenu.emit(item);
    return false;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  toggleWithoutPropagationForLink($event: MouseEvent, menuItem) {
    this.toggleSubMenu.emit(menuItem);
    $event.preventDefault();
    $event.stopPropagation();
  }
}
