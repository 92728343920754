import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { UserProfile } from '../../../projects/qpoint-profile/src/lib/model/user.model';
import IUser = UserProfile.IUser;
import { IQPointProfileService } from '../../../projects/qpoint-profile/src/lib/qpoint-profile.service';
import { Location } from '@angular/common';


@Injectable({
  providedIn: 'root'
})
export class QPointProfileService implements IQPointProfileService {

  constructor(private location: Location) {
  }

  getUserProfileData(): Observable<IUser> {
    return of({
      surname: 'Mustermann',
      city: 'Vienna',
      companyName: 'qpoint',
      country: 'AT',
      cultureInfoDateTime: 'de-CH',
      cultureInfoNumber: 'de-CH',
      emailAddress: 'qpoint@qpoint.com',
      fax: '000048812848218',
      firstName: 'Max',
      mobile: '004127471247',
      phone: '004127471247',
      preferredLanguage: 'de-DE',
      streetAddress: 'Hauptstraße',
      postalCode: '2000',
      profileImage: null,
      givenname: 'Max',
      profileImageUri: null
    });
  }

  getCultures(): BehaviorSubject<UserProfile.ICulture[]> {
    return new BehaviorSubject<UserProfile.ICulture[]>([
      { code: 'de-DE', name: 'German (Germany)' },
      { code: 'de-CH', name: 'German (Switzerland)' },
      { code: 'en-US', name: 'English (United States)' },
      { code: 'ro-RO', name: 'Romanian (Romania)' }
    ]);
  }

  cancelButtonClicked(): void {
    this.location.back();
  }

  saveUserProfile(user: UserProfile.IUser, imageHasChanged: boolean): void {
    console.log(user);
    console.log(imageHasChanged);
  }

  getCurrentUserDefaultProfileImage(): Observable<Blob> {
    return null;
  }
}
