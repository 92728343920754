import { Component, EventEmitter, Inject, InjectionToken, Input, OnDestroy, Optional, Output } from '@angular/core';
import { cloneDeep, isEqual, pullAt } from 'lodash';
import { Subject } from 'rxjs';
import { MapModeStateService } from '../map-mode-selection/map-mode-state.service';
import { QPointMapMode } from '../map-mode-selection/mode';
import { takeUntil } from 'rxjs/operators';
import PolygonOptions = google.maps.PolygonOptions;

export type QPointMapPolygonType = 'Polygon';

export interface QPointMapPolygon {
  type: QPointMapPolygonType;
  /**
   * WGS 84, Array of [Longitude, Latitude] tuples
   */
  coordinates: [number, number][][]; // WGS 84, [Longitude, Latitude]
}

export type QPointMapPolygonOptions = Partial<PolygonOptions> & {
  /**
   * provide custom label for the delete button inside the info window
   */
  deleteVertexLabel?: string;
  /**
   * provide custom label for the reset button inside the context window
   */
  resetPolygonLabel?: string;
  minimumVerticesNumber?: number;
};
export const QPOINT_POLYGON_DEFAULT_OPTIONS = new InjectionToken<QPointMapPolygonOptions>('Polygon Options');

@Component({
  selector: 'qpoint-map-polygon',
  template: '',
})
export class MapPolygonComponent implements OnDestroy {
  private destroy$ = new Subject<void>();
  private readonly _defaultOptions: QPointMapPolygonOptions = this.defaultOptions || {
    fillColor: 'blue',
    fillOpacity: 0.15,
    strokeOpacity: 0.2,
    zIndex: 10,
    deleteVertexLabel: 'maps.polygon.deleteVertex',
    resetPolygonLabel: 'maps.polygon.resetPolygon',
    minimumVerticesNumber: 4,
  };

  polygon: QPointMapPolygon | null = null;
  private editMode: boolean | undefined;

  @Input('polygon') set polygonSetter(polygon: QPointMapPolygon) {
    this.polygon = cloneDeep(polygon);
  }

  @Input() options: QPointMapPolygonOptions;

  @Output() polygonChanged = new EventEmitter<QPointMapPolygon>();
  @Output() resetPolygonClicked = new EventEmitter<void>();

  private currentPolygonOptions: QPointMapPolygonOptions | null;

  constructor(@Inject(QPOINT_POLYGON_DEFAULT_OPTIONS) @Optional() private defaultOptions: QPointMapPolygonOptions,
              private mapModeState: MapModeStateService) {
    this.mapModeState.isModeActive$(QPointMapMode.Polygon).pipe(
      takeUntil(this.destroy$)
    ).subscribe(
      isActive => this.editMode = isActive
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get editable(): boolean {
    return this.editMode ?? this.options?.editable ?? this._defaultOptions?.editable ?? false;
  }

  get polygonOptions(): QPointMapPolygonOptions {
    const newOptions = this.createPolygonOptions();

    if( !this.currentPolygonOptions || !isEqual( this.currentPolygonOptions, newOptions ) ) {
      this.currentPolygonOptions = newOptions;
    }
    return this.currentPolygonOptions;
  }

  private createPolygonOptions() : QPointMapPolygonOptions {
    return {
      ...this._defaultOptions,
      ...this.options,
      editable: this.editable,
      zIndex: this.editable ? 100 : this.options?.zIndex ?? this._defaultOptions.zIndex,
      fillOpacity: this.mapModeState.anyModeActive() && !this.editable ? 0.07 : {
        ...this._defaultOptions,
        ...this.options
      }.fillOpacity,
      strokeOpacity: this.mapModeState.anyModeActive() && !this.editable ? 0.15 : {
        ...this._defaultOptions,
        ...this.options
      }.strokeOpacity,
    };
  }

  canDeleteVertex(polygonIndex: number): boolean {
    return this.polygonOptions.editable && this.polygon.coordinates[polygonIndex]?.length > this.polygonOptions.minimumVerticesNumber;
  }

  deletePolygonVertex(event: google.maps.PolyMouseEvent, polygonIndex: number) {
    if (!this.canDeleteVertex(polygonIndex)) {
      return;
    }

    if (event.vertex === 0 || event.vertex === this.polygon.coordinates[polygonIndex].length - 1) {
      pullAt(this.polygon.coordinates[polygonIndex], [0, this.polygon.coordinates[polygonIndex].length - 1]);
      this.polygon.coordinates[polygonIndex].push(this.polygon.coordinates[polygonIndex][0]);
    } else {
      pullAt(this.polygon.coordinates[polygonIndex], event.vertex);
    }
    this.emitAndUpdatePolygon(this.polygon);
  }

  resetPolygon() {
    this.resetPolygonClicked.emit();
  }

  updatePolygon(polygon: QPointMapPolygon) {
    this.emitAndUpdatePolygon(polygon);
  }

  private emitAndUpdatePolygon(polygon: QPointMapPolygon) {
    this.polygon = cloneDeep(polygon);
    this.polygonChanged.emit(cloneDeep(polygon));
  }
}
