import { EnvironmentProviders, inject, InjectionToken, makeEnvironmentProviders, Type } from '@angular/core';

export interface IQPointNavigation {
  sidebar: IQPointSidebarNavigation;
}

export interface IQPointSidebarNavigation {
  items: IQPointSidebarNavigationItem[];
}

export interface IQPointSidebarNavigationItem {
  accessLevel: 'hasAccess' | 'hidden' | 'disabled';
  contentRef: string;
  icon: string;
  iconType: string;
  locTextId: string;
  text: string;
  parentLinkActive?: boolean;
  items: IQPointSidebarNavigationItem[];
}

export interface IQPointNavigationLoader {
  getNavigationAsync(): Promise<IQPointNavigation>;
}

export const DI_NAVIGATION_LOADER = new InjectionToken<IQPointNavigationLoader>('QPointNavigationLoader');

export function injectNavigationLoader(): IQPointNavigationLoader {
  return inject(DI_NAVIGATION_LOADER);
}

export function provideNavigationLoader(useClass: Type<IQPointNavigationLoader>): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: DI_NAVIGATION_LOADER,
      useClass
    }
  ]);
}
