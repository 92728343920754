<div class="card mt-3">
  <div class="card-header">Async Drop Down</div>
  <div class="card-body">

    <div class="form-group"><label><input type="checkbox" [(ngModel)]="readonly"> readonly</label></div>
    <div class="form-group"><label><input type="checkbox" [(ngModel)]="disabled"> disabled</label></div>
    <button class="btn btn-primary btn-sm" (click)="restart()">Reset requests</button>

    <div class="form-group mt-3">
      <label>Drop Down List</label>
      <qpoint-async-drop-down
        [disabled]="disabled"
        [readonly]="readonly"
        [observable]="request"
        [(ngModel)]="value"
        (selectionChanged)="onSelectionChange($event)"
        placeholder="Select something"
        valueField="id"
        textField="title"
        sortBy="title"
      ></qpoint-async-drop-down>
    </div>

    <div class="form-group">
      <label>Drop Down List with instant request</label>
      <qpoint-async-drop-down
        [disabled]="disabled"
        [readonly]="readonly"
        [observable]="instantRequest"
        [(ngModel)]="value"
        (selectionChanged)="onSelectionChange($event)"
        placeholder="Select something"
        valueField="id"
        textField="title"
        sortBy="title"
      ></qpoint-async-drop-down>
    </div>

    <div class="form-group">
      <label>Drop Down List with item template</label>
      <qpoint-async-drop-down
        [disabled]="disabled"
        [readonly]="readonly"
        [observable]="request"
        [selection]="value"
        (selectionChanged)="onSelectionChange($event)"
        placeholder="Select something"
        valueField="id"
        textField="title"
        sortBy="title"
      >
        <ng-template qpointAsyncDropDownItemTemplate let-dataItem="dataItem">
          {{ dataItem.id }} - {{ dataItem.title }}
        </ng-template>
        <ng-template qpointAsyncDropDownNoDataTemplate>EMPTY!!!</ng-template>
      </qpoint-async-drop-down>
    </div>

    <div class="form-group">
      <label>Empty Drop Down List with empty template</label>
      <qpoint-async-drop-down
        [disabled]="disabled"
        [readonly]="readonly"
        [observable]="emptyRequest"
        [(ngModel)]="value"
        (selectionChanged)="onSelectionChange($event)"
        placeholder="Select something"
        valueField="id"
        textField="title"
        sortBy="title"
      >
        <ng-template qpointAsyncDropDownNoDataTemplate>EMPTY 🤷‍♀️</ng-template>
      </qpoint-async-drop-down>
    </div>

    <div class="form-group">
      <label>Error Drop Down List</label>
      <qpoint-async-drop-down
        [disabled]="disabled"
        [readonly]="readonly"
        [observable]="errorRequest"
        [(ngModel)]="value"
        (selectionChanged)="onSelectionChange($event)"
        placeholder="Select something"
        valueField="id"
        textField="title"
        sortBy="title"
        retryText="Retry now"
      ></qpoint-async-drop-down>
    </div>

    <div class="form-group">
      <label>Without model</label>
      <qpoint-async-drop-down
        [disabled]="disabled"
        [readonly]="readonly"
        [observable]="request"
        (selectionChanged)="onSelectionChange($event)"
        placeholder="Select something"
        valueField="id"
        textField="title"
        sortBy="title"
        retryText="Retry now"
      ></qpoint-async-drop-down>
    </div>

    <h3 class="mt-2">selection:</h3>
    <pre>{{ value | json }}</pre>
  </div>
</div>
