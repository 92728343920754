  <button [tabindex]="tabindex" [type]="type" [ngClass]="classes" [disabled]="isDisabled" (click)="onClick($event)" [attr.form]="formId">
    <div class="spinner-wrapper" *ngIf="loading && !icon">
      <qpoint-loading-spinner style="min-width: 15px" [inline]="true" [variant]="QPointLoadingSpinnerVariant.dark"
                              *ngIf="loading"></qpoint-loading-spinner>
    </div>
    <div class="button-content-wrapper" [ngClass]="{'no-content': buttonContent.children.length === 0}">
      <ng-container *ngIf="icon">
        <qpoint-icon *ngIf="!loading" [icon]="icon" [iconType]='iconType' [rotate]="iconRotate"></qpoint-icon>
        <qpoint-loading-spinner style="min-width: 15px" [variant]="QPointLoadingSpinnerVariant.dark"
                                *ngIf="loading"></qpoint-loading-spinner>
      </ng-container>
      <div #buttonContent class="btn-content">
        <ng-content></ng-content>
      </div>
    </div>
  </button>
