import {Component} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JsonPipe } from '@angular/common';

@Component({
    selector: 'app-null-value-directive',
    templateUrl: './null-value-directive.component.html',
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        JsonPipe,
    ],
})
export class NullValueDirectiveComponent {
  form: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) {
    this.form = this.fb.group({
      inputWithoutDirective: [null],
      inputWithDirective: [null],
    });
  }

}
