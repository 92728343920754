<qpoint-toolbar [title]="'Loading Spinners'"></qpoint-toolbar>

<qpoint-content-container>
  <div class="card">
    <div class="card-header"><code>qpointLoading</code></div>
    <div class="card-body">
      <div class="mb-3">
        <qpoint-button (buttonClicked)="load()" [loading]="loading">Trigger random loading</qpoint-button>
        <qpoint-button (buttonClicked)="start()" *ngIf="!loading">Start</qpoint-button>
        <qpoint-button (buttonClicked)="stop()" *ngIf="loading">Stop</qpoint-button>
      </div>
      <div class="card" [qpointLoading]="loading">
        <div class="card-header">Some content</div>
        <div class="card-body">
          Some content that should be hidden.
        </div>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="card-header"><code>qpointLoadingSpinner</code></div>
    <div class="card-body">
      <h3><code>Dark</code></h3>
      <qpoint-loading-spinner variant="dark" [inline]="true" *ngFor="let size of sizes" [size]="size">
        size="{{ size }}"
      </qpoint-loading-spinner>
    </div>
    <div class="card-body" style="background: #000;">
      <h3><code>Bright</code></h3>
      <qpoint-loading-spinner variant="bright" [inline]="true" *ngFor="let size of sizes" [size]="size">
        size="{{ size }}"
      </qpoint-loading-spinner>
    </div>
  </div>
</qpoint-content-container>
