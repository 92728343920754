import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { fromEvent, Subscription } from 'rxjs';

import { QPointSidebarService } from '../sidebar.service/sidebar.service';
import { HammerGestureConfig } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { QPointMenuItemComponent } from './menu-item/menu-item.component';

@Component({
  selector: 'qpoint-menu',
  templateUrl: './menu.html',
  styleUrls: ['./menu.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule, QPointMenuItemComponent]
})
export class QPointMenuComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() sidebarCollapsed = false;
  @Input() showLegal: boolean;

  @Output() expandMenu = new EventEmitter<any>();

  public menuItems: any[];
  protected menuItemsSub: Subscription;
  protected onRouteChange: Subscription;
  protected onSwipe: Subscription;

  constructor(
    private router: Router,
    private service: QPointSidebarService) {
  }

  public updateMenu(newMenuItems) {
    this.menuItems = newMenuItems;
    this.selectMenuAndNotify();
  }

  public selectMenuAndNotify(): void {
    if (this.menuItems) {
      this.menuItems = this.service.selectMenuItem(this.menuItems);
      this.service.notifyActiveMenuItemChanged();
      this.service.sidebarHeightChanged.next();
    }
  }

  public ngOnInit(): void {
    this.onRouteChange = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.menuItems) {
          this.selectMenuAndNotify();
        } else {
          // on page load we have to wait as event is fired before menu elements are prepared
          setTimeout(() => this.selectMenuAndNotify());
        }
      }
    });

    this.menuItemsSub = this.service.menuItems.subscribe(this.updateMenu.bind(this));
  }

  public ngAfterViewInit() {
    this.service.sidebarHeightChanged.next();
    this.subscribeToSwipe();
  }

  private subscribeToSwipe() {
    const hammerGestureConfig = new HammerGestureConfig();
    hammerGestureConfig.options = {touchAction: 'pan-y'};
    const hammer = hammerGestureConfig.buildHammer(document.getElementById('qpoint-menu-aside'));
    this.onSwipe = fromEvent(hammer, 'swipe')
      .subscribe((res: any) => {
        if (res.deltaX < 0 && !this.service.isMenuCollapsed) {
          this.service.endTemporaryExpand();
          this.service.toggleSidebar();
        } else if (this.service.isMenuCollapsed) {
          this.service.temporaryExpand();
          this.service.toggleSidebar();
        }
      });
  }

  public ngOnDestroy(): void {
    this.onRouteChange.unsubscribe();
    this.menuItemsSub.unsubscribe();
    this.onSwipe.unsubscribe();
  }

  public toggleSubMenu($event): boolean {
    if (this.sidebarCollapsed) {
      this.expandMenu.emit(null);
      if (!$event.expanded) {
        $event.expanded = true;
      }
    } else {
      $event.expanded = !$event.expanded;
    }
    this.service.sidebarHeightChanged.next();
    return false;
  }
}
