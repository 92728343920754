import { Component } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QPointDateTimePickerComponent } from '@qpoint/forms';

@Component({
    selector: 'qpoint-datetime-picker-demo',
    templateUrl: './datetime-picker-demo.component.html',
    styleUrls: ['./datetime-picker-demo.component.scss'],
    standalone: true,
    imports: [FormsModule, QPointDateTimePickerComponent, ReactiveFormsModule]
})
export class DateTimePickerDemoComponent {
  public readonly = false;
  public disabled = false;

  public fc = new FormControl(null);
}
