import { Component, OnInit } from '@angular/core';
import { QPointToolbarComponent, QPointContentContainerComponent } from '@qpoint/layout';

@Component({
    selector: 'app-control-gallery-heading',
    templateUrl: './heading.component.html',
    styleUrls: ['./heading.component.scss'],
    standalone: true,
    imports: [QPointToolbarComponent, QPointContentContainerComponent]
})
export class ControlGalleryHeadingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
}
