import { Component, ContentChildren, QueryList } from '@angular/core';
import { QPointGridContextMenuItemComponent } from './grid-context-menu-item.component';


@Component({
  selector: 'qpoint-grid-context-menu',
  template: '',
})
export class QPointGridContextMenuComponent {
  @ContentChildren(QPointGridContextMenuItemComponent, {descendants: false})
  public contextMenuItems: QueryList<QPointGridContextMenuItemComponent>;

}
