import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { of } from 'rxjs';

export class HttpLocalizationLoader implements TranslateLoader {
  constructor(private http: HttpClient) {
  }

  /**
   * Gets the translations from json
   * @param lang
   * @returns {any}
   */
  public getTranslation(lang: string): any {
    if (lang === 'en-US') {
      return of({
        'global.action.cancel': 'Cancel',
        'global.action.save': 'Save',
      })
    }
    return of({
      'global.action.cancel': 'Abbrechen',
      'global.action.save': 'Speichern',
      'global.action.take': 'Übernehmen',
      'global.action.delete': 'Löschen',
      'global.common.ok': 'Ok',
      'common.ok': 'Ok',
      'user.accountPage.title': 'Profileinstellungen',
      'user.accountPage.givenname': 'Vorname',
      'user.accountPage.surname': 'Nachname',
      'user.accountPage.email': 'E-Mail',
      'user.accountPage.mobile': 'Mobile',
      'user.accountPage.phone': 'Phone',
      'user.accountPage.language': 'Sprache',
      'user.accountPage.companyName': 'Firmenname',
      'user.accountPage.address': 'Addresse',
      'user.accountPage.addressZipCode': 'PLZ',
      'user.accountPage.addressCity': 'Stadt',
      'user.accountPage.addressCountry': 'Land',
      'user.accountPage.displayFormatSettings': 'Format Einstellungen',
      'user.accountPage.region': 'Region',
      'user.accountPage.shortDate': 'Datum kurz',
      'user.accountPage.time': 'Zeit',
      'user.accountPage.longDate': 'Datum lang',
      'user.accountPage.numbers': 'Zahlen',
      'user.accountPage.profileInfo': 'Die nachfolgenden persönlichen Daten sind in allen Arbeitsbereichen sichtbar, in denen Sie Mitglied sind.',
    });
  }
}
