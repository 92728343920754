import { Inject, Optional } from '@angular/core';
import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';

import { DI_LOCALIZATION_NAMESPACES, IQPointLocalizationNamespacesService } from './localization-namespaces.service';

export class TextMissingTranslationHandler implements MissingTranslationHandler {

  constructor(@Inject(DI_LOCALIZATION_NAMESPACES) @Optional() private localizationNamespacesService?: IQPointLocalizationNamespacesService) { }

  handle(params: MissingTranslationHandlerParams) {
    // return if label has dot at the end or whitespaces in it (already translated label)
    if (/(\s)|(\.+$)/.test(params.key) && (!this.localizationNamespacesService || this.localizationNamespacesService.getNamespaces() === null)) {
      return params[0];
    }
    if (params.interpolateParams && params.interpolateParams.hasOwnProperty('defaultTranslation') &&
      params.interpolateParams['defaultTranslation'] !== null) {
      return params.interpolateParams['defaultTranslation'];
    }

    if (params.key !== undefined) {
      const splitedKeys = params.key.split('.').filter(a => a !== '');

      if (splitedKeys.length >= 1 && splitedKeys[splitedKeys.length - 1] === 'null') {
        return '';
      }

      if (splitedKeys.length > 1) {
        // remove the namespace part from the key
        // get all namespaces from the localizationService
        const namespaceList = this.localizationNamespacesService ? this.localizationNamespacesService.getNamespaces() : null;
        if (namespaceList) {
          // loop over splitedKeys and build from the longest to the shortest namespace
          for (let i = splitedKeys.length - 1; i >= 0; i--) {
            //build namespace
            const namespaceTolookUp = splitedKeys.slice(0, i).join('.');
            // looling for namespace
            if (namespaceList.find(ns => ns === namespaceTolookUp)) {
              //namespace found! Build key without namespace
              return splitedKeys.slice(i).join('.');
            }
          }
          // namespace not found at all. return entire param.key
          return params.key;

        } else {
          // return the key after the last .
          return params.key.substring(params.key.lastIndexOf('.') + 1);
        }
      }
    }
    return params.key;
  }
}
