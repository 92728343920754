<qpoint-toolbar title="Info Messages"></qpoint-toolbar>
<qpoint-content-container>
  <div class="card" *ngFor="let entry of messageTypes">
    <div class="card-header">{{entry}}</div>
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <qpoint-info-message [severity]="MessageTypeEnum[entry]" text="border solid" (bannerClicked)="onBannerClicked()"></qpoint-info-message>
        </div>
        <div class="col-12">
          <qpoint-info-message [severity]="MessageTypeEnum[entry]" text="border striped" borderType="striped"></qpoint-info-message>
        </div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header">Callback Link</div>
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <qpoint-info-message [severity]="MessageTypeEnum.INFO" text="Info" [callback]="callback"></qpoint-info-message>
        </div>
      </div>
    </div>
  </div>
</qpoint-content-container>
