import { Component, ContentChildren, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DetailTemplateDirective, SharedModule } from '@progress/kendo-angular-grid';
import { QPointDateFormat, QPointLocalizationService, QPointTranslateUnitPipe, QPointFormatNumberAndTranslateUnitPipe } from '@qpoint/i18n';
import { interval, Subject, timer } from 'rxjs';
import {
  ExcelImportComponent,
  IQPointBase64Image,
  QPointCropperComponent,
  QPointImageSelectorComponent,
  QPointImageUploadComponent
} from '@qpoint/uploads';
import { QPointConfirmDialogService } from '@qpoint/layout';
import { SidebarBadgesService } from '../services/sidebar-badges.service';
import { ThemeService } from '../../../projects/qpoint-theme/src/lib/theme/theme.service';
import {
  assemblyLocationIcon,
  breakSpaceIcon,
  cleaningSpaceIcon,
  constructionSiteIcon,
  constructionSitePositionIcon,
  FunctionType,
  gridDepotIcon,
  MapComponent,
  MapContextMenuEntry,
  materialIcon,
  plantIcon,
  QPointMapMode,
  QPointMapModeConfiguration,
  QPointMapPolygon,
  QPointMapPolyline,
  QPointMapsLoader,
  siteBeginIcon,
  siteEndIcon,
  siteEntranceIcon,
  siteExitIcon,
  TargetComponent,
  turningPlaceIcon,
  vehicleLoadedIcon,
  vehicleOfflineIcon,
  vehiclePausedIcon,
  vehicleUnloadedIcon,
  waitingPlaceIcon,
  waterPlaceIcon,
  waypointIcon
} from '../../../projects/qpoint-maps/src/public_api';
import circle from '@turf/circle';
import { cloneDeep, last, uniqBy } from 'lodash';
import { TranslateModule } from '@ngx-translate/core';
import { QPointMapsInputsModule } from '../../../projects/qpoint-maps/map-inputs/src/map-input/maps-inputs.module';
import { QPointGridModule } from '../../../projects/qpoint-layout/src/lib/components/grid/q-point-grid.module';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { QPointLoadingIndicatorComponent } from '@qpoint/loading';
import { ChartModule } from '@progress/kendo-angular-charts';
import { FormsModule } from '@angular/forms';
import { QPointInlineEditingDateComponent, QPointInlineEditingInputComponent, QPointSwitchComponent } from '@qpoint/forms';
import { NgFor, NgIf } from '@angular/common';
import { QPointMapsModule } from '../../../projects/qpoint-maps/src/lib/qpoint-maps.module';
import { QPointButtonComponent } from '../../../projects/qpoint-button/src/lib/button/button.component';
import { QPointIconModule } from '../../../projects/qpoint-icons/src/lib/q-point-icon.module';
import { DropDownButtonModule, ListModule } from '@progress/kendo-angular-buttons';
import {
  ChangedInfoComponent,
  QPointContentContainerComponent,
  QPointContextbarComponent,
  QPointContextbarSectionComponent,
  QPointToolbarButtonComponent,
  QPointToolbarButtonWrapperComponent,
  QPointToolbarComponent,
  QPointToolbarDatesliderComponent,
  QPointToolbarSplitButtonActionComponent,
  QPointToolbarSplitButtonComponent
} from '@qpoint/layout';
import MarkerOptions = google.maps.MarkerOptions;

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [QPointToolbarComponent, QPointToolbarDatesliderComponent, QPointToolbarButtonComponent, QPointToolbarButtonWrapperComponent, DropDownButtonModule, QPointIconModule, ListModule, QPointToolbarSplitButtonComponent, QPointToolbarSplitButtonActionComponent, QPointContextbarComponent, QPointContextbarSectionComponent, QPointButtonComponent, ExcelImportComponent, QPointContentContainerComponent, QPointMapsModule, NgFor, NgIf, QPointSwitchComponent, FormsModule, ChartModule, QPointInlineEditingDateComponent, QPointInlineEditingInputComponent, QPointCropperComponent, QPointImageSelectorComponent, QPointImageUploadComponent, QPointLoadingIndicatorComponent, ProgressBarModule, QPointGridModule, SharedModule, QPointMapsInputsModule, ChangedInfoComponent, TranslateModule, QPointTranslateUnitPipe, QPointFormatNumberAndTranslateUnitPipe]
})
export class MainComponent implements OnInit {

  @ViewChild(MapComponent)
  private map: MapComponent;
  public searchPattern = /^.{3,}$/;
  public searchPatternMessage = 'Minimum 3 Characters';

  public showLoadingIndicator = false;
  public showButtonLoadingIndicator = false;
  public gridData: Object[] = [];

  public inputModel = 'Test';
  public inputType = 'default';
  public inputMessage = '';
  public title = [{ name: 'test', route: 'main' }, { name: 'testDisableTrue', route: 'testDisable', disabled: true }, {
    name: 'testDisableFalse',
    route: 'testDisable',
    disabled: false
  }, { name: 'link' }];
  public buttons: any[];
  public suggestions = ['Test', 'Wert', 'Value'];
  public date = '2019-01-01';
  public isTestLoading: boolean;
  public excelExportCustomColumns = [
    { field: 'operatingCompany', title: 'CustomOperatingCompany' },
    { field: 'deliveryNote', title: 'Lieferschein' }
  ];
  public image: IQPointBase64Image;
  public secondImage: string;
  public secondImageIsInEditMode = false;

  qpointSwitchNgModel = true;

  polygon = circle(this.formatLatLng([{ lat: 46.50207594, lng: 16.22788167 }]), 25, { units: 'meters', steps: 6 });
  coordinates = uniqBy(
    this.polygon.geometry.coordinates[0].map(entry => {
      return [entry[1], entry[0]];
    }),
    entry => entry[0] + entry[1],
  ) as [number, number][];
  geofence: QPointMapPolygon = {
    type: 'Polygon',
    coordinates: [[last(this.coordinates)].concat(this.coordinates)]
  };

  mapModes: QPointMapModeConfiguration[] = [
    {
      mode: QPointMapMode.Polygon,
      multi: false,
      active: false,
      tooltip: 'test 123'
    },
    {
      mode: QPointMapMode.Polyline,
      multi: false,
      active: false,
      tooltip: 'test 123'
    }
  ];

  public itemNeedSpecialAttention: Function;
  @ContentChildren(DetailTemplateDirective) detailTemplateDirectives;

  private changeSelectedDataSubject = new Subject<any>();
  public changeSelectedDataSubject$ = this.changeSelectedDataSubject.asObservable();
  showStateTransitionDropdown: any;
  currentDate = new Date();
  markerOptions: any = {};
  additionalMarkerOptions: any[] = [];
  displayDetailTemplate = true;
  secondMarkerOptions: MarkerOptions = {};
  polyline: QPointMapPolyline = null;
  geoJSONCheckPolyline: QPointMapPolyline = {
    type: 'LineString',
    coordinates: [
      [
        13.90973,
        48.67811
      ],
      [
        13.90967,
        48.67797
      ],
      [
        13.90974,
        48.6779
      ],
      [
        13.90933,
        48.67735
      ],
      [
        13.90936,
        48.67689
      ]
    ]
  };
  mapContextMenu: MapContextMenuEntry[] = [
    {
      label: 'Always visible and disabled',
      entryClick: this.generateLogEntry.bind(this),
      targetComponents: [TargetComponent.Map, TargetComponent.Polygon, TargetComponent.Vertex, TargetComponent.Edge],
      modes: [QPointMapMode.Polyline, QPointMapMode.Polygon],
      disabled: this.contextMenuDisabled.bind(this),
    },
    {
      label: 'Vertex only',
      entryClick: this.generateLogEntry.bind(this),
      modes: [QPointMapMode.Polyline, QPointMapMode.Polygon],
      targetComponents: [TargetComponent.Vertex],
    },
    {
      label: 'Map only',
      entryClick: this.openDemoDialog.bind(this),
      targetComponents: [TargetComponent.Map],
    },
    {
      label: 'Polygon only',
      entryClick: this.generateLogEntry.bind(this),
      modes: [QPointMapMode.Polygon],
      targetComponents: [TargetComponent.Polygon],
    },
    {
      label: 'Polyline only',
      entryClick: this.generateLogEntry.bind(this),
      modes: [QPointMapMode.Polyline],
      targetComponents: [TargetComponent.Polyline],
    },
    {
      label: 'Delete vertex',
      modes: [QPointMapMode.Polyline, QPointMapMode.Polygon],
      internalFunctionType: FunctionType.DeleteVertex,
      targetComponents: [TargetComponent.Vertex],
    },
    {
      label: 'Edge on polyline only',
      entryClick: this.generateLogEntry.bind(this),
      modes: [QPointMapMode.Polyline],
      targetComponents: [TargetComponent.Edge],
    },
    {
      label: 'Edge on polygon only',
      entryClick: this.generateLogEntry.bind(this),
      modes: [QPointMapMode.Polygon],
      targetComponents: [TargetComponent.Edge],
    }
  ];

  public translatedTextExampleGerman = "";
  public translatedTextExampleEnglish = "";

  constructor(
    private sidebarBadgesService: SidebarBadgesService,
    private confirmService: QPointConfirmDialogService,
    public themeService: ThemeService,
    private loaderService: QPointMapsLoader,
    private localizationService: QPointLocalizationService) {
  }

  ngOnInit() {
    this.createMockData();
    timer(5000).subscribe(value => {
      console.log('loaded');
      this.markerOptions = {
        position: {
          lat: 46.50207594,
          lng: 16.22788167
        },
        label: { text: 'myLabel<br/>Zweite', className: 'poi-label' },
        icon: siteBeginIcon(),
        draggable: true
      };

      this.additionalMarkerOptions = this.createAdditionalMarkerOptions({
        lat: 46.50307594,
        lng: 16.22888167
      });

      this.polyline = {
        type: 'LineString',
        coordinates: this.additionalMarkerOptions?.map(entry => {
          return [entry?.position?.lng, entry?.position?.lat];
        })
      };

      const lat = this.secondMarkerOptions?.position?.lat as number;
      this.secondMarkerOptions = {
        position: {
          lat: this.secondMarkerOptions?.position ? (lat + 0.001) : 48.50217902,
          lng: 16.22622836
        },
        icon: vehicleLoadedIcon(),
      };
      // this.displayDetailTemplate = !this.displayDetailTemplate;
      this.displayDetailTemplate = true;
    });

    this.localizationService.translateTo('de-DE', 'global.action.save')
      .then(res => this.translatedTextExampleGerman = res);

    this.localizationService.translateTo('en-US', 'global.action.save')
      .then(res => this.translatedTextExampleEnglish = res);
  }

  imageChanged($event: IQPointBase64Image) {
    this.image = $event;
  }

  secondImageChanged($event: { imageData: string, imageUri: string }) {
    this.secondImage = $event.imageData;
  }

  private createAdditionalMarkerOptions(location: { lat: number, lng: number }, move: number = 0.0007): Array<{
    position: { lat: number, lng: number },
    icon: any,
    draggable: boolean
  }> {
    const data = [];

    for (const iconFn of [plantIcon, constructionSiteIcon, constructionSitePositionIcon, siteBeginIcon, siteEndIcon, siteEntranceIcon,
      siteExitIcon, turningPlaceIcon, waitingPlaceIcon, waterPlaceIcon, waypointIcon, assemblyLocationIcon, breakSpaceIcon, cleaningSpaceIcon,
      gridDepotIcon, materialIcon, vehicleLoadedIcon, vehicleUnloadedIcon, vehiclePausedIcon, vehicleOfflineIcon
    ]) {
      data.push({
        position: { ...location },
        icon: iconFn(),
        draggable: true
      });

      data.push({
        position: { lat: location.lat + 0.002, lng: location.lng },
        icon: iconFn(0.5),
        draggable: true
      });

      data.push({
        position: { lat: location.lat - 0.002, lng: location.lng },
        icon: iconFn(1.5),
        draggable: true
      });

      location.lat += move;
      location.lng += move;
    }

    return data;
  }

  public checkInputType() {
    let changeFlag = false;

    if (this.inputModel.includes('info')) {
      this.inputType = 'info';
      this.inputMessage = 'This is a info input';
      changeFlag = true;
    }

    if (this.inputModel.includes('*')) {
      this.inputType = 'warning';
      this.inputMessage = 'It`s not recommended to use "*"';
      changeFlag = true;
    }

    if (this.inputModel.length > 10) {
      this.inputType = 'error';
      this.inputMessage = 'To many letters';
      changeFlag = true;
    }

    if (this.inputModel.length === 0) {
      this.inputType = 'error';
      this.inputMessage = 'Should not be empty';
      changeFlag = true;
    }

    if (!changeFlag) {
      this.inputType = 'success';
      this.inputMessage = 'Valid value';
    }

    console.log('Type: ' + this.inputType);
  }

  public changeInputType() {
    if (this.inputType === 'success') {
      this.inputType = 'error';
    } else {
      this.inputType = 'success';
    }
  }

  public testNotification() {
    interval(100).subscribe(value => {
      this.sidebarBadgesService.myBadgeSubject.next(value);
    });
  }

  public testLoading() {
    this.isTestLoading = true;
    setTimeout(() => this.isTestLoading = false, 100000);
  }

  public updateSelectedItems(event) {
    console.log(event);
  }

  public log(...args) {
    console.log(...args);
  }

  public onGridDetailsClick(event) {
    console.log(event);
  }

  createMockData(quantity?: number) {
    const indexMax = quantity ? quantity : 5;
    for (let index = 0; index < indexMax; index++) {
      const gridEntry = {
        id: index,
        show: index % 2 === 0,
        lcDocumentType: 'Dokumenttyp ' + index,
        operatingCompany: 'Betreiberfirma ' + index,
        plant: 'Anlage ' + index,
        deliveryNote: 'Lieferschein ' + index,
        customer: 'Kunde ' + index,
        constructionSite: 'Baustelle ' + index,
        excelExportTest: 1,
        detail: [
          'Test1', 'Test2'
        ]
      };

      this.gridData.push(gridEntry);
    }

    this.buttons = [
      {
        id: 'create-new-plant-private',
        type: 'type',
        click: () => console.log('private')
      },
      {
        id: 'create-new-plant-public',
        type: 'type',
        click: () => console.log('public')
      }
    ];
  }

  itemNeedSpecialAttentionFunction(dataItem) {
    return dataItem.id % 2 === 0;
  }

  onFormSubmit() {
    alert('form submitted!');
  }

  createMoreMocks() {
    this.createMockData(10);
    this.gridData = [...this.gridData];
  }

  selectionChanged($event: any[]) {
    console.log($event);
  }

  updateSelectedValue() {
    this.changeSelectedDataSubject.next([this.gridData[2]]);
  }

  async confirmDialogDeleteWarning() {
    const confirmResult = await this.confirmService.confirm(2,
      null,
      'Soll der Datensetz unwiederruflich gelöscht werden?',
      'global.action.delete',
      'global.action.cancel', null, null, 'warning', 1);
  }

  async confirmDialogDeleteErrorWithTitle() {
    const confirmResult = await this.confirmService.confirm(1,
      'global.action.delete',
      'Soll der Datensetz unwiederruflich gelöscht werden?',
      'global.common.ok',
      null, null, null, 'error');
    {
      if (confirmResult) {
        console.log(confirmResult);
      }
    }
  }

  async confirmDialogDeleteError() {
    const confirmResult = await this.confirmService.confirm(2,
      null,
      'Soll der Datensetz unwiederruflich gelöscht werden?',
      'global.action.delete',
      'global.action.cancel', null, null, 'error');
    {
      if (confirmResult) {
        console.log(confirmResult);
      }
    }
  }

  async confirmDialogDeleteOk() {
    const confirmResult = await this.confirmService.confirm(1, 'OK?', 'Bitte Ok drucken', undefined, undefined, undefined, undefined, 'error');
    {
      if (confirmResult) {
        console.log(confirmResult);
      }
    }
  }

  toggleShow() {
    this.showStateTransitionDropdown = !this.showStateTransitionDropdown;
  }

  public get QPointDateFormat() {
    return QPointDateFormat;
  }

  setCenter() {
    this.map.setCenter({ lat: 12, lng: 42 });
  }

  private formatLatLng(data: google.maps.LatLng[] | google.maps.LatLngLiteral[]): any {
    return {
      type: 'Feature',
      properties: {},
      geometry: {
        type: data.length === 1 ? 'Point' : 'LineString',
        coordinates: data.length === 1 ?
          [data[0].lat, data[0].lng]
          : data.map(entry => {
            return [+(entry.lat || entry.lat()), +(entry.lng || entry.lng())];
          }),
      },
    };
  }

  polygonChanged(event) {
    console.log('POLYGON CHANGED: ', { event });
  }

  resetPolygon() {
    this.openDemoDialog();
    this.geofence = cloneDeep(this.geofence);
  }

  public generateLogEntry(event: any, geoCoordinate: google.maps.LatLng, polygon: QPointMapPolygon) {
    console.log('This is a log entry', event, geoCoordinate, polygon);
  }

  public contextMenuDisabled() {
    return true;
  }

  openDemoDialog() {
    this.confirmService.openConfirm({
      title: null,
      message: 'Dies ist ein Demo Confirm Dialod',
      container: this.map.isFullscreen ? this.map.getMapDialogAnchor() : null
    }).closed.subscribe(
      res => console.log({ res })
    );
  }

  searchResultSelected($event: any) {
    console.log($event);
  }
}
