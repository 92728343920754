<div class="delivery-note-transfer-container" [qpointLoading]="loading$ | async">
  <div
    class="delivery-note-transfer-title">{{(deliveryNoteTransferService?.texts?.title || 'vehicle.deliveryNoteDelivery') | translate}}</div>
  <hr class="my-2"/>
  <div
    class="delivery-note-transfer-text">{{(deliveryNoteTransferService?.texts?.text || 'vehicle.deliveryNoteDeliveryText') | translate}}</div>
  <form [formGroup]="form" *ngIf="onboardingStatusState$ | async as onboardingStatusState">
    <div class="d-flex switch-container justify-content-start my-2">
      <qpoint-switch size="small" formControlName="deliveryNoteTransferEnabled" class="w-auto">
      </qpoint-switch>
      <div class="d-flex align-items-center ps-4 form-control-label">
        {{(deliveryNoteTransferService?.texts?.transferEnabledToggle || 'vehicle.deliveryNoteTransferEnabled')| translate}}</div>
    </div>
    <div>
      <div class="d-flex delivery-note-transfer-mobile-phone mb-3">
        <div class="delivery-note-transfer-mobile-phone-label">
          {{((deliveryNoteTransferService?.texts?.transferMobilePhoneInput || 'vehicle.deliveryNoteTransferMobilePhone') | translate) + ':'}}</div>
        <div class="d-flex">
          <qpoint-reactive-form-input-group class="d-flex">
            <qpoint-inline-editing-input class="qpoint-inline-editing-input-width"
                                         formControlName="deliveryNoteTransferMobilePhone" qpointValidationFormControl
                                         [activeInlineEditing]="activeInlineEditing$" [editDisabled]="vehicle.disabled"
                                         (editModeIsActive)="onEditModeIsActive($event)" [loadingIndicator]="loading$ | async">
            </qpoint-inline-editing-input>
          </qpoint-reactive-form-input-group>
          <div class="mx-3"
               *ngIf="(onboardingStatusState.onboardingStatus === 'Onboarding' ||  onboardingStatusState.onboardingStatus === 'Uninstalled' ) && deliveryNoteTransferMobilePhone.value?.length > 0">
            <qpoint-button *ngIf="invitationState$ | async as invitationIconState" variant="secondary"
                           [icon]="invitationIconState.icon" [loading]="invitationIconState.loading"
                           [iconVariant]="invitationIconState.color" (buttonClicked)="restartOnboarding()">{{
              (deliveryNoteTransferService?.texts?.resendWelcomeSms || 'vehicle.resendWelcomeSms') |
                translate }}</qpoint-button>
          </div>
        </div>
      </div>

      <div class="status-icon d-flex justify-items-center" [ngClass]="'status-' + (statusColor$ | async)"
           *ngIf="onboardingStatusState.onboardingStatus !== null && onboardingStatusState.onboardingStatus !== undefined && deliveryNoteTransferMobilePhone.value">
        <ng-container
          *ngIf="onboardingStatusState.onboardingStatus === 'NotFound' || onboardingStatusState.onboardingStatus === 'Uninstalled'"
        >
          <qpoint-icon iconType="material-outlined" icon="mobile_off"></qpoint-icon>
          {{(deliveryNoteTransferService?.texts?.statusNotFound || 'vehicle.onboarding.NotFound') | translate}}
        </ng-container>
        <ng-container *ngIf="onboardingStatusState.onboardingStatus === 'Onboarding'">
          <qpoint-icon iconType="material-outlined" icon="perm_device_information">
          </qpoint-icon>
          {{(deliveryNoteTransferService?.texts?.statusOnboarding || 'vehicle.onboarding.Onboarding') | translate}}
        </ng-container>
        <ng-container *ngIf="onboardingStatusState.onboardingStatus === 'Registered'">
          <qpoint-icon iconType="material-outlined" icon="phonelink_ring">
          </qpoint-icon>
          {{(deliveryNoteTransferService?.texts?.statusRegistered || 'vehicle.onboarding.Registered') | translate}}
        </ng-container>
      </div>
    </div>
  </form>
</div>
