import { Component, HostListener } from '@angular/core';
import { MapModeStateService } from './map-mode-state.service';
import { QPointMapModeConfiguration } from './mode';

@Component({
  selector: 'qpoint-map-mode-selection',
  templateUrl: './map-mode-selection.component.html',
  styleUrls: ['./map-mode-selection.component.scss']
})
export class MapModeSelectionComponent {
  modes = this.mapModeState.modesSignal;

  @HostListener('context', ['$event'])
  handleContextMenu(event: PointerEvent) {
    event.preventDefault();
    event.stopImmediatePropagation();
    event.stopPropagation();
  }

  constructor(private mapModeState: MapModeStateService) {
  }

  toggleModeActive(config: QPointMapModeConfiguration) {
    this.mapModeState.updateModeActive(config.mode, !config.active);
  }
}
