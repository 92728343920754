import { Component } from '@angular/core';

@Component({
  selector: 'qpoint-special-attention',
  templateUrl: './special-attention.component.html',
  styleUrls: ['./special-attention.component.scss']
})
export class SpecialAttentionComponent {

  public dataItem;
  public templateRef: any;
  public closePopup: () => unknown;

  public closeCallback() {
    return () => {
      this.closePopup();
    };
  }
}
