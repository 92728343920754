import { Component, OnInit } from '@angular/core';
import { DropDownVariant, QPointSuggestlistComponent } from '@qpoint/forms';
import { QPointButtonComponent } from '../../../../projects/qpoint-button/src/lib/button/button.component';
import { QPointToolbarComponent, QPointContentContainerComponent } from '@qpoint/layout';

@Component({
    selector: 'app-control-gallery-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss'],
    standalone: true,
    imports: [QPointToolbarComponent, QPointContentContainerComponent, QPointButtonComponent, QPointSuggestlistComponent]
})
export class ControlGalleryDropdownComponent implements OnInit {
  public listItems: Array<string> = ['KendoChart', 'KPI', 'SsrsReport', 'Map', 'Grid'];
  public isLoading: boolean;

  DropdownVariant = DropDownVariant;
  constructor() { }

  ngOnInit() {
  }
}
