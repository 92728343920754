import { Injectable } from '@angular/core';

export interface ISidebarSizes {
    /**
     * width of the sidebar when
     * 1200
     */
    sidebarCollapseWidth: number;

    /**
     * width of the sidebar when
     * 500
     */
    sidebarHideWidth: number;
}

@Injectable()
export class SidebarSizes implements ISidebarSizes {
    sidebarCollapseWidth = 1200;
    sidebarHideWidth = 500;
}
