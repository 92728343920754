import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { TextBoxModule } from '@progress/kendo-angular-inputs';
import { QPointFormValidators, QPointReactiveFormInputGroupComponent, QPointTableRowComponent } from '@qpoint/forms';
import { ILanguage, QPointLocalizationService } from '@qpoint/i18n';
import { QPointIconModule } from '@qpoint/icons';
import {
  QPointAuthorizedImagePipe,
  QPointContentContainerComponent,
  QPointToolbarButtonComponent,
  QPointToolbarComponent,
} from '@qpoint/layout';
import { QPointLoadingDirective } from '@qpoint/loading';
import { IQPointBase64Image, QPointImageSelectorComponent } from '@qpoint/uploads';
import { BehaviorSubject, lastValueFrom, Subject } from 'rxjs';
import { filter, finalize, skip, take, takeUntil } from 'rxjs/operators';

import { QPointFormatPreviewComponent } from '../format-preview/format-preview.component';
import { UserProfile } from '../model/user.model';
import { injectUserProfileService } from '../qpoint-profile.service';

import ICulture = UserProfile.ICulture;
import IUser = UserProfile.IUser;

@Component({
  selector: 'qpoint-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [
    QPointLoadingDirective,
    TranslateModule,
    QPointToolbarComponent,
    QPointToolbarButtonComponent,
    CommonModule,
    QPointIconModule,
    QPointImageSelectorComponent,
    QPointAuthorizedImagePipe,
    QPointTableRowComponent,
    QPointReactiveFormInputGroupComponent,
    ReactiveFormsModule,
    TextBoxModule,
    DropDownListModule,
    QPointFormatPreviewComponent,
    QPointContentContainerComponent
  ],
  standalone: true
})
export class QPointProfileComponent implements OnInit, OnDestroy {
  private profileService = injectUserProfileService();

  @Output()
  public profileInformationChanged: EventEmitter<IUser> = new EventEmitter();
  public formGroup: UntypedFormGroup;
  public isLoading = true;
  public isSubmitted = false;
  public languages$: BehaviorSubject<ILanguage[]>;
  public cultures$: BehaviorSubject<ICulture[]>;
  private ngUnsubscribe = new Subject<void>();
  private pristineUser: IUser;
  public imageUri: string;

  constructor(private localizationService: QPointLocalizationService) {
    this.cultures$ = this.profileService.getCultures();
  }

  get image() {
    return this.formGroup.get('image');
  }

  get givenname(): UntypedFormControl {
    return this.formGroup.get('givenname') as UntypedFormControl;
  }

  get surname(): UntypedFormControl {
    return this.formGroup.get('surname') as UntypedFormControl;
  }

  get eMail(): UntypedFormControl {
    return this.formGroup.get('emailAddress') as UntypedFormControl;
  }

  get phone(): UntypedFormControl {
    return this.formGroup.get('phone') as UntypedFormControl;
  }

  get mobile(): UntypedFormControl {
    return this.formGroup.get('mobile') as UntypedFormControl;
  }

  get preferredLanguage(): UntypedFormControl {
    return this.formGroup.get('preferredLanguage') as UntypedFormControl;
  }

  get companyName(): UntypedFormControl {
    return this.formGroup.get('companyName') as UntypedFormControl;
  }

  get streetAddress(): UntypedFormControl {
    return this.formGroup.get('streetAddress') as UntypedFormControl;
  }

  get postalCode(): UntypedFormControl {
    return this.formGroup.get('postalCode') as UntypedFormControl;
  }

  get city(): UntypedFormControl {
    return this.formGroup.get('city') as UntypedFormControl;
  }

  get country(): UntypedFormControl {
    return this.formGroup.get('country') as UntypedFormControl;
  }

  get cultureInfo(): UntypedFormControl {
    return this.formGroup.get('cultureInfo') as UntypedFormControl;
  }

  public async ngOnInit() {
    this.initForm({} as any);
    this.isLoading = true;
    this.languages$ = await this.localizationService.getLanguageSubject();
    this.profileService.getUserProfileData()
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(finalize(() => this.isLoading = false))
      .subscribe(
        {
          next: async userResponse => {
            this.pristineUser = userResponse;
            this.initForm(userResponse);
          }
          ,
          error: error => {
            console.log('error while fetching user information');
          }
        }
      );
  }


  public initForm(user: IUser) {
    this.formGroup = new UntypedFormGroup({
      givenname: new UntypedFormControl(user.givenname, [Validators.required, Validators.maxLength(100)]),
      surname: new UntypedFormControl(user.surname, [Validators.required, Validators.maxLength(100)]),
      emailAddress: new UntypedFormControl({value: user.emailAddress, disabled: true}, Validators.required),
      phone: new UntypedFormControl(user.phone, {validators: QPointFormValidators.phoneValidator, updateOn: 'blur'}),
      mobile: new UntypedFormControl(user.mobile, {validators: QPointFormValidators.phoneValidator, updateOn: 'blur'}),
      preferredLanguage: new UntypedFormControl(user.preferredLanguage),
      companyName: new UntypedFormControl(user.companyName, Validators.maxLength(100)),
      streetAddress: new UntypedFormControl(user.streetAddress, Validators.maxLength(100)),
      postalCode: new UntypedFormControl(user.postalCode, Validators.maxLength(100)),
      city: new UntypedFormControl(user.city, Validators.maxLength(100)),
      country: new UntypedFormControl(user.country, Validators.maxLength(100)),
      cultureInfo: new UntypedFormControl(user.cultureInfoDateTime, Validators.required),
      image: new UntypedFormControl(user.profileImage ? user.profileImage.data : '')
    });
    this.imageUri = user.profileImageUri;
    if(!user.cultureInfoDateTime)return;

    if(this.cultures$ && this.cultures$.getValue() && this.cultures$.getValue().length > 0) {
      this.cultureInfo.patchValue(this.cultures$.getValue().find((culture: ICulture) => culture.code === user.cultureInfoDateTime));
    } else {
      this.cultures$
      .pipe(skip(1), take(1))
      .pipe(filter(value => value && value.length > 0))
      .subscribe(cultures => {
        this.cultureInfo.patchValue(cultures.find((culture: ICulture) => culture.code === user.cultureInfoDateTime));
      });
    }

  }

  public discardChanges() {
    this.initForm(this.pristineUser);
    this.formGroup.markAsPristine();
  }

  public async updateUserWithFormValues() {
    this.isSubmitted = true;
    if (this.formGroup.invalid) {
      this.isLoading = false;
      return;
    }
    this.isLoading = true;
    try {
      const modifiedUser = {
        ...this.formGroup.value,
        emailAddress: this.eMail.value,
        cultureInfoDateTime: this.cultureInfo?.value?.code,
        cultureInfoNumber: this.cultureInfo?.value?.code,
        profileImage: this.image.value || null
      } as IUser;
      await this.profileService.saveUserProfile(modifiedUser, this.image.dirty);
      this.pristineUser = modifiedUser;
      this.formGroup.markAsPristine();
    } catch (e) {
      console.log('error while updating user profile', e);
    } finally {
      this.isLoading = false;
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  async imageChanged($event: IQPointBase64Image) {
    // set default profile image if image has been deleted
    if ($event?.data == null) {
      const imageBlob = await lastValueFrom(this.profileService.getCurrentUserDefaultProfileImage());
      const defaultImage = await this.extractImageFromBlob(imageBlob);
      this.image.setValue(defaultImage);
      return;
    }
  }

  async extractImageFromBlob(imageBlob: Blob): Promise<IQPointBase64Image> {
    const convertBlobToBase64 = (blob: Blob) => new Promise<string | ArrayBuffer>((resolve, reject) => {
      const reader = new FileReader;
      reader.onerror = reject;
      reader.onload = () => {
          resolve(reader.result);
      };
      reader.readAsDataURL(blob); // provides image data as base64 encoded string inside data url
    });

    const dataUrl: string = (await convertBlobToBase64(imageBlob)).toString();
    const base64String: string = dataUrl.split(',', 2)[1]; // cut off data url prefix
    return { data: base64String, length: base64String.length };
  }
}
