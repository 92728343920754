import { Component } from '@angular/core';
import { DropDownVariant, QPointSuggestlistComponent, QPointReactiveFormInputGroupComponent, QPointCheckboxListComponent } from '@qpoint/forms';
import { UntypedFormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';

interface IDummyItem {
  key: string;
  value: string;
}

@Component({
    selector: 'app-control-gallery-suggest-list',
    templateUrl: './suggest-list.component.html',
    standalone: true,
    imports: [
        FormsModule,
        QPointSuggestlistComponent,
        QPointReactiveFormInputGroupComponent,
        ReactiveFormsModule,
        QPointCheckboxListComponent,
    ],
})
export class ControlGallerySuggestListComponent {
  items: IDummyItem[] = [];
  disabled = false;
  readonly = false;
  loading = false;
  value = null;

  suggestListFormControl = new UntypedFormControl(null, [Validators.required]);

  public dropDownVariant = DropDownVariant;

  constructor() {
    this.items = [];

    for (let i = 0; i < 50; i++) {
      this.items.push({
        key: `item-${i}`,
        value: `This is item ${i}`,
      });
    }
  }

  onChange($event) {
    this.value = $event;
  }

  setToPreselection() {
    this.value = {'key': 'item-40', 'value': 'This is item 40'};
  }

  setToNonExisting() {
    requestAnimationFrame(() => this.value = {'key': 'item----', 'value': 'This is item ----'});
  }

  setToNewItems() {
    this.items = [...this.items];
  }
}
