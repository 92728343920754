import {
  AfterContentInit,
  AfterViewChecked,
  ChangeDetectorRef,
  ComponentRef,
  ContentChild,
  ContentChildren,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Injectable,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import {
  ColumnChooserComponent,
  ColumnComponent,
  ColumnReorderEvent,
  ColumnResizeArgs,
  ColumnVisibilityChangeEvent,
  DetailExpandEvent,
  ExcelComponent,
  ExcelExportEvent,
  GridComponent,
  MultipleSortSettings,
  PageChangeEvent,
  RowArgs,
  RowClassArgs,
  SelectableSettings,
  SelectAllCheckboxState,
  SelectionEvent,
  SingleSortSettings,
} from '@progress/kendo-angular-grid';
import { PopupRef, PopupService } from '@progress/kendo-angular-popup';
import { GroupDescriptor, GroupResult, orderBy, process, SortDescriptor } from '@progress/kendo-data-query';
import { Observable, Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { EllipseMenuComponent } from './ellipse-menu/ellipse-menu.component';
import { QPointExcelExportCustomComponent } from './excel-export-custom/excel-export-custom.component';
import { GridConnectService } from './grid-connect.service';
import { QPointGridContextMenuComponent } from './grid-context-menu.component';
import { QPointGridDetailTemplateDirective } from './grid-detail-template.directive';
import {
  orderColumnsBasedOnLocalStorage,
  persistOnReorder,
  persistOnResize,
  persistOnSort,
  persistOnVisibilityChange,
} from './grid-localstore-functions';
import { GridSpecialAttentionTemplateDirective } from './grid-special-attention-template.directive';
import { ColumnOrderPosition, IVirtualScrollSettings, QPointKeydownEndEvent, QPointSelectAllEvent } from './grid.component';
import { SpecialAttentionComponent } from './special-attention/special-attention.component';


@Directive()
@Injectable()
export class BaseGridDirective implements OnInit, AfterContentInit, OnChanges, OnDestroy, AfterViewChecked {
  selectAllCheckboxClicked = false;
  /***
   * identifier for localstorage, resize + reorders
   */
  @Input()
  public storageIdentifier: string;
  @Input()
  public resizableColumn = true;
  @Input()
  public reorderableColumn = true;
  /***
   * Selected Item array
   */
  public kendoGridSelectedKeys: any[] = [];
  /***
   * Datasource for kendo grid
   */
  public kendoGridInput;

  /***
   * Select all checkbox state
   */
  public selectAllState: SelectAllCheckboxState = 'unchecked';
  /***
   * Hide checkboxes toggle
   */
  public checkBoxesDisplayNone = true;
  /***
   * Show context menu toggle
   */
  public showContextMenu = false;

  /***
   * Kendo grid loading spinner toggle
   */
  @Input()
  public isDataLoading = false;

  /***
   * Additional order by for non viewable columns
   */
  @Input()
  public additionalClientOrderByNonViewableColumn: SortDescriptor[];

  /***
   * Selection settings for kendo grid
   */
  public kendoSelectableSettings: SelectableSettings;

  /***
   * Rows are selectable
   */
  @Input()
  public selectable = true;

  /***
   * selection mode
   */
  @Input()
  public selectionMode: 'single' | 'multiple' = 'multiple';

  /***
   * Items to display
   */
  @Input()
  public dataInput: any[];

  /***
   * Show select all column + checkbox
   */
  @Input()
  public showSelectAllColumn = false;

  /***
   * Text to display if no data is available
   */
  @Input()
  public noRecordsText = 'No records available.';

  @Input()
  public showDetailViewValue: boolean | any = false;

  /***
   * Display row lines
   */
  @Input()
  public rowBorderBottom = false;

  /***
   * Scrollmode of the grid
   */
  public scrollable = 'virtual';

  /***
   * Override virtual scroll settings if scrollmode is set to 'virtual'
   */
  @Input()
  public virtualScrollSettings: IVirtualScrollSettings;


  /***
   * if enabled and scrollmode == 'virtual', the scrollBottomCallback event emitter will be triggered
   */
  public infiniteScroll = false;

  /***
   * Size of a grid row
   */
  @Input()
  public rowType: 'small' | 'medium' | 'large' = 'small';

  /***
   * Size of a grid detail row
   */
  public detailRowHeightInternal;

  /**
   * Check if scrollbar is visible, needed for the padding in the header
   */
  public isScrollbarVisible = true;

  /**
   * standard columns merged with content children
   */
  public gridColumnsMergeComplete = false;

  @Input() set detailRowHeight(data: number) {
    this.detailRowHeightInternal = data;
  }

  public columnVisiblityChanged = new Subject();

  get detailRowHeight() {
    if (this.scrollable === 'virtual' && this.detailRowHeightInternal == null) {
      switch (this.rowType) {
        case 'small':
          return 225;
        case 'medium':
          return 350;
        case 'large':
          return 475;
      }
    }
    return this.detailRowHeightInternal;
  }

  /***
   * Input observable to update selection outside of the grid
   */
  @Input()
  public changeSelectedDataSubject: Observable<any[] | any>;

  @Input()
  public group: GroupDescriptor[];

  @Input()
  public isSpecialAttentionRowCallback: Function;

  @Input()
  public rowCallback: (context: RowClassArgs) => { [key: string]: any };

  @Input()
  public columnConfigurationEnabled = true;

  @Input()
  public excelExportEnabled = false;

  @Input()
  public manuallyPlaceExcelExportButton = false;

  @Input()
  public excelExportTooltip: string = null;
  /***
   Select all checkbox is only visible if total length of data is lower than value
   */
  @Input()
  public maxCountToEnableSelectAllFeature = 500;

  /***
   * Event when sort mode of the grid changes
   */
  @Output()
  public serverFilterChanged: EventEmitter<IVirtualScrollSettings> = new EventEmitter<IVirtualScrollSettings>();

  /***
   * Selection change event
   */
  @Output()
  public selectionChanged = new EventEmitter<any[]>();

  /**
   * Select all clicked event
   */
  @Output() selectAllClicked = new EventEmitter<QPointSelectAllEvent>();
  @Input() selectAllCustomCallback = false;

  /***
   * Double click event on grid, emits selected keys
   */
  @Output()
  public dblClickOnRow = new EventEmitter<any>();

  /***
   * Detail expand event on grid, emits expanded master row
   */
  @Output()
  public detailExpand = new EventEmitter<any>();


  /***
   * Input context menu
   */
  @ContentChild(QPointGridContextMenuComponent)
  public contextMenu: QPointGridContextMenuComponent;

  /***
   * Detail template
   */
  @ContentChild(QPointGridDetailTemplateDirective, {read: TemplateRef})
  public gridDetailTemplate;
  /***
   * Special Attention template
   */
  @ContentChild(GridSpecialAttentionTemplateDirective, {read: TemplateRef})
  public gridSpecialAttentionRef;

  @ViewChild(ExcelComponent)
  public kendoExcelComponent: ExcelComponent;
  @ContentChild(QPointExcelExportCustomComponent)
  private excelExportCustomComponent: QPointExcelExportCustomComponent;
  @ViewChild(ColumnChooserComponent)
  public columnChooser: ColumnChooserComponent;
  @Input()
  public class = '';
  public localStorageColumnMirrorArray: ColumnOrderPosition[];
  public showExportPopup: boolean;
  @ViewChild('exportAnchor')
  public exportAnchor: ElementRef;
  @ViewChild('exportPopup', {read: ElementRef})
  public exportPopup: ElementRef;
  @Input()
  public excelFileName = 'Export.xlsx';
  @Input()
  public excelExportFetchData;

  @Input()
  public sortable: boolean | SingleSortSettings | MultipleSortSettings = {allowUnsort: false, mode: 'single'};

  @Input() persistSortSettings = true;

  @Output()
  public exportCustomOutput = new EventEmitter<ExcelExportEvent>();
  /***
   * Number for footer toolbar to show total count of server items
   */
  @Input()
  public totalItemCount: number = null;
  /***
   * Single Element text for grid footer template
   */
  @Input()
  public elementTextPath = 'common.grid.element';
  /***
   * Element Plural text for grid footer template
   */
  @Input()
  public elementPluralTextPath = 'common.grid.elementPlural';
  /***
   * Tooltip text for export button when there are too much elements to export
   */
  @Input()
  public tooMuchElementsToExport = 'common.grid.tooMuchElementsToExport';

  /***
   * Text for column config popup title
   */
  @Input()
  public columnsTextPath = 'common.grid.columns';

  /***
   * Text for column config popup apply button
   */
  @Input()
  public columnsApplyTextPath = 'common.grid.columnsApply';

  /***
   * Text for column config popup reset button
   */
  @Input()
  public columnsResetTextPath = 'common.grid.columnsReset';

  /***
   * Single Element text for grid footer template
   */
  @Input()
  public singleElementSelectedTextPath = 'common.grid.selection.singleElementSelected';
  /***
   * Multiple Elements selected text for grid footer template
   */
  @Input()
  public multipleElementSelectedTextPath = 'common.grid.selection.multipleElementsSelected';
  /***
   * Enable Footer toolbar to show selection count
   */
  @Input()
  public footerToolbarEnabled = false;

  /***
   * if excel export should be filterable
   */
  @Input()
  public filterable = false;

  /***
   * sort descriptor array for kendo
   */
  public sort: SortDescriptor[] = [];

  /***
   * array with all expanded detail keys.
   * Key field is determined by the expandDetailsBy function.
   */
  public expandedDetailKeys: any[] = [];

  /***
   * Grid columns to merge standard kendo grid columns (checkbox, contextmenu)
   */
  @ContentChildren(ColumnComponent)
  private columns: QueryList<ColumnComponent>;
  /***
   * Kendo grid
   */
  @ViewChild('mainGrid', {static: false})
  private grid: GridComponent;

  @ViewChild('gridWrapper', {static: true})
  private gridWrapper: ElementRef;

  @ViewChild('selectedKeysGrid', {static: false})
  private selectedKeysGrid: GridComponent;

  @ViewChild(ColumnChooserComponent, {static: false, read: ElementRef})
  public columnChooserComponent: ElementRef;

  @HostListener('document:keydown.end', ['$event'])
  onEndKeyHandler(event: KeyboardEvent) {
    if (this.keydownEndCallback) {
      event.stopPropagation();
      event.preventDefault();

      this.keydownEndCallback({
        event,
        scrollToBottom: this.scrollToBottom.bind(this)
      });
    }
  }

  @Input()
  keydownEndCallback: (event: QPointKeydownEndEvent) => void;

  /***
   * Unsubscribe from all subscriptions on ngdestroy
   */
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  /***
   * is set to handle shift selection in virtual scroll mode
   */
  private lastClickedItemForShiftSelection;
  private openContextMenuRef: PopupRef;
  private specialAttentionComponentRef: PopupRef;
  /***
   * Bool to flag if last selection was done via ctrlClick (selection box) to bubble kendoGridSelectionKeyChanged events to parent
   */
  private lastSelectionViaCtrlClick = false;
  private isGroupable = false;

  /***
   * function to specify field by wich to expand.
   * Specified field will be saved in expandedDetailKeys.
   * Per default, the whole object is saved.
   */
  @Input()
  public expandDetailsBy = (dataItem: any) => dataItem;

  constructor(private changeDetectorRef: ChangeDetectorRef,
              private popupService: PopupService,
              private gridConnectService: GridConnectService,
              private ngbTooltipConfig: NgbTooltipConfig) {
    ngbTooltipConfig.container = 'body';
  }

  public openChooserMenu() {
    this.columnChooserComponent.nativeElement.firstChild.click();
  }

  ngAfterViewChecked(): void {

  }

  @HostBinding('class')
  public get hostClasses(): string {
    return [
      this.class, // include our new one
      'qpoint-grid-container'
    ].join(' ');
  }

  private updateSort() {
    const sort: SortDescriptor[] = [];

    if (this.localStorageColumnMirrorArray) {
      let localStorageSort = this.localStorageColumnMirrorArray.filter(l => l.sort === 'desc' || l.sort === 'asc');
      if (localStorageSort && localStorageSort.length > 0) {
        localStorageSort = localStorageSort.sort((a, b) => a.sortOrder - b.sortOrder);
        localStorageSort.forEach(s => {
          sort.push({field: s.name, dir: s.sort});
        });
        this.sort = sort;
        return;
      }
    }
    if (this.virtualScrollSettings && this.virtualScrollSettings.orderBy) {
      this.virtualScrollSettings.orderBy.forEach((item) => {
        const splittedItems = item.split(' ');
        const dir = splittedItems.length === 2 ? splittedItems[1] : null;
        sort.push({
          field: splittedItems[0],
          dir: dir && dir === 'desc' ? 'desc' : 'asc'
        });
      });
      this.sort = sort;
    } else {
      this.sort = [];
    }
  }

  /***
   * Get rowheight for virtual scroll, if changed also change values in scss
   */
  public get rowHeight() {
    if (this.scrollable === 'virtual') {
      switch (this.rowType) {
        case 'small':
          return 42;
        case 'medium':
          return 59;
        case 'large':
          return 86;
      }
    } else {
      return null;
    }
  }

  public get gridClasses() {
    const classes = {
      'rowBorderBottom': this.rowBorderBottom
    };

    classes[this.rowType] = true;
    return classes;
  }

  public get noRecordsTextTemplate(): string {
    return this.isDataLoading ? '' : this.noRecordsText;
  }

  public get localStorageGridOrderKey() {
    return this.storageIdentifier + '-grid-custom';
  }

  /**
   * Listener to close export popup when clicked outside
   * @param event
   */
  @HostListener('document:click', ['$event'])
  public documentClick(event: any): void {
    if (!this.isExportElementClicked(event.target)) {
      this.showExportPopup = false;
    }

    if (event && event.path && event.path.length > 0) {
      const pathClassList = event.path.map(p => p.className);
      if (pathClassList.includes('btn qpoint-button btn-icon-variant-primary btn-secondary') && pathClassList.includes('k-column-list-wrapper ng-star-inserted')) {
        this.columnChooser.ngOnDestroy();
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize($event) {
    this.checkForScrollbar();
  }

  public ngOnInit() {
    if (this.excelExportEnabled || this.columnConfigurationEnabled) {
      this.gridConnectService.registerGrid(this);
    }
    // set excelExport fetch data to only export selected rows if selected
    if (!this.excelExportFetchData) {
      this.excelExportFetchData = () => {
        return {
          data: this.kendoGridSelectedKeys.length > 0 ? process(this.kendoGridSelectedKeys, {group: this.group}).data :
            (this.kendoGridInput.data ? this.kendoGridInput.data : this.kendoGridInput),
          group: this.group
        };
      };
    }
    this.isGroupable = !!this.group;
    this.kendoSelectableSettings = {
      checkboxOnly: false,
      enabled: this.selectable,
      mode: this.selectionMode
    };
    if (!this.virtualScrollSettings) {
      this.virtualScrollSettings = {
        skip: 0,
        take: 100
      };
    } else {
      // set to max
      if (this.virtualScrollSettings.take > 100) {
        this.virtualScrollSettings.take = 100;
      }
    }

    if (this.changeSelectedDataSubject) {
      this.changeSelectedDataSubject
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(value => {
          if (value) {
            if (typeof value[Symbol.iterator] === 'function') {
              this.kendoGridSelectedKeys = [...value];
            } else {
              this.kendoGridSelectedKeys = [value];
            }

          } else {
            this.kendoGridSelectedKeys = [];
          }
          this.updateSelectedAllCheckboxState();
        });
    }

    if (!!this.sortable && this.sortable['mode'] && this.sortable['mode'] === 'multiple') {
      this.sortable['multiSortKey'] = 'ctrl';
    }
  }

  /***
   * Merge custom columns with standard checkbox + contextMenu column
   */
  public ngAfterContentInit(): void {
    timer(1).subscribe(() => {
      if (this.grid.columns.length > 2) {
        return;
      }
      const checkBoxColumn = this.grid.columns.toArray()[0];

      const dataColumnsFromContent = this.columns.toArray();
      if (this.storageIdentifier) {
        dataColumnsFromContent.forEach(value => {
          if (!value.field) {
            throw new Error('property -field- must bet set on all kendo grid columns to persist customizations');
          }
        });
      }
      const mergedColumns = [checkBoxColumn, ...orderColumnsBasedOnLocalStorage.bind(this)(dataColumnsFromContent)];
      this.grid.columns.reset(mergedColumns);
      this.gridColumnsMergeComplete = true;
      if (this.kendoExcelComponent && this.excelExportCustomComponent && this.excelExportCustomComponent.excelExportColumns.length > 0) {
        this.kendoExcelComponent.columns.reset(this.excelExportCustomComponent.excelExportColumns.toArray());
      }
      this.updateSort();
      this.loadData();
      this.updateOrderByFilter(this.sort);
      this.serverFilterChanged.emit(this.virtualScrollSettings);
      this.changeDetectorRef.detectChanges();
    });

  }

  public showDetailCondition = (dataItem: any) => {
    if (!this.showDetailViewValue) {
      return false;
    } else {
      if (typeof (this.showDetailViewValue) === 'boolean') {
        return true;
      } else {
        if (dataItem.hasOwnProperty(this.showDetailViewValue)) {
          return dataItem[this.showDetailViewValue];
        } else {
          return false;
        }
      }
    }
  };

  public rowClassCallback = (context: RowClassArgs) => {
    const rowCallbackResult = this.rowCallback ? this.rowCallback(context) : {};
    if (this.isSpecialAttentionRowCallback) {
      const result = this.isSpecialAttentionRowCallback(context.dataItem);
      rowCallbackResult.specialAttentionRow = result;
    }
    return rowCallbackResult;
  };

  private getItemFromGroupResults(data: GroupResult[], index: number) {
    if (!data || data.length == 0) {
      return null;
    }
    let groupIndex = 0;
    let skippedDataLength = 0;
    while (data[groupIndex] && index >= data[groupIndex].items.length + skippedDataLength) {
      skippedDataLength += data[groupIndex].items.length;
      groupIndex++;
    }

    return data[groupIndex].items[index - skippedDataLength];
  }

  /***
   * Emit selected keys
   * @param context
   */
  public onDblClickEvent(context: MouseEvent) {
    const target = context.target as HTMLElement;
    if (target.nodeName === 'TD' && target.innerText !== this.noRecordsText) {
      context.stopPropagation();
      const parent = target.parentElement;
      const rowIndex = +parent.dataset.kendoGridItemIndex;

      if (rowIndex == null || isNaN(rowIndex)) {
        this.dblClickOnRow.emit(this.kendoGridSelectedKeys);
        return;
      }

      const sort = this.additionalClientOrderByNonViewableColumn ?
        [...this.additionalClientOrderByNonViewableColumn, ...this.sort] : this.sort;

      let clickedData = null;
      if (this.dataInput) {
        if (this.group) {
          clickedData = this.getItemFromGroupResults(process(this.dataInput, {sort: sort, group: this.group}).data, rowIndex);
        } else if (this.sort) {
          clickedData = orderBy(this.dataInput, sort)[rowIndex];
        } else {
          clickedData = this.dataInput[rowIndex];
        }
      }

      this.dblClickOnRow.emit([clickedData]);
    } else {
      if (this.checkIfElementIsPartOfRow(context.target) && (context.target as HTMLElement).innerText !== this.noRecordsText) {
        context.stopPropagation();
        this.dblClickOnRow.emit(this.kendoGridSelectedKeys);
      }
    }
  }

  public onDetailExpand(event: DetailExpandEvent) {
    this.detailExpand.emit(event);
  }

  /***
   * Should not fire Event for th element and not looking for tr elements outside kendo-grid
   * @param element
   */
  public checkIfElementIsPartOfRow(element: any) {
    if (element.tagName.toLowerCase() === 'tr' && !element.classList.contains('k-detail-row')) {
      return true;
    } else {
      return !element.parentElement || element.tagName.toLowerCase() === 'kendo-grid' || element.tagName.toLowerCase() === 'th' ?
        false : this.checkIfElementIsPartOfRow(element.parentElement);
    }
  }

  /***
   * Reload grid data if datainput changes
   * @param changes
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataInput) {
      this.closeSpecialAttentionPopup();
      this.loadData();
    }
    // grid content changes --> apply columns again
    if (changes.footerToolbarEnabled) {
      this.ngAfterContentInit();
    }

    timer(1).subscribe(value => {
      this.checkForScrollbar();
    });
  }

  /***
   * Open Submenu popup when context menu is toggled
   * @param anchor
   * @param dataItem
   */
  public onContextButtonToggle(anchor, dataItem): void {
    if (this.openContextMenuRef) {
      this.openContextMenuRef.close();
    }
    this.openContextMenuRef = this.popupService.open({

      anchor: anchor,
      content: EllipseMenuComponent,
      anchorAlign: {
        horizontal: 'right',
        vertical: 'center'
      },
      popupAlign: {
        horizontal: 'right',
        vertical: 'center'
      },
      collision: {
        horizontal: 'flip',
        vertical: 'flip'
      },
      popupClass: 'grid-ellipsis-popup popup',
      animate: false,
      margin: {horizontal: 10, vertical: 0}
    });
    const component = this.openContextMenuRef.content as ComponentRef<EllipseMenuComponent>;
    component.instance.dataItem = dataItem;
    component.instance.contextMenu = this.contextMenu;
  }

  /***
   * Only show context menu button if item is the only one selected and context items are present
   * @param dataItem
   */
  public showContextButton(dataItem: any): boolean {
    return this.contextMenu ?
      this.kendoGridSelectedKeys.indexOf(dataItem) >= 0 && this.kendoGridSelectedKeys.length === 1 &&
      this.contextMenu.contextMenuItems.some(cm => cm.showItem(dataItem)) : false;
  }

  public onSelectAllChange(checkedState: SelectAllCheckboxState) {
    this.showContextMenu = false;
    const checkAll = () => {
      this.checkBoxesDisplayNone = false;
      this.kendoGridSelectedKeys = [...this.dataInput];
      this.selectAllState = 'checked';
      this.selectionChanged.emit(this.kendoGridSelectedKeys);
      this.selectAllCheckboxClicked = false;
    };

    const event: QPointSelectAllEvent = {
      checkboxState: checkedState,
      loadingFinishCallback: () => {
        checkAll();
      }
    };

    this.selectAllClicked.emit(event);

    if (checkedState === 'checked') {
      if (!this.selectAllCustomCallback) {
        checkAll();
      }
    } else {
      this.kendoGridSelectedKeys = [];
      this.selectAllState = 'unchecked';
      this.selectionChanged.emit(this.kendoGridSelectedKeys);
      this.selectAllCheckboxClicked = false;
    }
  }

  public selectionKey(rowArgs: RowArgs): any {
    return rowArgs.dataItem;
  }

  public getExcelExportTooltip(): string {
    return this.excelExportTooltip;
  }

  public onSortChange(sort: SortDescriptor[]): void {
    sort = sort.filter(s => s.dir);
    persistOnSort.bind(this)(sort);
    this.updateOrderByFilter(sort);
    this.updateSort();
    this.serverFilterChanged.emit(this.virtualScrollSettings);
    this.lastClickedItemForShiftSelection = null;

    if (this.scrollable !== 'virtual') {
      this.grid.sort = this.sort;
      this.loadData();
    }
    if (!this.infiniteScroll) {
      this.dataInput = orderBy(this.dataInput, this.sort);
      this.loadData();
    }
  }

  public onColumnVisabilityChange(event: ColumnVisibilityChangeEvent): void {
    this.columnVisiblityChanged.next(event);
    persistOnVisibilityChange.bind(this)(event.columns);
  }

  public onSelectedKeysChange(selectedKeys: any) {
    this.kendoGridSelectedKeys = selectedKeys;
    this.closeSpecialAttentionPopup();
    this.showContextMenu = false;
    this.updateSelectedAllCheckboxState();

    /***
     * Emit changes to bubble selection without custom virtual scroll logic
     */
    if(!this.selectAllCheckboxClicked) {
      this.selectionChanged.emit(selectedKeys);
    }

    if (this.scrollable !== 'virtual' || this.lastSelectionViaCtrlClick) {
      this.lastSelectionViaCtrlClick = false;
    }
  }

  public selectionChange($event: SelectionEvent) {
    this.closeSpecialAttentionPopup();
    if ($event.ctrlKey) {
      this.lastSelectionViaCtrlClick = true;
    }
  }

  /***
   * Handle shift selection on virtual scroll
   * @param e
   */
  public onCellClick(e) {
    if (!this.selectable) {
      return;
    }
    this.closeSpecialAttentionPopup();
    if (this.scrollable === 'virtual') {
      if (!e.originalEvent.shiftKey) {
        if (!e.originalEvent.ctrlKey) {
          this.kendoGridSelectedKeys = [e.dataItem];
          this.selectionChanged.emit(this.kendoGridSelectedKeys);
        }
        this.lastClickedItemForShiftSelection = e.dataItem;
      } else {
        this.kendoGridSelectedKeys = [];
        if (!this.lastClickedItemForShiftSelection) {
          this.lastClickedItemForShiftSelection = orderBy(this.dataInput, this.sort)[0];
        }
        if (!e.originalEvent.ctrlKey) {
          const orderedDataInput = orderBy(this.dataInput, this.sort);
          const indexOfLastClicked = orderedDataInput.indexOf(this.lastClickedItemForShiftSelection);
          const indexOfNext = orderedDataInput.indexOf(e.dataItem);
          if (this.selectionMode !== 'single') {
            if (indexOfNext > indexOfLastClicked) {
              this.kendoGridSelectedKeys = orderedDataInput.slice(indexOfLastClicked, indexOfNext + 1);
              this.selectionChanged.emit(this.kendoGridSelectedKeys);
            } else {
              this.kendoGridSelectedKeys = orderedDataInput.slice(indexOfNext, indexOfLastClicked + 1);
              this.selectionChanged.emit(this.kendoGridSelectedKeys);
            }
          } else {
            this.kendoGridSelectedKeys = [orderedDataInput[indexOfNext]];
            this.selectionChanged.emit(this.kendoGridSelectedKeys);
          }

        }
      }
      this.updateSelectedAllCheckboxState();
    }
    if (this.kendoGridSelectedKeys.length === 1 && this.isSpecialAttentionRowCallback && this.isSpecialAttentionRowCallback(e.dataItem)) {
      const closestTr = e.originalEvent.target.closest('tr');
      this.openSpecialAttentionComponent(closestTr);
    }

    if (this.kendoGridSelectedKeys.length === 1 && this.showContextButton(e.dataItem)) {
      const closestTr = e.originalEvent.target.closest('tr');
      this.onContextButtonToggle(closestTr, e.dataItem);
    }

    this.checkBoxesDisplayNone = this.kendoGridSelectedKeys.length <= 0;
  }

  /***
   * Reload data for kendo grid and set new page skip when scroll reaches take
   * @param event
   */
  public pageChange(event: PageChangeEvent): void {
    this.virtualScrollSettings.skip = event.skip;
    this.loadData();
  }

  ngOnDestroy(): void {
    if (this.gridConnectService.isRegisteredGrid(this)) {
      this.gridConnectService.unregisterGrid(this);
    }
    this.closeSpecialAttentionPopup();
    this.columnVisiblityChanged.complete();

    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  groupChange($event: Array<GroupDescriptor>) {
    this.group = $event;
    this.loadData();
  }

  selectionClicked($event) {
    this.closeSpecialAttentionPopup();
    const closestTr = $event.target.closest('tr');
    if (this.kendoGridSelectedKeys.length === 0) {
      timer(1).subscribe(value => {
        if (this.kendoGridSelectedKeys.length === 1
          && this.isSpecialAttentionRowCallback
          && this.isSpecialAttentionRowCallback(this.kendoGridSelectedKeys[0])) {
          this.openSpecialAttentionComponent(closestTr);
        }
      });
    }
  }

  columnOrderChanged($event: ColumnReorderEvent) {
    if ($event.newIndex === 0 || $event.newIndex > this.columns.filter(c => c.isVisible).length) {
      $event.preventDefault();
    } else {
      setTimeout(() => {
        persistOnReorder.bind(this)(this.grid.columns.toArray());
      });
    }
  }

  columnResize($event: Array<ColumnResizeArgs>) {
    persistOnResize.bind(this)($event);
  }

  onExportAnchorClicked($event: MouseEvent, exportAnchor: any) {
    // this.exportPopupOffset = {left: exportAnchor.getBoundingClientRect().left, top: exportAnchor.getBoundingClientRect().top}
    this.showExportPopup = !this.showExportPopup;
  }

  exportDone($event) {
    this.exportCustomOutput.next($event);
    this.showExportPopup = false;
  }

  exportExcel() {
    this.grid.saveAsExcel();
  }

  private updateSelectedAllCheckboxState() {
    const len = this.kendoGridSelectedKeys.length;
    if (len === 0) {
      this.selectAllState = 'unchecked';
    } else if (len > 0 && len < this.dataInput.length) {
      this.selectAllState = 'indeterminate';
    } else {
      this.selectAllState = 'checked';
    }
  }

  private isExportElementClicked(target: any): boolean {
    if (this.exportAnchor) {
      return this.exportAnchor.nativeElement.contains(target) ||
        (this.exportPopup ? this.exportPopup.nativeElement.contains(target) : false);
    }
    return false;
  }

  private openSpecialAttentionComponent(closestTr) {
    this.specialAttentionComponentRef = this.popupService.open({
      offset: {
        left: closestTr.getBoundingClientRect().left + 40,
        top: closestTr.getBoundingClientRect().bottom -
          ((closestTr.getBoundingClientRect().bottom - closestTr.getBoundingClientRect().top) / 3.2)
      },
      content: SpecialAttentionComponent,
      popupAlign: {
        horizontal: 'left',
        vertical: 'top'
      },
      collision: {
        horizontal: 'fit',
        vertical: 'fit'
      },
      popupClass: 'popup grid-content-popup'
    });
    const component = this.specialAttentionComponentRef.content as ComponentRef<SpecialAttentionComponent>;
    component.instance.templateRef = this.gridSpecialAttentionRef;
    component.instance.dataItem = this.kendoGridSelectedKeys[0];
    component.instance.closePopup = () => {
      this.closeSpecialAttentionPopup();
      this.changeDetectorRef.detectChanges();
    };
  }

  private closeSpecialAttentionPopup() {
    if (this.specialAttentionComponentRef) {
      this.specialAttentionComponentRef.close();
    }
  }

  private loadData(): void {
    const sort = this.additionalClientOrderByNonViewableColumn ?
      [...this.additionalClientOrderByNonViewableColumn, ...this.sort] : this.sort;
    if (this.dataInput != null && this.scrollable === 'virtual' && !this.isGroupable) {
      const data = orderBy(this.dataInput, sort)
        .slice(this.virtualScrollSettings.skip, this.virtualScrollSettings.skip + this.virtualScrollSettings.take);
      this.kendoGridInput = {
        data: data,
        total: this.dataInput.length
      };
    } else {
      if (this.dataInput && !this.isGroupable) {
        this.kendoGridInput = {
          data: orderBy(this.dataInput, sort),
          total: this.dataInput.length
        };
      } else if (this.dataInput && this.isGroupable) {
        this.kendoGridInput = process(this.dataInput, {sort: sort, group: this.group});
      }
    }
  }

  private updateOrderByFilter(sort: SortDescriptor[]) {
    const items: string[] = [];
    if (sort) {
      sort.forEach((item) => {
        let sortValue = item.field;
        if (item.dir) {
          sortValue += ' ' + item.dir;
        }
        items.push(sortValue);
      });
    }

    if (!this.virtualScrollSettings) {
      this.virtualScrollSettings = {};
    }

    this.virtualScrollSettings.orderBy = items;
  }

  public expandRow(dataItem: any) {
    this.expandedDetailKeys.push(this.expandDetailsBy(dataItem));
  }

  public collapseRow(dataItem: any) {
    this.expandedDetailKeys = this.expandedDetailKeys.filter(k => k !== this.expandDetailsBy(dataItem));
  }


  /**
   * if no scrollbar is necessary we want to remove the padding from the header as well
   */
  private checkForScrollbar() {
    if (!this.gridWrapper) {
      return;
    }
    const gridContent = this.gridWrapper.nativeElement.querySelector('.k-grid-content');
    if (gridContent) {
      const clientHeight = gridContent.clientHeight;
      const scrollHeight = gridContent.scrollHeight;
      const scrollbarVisibleBefore = this.isScrollbarVisible;
      this.isScrollbarVisible = scrollHeight > clientHeight;
      if (scrollbarVisibleBefore !== this.isScrollbarVisible) {
        this.changeDetectorRef.markForCheck();
      }
    }
  }

  scrollToBottom() {
    this.grid.scrollTo({row: this.dataInput?.length - 1});
  }
}
