<qpoint-toolbar [title]="'Forms'"></qpoint-toolbar>

<qpoint-content-container>
  <div class="card mb-4">
    <div class="card-header">Color Picker</div>

    <div class="card-body">
      <qpoint-color-picker-demo></qpoint-color-picker-demo>
    </div>
  </div>

  <qpoint-multi-view-date-range-picker-demo></qpoint-multi-view-date-range-picker-demo>

  <div class="card mb-4">
    <div class="card-header">Date inputs</div>

    <div class="card-body">
      <qpoint-datetime-picker-demo></qpoint-datetime-picker-demo>
    </div>
  </div>

  <div class="card mb-4">
    <div class="card-header">qpointTimerPicker Directives</div>

    <div class="card-body">
      <kendo-timepicker [formControl]="timePickerFc" [min]="dateMin" (blur)="onBlur()" qpointTimePickerUnit="custom text!" qpointTimePickerOverride qpointTimePickerAutocomplete></kendo-timepicker>
    </div>
  </div>

  <div class="card mb-4">
    <div class="card-header">Form validation</div>
    <div class="card-body">
      <form [formGroup]="form" (ngSubmit)="onSubmit()" (reset)="onReset()">
        <h6>Messages</h6>
        <p>Diese Strings können optional angegeben werden.<br>
          Wenn diese nicht vorhanden sind, wird immer nach <i>common.validation.VALIDATION_KEY</i> (z.B. <i>common.validation.required</i>) gesucht.<br>
          Wird dieser String nicht gefunden, wird immer <i>common.validation.invalid</i> verwendet.
        </p>
        <pre><code>{{ messages | json }}</code></pre>
        <qpoint-form-group forControl="name" [messages]="messages" [show]="submitted">
          <qpoint-form-label>Name</qpoint-form-label>
          <input class="form-control" type="text" formControlName="name">
        </qpoint-form-group>

        <p>Aktuelle Validatoren für <code>name</code>:
          <code *ngIf="validatorsUpdated">[Validators.required]</code>
          <code *ngIf="!validatorsUpdated">[]</code>
        </p>
        <button class="btn btn-primary" (click)="updateValidators()">Validatoren ändern</button>

        <pre>
        <code>
&lt;qpoint-form-group forControl="name" [messages]="messages" [show]="submitted"&gt;
  &lt;qpoint-form-label&gt;Name&lt;/qpoint-form-label&gt;
  &lt;input class="form-control" type="text" formControlName="name"&gt;
&lt;/qpoint-form-group&gt;
        </code>
      </pre>

        <qpoint-reactive-form-input-group [qpointFormControl]="email" [isSubmitted]="true">
          <qpoint-form-label>email</qpoint-form-label>
          <input class="form-control" formControlName="email">
        </qpoint-reactive-form-input-group>

        <pre>
        <code>
&lt;qpoint-form-group forControl="email" [messages]="messages" [show]="submitted"&gt;
  &lt;qpoint-form-label&gt;E-Mail&lt;/qpoint-form-label&gt;
  &lt;input class="form-control" type="text" formControlName="email"&gt;
&lt;/qpoint-form-group&gt;
        </code>
      </pre>

        <qpoint-form-group forControl="description" [show]="submitted">
          <qpoint-form-label>Description</qpoint-form-label>
          <textarea class="form-control" formControlName="description"></textarea>
          <div class="small">Mindestens 10 Zeichen</div>
        </qpoint-form-group>

        <pre>
        <code>
&lt;qpoint-form-group forControl="description" [show]="submitted"&gt;
  &lt;qpoint-form-label&gt;Description&lt;/qpoint-form-label&gt;
  &lt;textarea class="form-control" type="text" formControlName="description"&gt;&lt;/textarea&gt;
&lt;/qpoint-form-group&gt;
        </code>
      </pre>

        <div class="mt-4">
          <qpoint-button type="submit">Submit</qpoint-button>
          <qpoint-button type="reset">Reset</qpoint-button>
        </div>
      </form>

      <h2 class="mt-4">Styling</h2>

      <p>Minimales Styling Beispiel</p>

      <code><pre>
.has-error &#123;
  color: red;

  .invalid-feedback &#123;
    display: block;
  &#125;
&#125;
    </pre></code>
    </div>
  </div>


  <app-control-gallery-drop-down-list></app-control-gallery-drop-down-list>

  <app-control-gallery-async-drop-down></app-control-gallery-async-drop-down>

  <app-control-gallery-suggest-list></app-control-gallery-suggest-list>

  <app-control-gallery-multiple-emails-input></app-control-gallery-multiple-emails-input>

  <app-null-value-directive></app-null-value-directive>

  <div class="card mt-3">
    <div class="card-header">
      Kendo Numerictextbox
    </div>
    <div class="card-body">
      <div class="d-flex flex-column">
        default:
        <kendo-numerictextbox></kendo-numerictextbox>
        <hr/>
        readonly:
        <kendo-numerictextbox [readonly]="true"></kendo-numerictextbox>
        <hr/>
        disabled:
        <kendo-numerictextbox [disabled]="true"></kendo-numerictextbox>
      </div>
    </div>
  </div>

  <div class="card mt-3">
    <div class="card-body">
      <qpoint-delivery-note-transfer-demo></qpoint-delivery-note-transfer-demo>
    </div>
  </div>



</qpoint-content-container>
