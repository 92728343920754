<qpoint-toolbar [title]="'Accordion'" [showContextbarButton]="false">
</qpoint-toolbar>
<qpoint-content-container [fullHeight]="true">
    <div ngbAccordion class="d-flex flex-column">
        <div ngbAccordionItem [collapsed]="false">
            <div ngbAccordionHeader>
                <button ngbAccordionButton>Accordion 1</button>
            </div>
            <div ngbAccordionCollapse>
                <div ngbAccordionBody>
                    <ng-template>
                        <qpoint-table-row [label]="'Input Field'" [icon]="'info_outline'" [iconType]="'material'"
                            [toolTip]="'Das ist ein Test'">
                            <ng-container flexTableMain>
                                <input kendoTextBox placeholder="Input">
                            </ng-container>
                        </qpoint-table-row>

                        <qpoint-table-row [label]="'Input Field'" [icon]="'info_outline'" [iconType]="'material'"
                            [toolTip]="'Das ist ein Test'">
                            <ng-container flexTableMain>
                                <input kendoTextBox placeholder="Input">
                            </ng-container>
                        </qpoint-table-row>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
    <div ngbAccordion class="d-flex flex-column">
      <div ngbAccordionItem>
          <div ngbAccordionHeader>
              <button ngbAccordionButton>Accordion 2</button>
          </div>
          <div ngbAccordionCollapse>
              <div ngbAccordionBody>
                  <ng-template>
                      <qpoint-table-row [label]="'Input Field'" [icon]="'info_outline'" [iconType]="'material'"
                          [toolTip]="'Das ist ein Test'">
                          <ng-container flexTableMain>
                              <input kendoTextBox placeholder="Input">
                          </ng-container>
                      </qpoint-table-row>

                      <qpoint-table-row [label]="'Input Field'" [icon]="'info_outline'" [iconType]="'material'"
                          [toolTip]="'Das ist ein Test'">
                          <ng-container flexTableMain>
                              <input kendoTextBox placeholder="Input">
                          </ng-container>
                      </qpoint-table-row>
                  </ng-template>
              </div>
          </div>
      </div>
  </div>   
  <div ngbAccordion class="d-flex flex-column">
    <div ngbAccordionItem>
        <div ngbAccordionHeader>
            <button ngbAccordionButton>Accordion 3</button>
        </div>
        <div ngbAccordionCollapse>
            <div ngbAccordionBody>
                <ng-template>
                    <qpoint-table-row [label]="'Input Field'" [icon]="'info_outline'" [iconType]="'material'"
                        [toolTip]="'Das ist ein Test'">
                        <ng-container flexTableMain>
                            <input kendoTextBox placeholder="Input">
                        </ng-container>
                    </qpoint-table-row>

                    <qpoint-table-row [label]="'Input Field'" [icon]="'info_outline'" [iconType]="'material'"
                        [toolTip]="'Das ist ein Test'">
                        <ng-container flexTableMain>
                            <input kendoTextBox placeholder="Input">
                        </ng-container>
                    </qpoint-table-row>
                </ng-template>
            </div>
        </div>
    </div>
</div>     
</qpoint-content-container>