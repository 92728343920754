import { Component, ContentChildren, QueryList } from '@angular/core';
import { ColumnComponent } from '@progress/kendo-angular-excel-export';

@Component({
  selector: 'qpoint-excel-export-custom',
  template: ''
})
export class QPointExcelExportCustomComponent {

  @ContentChildren(ColumnComponent)
  public excelExportColumns: QueryList<ColumnComponent>;
}
