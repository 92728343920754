import { Injectable } from "@angular/core";
import { IQPointNumberFormatService } from "@qpoint/i18n";

@Injectable({
    providedIn: "root"
})
export class NumberFormatService implements IQPointNumberFormatService {
    constructor() {
    }

    private unitFormatMap = new Map<string, string>([
        ["t", ",0.0000"],
        ["m³", ",0.00"],
        ["m", ",0.0"]
    ]);

    formatUnit(unitSign: string): string {
        return this.unitFormatMap.get(unitSign) ?? ",0";
    }
}