<qpoint-toolbar [title]="'Selections'"></qpoint-toolbar>

<qpoint-content-container>
  <!-- Selections -->
  <div class="card">
    <div class="card-header">Auswahlboxen</div>
    <div class="card-body">
      <p>Radiobutton</p>
      <div class="row">
        <div class="col-md-3">
          <p>
            <input type="radio" id="r1" name="rg" class="k-radio" />
            <label class="k-radio-label" for="r1">Beton</label>
            <br />
            <input type="radio" id="r2" name="rg" class="k-radio" />
            <label class="k-radio-label" for="r2">Asphalt</label>
            <br />
          </p>
        </div>
        <div class="col-md-3">
          <p>
            <input type="radio" id="r3" name="rg" class="k-radio" />
            <label class="k-radio-label" for="r3">Bindemittel</label>
            <br />
            <input type="radio" id="r4" name="rg" class="k-radio" />
            <label class="k-radio-label" for="r4">Wasser</label>
            <br />
          </p>
        </div>
        <div class="col-md-3">
          <p>
            <input type="radio" id="r5" name="rg" class="k-radio" />
            <label class="k-radio-label" for="r5">Kies</label>
            <br />
          </p>
        </div>
        <div class="col-md-3">
          <p>
            <input type="radio" id="r6" name="rg" class="k-radio" />
            <label class="k-radio-label" for="r6">Rot</label>
            <br />
            <input type="radio" id="r7" name="rg" class="k-radio" />
            <label class="k-radio-label" for="r7">Blau</label>
            <br />
          </p>
        </div>
      </div>
      <hr>
      <p>Checkbox</p>
      <div kendoRippleContainer class="row">
        <div class="col-md-3">
          <p>
            <input type="checkbox" id="c1" class="k-checkbox" />
            <label class="k-checkbox-label" for="c1">Beton</label>
            <br/>
            <input type="checkbox" id="c2" class="k-checkbox" />
            <label class="k-checkbox-label" for="c2">Asphalt</label>
            <br/>
          </p>
        </div>
        <div class="col-md-3">
          <p>
            <input type="checkbox" id="c3" class="k-checkbox" />
            <label class="k-checkbox-label" for="c3">Bindemittel</label>
            <br/>
            <input type="checkbox" id="c4" class="k-checkbox" />
            <label class="k-checkbox-label" for="c4">Wasser</label>
            <br/>
          </p>
        </div>
        <div class="col-md-3">
          <p>
            <input type="checkbox" id="c5" class="k-checkbox" />
            <label class="k-checkbox-label" for="c5">Kies</label>
            <br/>
          </p>
        </div>
        <div class="col-md-3">
          <p>
            <input type="checkbox" id="c6" class="k-checkbox" />
            <label class="k-checkbox-label" for="c6">Rot</label>
            <br/>
            <input type="checkbox" id="c7" class="k-checkbox" />
            <label class="k-checkbox-label" for="c7">Blau</label>
            <br/>
          </p>
        </div>
      </div>
    </div>
  </div>
</qpoint-content-container>
