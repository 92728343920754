// Languages for Kendo UI
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule, importProvidersFrom } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbAlertModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { ExcelModule, PDFModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { IntlModule } from '@progress/kendo-angular-intl';
import '@progress/kendo-angular-intl/locales/ar/all'; // Arabic
import '@progress/kendo-angular-intl/locales/bg/all'; // Bulgarian
import '@progress/kendo-angular-intl/locales/cs/all'; // Czech
import '@progress/kendo-angular-intl/locales/da/all'; // Danish
import '@progress/kendo-angular-intl/locales/de-CH/all'; // German
import '@progress/kendo-angular-intl/locales/de/all'; // German
import '@progress/kendo-angular-intl/locales/el/all'; // Greek
import '@progress/kendo-angular-intl/locales/en/all'; // English
import '@progress/kendo-angular-intl/locales/es/all'; // Spanish
import '@progress/kendo-angular-intl/locales/et/all'; // Estonian
import '@progress/kendo-angular-intl/locales/fi/all'; // Finnish
import '@progress/kendo-angular-intl/locales/fr/all'; // French
import '@progress/kendo-angular-intl/locales/hi/all'; // Hindi
import '@progress/kendo-angular-intl/locales/hr/all'; // Croatian
import '@progress/kendo-angular-intl/locales/hu/all'; // Hungarian
import '@progress/kendo-angular-intl/locales/it/all'; // Italian
import '@progress/kendo-angular-intl/locales/ja/all'; // Japanese
import '@progress/kendo-angular-intl/locales/ko/all'; // Korean
import '@progress/kendo-angular-intl/locales/lt/all'; // Lithuanian
import '@progress/kendo-angular-intl/locales/lv/all'; // Latvian
import '@progress/kendo-angular-intl/locales/nb/all'; // Norsk
import '@progress/kendo-angular-intl/locales/nl/all'; // Dutch
import '@progress/kendo-angular-intl/locales/pl/all'; // Polish
import '@progress/kendo-angular-intl/locales/pt/all'; // Portuguese
import '@progress/kendo-angular-intl/locales/ro/all'; // Romanian
import '@progress/kendo-angular-intl/locales/ru/all'; // Russian
import '@progress/kendo-angular-intl/locales/sk/all'; // Slovak
import '@progress/kendo-angular-intl/locales/sl/all'; // Slovenian
import '@progress/kendo-angular-intl/locales/sq/all'; // Albanian
import '@progress/kendo-angular-intl/locales/sv/all'; // Svedish
import '@progress/kendo-angular-intl/locales/th/all'; // Thai
import '@progress/kendo-angular-intl/locales/tr/all'; // Turkish
import '@progress/kendo-angular-intl/locales/zh/all'; // Chinese
import { FloatingLabelModule } from '@progress/kendo-angular-label';
import { SortableModule } from '@progress/kendo-angular-sortable';
import { UploadModule } from '@progress/kendo-angular-upload';
import { QPointGridModule } from '@qpoint/layout';
import { DI_SETTINGS, provideAuthentication } from '@qpoint/utils';
import { MarkdownModule } from 'ngx-markdown';
import { DI_TIME_PICKER_FORMAT_PLACEHOLDER, QPointValidationFormDirective } from '../../projects/qpoint-forms/src/public_api';
import { TextMissingTranslationHandler } from '../../projects/qpoint-i18n/src/lib/services/text-missing-translation.handler';
import { DI_IMAGE_LOADER_SERVICE } from '../../projects/qpoint-layout/src/lib/services/image-loader.service';
import {
  QPointContextbarService,
  QPointMainComponent,
  QPointSidebarComponent,
  QPointUserInfoComponent,
  QPointWorkspaceSelectionComponent,
  SidebarSizes,
  provideUserInfoSidebar
} from '../../projects/qpoint-layout/src/public_api';
import { QPointLegalModule } from '../../projects/qpoint-legal/src/lib/qpoint-legal.module';
import { QPointMapsInputsModule } from '../../projects/qpoint-maps/map-inputs/src/map-input/maps-inputs.module';
import { QPointMapsModule } from '../../projects/qpoint-maps/src/lib/qpoint-maps.module';
import { DI_MAPS_API_KEY_SERVICE } from '../../projects/qpoint-maps/src/lib/services/google-api-key.service';
import { QPointHttpInterceptor } from '../../projects/qpoint-utils/src/public_api';
import { AppComponent } from './app.component';
import { Authentication } from './auth';
import { Settings } from './common';
import { AppRoutingModule } from './routes/app-routing.module';
import { HttpLocalizationLoader } from './services/http-localization-loader';
import { ImageLoaderService } from './services/image-loader.service';
import { LocalizationInfoService } from './services/localization-info.service';
import { LocalizationNamespaceService } from './services/localization-namespace.service';
import { MapsApiKeyService } from './services/maps-api-key.service';
import { QPointProfileService } from './services/qpoint-profile.service';
import { UserInfoSidebarProviderService } from './services/user-info-sidebar-provider.service';

import { fal } from '@fortawesome/pro-light-svg-icons';
import { faDatabase, far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { QPointLocalizationService, provideLocalizationInfo, provideQPointI18n, DI_UNIT_TRANSLATIONS_BASE_PATH, provideNumberFormatService, DefaultNumberFormatService } from '@qpoint/i18n';
import { DI_USER_PROFILE_SERVICE } from '@qpoint/profile';
import { DI_FONTAWESOME_ICONS, DI_FONTAWESOME_ICON_PACKS, QPointIconModule } from '../../projects/qpoint-icons/src/lib/q-point-icon.module';
import { QPointSidebarService } from '../../projects/qpoint-layout/src/lib/components';
import { MENU } from './app.menu';
import { TimePickerConfigService } from './services/time-picker-config.service';
import { NumberFormatService } from './services/number-format.service';

const FONTAWESOME_ICONS = [
  faDatabase
];

// kendo
export function createTranslateLoader(httpClient: HttpClient) {
  return new HttpLocalizationLoader(httpClient);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MarkdownModule,
    FormsModule,
    ReactiveFormsModule,
    PDFModule,
    ExcelModule,
    QPointIconModule,
    QPointMapsModule,
    QPointMapsInputsModule,
    QPointLegalModule,
    UploadModule,
    AppRoutingModule,
    ChartsModule,
    NgbModule,
    IntlModule,
    HttpClientModule,
    FloatingLabelModule,
    InputsModule,
    SortableModule,
    NgbAlertModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: TextMissingTranslationHandler,
        deps: [LocalizationNamespaceService]
      }
    }),
    CommonModule,
    QPointMainComponent,
    QPointSidebarComponent,
    QPointUserInfoComponent,
    QPointWorkspaceSelectionComponent,
    QPointValidationFormDirective
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      deps: [Injector],
      multi: true,
      useFactory: (injector: Injector) => {
        return () => {
          const localizationService = injector.get(QPointLocalizationService);
          const _localizationInfoService = injector.get(LocalizationInfoService);
          const _localizationNamespacesService = injector.get(LocalizationNamespaceService);
          const sidebarService = injector.get(QPointSidebarService);
          sidebarService.updateMenuByRoutes(<any>MENU);
          localizationService.setTranslation();
          return Promise.resolve();
        };
      }
    },
    provideAuthentication(Authentication),
    { provide: DI_SETTINGS, useClass: Settings },
    { provide: DI_USER_PROFILE_SERVICE, useClass: QPointProfileService },
    { provide: DI_IMAGE_LOADER_SERVICE, useClass: ImageLoaderService },
    provideLocalizationInfo(LocalizationInfoService),
    provideUserInfoSidebar(UserInfoSidebarProviderService),
    provideNumberFormatService(NumberFormatService),
    provideQPointI18n(),
    { provide: DI_MAPS_API_KEY_SERVICE, useClass: MapsApiKeyService },
    { provide: DI_FONTAWESOME_ICONS, useValue: FONTAWESOME_ICONS }, // just an example to demonstrate how to explicitly use one icon from an entire package
    { provide: DI_FONTAWESOME_ICON_PACKS, useValue: [far, fas, fal] },
    {
      provide: DI_TIME_PICKER_FORMAT_PLACEHOLDER,
      useClass: TimePickerConfigService
    },
    {
      provide: DI_UNIT_TRANSLATIONS_BASE_PATH,
      useValue: 'masterData.unit.sign.'
    },
    SidebarSizes,
    QPointContextbarService,
    importProvidersFrom(QPointGridModule),
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}
