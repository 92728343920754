<ul ngbNav #tabs="ngbNav" class="nav-tabs" [(activeId)]="activeId"  (navChange)="tabChange($event)">
  <li [ngbNavItem]="infoTab" title="{{ infoTitle }}">
    <a ngbNavLink>{{infoTitle}}</a>
    <ng-template ngbNavContent>
      <img
        src="{{ productImagePath }}"
        alt="{{ productName }}"
        class="productImage"
      />
      <markdown [data]="infoText"></markdown>
    </ng-template>
  </li>
  <li [ngbNavItem]="imprintTab" >
    <a ngbNavLink>{{imprintTitle}}</a>
    <ng-template ngbNavContent>
      <markdown [data]="imprintText"></markdown>
    </ng-template>
  </li>
  <li [ngbNavItem]="termsOfUseTab" title="{{ termsOfUseTitle }}">
    <a ngbNavLink>{{termsOfUseTitle}}</a>
    <ng-template ngbNavContent>
      <markdown [data]="termsOfUseText"></markdown>
    </ng-template>
  </li>
  <li [ngbNavItem]="privacyTab" title="{{ privacyTitle }}">
    <a ngbNavLink>{{privacyTitle}}</a>
    <ng-template ngbNavContent>
      <markdown [data]="privacyText"></markdown>
    </ng-template>
  </li>
</ul>
<div [ngbNavOutlet]="tabs"></div>
