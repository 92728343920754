import { NgModule } from '@angular/core';
import { NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { MarkdownModule } from 'ngx-markdown';

import { QPointLegalInfoComponent } from './legal-info/legal-info.component';

@NgModule({
  declarations: [
    QPointLegalInfoComponent
  ],
  imports: [
    NgbModule,
    NgbNavModule,
    MarkdownModule.forRoot()
  ],
  exports: [
    QPointLegalInfoComponent
  ]
})
export class QPointLegalModule { }
