<qpoint-toolbar title="Modal"></qpoint-toolbar>
<qpoint-content-container>
  <div class="card">
    <div class="card-header">Modal Service</div>
    <div class="card-body">
      <p>Use Class
      "QPointModalService"
      for managing modals
      </p>
      <p>Use following Components for header, body & footer:</p>
      <ul>
        <li>Header: <pre>qpoint-modal-header</pre></li>
        <li>Body: <pre>qpoint-modal-body</pre></li>
        <li>Footer: <pre>qpoint-modal-footer</pre></li>
      </ul>
      <qpoint-button (buttonClicked)="openModal()">open demo modal</qpoint-button>
    </div>
  </div>
</qpoint-content-container>
