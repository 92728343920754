<div class="card mt-3">
  <div class="card-header">Multiple E-Mail Input</div>
  <div class="card-body">
    <form class="form-horizontal" [formGroup]="form">
      <p>Input type=text without [qpointInputNullValue] directive</p>
      <input type="text" formControlName="inputWithoutDirective"/>
      value: {{form.value.inputWithoutDirective | json}}
      <hr/>
      <p>Input type=text without [qpointInputNullValue] directive</p>
      <input type="text" formControlName="inputWithDirective" qpointInputNullValue/>
      value: {{form.value.inputWithDirective | json}}
    </form>
  </div>
</div>
