<li class="qpoint-sidebar-list-item with-sub-menu workspace-selection-dropdown"
    [ngClass]="{'qpoint-sidebar-item-expanded': isSubmenuExpand, 'qpoint-sidebar-collapsed': service.isMenuCollapsed}">
  <a *ngIf="selectedWorkspace" class="qpoint-sidebar-list-link" (click)="toggleSubmenu()">
    <img *ngIf="selectedWorkspace.imageSrc" src="{{ selectedWorkspace.imageSrc }}" alt="{{ selectedWorkspace.name }}"/>
    <qpoint-icon *ngIf="!selectedWorkspace.imageSrc" icon="building"></qpoint-icon>
    <qpoint-restricted-lines-text *ngIf="!service.isMenuCollapsed" class="selected-workspace-name"
                                  [text]="selectedWorkspace.name">
    </qpoint-restricted-lines-text>
    <qpoint-icon class="menu-item-toggler" [icon]="isSubmenuExpand ? 'angle-up' : 'angle-down'"></qpoint-icon>
  </a>
  <a *ngIf="!selectedWorkspace && showAddWorkspaceButton" class="qpoint-sidebar-list-link"
     (click)="onAddNewWorkspaceClick()">
    <qpoint-icon icon="plus-circle" style="margin-left: 7px;"></qpoint-icon>
    <span *ngIf="!service.isMenuCollapsed">{{ 'workspace.addWorkspace' | translate }}</span>
  </a>
  <ul *ngIf="selectedWorkspace" class="qpoint-sidebar-sublist" [ngClass]="{'transition-submenu': isSubmenuExpand}">
    <li *ngIf="showWorkspaceSettings" (click)="onSelectedWorkspaceSettingsClick()"
        [class.selected]="isWorkspaceSettingsSelected" class="qpoint-sidebar-sublist-item">
      <a class="qpoint-sidebar-list-link">
        <qpoint-icon class="workspaceSettingsIcon" icon="cog"></qpoint-icon>
        <span>{{ 'workspace.workspaceSettings' | translate }}</span>
      </a>
      <hr>
    </li>
    <li *ngFor="let workspace of workspaces" (click)="onWorkspaceClick(workspace)" class="qpoint-sidebar-sublist-item"
        [ngClass]="{'workspaceChangeDisabled': workspaceChangeDisabled}">
      <a class="qpoint-sidebar-list-link">
        <img *ngIf="workspace.imageSrc" src="{{ workspace.imageSrc }}" alt="{{ workspace.name }}"/>
        <qpoint-icon *ngIf="!workspace.imageSrc" icon="building"></qpoint-icon>
        <qpoint-restricted-lines-text [text]="workspace.name"></qpoint-restricted-lines-text>
      </a>
    </li>
    <li *ngIf="showAddWorkspaceButton" class="qpoint-sidebar-sublist-item" (click)="onAddNewWorkspaceClick()"
        [ngClass]="{'workspaceChangeDisabled': workspaceChangeDisabled}">
      <a class="qpoint-sidebar-list-link">
        <qpoint-icon icon="plus" class="addWorkspaceIcon"></qpoint-icon>
        <span>{{ 'workspace.addWorkspace' | translate }}</span>
      </a>
    </li>
  </ul>
</li>
