<qpoint-toolbar [title]="'Heading'"></qpoint-toolbar>

<qpoint-content-container>
  <!-- Headings -->
  <div class="card">
    <div class="card-header">Headings</div>
    <div class="card-body">y<p>Titel &lt;h1&gt; mit Untertitel (optional)</p>
      <h1>Berichte
        <small>
          <small>Berichte ausführen und anzeigen</small>
        </small>
      </h1>
      <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore
        magna aliquyam...</p>
      <hr>
      <p>Titel &lt;h2&gt;</p>
      <h2>Einstellungen</h2>
      <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore
        magna aliquyam...</p>
      <hr>
      <p>Titel &lt;h3&gt;</p>
      <h3>Erweitert</h3>
      <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore
        magna aliquyam...</p>
      <hr>
      <p>Titel &lt;h4&gt;</p>
      <h4>Info</h4>
      <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore
        magna aliquyam...</p>
    </div>
  </div>
</qpoint-content-container>
