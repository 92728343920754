<button [type]="type" [ngClass]="classes" (click)="onClick($event)" [disabled]="isDisabled" [attr.title]="title">
  <div class="spinner-wrapper" *ngIf="loading">
    <div>
      <svg class="spinner" xmlns="http://www.w3.org/2000/svg">
        <circle class="path" fill="none" stroke-width="2px" stroke-linecap="round" cx="12" cy="12" r="10"></circle>
      </svg>
    </div>
  </div>
  <qpoint-icon [icon]="icon"></qpoint-icon>
</button>
