import { AfterViewInit, Directive, HostListener, OnDestroy, Optional, Self } from '@angular/core';
import { QPointFormValidationService } from '../services/qpoint-form-validation.service';
import { FormGroupDirective } from '@angular/forms';

@Directive({
  selector: '[qpointValidationForm], form[formGroup]',
  providers: [QPointFormValidationService],
  standalone: true
})
export class QPointValidationFormDirective implements OnDestroy, AfterViewInit {
  constructor(private formValidationService: QPointFormValidationService, @Optional() @Self() private formGroup: FormGroupDirective) {
    this.formValidationService.setFormGroup(this);
  }

  ngAfterViewInit() {
    if (this.formGroup && this.formGroup.form) {
      const form = this.formGroup.form;
      const originalResetFormFunc = form.reset;

      form.reset = () => {
        originalResetFormFunc.apply(form, arguments);
        this.formValidationService.onFormReset(this, null);
      };
    }
  }

  @HostListener('submit', ['$event'])
  onSubmit($event: Event) {
    this.formValidationService.onFormSubmit(this, $event);
  }

  @HostListener('reset', ['$event'])
  onReset($event: Event) {
    this.formValidationService.onFormReset(this, $event);
  }

  ngOnDestroy(): void {
    this.formValidationService.removeFormGroup(this);
  }
}
