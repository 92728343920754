import { inject, InjectionToken, Provider, Type } from '@angular/core';
import { Observable } from 'rxjs';

import { OnboardingStatus } from '../types/onboarding-status.enum';

export interface QPointDeliveryNoteTransferTexts {
  title: string;
  text: string;
  transferEnabledToggle: string;
  transferMobilePhoneInput: string;
  resendWelcomeSms: string;
  statusNotFound: string;
  statusOnboarding: string;
  statusRegistered: string;
  startOnboardingErrorText: string;
  startOnboardingErrorTitle: string;
  startOnboardingErrorConflictText: string;
  startOnboardingErrorConflictTitle: string;
}

export interface QPointDeliveryNoteTransferService {
  texts?: QPointDeliveryNoteTransferTexts;

  startOnboarding(vehicleId: string, deliveryNoteTransferMobilePhone): Observable<void>;

  getOnboardingStatus(deliveryNoteTransferMobilePhone: string): Observable<{ status: OnboardingStatus }>;

  setDeliveryNoteTransferEnabled(vehicleId: string, deliveryNoteTransferEnabled: boolean): Observable<void>;

  setDeliveryNoteTransferMobilePhone(vehicleId: string, deliveryNoteTransferMobilePhone: string): Observable<void>;

  handleVehicleWithTheSameTransferMobilePhone(vehicleId: string, deliveryNoteTransferMobilePhone: string): Observable<boolean | null>;
}

export const DI_DELIVERY_NOTE_TRANSFER_SERVICE = new InjectionToken<QPointDeliveryNoteTransferService>('QPointDeliveryNoteTransferService');

export function provideDeliveryNoteTransferService(useClass: Type<QPointDeliveryNoteTransferService>): Provider {
  return {
    provide: DI_DELIVERY_NOTE_TRANSFER_SERVICE,
    useClass
  }
}

export function injectDeliveryNoteTransferService(): QPointDeliveryNoteTransferService {
  return inject(DI_DELIVERY_NOTE_TRANSFER_SERVICE);
}
