import { Component } from '@angular/core';
import { QPointToolbarComponent, QPointContentContainerComponent } from '@qpoint/layout';

@Component({
    selector: 'app-control-gallery-selection',
    templateUrl: './selection.component.html',
    styleUrls: ['./selection.component.scss'],
    standalone: true,
    imports: [QPointToolbarComponent, QPointContentContainerComponent]
})
export class ControlGallerySelectionComponent {

  constructor() { }
}
