import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { interval, merge, Subject, Subscription, timer } from 'rxjs';
import { debounceTime, delay, takeUntil } from 'rxjs/operators';

import { SidebarSizes } from './sidebar';
import { QPointSidebarService } from './sidebar.service/sidebar.service';
import { QPointIconModule } from '@qpoint/icons';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { QPointUserInfoComponent } from '../user-info';
import { QPointMenuComponent } from './menu/menu.component';

@Component({
  selector: 'qpoint-sidebar',
  templateUrl: './sidebar.html',
  styleUrls: ['./sidebar.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('showIndicator', [
      // ...
      state('show', style({
        height: '60px',
        opacity: 1,
      })),
      state('hide', style({
        height: '0px',
      })),
      transition('show => hide', [
        animate('0.5s')
      ])
    ]),
  ],
  imports: [
    QPointIconModule,
    CommonModule,
    TranslateModule,
    QPointUserInfoComponent,
    QPointMenuComponent
  ],
  standalone: true
})
export class QPointSidebarComponent implements OnInit, OnDestroy, AfterViewInit {
  public isMenuCollapsed = false;
  public isMenuShouldCollapsed = false;
  public showScrollDownIndicator: boolean;
  public showScrollUpIndicator: boolean;
  @Input()
  public imgPath: string;
  @Input()
  public imgPathSmall: string;
  @Input()
  public showUserInfo: boolean;
  @Input()
  public myAccountRoutePath: string;
  @Output()
  public logout: EventEmitter<void> = new EventEmitter();
  @Input()
  public showMyAccount = false;
  @Input()
  public showLegal = false;
  @Output()
  public myAccount: EventEmitter<void> = new EventEmitter();
  @Output()
  public legal: EventEmitter<void> = new EventEmitter();
  private scrollMenuUpSubscription: Subscription;
  private scrollMenuDownSubscription: Subscription;
  private ngUnsubscribe = new Subject<void>();

  constructor(private elementRef: ElementRef,
              private sidebarService: QPointSidebarService,
              private sidebarSize: SidebarSizes) {
    this.sidebarService.menuCollapsedChanged.subscribe(isCollapsed => this.isMenuCollapsed = isCollapsed);
  }

  public ngOnInit(): void {
    if (this.isMediumScreenWidth()) {
      this.menuCollapse();
    }

    const toggleScrollIndicatorObservable = merge(this.sidebarService.sidebarHeightChanged, this.sidebarService.menuItems);
    toggleScrollIndicatorObservable.pipe(takeUntil(this.ngUnsubscribe), debounceTime(200), delay(220)).subscribe(_ => {
      const sideMenu = document.getElementById('qpoint-menu-aside');
      if (sideMenu) {
        this.toggleScrollIndicators(sideMenu);
      }
    });

    this.sidebarService.menuActiveItemChanged.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(menuItem => {
      if ((this.isSmallScreenWidth() || this.isMediumScreenWidth()) && !this.isMenuCollapsed) {
        this.menuCollapse();
      }
    });
  }

  public onMouseOver() {
    if (this.isLargeScreenWidth() && this.isMenuCollapsed) {
      this.sidebarService.temporaryExpand();
      this.menuExpand();
    } else if (this.isMediumScreenWidth() && this.isMenuCollapsed) {
      this.menuExpand();
    }
  }

  public onMouseLeave() {
    if (this.isLargeScreenWidth() && this.sidebarService.isMenuTemporaryExpanded) {
      this.sidebarService.endTemporaryExpand();
      this.menuCollapse();
    }
    if (this.isMediumScreenWidth()) {
      this.menuCollapse();
    }
  }

  public onLogout(): void {
    this.logout.emit(null);
  }

  public onMyAccount(): void {
    this.myAccount.emit(null);
  }

  public onLegalClick(): void {
    this.legal.emit();
  }

  public ngAfterViewInit(): void {
    const element = document.getElementById('qpoint-menu-aside');
    timer(10).subscribe(value => {
      this.toggleScrollIndicators(element);
    });

    element.addEventListener('scroll', evt => {
      const targetEvent = evt as any;
      this.toggleScrollIndicators(targetEvent.target);
    });
  }

  @HostListener('window:resize')
  public onWindowResize(): void {
    const isMenuShouldCollapsed = this.isMediumScreenWidth();

    if (this.isMenuShouldCollapsed !== isMenuShouldCollapsed) {
      this.menuCollapseStateChange(isMenuShouldCollapsed);
    }
    this.isMenuShouldCollapsed = isMenuShouldCollapsed;
  }

  public menuExpand(): void {
    this.menuCollapseStateChange(false);
  }

  public menuCollapse(): void {
    this.menuCollapseStateChange(true);
  }

  public menuCollapseStateChange(isCollapsed: boolean): void {
    this.isMenuCollapsed = isCollapsed;
    this.sidebarService.menuCollapsedChanged.next(this.isMenuCollapsed);
  }

  public scrollUpMenu($event: MouseEvent) {
    if ($event.type === 'mouseenter') {
      const element = document.getElementById('qpoint-menu-aside');
      this.scrollMenuUpSubscription = interval(5).subscribe(value => {
        element.scrollTo(0, element.scrollTop - 10);
        if (element.scrollTop <= 0) {
          this.scrollMenuUpSubscription.unsubscribe();
        }
      });
    } else {
      if (this.scrollMenuUpSubscription) {
        this.scrollMenuUpSubscription.unsubscribe();
      }
    }
  }

  public scrollDownMenu($event: MouseEvent) {
    if ($event.type === 'mouseenter') {
      const element = document.getElementById('qpoint-menu-aside');
      this.scrollMenuDownSubscription = interval(5).subscribe(value => {
        element.scrollTo(0, element.scrollTop + 10);
        if (this.isEndOfScroll(element)) {
          this.scrollMenuDownSubscription.unsubscribe();
        }
      });
    } else {
      if (this.scrollMenuDownSubscription) {
        this.scrollMenuDownSubscription.unsubscribe();
      }
    }
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private isLargeScreenWidth(): boolean {
    return window.innerWidth > this.sidebarSize.sidebarCollapseWidth;
  }

  private isMediumScreenWidth(): boolean {
    return (window.innerWidth <= this.sidebarSize.sidebarCollapseWidth) && !this.isSmallScreenWidth();
  }

  private isSmallScreenWidth(): boolean {
    return window.innerWidth <= this.sidebarSize.sidebarHideWidth;
  }

  private toggleScrollIndicators(element: Element) {
    const isEndOfScroll = this.isEndOfScroll(element);
    this.showScrollDownIndicator = !isEndOfScroll;
    this.showScrollUpIndicator = !(element.scrollTop === 0);
  }

  private isEndOfScroll(element: Element) {
    return (element.scrollTop + element.clientHeight) >= (element.scrollHeight - 1);
  }
}
