<div class="card mt-3">
  <div class="card-header">
    Multi View Calendar Date Range Picker
  </div>
  <div class="card-body">
    <div class="d-flex flex-row">
      <qpoint-button (buttonClicked)="disabled = !disabled">toggle disabled</qpoint-button>
      <qpoint-button (buttonClicked)="showNavigationArrows = !showNavigationArrows">toggle navigation arrows
      </qpoint-button>
      <qpoint-button (buttonClicked)="patchToday()">Patch Today</qpoint-button>
      <qpoint-button (buttonClicked)="patchRange()">Patch Range</qpoint-button>
      <qpoint-button (buttonClicked)="patchIncompleteRange()">Patch Incomplete Range</qpoint-button>
      <qpoint-button (buttonClicked)="toggleMode()">toggle mode to {{mode === 'single' ? 'range' : 'single'}}</qpoint-button>
      <qpoint-button (buttonClicked)="toggleSelectionButtons()">toggle selection buttons</qpoint-button>
    </div>
    FormControl Test: {{multiViewDateRangePickerFormControl?.valueChanges | async | json}}<br/>
    <qpoint-multi-view-date-range-picker [formControl]="multiViewDateRangePickerFormControl"
                                         [translations]="translations" [disabled]="disabled"
                                         [showNavigationArrows]="showNavigationArrows" [mode]="mode" [showModeSelection]="showSelectionButtons" [disabledDates]="disabledDates"></qpoint-multi-view-date-range-picker>
  </div>
</div>
