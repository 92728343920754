<qpoint-toolbar [title]="'Inputs'"></qpoint-toolbar>

<qpoint-content-container>
  <!-- Input -->
  <div class="card">
    <div class="card-header">Text Eingabe</div>
    <div class="card-body">
      <!-- Textfeld -->
      <p>Textfeld</p>
      <div class="row">
        <div class="col-md-4">
          <kendo-floatinglabel text="Lorem ipsum">
            <input kendoTextBox />
          </kendo-floatinglabel>
        </div>
        <div class="col-md-4">
          <kendo-floatinglabel text="Lorem ipsum">
            <input kendoTextBox />
          </kendo-floatinglabel>
        </div>
        <div class="col-md-4">
          <kendo-floatinglabel text="Lorem ipsum">
            <input kendoTextBox />
          </kendo-floatinglabel>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <kendo-floatinglabel text="Lorem ipsum">
            <input kendoTextBox />
          </kendo-floatinglabel>
        </div>
        <div class="col-md-4">
          <kendo-floatinglabel text="Lorem ipsum">
            <input kendoTextBox />
          </kendo-floatinglabel>
        </div>
        <div class="col-md-4">
          <kendo-floatinglabel text="Lorem ipsum">
            <input kendoTextBox />
          </kendo-floatinglabel>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <kendo-floatinglabel text="Lorem ipsum">
            <input kendoTextBox />
          </kendo-floatinglabel>
        </div>
        <div class="col-md-4">
          <kendo-floatinglabel text="Lorem ipsum">
            <input kendoTextBox />
          </kendo-floatinglabel>
        </div>
        <div class="col-md-4">
          <kendo-floatinglabel text="Lorem ipsum">
            <input kendoTextBox />
          </kendo-floatinglabel>
        </div>
      </div>
      <hr>
      <!-- Textarea -->
      <p>Textarea</p>
      <div class="row">
        <div class="col-md-4">
          <textarea class="k-textarea">Lorem Ipsum is simply dummy text.</textarea>
        </div>
        <div class="col-md-4">
          <textarea class="k-textarea">Lorem Ipsum is simply dummy text.</textarea>
        </div>
        <div class="col-md-4">
          <textarea class="k-textarea">Lorem Ipsum is simply dummy text.</textarea>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <textarea class="k-textarea">Lorem Ipsum is simply dummy text.</textarea>
        </div>
        <div class="col-md-4">
          <textarea class="k-textarea">Lorem Ipsum is simply dummy text.</textarea>
        </div>
        <div class="col-md-4">
          <textarea class="k-textarea">Lorem Ipsum is simply dummy text.</textarea>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <textarea class="k-textarea">Lorem Ipsum is simply dummy text.</textarea>
        </div>
        <div class="col-md-4">
          <textarea class="k-textarea">Lorem Ipsum is simply dummy text.</textarea>
        </div>
        <div class="col-md-4">
          <textarea class="k-textarea">Lorem Ipsum is simply dummy text.</textarea>
        </div>
      </div>
      <hr>
      <!-- Texteditor -->
      <p>Texteditor</p>
      <blockquote class="blockquote">Existiert noch nicht in Kendo UI for Angular.</blockquote>
      <hr>
      <!-- ACE -->
      <p>JSON Editor</p>
      <blockquote class="blockquote">Existiert noch nicht in Kendo UI for Angular.</blockquote>
    </div>
  </div>

</qpoint-content-container>
