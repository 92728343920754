<qpoint-toolbar [title]="'Progress bars'"></qpoint-toolbar>

<qpoint-content-container>
  <!-- Progress Bar -->
  <div class="card">
    <div class="card-header">Progress Bar</div>
    <div class="card-body">
      <p>Progress Bar</p>
      <blockquote class="blockquote">Existiert noch nicht in Kendo UI for Angular.</blockquote>
    </div>
  </div>
</qpoint-content-container>
