import { AfterContentInit, Component, ContentChildren, EventEmitter, Input, OnInit, Output, QueryList } from '@angular/core';

@Component({
  selector: 'qpoint-grid-context-menu-item',
  template: '  {{ label }}',
})
export class QPointGridContextMenuItemComponent implements OnInit, AfterContentInit {

  @ContentChildren(QPointGridContextMenuItemComponent, { descendants: false })
  public subMenuItems: QueryList<QPointGridContextMenuItemComponent>;

  public subItemArray: QPointGridContextMenuItemComponent[];

  @Input()
  public label: string;

  @Input()
  public icon: string;

  @Input()
  public showFunction: Function;

  @Input()
  public iconClassFunction: Function;

  @Output()
  click = new EventEmitter<any>();

  ngAfterContentInit(): void {
    this.subItemArray = this.subMenuItems.toArray();
    this.subMenuItems.changes.subscribe(value => {
      if (value) {
        this.subItemArray = value.toArray();
      } else {
        this.subItemArray = [];
      }
    });
  }

  showItem(dataItem) {
    if (this.showFunction) {
      return this.showFunction(dataItem);
    } else {
      return true;
    }
  }

  iconClass(dataItem) {
    if (this.iconClassFunction) {
      return this.iconClassFunction(dataItem);
    }
    return this.icon;
  }

  ngOnInit(): void {
  }
}
