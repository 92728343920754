<qpoint-suggestlist
  textField="identifier" 
  valueField="identifier"
  valuePrimitive="false"
  [dropDownVariant]="dropDownVariant" 
  [required]="required" 
  [value]="_value" 
  [data]="placeSuggestions"
  [disabled]="disabled"
  [itemDisabled]="itemDisabled"
  [isLoading]="isLoading()"
  [placeholder]="placeholder"
  [readonly]="readonly"
  [filterable]="true"
  (filterChange)="handleFilter($event)" 
  (touch)="onTouched()" 
  (valueChange)="handleOnChange($event)" 
  (close)="onClose($event)"
  (focusout)="onFocusOut()"
></qpoint-suggestlist>