import { CommonModule } from '@angular/common';
import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  DestroyRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { SplitButtonModule } from '@progress/kendo-angular-buttons';
import { PopupSettings } from '@progress/kendo-angular-popup';
import { QPointIconModule } from '@qpoint/icons';

import { ButtonSize, ButtonVariant } from '../button/button.component';
import { QPointSplitButtonActionComponent } from './split-button-action/split-button-action.component';
import { QPointLoadingSpinnerComponent } from '@qpoint/loading';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'qpoint-split-button',
  templateUrl: './split-button.component.html',
  styleUrls: ['./split-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    SplitButtonModule,
    CommonModule,
    QPointIconModule,
    QPointLoadingSpinnerComponent
  ],
  standalone: true
})
export class QPointSplitButtonComponent
  implements AfterContentInit, AfterViewInit, OnChanges {
  @Output() buttonClicked = new EventEmitter<Event>();

  @ViewChild('kendoSplitButton') kendoSplitButton;
  @ContentChildren(QPointSplitButtonActionComponent) splitButtonActions: QueryList<QPointSplitButtonActionComponent>;
  @Input() set disabled(disabled: boolean) {
    this.mainButtonDisabled = disabled;
    this.arrowButtonDisabled = disabled;
    this.updateButton("." + this.mainButtonClass, disabled);
    this.updateButton("." + this.arrowButtonClass, disabled);
  }

  @Input() mainButtonDisabled = false;
  @Input() arrowButtonDisabled = false;

  @Input() active = false;
  @Input() loading = false;
  @Input() block = false;
  @Input() variant: ButtonVariant = ButtonVariant.secondary;
  @Input() size: ButtonSize = ButtonSize.default;
  @Input() icon: string = null;
  @Input() iconType: string = null;
  @Input() btnClass: string;
  @Input() text: string;
  @Input() popupSettings: PopupSettings;


  @ViewChild(".button", { static: false }) buttons;
  public popUpItems: any;

  public mainButtonClass = "mainButton-" + Math.floor(Math.random() * 1000000);
  public arrowButtonClass = "arrowButton-" + Math.floor(Math.random() * 1000000);

  private destroyRef = inject(DestroyRef);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.mainButtonDisabled) {
      this.updateButton("." + this.mainButtonClass, this.mainButtonDisabled);
    }

    if (changes.arrowButtonDisabled) {
      this.updateButton("." + this.arrowButtonClass, this.arrowButtonDisabled);
    }
  }

  ngAfterContentInit() {
    this.setPopUpItems();
    this.splitButtonActions.changes.subscribe(() => this.setPopUpItems());

    this.splitButtonActions.map((action: QPointSplitButtonActionComponent) => {
      /* property changes are not detected by 'this.splitButtonActions.changes.subscribe()' */
      action.textChanged
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => this.setPopUpItems());
    });
  }

  ngAfterViewInit() {
    this.kendoSplitButton.wrapper.children[0].className = `btn qpoint-button btn-${this.variant} ${this.mainButtonClass}`;
    this.kendoSplitButton.wrapper.children[1].className = `btn qpoint-button btn-${this.variant} ${this.arrowButtonClass}`;
    this.updateButton("." + this.arrowButtonClass, this.arrowButtonDisabled);
    this.updateButton("." + this.mainButtonClass, this.mainButtonDisabled);
  }

  private updateButton(selector: string, disabled: boolean) {
    if (disabled) {
      this.disableButton(selector);
    } else {
      this.enableButton(selector);
    }
  }

  private disableButton(selector: string) {
    const button = document.querySelector(selector) as HTMLButtonElement;
    if (button) {
      button.setAttribute("disabled", "");
      button.classList.add("k-state-disabled");
      button.classList.add("disabled");
    }
  }

  private enableButton(selector: string) {
    const button = document.querySelector(selector) as HTMLButtonElement;
    if (button) {
      button.removeAttribute("disabled");
      button.classList.remove("k-state-disabled");
      button.classList.remove("disabled");
    }
  }

  public onClick($event) {
    if (!this.isDisabled) {
      this.buttonClicked.emit($event);
    } else {
      $event.preventDefault();
      $event.stopPropagation();
    }
  }

  public onClose($event) {
    window.requestAnimationFrame(() =>
      this.kendoSplitButton.button.nativeElement.blur()
    );
  }

  public onOpen($event) {
    window.requestAnimationFrame(() => {
      this.kendoSplitButton.button.nativeElement.blur();
    });
  }

  private setPopUpItems() {
    this.popUpItems = this.splitButtonActions.map(item => {
      return {
        text: item.text,
        disabled: item.disabled,
        click: () => item.actionClick.emit()
      };
    });

    // if no items are given, add a default action with the same behaviour as the default button
    if (this.popUpItems.length === 0) {
      this.popUpItems.push({
        text: this.text,
        disabled: false,
        click: () => this.buttonClicked.emit()
      });
    }
  }

  get classes(): string[] {
    const classes = ['btn', 'qpoint-button'];

    if (this.btnClass) {
      classes.push(this.btnClass);
    }

    switch (this.size) {
      case ButtonSize.lg:
        classes.push('btn-lg');
        break;
      case ButtonSize.sm:
        classes.push('btn-sm');
        break;
    }

    if (this.block) {
      classes.push('btn-block');
    }

    if (this.loading) {
      classes.push('btn-loading', 'disabled');
    }

    if (this.disabled) {
      classes.push('disabled');
    }

    if (this.active) {
      classes.push('active');
    }

    return classes;
  }

  get isDisabled() {
    return this.disabled || this.loading;
  }
}
