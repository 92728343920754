export enum InvitationStateIcon {
  IdleIcon = 'paper-plane',
  SuccessIcon = 'check',
  ErrorIcon = 'triangle-exclamation'
}

export enum InvitationStateIconColor {
  IdleIconColor = 'primary',
  SuccessIconColor = 'success',
  ErrorIconColor = 'danger'
}
