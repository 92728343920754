import { Directive, ElementRef, Input } from '@angular/core';
import { MapMarkerComponent } from './map-marker.component';

@Directive({
  selector: '[qpointMarkerLabelInternal]'
})
export class MarkerLabelInternalDirective {

  @Input()
  markerRef: MapMarkerComponent;
  public elementRef: ElementRef;
  constructor(private elm: ElementRef) {
    this.elementRef = elm;
  }

}
