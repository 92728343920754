<li class="qpoint-sidebar-list-item with-sub-menu user-info-dropdown" [ngClass]="{'qpoint-sidebar-item-expanded': isSubmenuExpand}">
  <a class="qpoint-sidebar-list-link" id="user-info-toggle-submenu" (click)="toggleSubmenu()">
    <img *ngIf="pictureUrl && pictureUrl !== ''" [src]="pictureUrl | authorizedImage | async" alt=""/> <span *ngIf="!service.isMenuCollapsed">{{ userName }}</span>
    <qpoint-icon class="menu-item-toggler" icon="{{ isSubmenuExpand ? 'angle-up' : 'angle-down'}}"></qpoint-icon>
  </a>
  <ul class="qpoint-sidebar-sublist" [ngClass]="{'transition-submenu': isSubmenuExpand}">
    <li *ngFor="let dataItem of entries" (click)="entryClicked(dataItem)" [class.selected]="dataItem.selected" class="qpoint-sidebar-sublist-item">
      <a class="qpoint-sidebar-list-link" id="user-info-{{ dataItem.idName }}">
        <qpoint-icon [icon]="dataItem.icon"></qpoint-icon>
        <span>{{ dataItem.text | translate }}</span>
      </a>
    </li>
  </ul>
</li>
